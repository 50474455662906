import {Injectable} from '@angular/core';
import {UsersApiClient} from "../clients/users-api/UsersApiClient";
import {Observable} from "rxjs";
import {MembershipDataListUsersResult} from "../clients/users-api/UsersApiClient.gen";
import transformJavaScript from "@angular-devkit/build-angular/src/tools/esbuild/javascript-transformer-worker";

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private userApiClient: UsersApiClient) {
  }

  retrieveMembershipsByProfileId(profileId: number): Observable<MembershipDataListUsersResult> {
    return this.userApiClient.retrieveMembershipsByUserId(profileId);
  }
}
