import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as qs from 'qs';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

export class DeviceFeatures {
  DeviceFeaturesHeading: string;
  DeviceFeature: DeviceFeature[];
  id: number;
  constructor(){
    this.DeviceFeaturesHeading = '';
    this.DeviceFeature = [];
    this.id = 0;
  }
}

export class DeviceFeature {
  FeatureName: string;
  FeatureHeading: TextSection[];
  FeatureDescription: TextSection[];
  FeatureIcon: any;
  id: number;
  constructor(){
    this.FeatureName = '';
    this.FeatureHeading = [];
    this.FeatureDescription = [];
    this.FeatureIcon = {} as any;
    this.id = 0;
  }
}

export class TextSection {
  public type: string;
  public children: InnerTextSection[];
  constructor(){
    this.type = '';
    this.children = [];
  }
}

export class InnerTextSection {
  public type: string;
  public text: string;
  constructor(){
    this.type = '';
    this.text = '';
  }
}

export interface DescriptionSection {
  ShortDeviceDescription: string;
  DeviceQuote: TextSection[];
  DeviceDescriptionPart1: TextSection[];
  DeviceDescriptionPart2: TextSection[];
}

export class DeviceInformationSection {
  DeviceInformationTitle: string;
  DeviceInformation: TextSection[];
  id: number;
  constructor(){
    this.DeviceInformationTitle = '';
    this.DeviceInformation = [];
    this.id = 0;
  }
}

export class DevicesData {
  public data: Attributes<Device>[];
  constructor(){
    this.data = [];
  }
}

export class DeviceData {
  public data: Attributes<Device>;
  constructor(){
    this.data = {} as Attributes<Device>;
  }
}

export class Attributes<T> {
  public attributes: T;
  public id: number;
  constructor(){
    this.attributes = {} as T;
    this.id = 0;
  }
}

export class Device {
  public DescriptionSection: DescriptionSection;
  public DeviceInformationSection: DeviceInformationSection[];
  public DeviceImage: any;
  public DeviceName: string;
  public DeviceFeatures: DeviceFeatures;
  public updatedAt: string;
  constructor(){
    this.DescriptionSection = {} as DescriptionSection;
    this.DeviceInformationSection = [];
    this.DeviceImage = {} as any;
    this.DeviceName = '';
    this.DeviceFeatures = new DeviceFeatures();
    this.updatedAt = '';
  }
}

@Injectable({
  providedIn: 'root'
})
export class StrapiDeviceService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

  public getStrapiDevices(deviceIndex?: number, fetchAll: boolean = false) {
    let url = `${environment.cmsBaseUrl}/api/devices`;
    if(deviceIndex){ url += `/${deviceIndex}`; }
    url += '?';

    const params = () => qs.stringify(
      fetchAll ? {
        populate: [
          "DeviceName",
          "DeviceImage",
          "DescriptionSection",
          "DescriptionSection.ShortDeviceDescription",
          "DeviceInformationSection",
          "DeviceInformationSection.DeviceInformationTitle",
          "DeviceInformationSection.DeviceInformation",
          "DeviceFeatures",
          "DeviceFeatures.DeviceFeature.FeatureName",
          "DeviceFeatures.DeviceFeature.FeatureIcon",
          "DeviceFeatures.DeviceFeature.FeatureDescription",
          "updatedAt"
        ], 
        locale: this.translate.currentLang
      } : {
        populate: [
          "DeviceName",
          "DeviceImage",
        ],
        locale: this.translate.currentLang
      }
    );

    url += params();

    return this.http.get<DevicesData | DeviceData>(url).pipe(
      map((devices: DevicesData | DeviceData) => {
        return devices;
      })
    )
  }
}
