<section class="w-screen overflow-x-hidden z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[7vw] 2xl:px-[4vw] px-7 md:px-10 pb-24 xl:pt-20 align-middle relative text-white">
  <div class="flex flex-col md:flex-row justify-end gap-[3rem] w-full mt-10">

    <!--    USER DATA-->
    <div class="flex flex-col items-center w-100 md:w-[35%] h-max">

      <!--      USER NAME AND SURNAME-->
      <div *ngIf="loggedInUser!==undefined" class="flex flex-row gap-5 w-full justify-between items-center">
        <div class="text-white ml-3 font-semibold relative">
          <img src="assets/images/profile%20icon.svg" class="scale-[1.7] text-center">
          <div
            class="centered profile-icon-text text-lg sm:text-2xl">{{loggedInUser!.name!.substring(0, 1) + '' + loggedInUser!.surname!.substring(0, 1)}}</div>
        </div>
        <div class="flex flex-col uppercase flex-grow">
          <div class="font-custom_bold text-base sm:text-xl">{{loggedInUser!.name!}}</div>
          <div class="font-custom_bold text-base sm:text-xl -mt-2">{{loggedInUser!.surname!}}</div>
        </div>
        <div class=" hover:text-fuchsia-600 hover:cursor-pointer" (click)="toggleEdit()">
          <span class="text-2xl material-symbols-outlined">edit</span>
        </div>

      </div>

      <!--      EMAIL AND PHONE NUMBER-->
      <div *ngIf="loggedInUser !== undefined"
           class="flex flex-col gap-2 sm:flex-row py-4 justify-between border border-transparent border-t-white border-b-white my-8 w-full">
        <div class="flex flex-row gap-2 items-center">
          <app-icon [materialIcon]="'email'" [color]="'brown'"></app-icon>
          <div class="text-sm">{{loggedInUser!.email!}}</div>
        </div>
        <div class="flex flex-row gap-2 items-center">
          <app-icon [materialIcon]="'phone'" [color]="'brown'"></app-icon>
          <div class="text-sm">{{loggedInUser!.phone!}}</div>
        </div>
      </div>

      <!--      MEMBERSHIPS-->
      <div class="w-full">
        <div *ngIf="userMemberships !== undefined && userMemberships.length !== undefined && userMemberships!.length === 0"
             class="text-sm font-custom_italic text-center">{{'profile.no-memberships' | translate}}
        </div>
        <div *ngIf="userMemberships !== undefined && userMemberships.length !== undefined && userMemberships!.length > 0"
             class="flex flex-col gap-4 justify-center items-start pb-8 md:pb-0 border border-transparent border-b-white md:border-b-transparent">

          <div class="flex flex-row w-full justify-between">
            <div class="font-custom_semibold text-base md:text-lg">{{'profile.memberships' | translate}}</div>
            <div class="md:hidden">
              <div class="text-white flex flex-col justify-center" (click)="toggleExpanded()">
                <span class="material-symbols-outlined hover:cursor-pointer" [ngClass]="{'rotate-180': expanded}">expand_more</span>
              </div>
            </div>
          </div>


          <div *ngIf="(expanded && screenWidth<1024) || screenWidth>=1024"
               class="flex flex-col overflow-y-auto section max-h-96 gap-3 full-width">
            <div *ngFor="let membership of userMemberships" class="full-width relative">
              <div [ngClass]="{'past-membership': !checkIfMembershipIsActive(membership)}"
                   class="w-full h-full z-40 absolute"></div>
              <div class="membership-background p-5 flex flex-col gap-4 relative full-width">
                <div class="flex flex-row justify-between items-center gap-4 w-full text-sm">
                  <div>{{membership.membershipTypeName}}</div>
                  <div *ngIf="checkIfMembershipIsActive(membership)"
                       class="flex flex-row gap-2 text-[0.7rem] items-center">
                    <div class="text-[#A4DD89] uppercase">{{"activate" | translate }}</div>
                    <div class="w-5 border h-0 border-[#A4DD89]"></div>
                  </div>
                  <div *ngIf="!checkIfMembershipIsActive(membership)" class="w-5 border h-0 border-[#914641]"></div>
                </div>
                <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row justify-between">
                  <div class="flex flex-col">
                    <div class="flex flex-row gap-2 3xl:gap-3 text-sm">
                      <div>{{"active from" | translate }}</div>
                      <div class="font-custom_semibold">{{membership.fromDate | date: 'dd.MM.YYYY'}}</div>
                    </div>
                    <div class="flex flex-row gap-2 3xl:gap-3 text-sm">
                      <div>{{"activate till" | translate }}</div>
                      <div class="font-custom_semibold">{{membership.toDate | date: 'dd.MM.YYYY'}}</div>
                    </div>
                  </div>
                  <div class="flex flex-row gap-2 3xl:gap-3 text-sm">
                    <div>{{"remaining sessions" | translate }}</div>
                    <div class="font-custom_semibold">{{membership.credits}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!--    BOOKINGS-->
    <div class="w-100 md:w-[65%]">

      <!--      TABS-->
      <div class="flex flex-row pb-4 justify-center mb-3 text-xs sm:text-base">
        <div class="w-1/2 hover:cursor-pointer text-center pb-3 px-5" (click)="changeTab(upcomingTerms)"
             [ngClass]="{'text-white border-2 border-t-transparent border-r-transparent border-l-transparent border-b-[#713ECF]': tabValue==upcomingTerms.valueOf(), 'text-stone-400 border border-transparent border-b-stone-400': tabValue==passedTerms.valueOf()}">
          {{"scheduled appointments" | translate }}
        </div>
        <div class="w-1/2 hover:cursor-pointer text-center pb-3 px-5" (click)="changeTab(passedTerms)"
             [ngClass]="{'text-white border-2 border-t-transparent border-r-transparent border-l-transparent border-b-[#713ECF]': tabValue==passedTerms.valueOf(), 'text-stone-400 border border-transparent border-b-stone-400': tabValue==upcomingTerms.valueOf()}">
          {{"passed_terms" | translate  }}
        </div>
      </div>

      <!--      BOOKINGS-->
      <div *ngIf="sortData !== undefined && sortData.length !== undefined && sortData.length > 0" class="flex flex-col gap-5">
        <div *ngFor="let term of sortData; index as i">
          <div
            class="card_generic flex flex-row gap-3 justify-between items-center pl-4 lg:pl-8 py-4 column relative h-max z-0"
            id="{{i}}">

            <div *ngIf="tabValue==1" class="visible-mobile -rotate-90 hover:cursor-pointer absolute top-3"
                 (click)="openOptions(i)">
              <img src="assets/images/more-icon.svg" class="scale-75">
            </div>

            <!--            SERVICE TITLE-->
            <div
              class="text font-custom_semibold text-[0.6rem] sm:text-sm lg:text-base w-full md:w-[55%] lg:w-3/5 pr-6 md:pr-2">{{term.serviceName}}</div>

            <!--              EXTRA CONTROLS-->
            <div *ngIf="displayOptions && displayOptionsForElement==i && screenWidth>=640"
                 style="z-index: 100 !important;"
                 class="p-5 bg-[#181422] text-xs text-white flex flex-col gap-4 absolute right-6 top-14 lg:top-12">

              <div class="flex flex-row gap-4 items-center uppercase hover:cursor-pointer hover:text-[#9f7ee1]"
                   (click)="redirectToEdit(term.id!)">
                <span class="text-xl material-symbols-outlined">edit</span>
                <span class="font-semibold">уреди</span>
              </div>
              <div class="flex flex-row gap-4 items-center uppercase hover:cursor-pointer hover:text-[#9f7ee1]"
                   (click)="openConfirmCancelPopUp(term.id!)">
                <span class="text-xl material-symbols-outlined">cancel</span>
                <span class="font-semibold">откажи</span>
              </div>

            </div>


            <!--            APPOINTMENT DATE AND TIME-->
            <div class="flex flex-row gap-5 w-full md:w-[45%] lg:w-2/5 justify-end align-left">
              <div class="flex flex-row gap-2 lg:gap-4 items-center justify-end expanded"
                   [ngClass]="{'mr-3':tabValue==2}">
                <div>
                  <app-icon [materialIcon]="'event_available'" [color]="'brown'"></app-icon>
                </div>
                <div
                  class="expanded w-full flex flex-col sm:flex-row gap-1 justify-between lg:gap-3 text text-xs sm:text-sm lg:text-base">
                  <div>{{term.fromDate | date: 'dd.MM.YYYY'}}</div>
                  <div>{{term.fromDate | date: 'HH:mm'}} {{"hour" | translate }}</div>
                </div>
              </div>

              <!--              EXTRA CONTROLS BUTTON-->
              <div *ngIf="tabValue==1" class="visibility-none -rotate-90 hover:cursor-pointer relative"
                   (click)="openOptions(i)" (clickOutside)="closeOptions(i)">
                <img src="assets/images/more-icon.svg" class="scale-75">
              </div>


            </div>


          </div>
        </div>
      </div>
      <div *ngIf="sortData !== undefined && sortData.length !== undefined && sortData.length === 0" class="text-center full-width font-xs sm:font-sm">{{"No data found" | translate }}
      </div>
    </div>

  </div>
</section>

<app-edit-user-pop-up *ngIf="editOpened" (close)="closePopUp()"
                      (submit)="updateUserData($event)" [userData]="loggedInUser"></app-edit-user-pop-up>

<app-confirm-cancel-popup *ngIf="showConfirmCancelPopUp" (close)="closePopUp()"
                          [numberOfTerms]="1" (submit)="onTermCanceled()"
                          [termId]="termToCancel"></app-confirm-cancel-popup>

<div *ngIf="displayOptions && screenWidth<640">

  <app-swipeable-modal
    [modal_state_function]="toggleModal"
    [backgdrop]="true"
    [custom_height]="100"
    [handle_overflow_input]="true"
    [down_swipe_limiter]="2"
    [opacity_modifier]=".2"
    (handleCloseEmitter)="handleModalClose()">
    <div class="h-max flex flex-col gap-5 px-8">
      <div class="flex flex-row gap-4 items-center uppercase hover:cursor-pointer hover:text-[#9f7ee1]"
           (click)="redirectToEdit(chosenTerm.id!)">
        <span class="text-xl material-symbols-outlined">edit</span>
        <span class="font-semibold">уреди</span>
      </div>
      <div class="w-full border border-white h-0"></div>
      <div class="flex flex-row gap-4 items-center uppercase hover:cursor-pointer hover:text-[#9f7ee1]"
           (click)="openConfirmCancelPopUp(chosenTerm.id!)">
        <span class="text-xl material-symbols-outlined">cancel</span>
        <span class="font-semibold">откажи</span>
      </div>
    </div>


  </app-swipeable-modal>
</div>

<app-spinner></app-spinner>

<svg class="scale-125 fixed top-0 left-0 right-0 bottom-0 z-[-2] object-cover" viewBox="0 0 1920 4468" fill="none"
     xmlns="http://www.w3.org/2000/svg">
  <path d="M-205.445 4998L1976.42 4998L1976.42 0.000381489L-205.444 0L-205.445 4998Z"
        fill="url(#paint0_linear_1509_1076)"/>
  <mask id="mask0_1509_1076" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-536" y="23" width="2579"
        height="3837">
    <path
      d="M1293.31 2757.65C1264.33 3318.94 236.363 3700.29 -285.402 3859.31L-535.999 23.0737L2042.42 23.0742C615.032 596.175 2.75839 1246.01 154.619 1606.61C406.099 2203.77 1320.37 2233.54 1293.31 2757.65Z"
      fill="url(#paint1_linear_1509_1076)"/>
  </mask>
  <g mask="url(#mask0_1509_1076)">
    <path
      d="M1293.31 2757.65C1264.33 3318.94 236.363 3700.29 -285.402 3859.31L-535.999 23.0737L2042.42 23.0742C615.032 596.175 2.75839 1246.01 154.619 1606.61C406.099 2203.77 1320.37 2233.54 1293.31 2757.65Z"
      fill="url(#paint2_linear_1509_1076)" fill-opacity="0.15"/>
  </g>
  <g filter="url(#filter0_b_1509_1076)">
    <path d="M-205 4056L1976 4056L1976 -0.000106943L-204.999 -0.000488281L-205 4056Z"
          fill="url(#paint3_linear_1509_1076)" fill-opacity="0.01"/>
  </g>
  <defs>
    <filter id="filter0_b_1509_1076" x="-215" y="-10.0005" width="2201" height="4076" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1509_1076"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1509_1076" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_1509_1076" x1="-397.015" y1="3320.55" x2="2295.58" y2="1668.27"
                    gradientUnits="userSpaceOnUse">
      <stop stop-color="#0D0B13"/>
      <stop offset="0.994419" stop-color="#09060E"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1509_1076" x1="685.864" y1="4048.73" x2="1146.81" y2="256.5"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.192708" stop-color="#1D142B" stop-opacity="0"/>
      <stop offset="0.484375" stop-color="#150F20"/>
      <stop offset="0.901042" stop-color="#0A0810" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1509_1076" x1="1821.87" y1="2483.44" x2="-1213.2" y2="2353.04"
                    gradientUnits="userSpaceOnUse">
      <stop stop-color="#1D142B"/>
      <stop offset="1" stop-color="#0A0810"/>
    </linearGradient>
    <linearGradient id="paint3_linear_1509_1076" x1="-205" y1="3212.49" x2="1375.33" y2="70.5834"
                    gradientUnits="userSpaceOnUse">
      <stop stop-color="#0D0B13"/>
      <stop offset="0.994419" stop-color="#09060E"/>
    </linearGradient>
  </defs>
</svg>
