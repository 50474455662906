import {Component, ComponentRef, Input, NgZone, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { AutoplayOptions, CardsEffectOptions, NavigationOptions, PaginationOptions, SwiperEvents } from 'swiper/types';
import { SwiperDirective, SwiperEvent } from 'src/app/directives/swiper.directive';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ProductCardData, ProductType } from 'src/app/pages/our-products-pages/our-products-cards/our-products-cards.component';

export enum SwiperStyleEnum {
  Coverflow,
  Card,
}

enum SwiperDirection {
  Next,
  Previous,
}

@Component({
  selector: 'app-streams-swiper',
  standalone: false,
  templateUrl: './streams-swiper.component.html',
  styleUrls: ['./streams-swiper.component.css']
})
export class StreamsSwiperComponent implements OnDestroy {
  @Input() autoClickOnTransitionEnd: boolean = false;
  
  @Input() swiperStyle?: SwiperStyleEnum = SwiperStyleEnum.Coverflow;
  @Input() autoPlay?: AutoplayOptions | boolean;
  @Input() pagination?: PaginationOptions | boolean;
  @Input() navigation?: NavigationOptions | boolean = true;
  @Input() loop?: boolean = true;
  @Input() centeredSlides?: boolean;
  @Input() updateOnWindowResize?: boolean;
  @Input() initialSlide?: number;
  @Input() setWrapperSize?: boolean;
  @Input() autoHeight?: boolean;
  @Input() grabCursor?: boolean;
  @Input() cardsEffect?: CardsEffectOptions;
  @Input() breakpoints?: { [width: number]: SwiperOptions; [ratio: string]: SwiperOptions; };
  @Input() on?: { [event in keyof SwiperEvents]?: SwiperEvents[event]; };

  @ViewChild(SwiperDirective) swiperDirective!: SwiperDirective;
  @Input() slideElements?: HTMLElement[] = [];
  slideReferences?: ProductCardData[];
  sanitizedSlideContents: SafeHtml[] = [];
  slideChangedSubscription?: Subscription;
  previousSlideIndex: number = 0;
  higlightedProducts: number = 0;
  ourProductsCount: number = 0;

  static readonly clickableElementClass: string = 'clickable-swiper-element';
  static readonly clickableElementClassDelimiter: string = '-';

  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer, private ngZone: NgZone){ }

  ngOnDestroy() {
    if (this.slideChangedSubscription) {
      this.slideChangedSubscription.unsubscribe();
    }
  }

  public generateSlides(slides?: ProductCardData[], index?: number) {
    this.slideElements = slides?.map(slide => slide.productCard.location.nativeElement) ?? this.slideElements;
    this.slideReferences = slides;

    if(this.slideReferences && this.slideReferences.length > 0){
      this.higlightedProducts = this.slideReferences.filter(slide => slide.productType === ProductType.HighlightedProduct).length;
      this.ourProductsCount = this.slideReferences.filter(slide => slide.productType === ProductType.OurProduct).length;
    }

    this.slideElements?.forEach((slide, _index) => {
      const divElement = this.renderer.createElement('div');

      const classList = `${StreamsSwiperComponent.clickableElementClass}${StreamsSwiperComponent.clickableElementClassDelimiter}${_index} 
      absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full rounded-[1.5rem] rounded-[inherit]`;
      this.renderer.setAttribute(divElement, 'class', classList);

      const sanitizedHTML: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`<div class="relative cursor-pointer"> ${slide.outerHTML} ${divElement.outerHTML} </div>`);
      this.sanitizedSlideContents.push(sanitizedHTML);
    });

    const config: SwiperOptions = {
      pagination: this.pagination ?? {
        clickable: true, 
        dynamicBullets: true
      },
      navigation: {
        enabled: true,
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      centeredSlides: this.centeredSlides ?? true,
      updateOnWindowResize: this.updateOnWindowResize ?? true,
      initialSlide: index ?? 0,
      setWrapperSize: this.setWrapperSize ?? true,
      loop: this.loop ?? true,
      preventClicks: false,
      preventClicksPropagation: true,
      grabCursor: this.grabCursor ?? true,
      slidesPerView: "auto",
      freeMode: false,
      autoplay: this.autoPlay ?? {
        delay: 5000,
        waitForTransition: true,
        disableOnInteraction: true,
        reverseDirection: false
      },
      on: this.on ?? {
        init() {
        },
      },
    };

    this.previousSlideIndex = index ?? 0;

    switch(this.swiperStyle){
      case SwiperStyleEnum.Card: 
        config.effect = 'cards';
        config.cardsEffect = this.cardsEffect ?? {
          slideShadows: false,
          rotate: false,
          perSlideOffset: 60,
        };
      break;
      case SwiperStyleEnum.Coverflow:
        config.effect = 'coverflow';
        config.coverflowEffect = {
          rotate: 0,
          stretch: 0,
          depth: 150,
          modifier: 2.5,
          slideShadows: false,
        }
    }  

    this.swiperDirective.generateSlides(config);

    if(this.autoClickOnTransitionEnd){    
      this.ngZone.runOutsideAngular(() => {
        this.slideChangedSubscription = this.swiperDirective.slideChanged.subscribe((swiperEvent: SwiperEvent) => {
          if(swiperEvent.swiper !== undefined && swiperEvent.slide !== undefined){
            let childElementGroup_1 = swiperEvent.slide?.children;
            if (childElementGroup_1 !== undefined && childElementGroup_1.length > 0) {
              let childElementGroup_2 = childElementGroup_1[0].children;
              if (childElementGroup_2 !== undefined && childElementGroup_2.length > 1) {
                let clickableElement = childElementGroup_2[1];
                if (clickableElement !== undefined) {
                  var slideIndex: string = clickableElement.classList[0].split(StreamsSwiperComponent.clickableElementClass + StreamsSwiperComponent.clickableElementClassDelimiter)[1];
                  if (slideIndex !== undefined && slideIndex.length > 0 && !isNaN(Number(slideIndex)) && this.slideReferences && this.slideReferences[Number(slideIndex)]) {
                    var slideNumber = Number(slideIndex);
                    this.previousSlideIndex = slideNumber;
                    this.slideReferences[slideNumber].productCard.instance.click();
                  }
                }
              }
            }
          }
        });
      });
    }
  }

  public async slideClicked(event: Event): Promise<void> {
    var clickedElement = event.target as HTMLElement;
    var slideIndex: string = clickedElement.classList[0].split(StreamsSwiperComponent.clickableElementClass + StreamsSwiperComponent.clickableElementClassDelimiter)[1];  
    if(slideIndex !== undefined && slideIndex.length > 0 && !isNaN(Number(slideIndex))
      && this.slideReferences && this.slideReferences[Number(slideIndex)]){
      this.slideReferences[Number(slideIndex)].productCard.instance.click();

      let diff = (this.previousSlideIndex+1) - (Number(slideIndex)+1);
      let slideChangeModifier: SwiperDirection = diff > 0 ? SwiperDirection.Previous : SwiperDirection.Next;
      slideChangeModifier = Math.abs(diff) <= Math.floor((this.slideElements?.length ?? 1) / 2) ? slideChangeModifier : (slideChangeModifier === SwiperDirection.Next ? SwiperDirection.Previous : SwiperDirection.Next);

      if(Math.abs(diff) > 0){
        for(let i = 0; i < Math.min((Math.floor((this.slideElements?.length ?? 1) / 2)), Math.abs(diff)); i++){
          new Promise<void>(async (resolve) => {
            if(slideChangeModifier === SwiperDirection.Previous){ await this.previousSlide(); }
            else{ await this.nextSlide(); }
            resolve();
          });
        }
      }

      this.previousSlideIndex = Number(slideIndex);
    }
  }

  public async nextSlide(): Promise<void> {
    if(this.swiperDirective){
      new Promise<void>((resolve) => {
        this.swiperDirective.nextSlide();
        this.previousSlideIndex = this.previousSlideIndex + 1;
        this.previousSlideIndex = this.previousSlideIndex % (((this.slideElements?.length ?? 1) - 1) ?? 0);
        resolve();
      });
    }
  }

  public async previousSlide(): Promise<void>{
    if(this.swiperDirective){
      new Promise<void>((resolve) => {
        this.swiperDirective.previousSlide();
        this.previousSlideIndex = this.previousSlideIndex - 1;
        this.previousSlideIndex = this.previousSlideIndex % (((this.slideElements?.length ?? 1) - 1) ?? 0);
        resolve();
      });
    }
  }
}
