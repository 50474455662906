import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";
import {LoginRequestData, SecurityResultStatus, VerificationCodeData} from "../../../clients/users-api/UsersApiClient.gen";
import {FormControl, Validators} from "@angular/forms";
import { UserProfileDto } from 'src/app/models/UserProfileDto';
import {ToastrService} from "ngx-toastr";
import {SessionService} from "../../../services/session.service";
import {PopUpTypeEnum} from "../../../enums/PopUpTypeEnum";
import {UserService} from "../../../services/user.service";
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.css']
})
export class VerificationCodeComponent {
  verificationCodeInput: FormControl = new FormControl<any>('', Validators.required)
  showError: boolean = false;
  codeExpired: boolean = false;
  submitted: boolean = false;
  showEmailInput: boolean = false;

  @Input() verificationCode: VerificationCodeData = new VerificationCodeData()
  @Input() userEmail: string = ''
  @Input() userPassword: string = ''
  @Output() codeVerified = new EventEmitter<PopUpTypeEnum>()
  @Output() closePopUp = new EventEmitter<void>()

  constructor(private authenticationService: AuthenticationService,
              private userService: UserService,
              private toasterService: ToastrService,
              private sessionService: SessionService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    if (this.userEmail != '')
      this.authenticationService.retrieveVerificationCodeByUserEmail(this.userEmail).subscribe(result => {
        if (result.status == SecurityResultStatus.Success) {
          this.verificationCode.id = result.content!;
        } else {

        }
      })
  }

  getInputStyling() {
    return {
      'focus:border-2 focus:border-b-white': !this.verificationCodeInput?.dirty && !this.submitted && this.verificationCodeInput?.invalid,
      'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (this.verificationCodeInput?.dirty || this.submitted) && this.verificationCodeInput?.invalid,
      'border border-b-white text-white': this.verificationCodeInput?.valid
    };
  }

  verifyCode() {
    this.submitted = true;

    if (this.verificationCodeInput.value == '') {
      this.showError = true;
      return;
    }

    this.authenticationService.verifyEmail(this.verificationCode.id!, this.verificationCodeInput.value!).subscribe(result => {
      if (result.status == SecurityResultStatus.Success) {
        this.showError = false;
        this.codeExpired = false;

        let userData = {
          username: this.userEmail,
          password: this.userPassword
        } as LoginRequestData

        this.authenticationService.login(userData).subscribe(result => {
          if (result.status == SecurityResultStatus.Success) {

            this.userService.retrieveUserDetailByEmail(userData.username!).subscribe(result => {
              let userProfile = {
                username: result.content?.username,
                name: result.content?.name,
                surname: result.content?.surname,
                phone: result.content?.phone
              } as UserProfileDto

              this.sessionService.setProfile(userProfile!)
              this.toasterService.success(this.translate.instant('Login successful'), '', {positionClass: 'toast-top-center', timeOut: 5000})
              this.codeVerified.emit();
            })

          }
        })
      } else if (result.status == SecurityResultStatus.InvalidVerificationCode) {
        this.showError = true;
        this.codeExpired = false;
      } else if (result.status == SecurityResultStatus.VerificationCodeExpired) {
        this.codeExpired = true;
        this.showError = false;
      } else {
        this.toasterService.error(this.translate.instant('An error occurred') + '!', '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
        this.closePopUp.emit();
      }
    })
  }

  sendNewLink() {
    this.showEmailInput = true;
    this.authenticationService.generateResetPasswordCode(this.userEmail).subscribe(result => {
      if (result.status == SecurityResultStatus.Success) {
        this.toasterService.success(this.translate.instant('new code sent to your email'), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
        this.verificationCode.id = result.content;
        //TODO: Hide 'send new link' button for a period of time
      }
    })
  }

}
