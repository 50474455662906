import {Component, Input} from '@angular/core';
import {ServiceCategoryData} from "../../../clients/users-api/UsersApiClient.gen";
import {ServicesService} from "../../../services/services.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-price-list-section',
  templateUrl: './price-list-section.component.html',
  styleUrls: ['./price-list-section.component.css']
})
export class PriceListSectionComponent {

  @Input() serviceCategory: ServiceCategoryData | undefined;
  @Input() shadow: number = 0
  height: number | undefined
  image: any

  constructor() { }

  ngOnInit() {
    this.image = ("data:image/png;base64,"+this.serviceCategory?.image)
    this.getHeight();
  }


  getHeight(){
    this.height = document.getElementById('element0')?.clientHeight;
  }

}
