<!--      TITLE-->
<h2 *ngIf="!hideTitle" class="md:text-[1.5rem] text-wrap text-center text-white font-semibold mb-4 text-[18px]">
  {{ determineAction() == 0 ? ('actions.set-password' | translate) : "actions.password-reset" | translate}}
</h2>

<!--      PASSWORD FORM-->
<form [formGroup]="passwordForm" class="flex flex-col gap-5">

  <input *ngIf="determineAction()==1" type="password" autocomplete="on" name="password_1" placeholder="{{'actions.old-password' | translate}}"
         [ngClass]="getStyling('oldPassword')"
         formControlName="oldPassword" (input)="checkPasswords($event)"
         class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

  <input type="password" autocomplete="on" name="password_2" placeholder="{{'actions.password' | translate}}" [ngClass]="getStyling('password')"
         formControlName="password"
         class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

  <input *ngIf="determineAction()==0 || determineAction()==2" type="password" autocomplete="on" name="password_3" placeholder="{{'actions.confirm-password' | translate}}"
         [ngClass]="getStyling('confirmPassword')"
         formControlName="confirmPassword" (input)="checkPasswords($event)"
         class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

  <div *ngIf="showPasswordsDontMatchError" class="mt-2">
    <app-status-indicator [message]="'register.passwords-dont-match' | translate"></app-status-indicator>
  </div>
  <div [ngClass]="{'mt-2': !showPasswordsDontMatchError, '-mt-2': showPasswordsDontMatchError}">
    <button (click)="onSubmit()"
            class="bg-[#9f7ee1] kin-primary w-full text-white px-4 py-2 rounded text-xs md:text-sm">{{determineAction() == 0 ? ("actions.register" | translate) : ("actions.reset" | translate) }}</button>
  </div>

</form>
