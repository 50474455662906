<app-svg-load-morph> </app-svg-load-morph>
<app-spinner> </app-spinner>

<section class="overflow-hidden z-[9]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 lg:px-10 px-2
justify-center align-middle page-height flex flex-col items-center">

  <div class="text-white mt-[2rem] flex flex-col flex-grow justify-center w-full items-center">

    <div
      class="h-75 card_generic p-3 sm:p-5 lg:p-5 2xl:p-18 border-radius-20 m-auto">

      <!--      CONTENT-->
      <div class="flex flex-col justify-between gap-8 xl:gap-20 w-full">

        <!--            BOOKING SUMMARY-->
        <div class="flex flex-col items-center gap-4 text-left w-full">

          <div
            class="uppercase font-bold text-sm md:text-lg text-center">{{'booking-confirmation-component.title' | translate}}</div>
          <img class="w-40 h-40" src="assets/images/success_icon.png">

          <!--          SERVICE TITLE-->
          <div class="flex flex-row w-full justify-center items-center">
            <div
              class="text-sm lg:text-base md:leading-1 font-semibold uppercase text-center">{{bookingData.serviceName}}</div>
          </div>

          <!--          SERVICE PRICE AND DURATION-->
          <app-appointment-info [serviceDuration]="bookingData.serviceDuration!"
                                [servicePrice]="bookingData.servicePrice!"
                                [employeeName]="employeeName" class="full-width"></app-appointment-info>


          <!--          APPOINTMENTS-->
          <div class="flex flex-col gap-3 pb-4 border border-transparent border-b-white"
               style="width: -webkit-fill-available">
            <!--            TODO: Translate-->
            <div class="text-xs lg:text-sm uppercase font-semibold">{{"booked" | translate }} {{bookingData.terms!.length > 1 ? 'и' : ''}}
              {{"term" | translate }}{{bookingData.terms!.length > 1 ? 'и' : ''}}</div>
            <div class="flex flex-col gap-3 md:max-h-40 md:overflow-y-auto section ">
              <div *ngFor="let appointment of bookingData.terms!;index as i">
                <div class="flex flex-row w-100">
                  <div class="w-5/7 flex flex-row p-3 gap-4 items-center card_generic pl-2 py-2 flex-grow">
                    <div class="w-1/7">
                      <app-icon [materialIcon]="'event_available'" [color]="'brown'"></app-icon>
                    </div>
                    <div
                      class="to-column w-full flex flex-row gap-2 justify-between lg:gap-5 text text-xs sm:text-base md:text-sm lg:text_base">
                      <div>{{appointment.fromDate | date: 'dd.MM.YYYY'}}</div>
                      <div>{{appointment.fromDate | date: 'HH:mm'}} {{"hour" | translate }}</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="border border-white border-opacity-25 rounded-lg text-center p-1 text-xs md:text-sm"
                 style="word-wrap: break-word">
              <div>{{bookingData!.bookingToken}}</div>
            </div>
          </div>

          <!--          CLIENT DATA-->
          <div class="to-column flex flex-row w-full border border-transparent border-b-white pb-4">
            <div class="w-full text-xs lg:text-sm uppercase font-semibold">{{"customer data" | translate }}</div>
            <div class="w-full flex flex-col gap-2 items-end align-left-mobile">
              <div
                class="text-xs lg:text-sm text-skin-primary-light">{{bookingData.name}}  {{bookingData.surname}}</div>

              <div class="flex flex-col text-xs xl:text-base gap-2 lg:text-sm">
                <div class="flex flex-row justify-end w-full text-left align-left-mobile">
                  <div class="mr-2">
                    <span class="material-symbols-outlined text-sm sm:text-lg">email</span>
                  </div>
                  <div>{{bookingData.anonymousEmail}}</div>
                </div>
                <div class="flex flex-row justify-end w-full text-left align-left-mobile">
                  <div class="mr-2">
                    <span class="material-symbols-outlined text-sm sm:text-lg">phone</span>
                  </div>
                  <div>{{bookingData.phone}}</div>
                </div>
              </div>

            </div>

          </div>

          <!--          ACTION BUTTONS-->
          <div class="full-width">
            <div class="flex flex-col sm:flex-row w-100 justify-between gap-2 sm:gap-5">
              <button (click)="editBooking()" [disabled]="showCancelConfirmation"
                      class="w-full sm:w-1/2 btn btn-sm deep-purple-button text-white text-xs xl:text-sm font-semibold self-end p-2 rounded-md select-none shadow-md uppercase">
                {{'actions.edit' | translate}}
              </button>

              <button (click)="cancelBooking()" [disabled]="showCancelConfirmation"
                      class="w-full sm:w-1/2 btn btn-sm cancel-button text-white text-xs xl:text-sm font-semibold self-end p-2 rounded-md select-none shadow-md uppercase">
                {{'booking-confirmation-component.cancelBooking' | translate}}
              </button>
            </div>

          </div>


        </div>

      </div>

    </div>
  </div>

  <div class="relative bottom-0 w-full bg-transparent flex flex-row justify-center py-10 px-5 text-xs md:text-sm text-white/[0.6]">
    Vacu Activ Skopje &#64; All Rights Reserved 2023
  </div>
  
</section>

<ng-container *ngIf="showCancelConfirmation">
  <app-confirm-cancel-popup (close)="closePopUp()" [termId]="bookingData!.terms![0].id!" [numberOfTerms]="bookingData!.terms!.length"></app-confirm-cancel-popup>
</ng-container>

<app-svg-bg-animation> </app-svg-bg-animation>


  

