<section class="overflow-hidden z-[9]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 lg:px-10 px-7
justify-center align-middle page-height flex flex-col items-center">

<div class=" flex flex-col w-full h-full gap-2 my-[2rem]">
  <div class="text-white flex flex-row flex-grow justify-center w-full items-center mt-[-1rem]">
    <div class="h-75 sm:w-1/2 md:w-3/4 card_generic p-3 sm:p-10 lg:p-20 border-radius-20 m-auto">
      <div class="flex flex-col-reverse lg:flex-row justify-between gap-8 lg:gap-32 w-full">

        <!--            CONTACT MESSAGE-->
        <div class="flex flex-col gap-2 xl:gap-5 text-left w-full lg:w-1/2">

          <!--          TITLE-->
          <div class="font-bold text-base lg:text-xl text-white heading sm:mb-1 lg:mb-4 uppercase self-center md:self-auto mt-[1rem] md:mt-auto">
            {{'contact-page.call-to-action' | translate}}
          </div>

          <!--          DESCRIPTION-->
          <div class="text-xs lg:text-sm text-white leading-1 text-center md:text-left">
            {{'contact-page.description' | translate}}
          </div>

          <!--          USER FORM-->
          <form [formGroup]="contactForm!" class="flex flex-col gap-3 lg:gap-5 my-[1rem]" (submit)="sendMessage()">

            <!--            NAME INPUT-->
            <input placeholder="{{'actions.yourName' | translate}}"
                   [formControlName]="'name'" type="text"
                   [ngClass]="{'focus:border-2 focus:border-b-white' : ((!contactForm.get('name')!.dirty && !submitted) || !contactForm.get('name')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (contactForm.get('name')!.dirty || submitted) && contactForm.get('name')!.invalid, 'border border-b-white': contactForm.get('name')!.valid}"
                   class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

            <!--            EMAIL INPUT-->
            <input placeholder="{{'actions.yourEmail' | translate}}"
                   formControlName="email" type="text"
                   [ngClass]="{'focus:border-2 focus:border-b-white' : ((!contactForm.get('email')!.dirty && !submitted) || !contactForm.get('email')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (contactForm.get('email')!.dirty || submitted) && contactForm.get('email')!.invalid, 'border border-b-white': contactForm.get('email')!.valid}"
                   class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

            <!--            MESSAGE INPUT-->
            <textarea rows="3" placeholder="{{'actions.yourMessage' | translate}}"
                      formControlName="message" type="text"
                      [ngClass]="{'focus:border-2 focus:border-b-white' : ((!contactForm.get('message')!.dirty && !submitted) || !contactForm.get('message')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (contactForm.get('message')!.dirty || submitted) && contactForm.get('message')!.invalid, 'border border-b-white': contactForm.get('message')!.valid}"
                      class="max-h-[12rem] min-h-[3rem] text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"></textarea>

            <div class="flex items-center mt-[2rem]">
              <h4 class="mr-3 text uppercase">{{'contact-page.button' | translate}}</h4>
              <hr class="w-10 md:w-12 h-0.5 bg-[#bdacc5] border-0">
              <button class="w-10 h-10 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110"><img
                src="assets/images/button-arrow.svg" alt="send-email" type="submit"></button>
            </div>
          </form>

        </div>


        <!--            CONTACT INFO AND WORKING HOURS-->
        <div class="w-full lg:w-1/2">
          <div class="flex flex-col sm:justify-between items-start md:flex-row lg:flex-col w-full text-left">

            <!--            CONTACT INFO-->
            <div class="w-full mt-[1rem] flex flex-col items-center md:items-start md:mt-auto">
              <div class="font-bold text-base lg:text-xl text-white heading uppercase text-center md:text-left">{{'contact-page.title' | translate}}</div>

              <div class="">
                <div class="flex flex-col gap-3 text-xs md:text-sm xl:text-base mt-[1rem] md:mt-5">

                  <div class="flex flex-row w-full justify-center md:justify-start">

                    <div class="mr-2">
                      <span class="material-symbols-outlined text-sm sm:text-lg">location_on</span>
                    </div>

                    <div class="flex flex-row w-full gap-2 text">
                      <strong>{{'contact-page.address' | translate}}:</strong>
                      <a href="https://maps.app.goo.gl/s2t1BkYVxGnuuJCh8" target="_blank" class="text-sm">{{"address" | translate }}</a>
                    </div>
                  </div>

                  <div class="flex flex-row w-full justify-center md:justify-start">
                    <div class="mr-2">
                      <span class="material-symbols-outlined text-sm sm:text-lg">phone</span>
                    </div>
                    <div class="flex flex-row gap-2 text">
                      <strong>{{'actions.phoneNum' | translate}}:</strong>
                      <a [href]="'tel:' + phoneNumber" class="text-sm">{{"compnay_phone_number" | translate}}</a>
                    </div>
                  </div>

                  <div class="flex flex-row  w-full justify-center md:justify-start">
                    <div class="mr-2">
                      <span class="material-symbols-outlined text-sm sm:text-lg">email</span>
                    </div>
                    <div class="flex flex-row gap-2 text">
                      <strong>{{'contact-page.mail' | translate}}:</strong>
                      <a [href]="'mailto:' + companyMail" class="text-sm">{{"company_mail" | translate}}</a>
                    </div>
                  </div>

                </div>
              </div>


            </div>

            <!--WORKING HOURS-->
            <div class="w-full mt-[1rem] md:mt-auto flex flex-col gap-3" *ngIf="sortedWorkingHours !== undefined && sortedWorkingHours.length !== undefined && sortedWorkingHours!.length > 0">
              <div class="mt-5 md:mt-0 lg:mt-10 flex flex-row gap-5 items-center">
                <div class="text-base lg:text-xl font-semibold heading uppercase w-full text-center md:text-left">{{'contact-page.working-hours' | translate}}</div>
                <div class="hidden md:flex border-b border-white w-2/3"></div>
              </div>

              <div class="mt-2 md:mt-5 flex flex-row w-full gap-3 xl:gap-5">

                <div class="grid gap-[1rem] w-full" 
                [ngClass]="{
                  'grid-cols-1': sortedWorkingHours !== undefined && sortedWorkingHours.length !== undefined && sortedWorkingHours!.length <= 1, 
                  'grid-cols-2': sortedWorkingHours !== undefined && sortedWorkingHours.length !== undefined && sortedWorkingHours!.length == 2, 
                  'grid-cols-3': sortedWorkingHours !== undefined && sortedWorkingHours.length !== undefined && sortedWorkingHours!.length > 2}">
                  <div *ngFor="let group of sortedWorkingHours" class="w-full mini-text border border-white/30 text-center rounded-lg py-2 lg:py-5 px-3 md:px-5 lg:px-8 flex flex-col gap-2 items-center text-xs lg:text-sm">
                   
                    <div class="flex flex-row justify-center gap-2 w-1/2 font-semibold uppercase text-center">
                      {{ translate("DaysOfWeek." + group[0].dayOfWeek?.toString()) }}
                      <div class="flex flex-row gap-2" *ngIf="group.length > 1">
                        <p>-</p>
                        <p>{{translate("DaysOfWeek." + group[group.length - 1].dayOfWeek?.toString())}} </p>
                      </div>
                    </div>

                    <div>
                      <p>
                        {{ group[0].fromTime | date : "HH:mm" }} -
                        {{ group[group.length - 1].toTime | date : "HH:mm" }}
                      </p>
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</section>

<app-footer></app-footer>

<app-svg-bg-animation> </app-svg-bg-animation>

