import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  ServiceDataPaginationDataUsersResult,
  ServiceDataUsersResult
} from '../clients/users-api/UsersApiClient.gen';

import {UsersApiClient} from '../clients/users-api/UsersApiClient';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(private usersApiClient: UsersApiClient) {
  }

  retrieveAllServices(): Observable<ServiceDataPaginationDataUsersResult> {
    return this.usersApiClient.retrieveAllServices();
  }

  retrieveAllServicesByCategoryId(categoryId: number | undefined): Observable<ServiceDataPaginationDataUsersResult> {
    return this.usersApiClient.retrieveAllServicesByCategoryId(categoryId, false);
  }

  retrieveServiceById(serviceId: number | undefined): Observable<ServiceDataUsersResult> {
    return this.usersApiClient.retrieveServiceById(serviceId);
  }

}
