import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { HighlightedProduct } from '../../highlighted-products-page/highlighted-products.component';
import { HighlightedProductsService } from 'src/app/services/highlighted-products.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vibra-shape',
  templateUrl: './vibra-shape.component.html',
  styleUrls: ['./vibra-shape.component.css']
})
export class VibraShapeComponent implements OnInit, HighlightedProduct {
  product: HighlightedProduct | undefined;

  productId: string = '4'; 
  backdropPath: string = 'assets/images/vibrashape_bg.jpg'; 
  
  readMore_1: boolean = false;

  treatmentItems:string[] = [
    "vibra-shape-page.treatment-1",
    "vibra-shape-page.treatment-2",
    "vibra-shape-page.treatment-3",
    "vibra-shape-page.treatment-4",
    "vibra-shape-page.treatment-5",
    "vibra-shape-page.treatment-6",
  ]

  constructor(private highlightedProductsService: HighlightedProductsService) { }

  ngOnInit(): void {
    if(this.highlightedProductsService){
      this.product = this.highlightedProductsService.getProductById(this.productId);
      this.highlightedProductsService.addProduct(this);
    }
  }

  toggleReadMore1() { 
    this.readMore_1 = !this.readMore_1;
  }
}
