<section class="page-height w-screen overflow-x-hidden z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 md:px-[8.625rem] ml:px-[7rem] px-5 sm:px-5 md:pb-40 xl:pt-20 align-middle relative">
    <div class="text-white flex flex-col flex-grow justify-center w-full items-center ">
        <div class="h-75 max-w-[40rem] w-[40rem] card_generic p-3 sm:p-5 lg:p-10 2xl:p-18 border-radius-20 m-auto flex flex-col gap-5 text-center">
            <img
                src="assets/images/logo.svg"
                class="w-[10rem] mx-auto"
                alt="VacuActiv Logo"
              />
            <h1 class="mx-auto text-[1.75rem]">{{"change password" | translate }}</h1>
            <form class="w-[30rem] mx-auto" [formGroup]="changePasswordForm" (submit)="onSubmit()">
              <div class="flex flex-col gap-3">
                <input placeholder="{{'Верификациски код' | translate}}"
                   [formControlName]="'verification_code'" type="text"
                   [ngClass]="{'focus:border-2 focus:border-b-white' : ((!changePasswordForm.get('verification_code')!.dirty) || !changePasswordForm.get('verification_code')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (changePasswordForm.get('verification_code')!.dirty) && changePasswordForm.get('verification_code')!.invalid, 'border border-b-white': changePasswordForm.get('verification_code')!.valid}"
                   class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">
                   <input placeholder="Нова лозинка"
                   [formControlName]="'newPassword'" type="password" name="password_1" autocomplete="on"
                   [ngClass]="{'focus:border-2 focus:border-b-white' : ((!changePasswordForm.get('newPassword')!.dirty) || !changePasswordForm.get('newPassword')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (changePasswordForm.get('newPassword')!.dirty) && changePasswordForm.get('newPassword')!.invalid, 'border border-b-white': changePasswordForm.get('newPassword')!.valid}"
                   class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

                   <input placeholder="Потврди нова лозинка"
                   [formControlName]="'confirmPassword'" type="password" name="password_2" autocomplete="on"
                   (input)="checkPasswords($event)"
                   [ngClass]="{'focus:border-2 focus:border-b-white' : ((!changePasswordForm.get('confirmPassword')!.dirty) || !changePasswordForm.get('confirmPassword')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (changePasswordForm.get('confirmPassword')!.dirty) && changePasswordForm.get('confirmPassword')!.invalid, 'border border-b-white': changePasswordForm.get('confirmPassword')!.valid}"
                   class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

                   <div *ngIf="showPasswordsDontMatchError" class="mt-2">
                    <app-status-indicator [message]="'register.passwords-dont-match' | translate"></app-status-indicator>
                  </div>
              </div>
              <div class="flex gap-5 justify-center items-center ">
            <button type="submit"
            [disabled]="changePasswordForm.invalid || showPasswordsDontMatchError"
            [ngClass]="{
              'opacity-50': changePasswordForm.invalid || showPasswordsDontMatchError
            }"
              class="btn btn-sm justify-center bg-primary_gradient mt-6 text-white text-xs lg:text-sm sm:font-semibold  p-2 rounded-md w-1/2  select-none shadow-md">
              {{ ('change password' | translate) | titlecase }}
            </button>
              </div>
            </form>
          <!--      SEND NEW LINK-->
          <div class="flex justify-center items-center">
            <div class="text-xs text-center mt-2 text-[#9f7ee1] hover:cursor-pointer w-[14rem]"
      (click)="sendNewLink()">{{"Submit a new code" | translate }}</div>
        </div>
          </div>
    </div>
</section>



<app-svg-bg-animation> </app-svg-bg-animation>
