import {Injectable} from '@angular/core';
import {UsersResultStatus} from "../clients/users-api/UsersApiClient.gen";
import {Observable} from "rxjs";
import {UsersApiClient} from "../clients/users-api/UsersApiClient";

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private userApiClient: UsersApiClient) {
  }

  sendMail(body: string | undefined): Observable<UsersResultStatus> {
    return this.userApiClient.sendMessage(body);
  }
}
