import {Injectable} from '@angular/core';
import {UsersApiClient} from "../clients/users-api/UsersApiClient";
import {
  ChangePasswordRequestData, Int32SecurityResult,
  LoginRequestData,
  RegistrationData, SecurityResultStatus, StringSecurityResult, UserProfileDataUsersResult,
  UsersResultStatus, VerificationCodeDataUsersResult,
} from "../clients/users-api/UsersApiClient.gen";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _isLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(private userApiClient: UsersApiClient) {
  }

  set(value: boolean) {
    this._isLoggedIn.next(value);
  }

  get isLoggedIn() {
    return this._isLoggedIn.asObservable();
  }

  login(loginRequestData: LoginRequestData) {
    return this.userApiClient.login(loginRequestData);
  }

  register(registerData: RegistrationData): Observable<VerificationCodeDataUsersResult> {
    return this.userApiClient.register(registerData);
  }

  checkForExistingUsername(username: string): Observable<UsersResultStatus> {
    return this.userApiClient.checkForExistingUsername(username);
  }

  verifyEmail(key: number, code: string): Observable<StringSecurityResult> {
    return this.userApiClient.verifyEmail(key, code);
  }

  signOut(): Observable<SecurityResultStatus> {
    return this.userApiClient.signout();
  }

  resetPassword(changePasswordRequestData: ChangePasswordRequestData): Observable<SecurityResultStatus> {
    return this.userApiClient.changePassword(changePasswordRequestData);
  }

  generateResetPasswordCode(email: string): Observable<Int32SecurityResult> {
    return this.userApiClient.generateResetPasswordCode(email);
  }

  retrieveVerificationCodeByUserEmail(email: string): Observable<Int32SecurityResult> {
    return this.userApiClient.getVerificationCodeByUserEmail(email);
  }

  resetPasswordWithCode(key: number, code: string, newPassword: string): Observable<SecurityResultStatus> {
    return this.userApiClient.resetPasswordWithCode(key, code, newPassword);
  }

}
