import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent {

  @Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
  })

    //verified: boolean;
    verified!: number;
    key!: number;
    code!: string;
    email!: string;
    baseUrl = "";

    constructor(private route: ActivatedRoute,
      private router: Router,
        private translate: TranslateService,) {
        translate.setDefaultLang('mk');
        translate.addLangs(['mk', 'al']) }

    ngOnInit(): void {
      this.route.queryParams
        .subscribe(params => {
          this.verified = params['success'];
          this.key = params['key'];
          this.code = params['code'];
          this.email = params['email'];
        }
      );
    }

    setNewPassword() {
      const route = `../verification-code/${this.key}/${this.email}/${this.code}`;
      this.router.navigate([route]);
  }

    // getTransLanguage() {
    //   this.TransLang = [...this.translate.getLangs()];
    // }
  }
