<div class="w-screen h-[5rem] absolute left-1/2 -translate-x-1/2 bottom-[-2rem] z-[1000] flex flex-row justify-center align-middle items-center">
  <div class="w-[15rem] h-fit flex flex-row justify-between align-middle items-center self-center">
    <swiper-button-prev *ngIf="slideElements !== undefined && slideElements.length !== undefined && slideElements?.length! > 1" class="sm:flex flex-col justify-center align-middle items-center hidden 
    w-[1.75rem] h-[1.75rem] rounded-full z-[10000]
    sm:opacity-50 hover:cursor-pointer sm:hover:opacity-100 transition-opacity duration-200 ease-in-out select-none"
    (click)="previousSlide()">
      <img 
      alt="arrow-left" 
      src="assets/images/arrow_neon_L.svg" 
      class="w-[1.35rem] h-[1.35rem] bg-no-repeat bg-contain bg-center self-center select-none">
    </swiper-button-prev>
    
    <swiper-button-next *ngIf="slideElements !== undefined && slideElements.length !== undefined && slideElements?.length! > 1" class="sm:flex flex-col justify-center align-middle items-center hidden 
    w-[1.75rem] h-[1.75rem] rounded-full z-[10000]
    sm:opacity-50 hover:cursor-pointer sm:hover:opacity-100 transition-opacity duration-200 ease-in-out select-none"
    (click)="nextSlide()">
      <img alt="arrow-right" 
      src="assets/images/arrow_neon_R.svg" 
      class="w-[1.35rem] h-[1.35rem] bg-no-repeat bg-contain bg-center self-center select-none">
    </swiper-button-next>
  </div>
</div>

<swiper-container fmSwiper init="false">
  <swiper-slide
  *ngFor="let slideContent of sanitizedSlideContents"
  class="pt-5"
  (click)="slideClicked($event)"
  [innerHTML]="slideContent">
  </swiper-slide>
</swiper-container>

