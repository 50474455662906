<app-svg-load-morph> </app-svg-load-morph>

<div class="z-[-1] overflow-x-clip">
  <header class="relative flex items-center justify-center top-[-6rem] h-[85vh] bg-cover bg-no-repeat bg-[center_left_-18rem] sm:bg-center w-screen"
    style="background-image: url(./assets/images/heroBg.jpg);"
    alt="vacuactiv">
  
    <div class="w-full h-[72%] mt-[6rem] relative self-start">
        <a href="/reservation" 
        aria-label="Make a reservation"
        aria-labelledby="Make a reservation"
        class="text-white dark:text-white font-custom_semibold text-[1.5rem] 
        sm:text-[2.25rem] lg:text-[2rem] hover:scale-[1.005] z-[2] block text-center whitespace-nowrap absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        {{"book term" | translate }} </a>
            
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full 
        outline-2 outline-[rgba(255,255,255,0.15)] outline w-[22rem] h-[22rem] sm:w-[27rem] sm:h-[27rem] sm:hover:w-[30rem] sm:hover:h-[30rem] z-[10] 
        transition-all duration-300 ease-in-out hover:cursor-pointer ring_light opacity-50 hover:opacity-100" (click)="navigateTo('/reservation')">

        </div>

        <div class="absolute right-0 top-1/2 transform -translate-y-1/2 flex flex-col space-y-5 pr-10 gap-4">
            <a [href]="facebookUrl" 
            target="_blank"
            aria-label="Facebook"
            aria-labelledby="Facebook"
            ><img src="/assets/images/fb.svg" alt="Instagram" class="hidden sm:block w-6 h-auto"></a> 
            <a [href]="instagramUrl"
            target="_blank"
            aria-label="Instagram"
            aria-labelledby="Instagram"
            ><img src="/assets/images/insta.svg" alt="Instagram" class="hidden sm:block w-6 h-auto"></a> 
        </div>
    </div>

  </header>
</div>

<div class="text-white w-screen flex items-center justify-center text-center z-[-2] overflow-x-clip">

  <div class="relative z-10 w-screen">

    <img src="assets/images/homeBg3.svg"
    class="absolute inset-0 w-full h-[100%] top-[-6rem] object-cover object-center z-[-4]" 
    alt="vacuactiv">
        
    <div class="relative z-[-1] w-screen top-0">
      <div class="absolute top-[-13rem] sm:top-[-28rem] lg:top-[-29.15rem] left-1/2 -translate-x-1/2">
        <app-our-products-cards></app-our-products-cards>
      </div>

      <!-- fitness overview -->
      <div class="text-center flex flex-col pt-[4rem] sm:justify-end sm:flex-row sm:mt-[12.5rem]">

        <div class="relative">
    
            <div class="sm:absolute sm:left-[-12rem] sm:top-[-3rem] md:top-[-3.75rem] lg:top-[-3.25rem] z-10 justify-start">
                <!-- Desktop bg-->
                <div class="bg-no-repeat bg-cover w-[17.5rem] h-[32.5rem] md:h-[26.5rem] bg-[center_left_-2rem] overflow-hidden rounded-bl-[150px] rounded-tr-[150px] border-0 border-transparent max-w-full hidden sm:block" style="box-shadow: 4px 4px 4px 0px #EB9AFF; background-image: url(assets/images/fitnessHome.jpg);"> </div>
                <!-- Mobile bg-->
                <div class="select-none z-0 bg-cover bg-no-repeat bg-center w-[80vw] h-[17.5rem] overflow-clip m-auto block sm:hidden border border-white/15 border-b-0 rounded-t-[0.5rem]" style="background-image: url(assets/images/fitnessHome.jpg);"> </div>
            </div>
    
            <div class="card_landing card_generic border border-t-0 sm:border-t-[1px] sm:border-r-0 border-white/15 flex flex-col w-[80vw] sm:w-[70vw] m-auto rounded-b-[0.5rem] sm:rounded-l-[0.5rem] space-y-3 sm:space-y-5 h-auto p-[2rem] sm:z-0 sm:pl-[7rem]">
    
                <div class="flex flex-row items-start sm:items-center justify-between mb-[1rem]">
                    <div>
                        <h1 class=" text-white text-[1rem] sm:text-[1.5rem] text-2xl font-bold ">{{"landingpage.fitness-title" | translate}}</h1>
                        <hr class="mt-2 w-20 h-[1px] bg-gray-100">
                    </div>
    
                    <img src="assets/images/heart.svg" alt="heart puls"
                        class="block sm:hidden top-3 md:top-7 right-7 sm:static sm:ml-auto sm:mb-0 w-8 md:w-12">
                </div>
    
                <div class=" flex flex-col justify-center items-start space-y-3 sm:space-y-5">
                    <h2 class="text-start font-semibold text-sm md-text-md lg:text-lg">{{"landingpage.fitness-text" | translate}}</h2>
                    <p class="text-start text-sm md-text-md lg:text-lg">{{"landingpage.fitness-text-02" | translate}}</p>
                </div>
    
                <div class="flex flex-col md:flex-row justify-between md:space-x-2  md:items-center w-full ">
    
                    <div class="flex items-center order-2 md:order-1 md:mt-0">
                        <h2 class="text-[10px] sm:text-sm md:text-md lg:text-lg mr-3 text-start">{{"service-card-component.make-appointment" | translate}}</h2>
                        <img src="/assets/images/lineBtn.svg" class="w-8 md:w-12" alt="line-button">
                        <button (click)="navigateTo('reservation')"
                           id="make-a-reservation"
                           aria-label="make-a-reservation"
                           aria-labelledby="make-a-reservation"
                           role="button"
                            class="w-8 h-8 sm:w-10 sm:h-10 overflow-hidden transform transition-transform duration-300 hover:scale-110">
                            <img src="assets/images/btn.svg" alt="make-reservation">
                        </button>
                    </div>
    
                    <div class="flex justify-between space-x-0 md:space-x-2 order-1 md:order-2 mt-2 md:mt-0 mb-3 md:mb-0">
                        <div class="text-start">
                            <div class="flex items-center justify-start mb-1">
                                <hr class="h-4 w-0.5 bg-[#f6bca0] border-0 rounded mr-1">
                                <h1 class="font-medium text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px]">{{"landingpage.bodyshape" | translate}}
                                </h1>
                            </div>
                            <p class="text-[9px] sm:text-[11px] md:text[14px] w-22 sm:w-36 md:w-[20vw]">{{"landingpage.bodyshape-text" | translate}}</p>
                        </div>
    
                        <div class="text-start">
                            <div class="flex items-center mb-1 md:justify-start">
                                <hr class="h-4 w-0.5 bg-[#f6bca0] border-0 rounded mr-1">
                                <h1 class="font-medium text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px]">{{"landingpage.rollshape" | translate}}
                                </h1>
                            </div>
                            <p class="text-[9px] sm:text-[11px] md:text[14px] w-22">{{"landingpage.rollshape-text" | translate}}</p>
                        </div>
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
      </div>
    
      <!-- spa center overview -->
      <div class="flex flex-col sm:justify-start sm:flex-row my-[5rem] sm:mt-[200px] sm:mb-[200px]">
        <div class="relative">
        
            <div class="sm:absolute sm:left-[-10rem] top-[-5rem] z-10 justify-start">
                <div class="select-none relative bg-cover bg-no-repeat bg-center w-[80vw] h-[17.5rem] overflow-clip m-auto block sm:hidden border border-white/15 border-b-0 rounded-t-[0.5rem]" style="background-image: url(assets/images/spaCenter.jpg);"> </div>
            </div>
    
            <div class="card_landing card_generic border border-t-0 sm:border-t-[1px] sm:border-l-0 border-white/15 flex flex-col w-[80vw] sm:w-[70vw] m-auto sm:rounded-b-[0rem] rounded-b-[0.5rem] space-y-3 sm:space-y-5 h-auto p-[2rem] z-0 sm:pr-[3rem]">
    
                <div class="flex flex-row items-start sm:items-center justify-between mb-[1rem] md:mb-0">
                    <div>
                        <h1 class="text-white text-[1rem] sm:text-[1.5rem] text-2xl font-bold mb-0 sm:mb-[1rem] md:mb-0">{{"landingpage.spa-title" | translate }}</h1>
                        <hr class="mt-2 w-20 h-[1px] bg-gray-100">
                    </div>
                    <img src="assets/images/heart.svg" alt="heart-puls" class="block sm:hidden top-3 md:top-7 right-7 sm:static sm:ml-auto sm:mb-0 w-8 md:w-12">
                </div>          
              
                    <div class="flex flex-col justify-center items-start space-y-3 sm:space-y-5">
                        <h2 class="text-start font-semibold text-sm md-text-md lg:text-lg sm:mr-[3rem] md:w-[50vw]">{{"landingpage.spa-text" | translate}}</h2>
                        <p class="text-start text-sm md-text-md lg:text-lg sm:mr-[3rem] md:w-[50vw]">{{"landingpage.spa-text-02" | translate}}</p>
                    </div>
        
                    <div class="flex flex-col md:flex-row md:space-x-3 justify-between md:items-center w-full">
                        
                        <div class="flex items-center order-2 md:order-1 mt-3 md:mt-0">
                            <h2 class="text-[10px] sm:text-sm md:text-md lg:text-lg mr-3 text-start">{{"service-card-component.make-appointment" | translate}}</h2>
                            
                            <img src="/assets/images/lineBtn.svg" 
                            class="w-8 md:w-12"  
                            alt="line-button">
                            
                            <button id="make-appointment" class="w-8 h-8 sm:w-10 sm:h-10 overflow-hidden transform transition-transform duration-300 hover:scale-110"
                            role="button"
                            aria-label="make-appointment"
                            aria-labelledby="make-appointment"
                            >
                                <img src="assets/images/btn.svg" 
                                alt="make-reservation"
                                (click)="navigateTo('/reservation')">
                            </button>
                        </div>
        
                        <div class="flex flex-row sm:gap-[0rem] justify-between sm:mr-[3rem] order-1 md:order-2 mt-2 md:mt-0 md:my-0">
                            <div class="text-start">
                                <div class="flex items-center mb-1">
                                    <hr class="h-4 w-0.5 bg-[#f6bca0] border-0 rounded mr-1">
                                    <h1 class="font-medium text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px]">{{"landingpage.spa-va-endoactive-title" | translate}}</h1>
                                </div>
        
                                <p class="text-[8px] sm:text-[11px] md:text[14px] w-22 sm:w-36 md:w-[20vw]">{{"landingpage.spa-va-endoactive-heading" | translate}}</p>
                            </div>
        
                            <div class="text-start">
                                <div class="flex items-center mb-1  md:justify-start">
                                    <hr class="h-4 w-0.5 bg-[#f6bca0] border-0 rounded mr-1 ">
                                    <h1 class="font-medium text-[10px] sm:text-[12px] lg:text-[16px]">{{"landingpage.spa-treatment-title" | translate }}</h1>
                                </div>
                                <p class="text-[8px] sm:text-[11px] lg:text[14px] ml-1.5 w-22 sm:w-36 md:w-[20vw]">{{"landingpage.spa-treatment-heading" | translate }}</p>
                            </div>
        
                        </div>
    
                </div>
    
    
            </div>
    
            <!-- Desktop bg SPA 1-->
            <div class="sm:absolute sm:right-[-11rem] md:right-[-3rem] bg-no-repeat bg-cover w-[14rem] h-[18rem] bg-center overflow-clip sm:rounded-tl-[150px] rounded-br-[150px] sm:top-[-5rem] md:top-[-7rem] lg:top-[-5rem] items-start border-0 border-transparent max-w-full hidden sm:block" style="background-image: url(assets/images/spaCenterSec.jpg);"> </div>

            <!-- Desktop bg SPA 2-->
            <div class="sm:absolute sm:right-[-9rem] md:right-[-11rem] sm:top-[10rem] md:top-[2rem] bg-no-repeat bg-cover w-[14rem] h-[22.5rem] bg-center overflow-clip rounded-bl-[150px] rounded-tr-[150px] border-0 border-transparent max-w-full hidden sm:block" style="box-shadow: 4px 4px 4px 0px #EB9AFF; background-image: url(assets/images/spaCenter.jpg);"> </div>
    
        </div>
      </div>
    
      <!-- about us -->
      <div class="card_generic w-[80vw] md:w-[60rem] flex flex-col m-auto mb-[5rem] sm:mb-[200px] rounded-[0.5rem] sm:rounded-br-[10rem]  sm:rounded-tl-[10em] relative">
       
        <div class="px-10 md:px-20 space-y-3 py-10 md:py-20 z-0"> 
            <div>
                <h1 class="text-white sm:pl-[1rem] text-[1rem] md:text-[1.5rem] text-2xl font-bold mb-2 md:text-start text-center"> {{ "landingpage.about-us" | translate }}</h1>
                <h1 class="text-white sm:pl-[1rem] text-[.85rem] md:text-[1rem] font-custom_medium_italic my-4 text-left"> {{ "landingpage.about-us-title" | translate }}</h1>
                <p class="text-start  sm:pl-[1rem] text-[14px] md:text-[16px] sm:mb-[3rem]">{{"landingpage.about-us-text-first" | translate }}</p>
            </div>

            <div class="flex flex-col sm:flex-row justify-between sm:space-x-2 items-center">
              <div class="relative flex-col justify-center align-middle items-center lg:ml-[0rem] hidden lg:flex sm:w-[100px] sm:h-[100px] md:w-[180px] md:h-[180px] lg:w-[180px] lg:h-[180px] ">
                <div class="select-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 outline outline-[#b4a4bc] outline-1 rounded-full sm:w-[100px] sm:h-[100px] md:w-[180px] md:h-[180px] lg:w-[180px] lg:h-[180px] "> </div>
                <img class="select-none w-[80%] -ml-[5px]" 
                src="assets/images/aboutUsHome.png" 
                alt="about-vacuactiv">
              </div>
              <p class="text-start sm:pr-[1rem] sm:pl-[1rem] sm:text-start m-0 lg:w-[74%] text-[14px] lg:text-[16px]">{{"landingpage.about-us-text-second" | translate }}</p>
            </div>
    
            <img class="select-none justify-end hidden lg:block left-[-1rem] w-[60px] sm:left-[-5rem] sm:bottom-[-3rem] md:bottom-[-4rem] sm:w-[150px] md:w-[200px] absolute z-10 border border-[#b4a4bc] rounded-full p-2 " 
            src="assets/images/aboutUs.png"
            alt="about-vacuactiv">
            
            <svg viewBox="0 0 271 359" fill="none" xmlns="http://www.w3.org/2000/svg" class="hidden sm:block left-[-4rem] bottom-[3rem] sm:left-[-8rem] sm:top-[10rem] md:left-[-10rem] md:top-[8rem] lg:top-[2rem] absolute z-10  w-[100px] sm:w-[150px] md:w-[200px] lg:w-[230px]">
                <g filter="url(#filter0_f_17_723)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.0354 123.441V123.452C91.0292 123.579 88.6149 125.994 88.4871 129H88.4766C88.3498 126.018 85.9728 123.618 83 123.455V123.437C85.937 123.277 88.2925 120.932 88.4711 118H88.4927C88.6727 120.956 91.0652 123.315 94.0354 123.441Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.0258 128.957C55.8655 128.865 54.1254 127.15 53.9944 125H53.9784C53.8486 127.132 52.1358 128.837 50 128.954V128.967C52.1618 129.086 53.8903 130.831 53.9825 133H53.9904C54.0834 130.814 55.8394 129.058 58.0258 128.965V128.957Z" fill="#EB9AFF"class="flicker-anim_2" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64 148.916C60.7699 148.779 58.168 146.215 57.9721 143H57.9479C57.7538 146.188 55.1931 148.737 52 148.913V148.932C55.232 149.11 57.8161 151.719 57.954 154.961H57.9661C58.1051 151.692 60.7308 149.067 64 148.928V148.916Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9632 174C12.2241 178.286 15.6924 181.705 19.9986 181.889V181.904C15.6404 182.09 12.1405 185.59 11.9551 189.949H11.938C11.7542 185.625 8.30905 182.147 4 181.909V181.884C8.25723 181.649 11.6712 178.251 11.93 174H11.9632Z" fill="#EB9AFF" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.4937 215C53.641 217.418 55.598 219.348 58.0279 219.452V219.46C55.5687 219.566 53.5938 221.541 53.4892 224H53.4792C53.3754 221.561 51.4315 219.597 49 219.463V219.449C51.4022 219.316 53.3286 217.399 53.4746 215H53.4937Z" fill="#EB9AFF" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.9937 281C43.1573 283.687 45.3321 285.831 48.0323 285.946V285.956C45.2996 286.073 43.105 288.267 42.9886 291H42.9771C42.8617 288.29 40.7017 286.108 38 285.96V285.943C40.6693 285.796 42.8098 283.665 42.9721 281H42.9937Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M104.051 285.914C99.7303 285.731 96.2495 282.3 95.9877 278H95.9546C95.695 282.264 92.2705 285.673 88 285.909V285.935C92.3224 286.174 95.7781 289.663 95.9627 294H95.9796C96.1658 289.627 99.6782 286.115 104.051 285.93V285.914Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M153.491 257C153.671 259.957 156.064 262.316 159.036 262.441V262.452C156.029 262.579 153.613 264.993 153.485 268H153.474C153.347 265.019 150.972 262.619 148 262.455V262.437C150.936 262.275 153.29 259.931 153.469 257H153.491Z" fill="#EB9AFF" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M134.491 344C134.671 346.957 137.064 349.316 140.036 349.441V349.452C137.029 349.579 134.613 351.993 134.485 355H134.474C134.347 352.019 131.972 349.619 129 349.455V349.437C131.936 349.275 134.29 346.931 134.469 344H134.491Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111.975 235C112.138 237.679 114.307 239.817 117 239.93V239.94C114.275 240.055 112.086 242.243 111.97 244.968H111.96C111.845 242.266 109.693 240.092 107 239.943V239.927C109.66 239.78 111.793 237.656 111.955 235H111.975Z" fill="#EB9AFF" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M93.9801 233C94.1107 235.143 95.8458 236.853 97.9996 236.944V236.952C95.8198 237.044 94.0689 238.794 93.976 240.974H93.9685C93.8765 238.813 92.1542 237.074 90 236.955V236.942C92.1283 236.824 93.835 235.125 93.9645 233H93.9801Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M103.994 216C104.093 217.613 105.398 218.899 107.018 218.968V218.974C105.378 219.043 104.061 220.36 103.991 222H103.985C103.916 220.374 102.621 219.066 101 218.976V218.966C102.601 218.877 103.885 217.599 103.982 216H103.994Z" fill="#EB9AFF" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M97.0023 192.918C93.77 192.783 91.1661 190.216 90.9703 187H90.9476C90.7535 190.188 88.1931 192.738 85 192.914V192.934C88.2319 193.113 90.8156 195.722 90.9536 198.965H90.9643C91.1035 195.694 93.7311 193.067 97.0023 192.93V192.918Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M90.9916 162C91.1226 164.151 92.8642 165.867 95.026 165.957V165.965C92.8382 166.056 91.0806 167.813 90.9876 170H90.9811C90.8888 167.832 89.1611 166.087 87 165.968V165.954C89.1351 165.837 90.8473 164.132 90.9771 162H90.9916Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M141.019 135.968C139.398 135.901 138.091 134.614 137.993 133H137.982C137.885 134.599 136.601 135.877 135 135.966V135.976C136.62 136.066 137.916 137.374 137.985 139H137.99C138.06 137.359 139.378 136.042 141.019 135.974V135.968Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M177 138.931C174.307 138.817 172.137 136.68 171.974 134H171.955C171.793 136.656 169.66 138.78 167 138.927V138.943C169.693 139.092 171.845 141.266 171.96 143.968H171.969C172.085 141.243 174.274 139.054 177 138.94V138.931Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M226.002 95.4099C222.501 95.2635 219.68 92.484 219.468 89H219.442C219.232 92.4534 216.459 95.2146 213 95.4054V95.4265C216.501 95.6197 219.299 98.446 219.449 101.958H219.461C219.612 98.4153 222.458 95.5702 226.002 95.422V95.4099Z" fill="#EB9AFF"class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M184 47.9298C181.307 47.8168 179.138 45.6793 178.975 43H178.955C178.793 45.6561 176.66 47.7797 174 47.9264V47.9425C176.693 48.091 178.845 50.2649 178.96 52.9663H178.97C179.085 50.2416 181.274 48.0535 184 47.9391V47.9298Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208 7.944C205.845 7.85373 204.11 6.14362 203.979 4H203.964C203.835 6.12488 202.128 7.8238 200 7.94126V7.9541C202.154 8.073 203.876 9.81218 203.968 11.9734H203.975C204.068 9.79335 205.819 8.04272 208 7.95136V7.944Z" fill="#EB9AFF"class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M231 158.944C228.845 158.854 227.11 157.144 226.979 155H226.964C226.835 157.125 225.128 158.824 223 158.941V158.954C225.154 159.073 226.876 160.812 226.968 162.973H226.975C227.068 160.793 228.819 159.043 231 158.951V158.944Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M197.022 179.462C195.131 179.383 193.607 177.882 193.492 176H193.479C193.366 177.865 191.868 179.357 190 179.46V179.471C191.891 179.576 193.402 181.103 193.483 183H193.489C193.57 181.086 195.108 179.549 197.022 179.469V179.462Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208.013 193.979C206.932 193.934 206.061 193.076 205.996 192H205.988C205.923 193.066 205.067 193.918 204 193.977V193.984C205.08 194.043 205.944 194.916 205.99 196H205.994C206.04 194.906 206.919 194.028 208.013 193.982V193.979Z" fill="#EB9AFF"class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M204.013 215.979C202.932 215.934 202.061 215.076 201.996 214H201.988C201.923 215.066 201.067 215.918 200 215.977V215.984C201.08 216.043 201.944 216.916 201.99 218H201.994C202.04 216.906 202.919 216.028 204.013 215.982V215.979Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M169.013 220.979C167.932 220.934 167.061 220.076 166.996 219H166.988C166.923 220.066 166.067 220.918 165 220.977V220.984C166.08 221.043 166.944 221.916 166.99 223H166.994C167.04 221.906 167.919 221.028 169.013 220.982V220.979Z" fill="#EB9AFF"class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M162 243.956C163.596 243.868 164.877 242.594 164.974 241H164.984C165.082 242.609 166.386 243.892 168.003 243.958V243.963C166.366 244.031 165.051 245.344 164.981 246.981H164.977C164.908 245.359 163.616 244.054 162 243.966V243.956Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M181.025 239.463C179.133 239.385 177.608 237.883 177.493 236H177.481C177.367 237.866 175.869 239.357 174 239.46V239.471C175.891 239.575 177.404 241.103 177.484 243H177.489C177.571 241.085 179.11 239.548 181.025 239.469V239.463Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M199.011 243.484C198.2 243.451 197.547 242.807 197.497 242H197.492C197.443 242.8 196.801 243.439 196 243.483V243.488C196.811 243.532 197.459 244.187 197.494 245H197.496C197.531 244.179 198.19 243.521 199.011 243.487V243.484Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M185.011 243.484C184.2 243.451 183.547 242.807 183.497 242H183.492C183.443 242.8 182.801 243.439 182 243.483V243.488C182.811 243.532 183.459 244.187 183.494 245H183.496C183.531 244.179 184.19 243.521 185.011 243.487V243.484Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M180.019 251.473C178.668 251.418 177.579 250.345 177.497 249H177.487C177.406 250.333 176.335 251.399 175 251.472V251.48C176.352 251.553 177.432 252.645 177.49 254H177.494C177.552 252.633 178.651 251.534 180.019 251.478V251.473Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M186.985 256H186.998C187.096 257.613 188.402 258.901 190.024 258.968V258.973C188.382 259.042 187.064 260.359 186.995 262H186.988C186.919 260.373 185.622 259.064 184 258.975V258.966C185.603 258.879 186.888 257.6 186.985 256Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M263.985 209H263.998C264.096 210.613 265.402 211.901 267.024 211.968V211.973C265.382 212.042 264.064 213.359 263.995 215H263.988C263.919 213.373 262.622 212.064 261 211.975V211.966C262.603 211.879 263.888 210.6 263.985 209Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M198.026 210.462C196.133 210.385 194.608 208.883 194.493 207H194.481C194.367 208.866 192.869 210.357 191 210.46V210.471C192.892 210.575 194.404 212.102 194.484 214H194.49C194.571 212.085 196.11 210.547 198.026 210.468V210.462Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M167.001 205.421C164.038 205.299 161.651 202.948 161.471 200H161.451C161.273 202.921 158.926 205.257 156 205.417V205.434C158.962 205.597 161.33 207.988 161.456 210.96H161.465C161.592 207.961 164.002 205.554 167.001 205.43V205.421Z" fill="#EB9AFF"class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M143.03 188.957C140.867 188.869 139.123 187.152 138.992 185H138.978C138.848 187.132 137.136 188.837 135 188.954V188.967C137.162 189.086 138.89 190.831 138.982 193H138.988C139.081 190.811 140.841 189.053 143.03 188.964V188.957Z" fill="#EB9AFF"class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M123.021 70.9678C121.4 70.8997 120.094 69.6129 119.996 68H119.985C119.888 69.5998 118.603 70.8787 117 70.9659V70.9755C118.622 71.0638 119.919 72.3729 119.988 73.9999H119.993C120.063 72.3596 121.38 71.0425 123.021 70.9736V70.9678Z" fill="#EB9AFF"class="flicker-anim_2"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.0354 123.441V123.452C91.0292 123.579 88.6149 125.994 88.4871 129H88.4766C88.3498 126.018 85.9728 123.618 83 123.455V123.437C85.937 123.277 88.2925 120.932 88.4711 118H88.4927C88.6727 120.956 91.0652 123.315 94.0354 123.441Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.0258 128.957C55.8655 128.865 54.1254 127.15 53.9944 125H53.9784C53.8486 127.132 52.1358 128.837 50 128.954V128.967C52.1618 129.086 53.8903 130.831 53.9825 133H53.9904C54.0834 130.814 55.8394 129.058 58.0258 128.965V128.957Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64 148.916C60.7699 148.779 58.168 146.215 57.9721 143H57.9479C57.7538 146.188 55.1931 148.737 52 148.913V148.932C55.232 149.11 57.8161 151.719 57.954 154.961H57.9661C58.1051 151.692 60.7308 149.067 64 148.928V148.916Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9632 174C12.2241 178.286 15.6924 181.705 19.9986 181.889V181.904C15.6404 182.09 12.1405 185.59 11.9551 189.949H11.938C11.7542 185.625 8.30905 182.147 4 181.909V181.884C8.25723 181.649 11.6712 178.251 11.93 174H11.9632Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.4937 215C53.641 217.418 55.598 219.348 58.0279 219.452V219.46C55.5687 219.566 53.5938 221.541 53.4892 224H53.4792C53.3754 221.561 51.4315 219.597 49 219.463V219.449C51.4022 219.316 53.3286 217.399 53.4746 215H53.4937Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.9937 281C43.1573 283.687 45.3321 285.831 48.0323 285.946V285.956C45.2996 286.073 43.105 288.267 42.9886 291H42.9771C42.8617 288.29 40.7017 286.108 38 285.96V285.943C40.6693 285.796 42.8098 283.665 42.9721 281H42.9937Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M104.051 285.914C99.7303 285.731 96.2495 282.3 95.9877 278H95.9546C95.695 282.264 92.2705 285.673 88 285.909V285.935C92.3224 286.174 95.7781 289.663 95.9627 294H95.9796C96.1658 289.627 99.6782 286.115 104.051 285.93V285.914Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M153.491 257C153.671 259.957 156.064 262.316 159.036 262.441V262.452C156.029 262.579 153.613 264.993 153.485 268H153.474C153.347 265.019 150.972 262.619 148 262.455V262.437C150.936 262.275 153.29 259.931 153.469 257H153.491Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M134.491 344C134.671 346.957 137.064 349.316 140.036 349.441V349.452C137.029 349.579 134.613 351.993 134.485 355H134.474C134.347 352.019 131.972 349.619 129 349.455V349.437C131.936 349.275 134.29 346.931 134.469 344H134.491Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111.975 235C112.138 237.679 114.307 239.817 117 239.93V239.94C114.275 240.055 112.086 242.243 111.97 244.968H111.96C111.845 242.266 109.693 240.092 107 239.943V239.927C109.66 239.78 111.793 237.656 111.955 235H111.975Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M93.9801 233C94.1107 235.143 95.8458 236.853 97.9996 236.944V236.952C95.8198 237.044 94.0689 238.794 93.976 240.974H93.9685C93.8765 238.813 92.1542 237.074 90 236.955V236.942C92.1283 236.824 93.835 235.125 93.9645 233H93.9801Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M103.994 216C104.093 217.613 105.398 218.899 107.018 218.968V218.974C105.378 219.043 104.061 220.36 103.991 222H103.985C103.916 220.374 102.621 219.066 101 218.976V218.966C102.601 218.877 103.885 217.599 103.982 216H103.994Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M97.0023 192.918C93.77 192.783 91.1661 190.216 90.9703 187H90.9476C90.7535 190.188 88.1931 192.738 85 192.914V192.934C88.2319 193.113 90.8156 195.722 90.9536 198.965H90.9643C91.1035 195.694 93.7311 193.067 97.0023 192.93V192.918Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M90.9916 162C91.1226 164.151 92.8642 165.867 95.026 165.957V165.965C92.8382 166.056 91.0806 167.813 90.9876 170H90.9811C90.8888 167.832 89.1611 166.087 87 165.968V165.954C89.1351 165.837 90.8473 164.132 90.9771 162H90.9916Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M141.019 135.968C139.398 135.901 138.091 134.614 137.993 133H137.982C137.885 134.599 136.601 135.877 135 135.966V135.976C136.62 136.066 137.916 137.374 137.985 139H137.99C138.06 137.359 139.378 136.042 141.019 135.974V135.968Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M177 138.931C174.307 138.817 172.137 136.68 171.974 134H171.955C171.793 136.656 169.66 138.78 167 138.927V138.943C169.693 139.092 171.845 141.266 171.96 143.968H171.969C172.085 141.243 174.274 139.054 177 138.94V138.931Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M226.002 95.4099C222.501 95.2635 219.68 92.484 219.468 89H219.442C219.232 92.4534 216.459 95.2146 213 95.4054V95.4265C216.501 95.6197 219.299 98.446 219.449 101.958H219.461C219.612 98.4153 222.458 95.5702 226.002 95.422V95.4099Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M184 47.9298C181.307 47.8168 179.138 45.6793 178.975 43H178.955C178.793 45.6561 176.66 47.7797 174 47.9264V47.9425C176.693 48.091 178.845 50.2649 178.96 52.9663H178.97C179.085 50.2416 181.274 48.0535 184 47.9391V47.9298Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208 7.944C205.845 7.85373 204.11 6.14362 203.979 4H203.964C203.835 6.12488 202.128 7.8238 200 7.94126V7.9541C202.154 8.073 203.876 9.81218 203.968 11.9734H203.975C204.068 9.79335 205.819 8.04272 208 7.95136V7.944Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M231 158.944C228.845 158.854 227.11 157.144 226.979 155H226.964C226.835 157.125 225.128 158.824 223 158.941V158.954C225.154 159.073 226.876 160.812 226.968 162.973H226.975C227.068 160.793 228.819 159.043 231 158.951V158.944Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M197.022 179.462C195.131 179.383 193.607 177.882 193.492 176H193.479C193.366 177.865 191.868 179.357 190 179.46V179.471C191.891 179.576 193.402 181.103 193.483 183H193.489C193.57 181.086 195.108 179.549 197.022 179.469V179.462Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208.013 193.979C206.932 193.934 206.061 193.076 205.996 192H205.988C205.923 193.066 205.067 193.918 204 193.977V193.984C205.08 194.043 205.944 194.916 205.99 196H205.994C206.04 194.906 206.919 194.028 208.013 193.982V193.979Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M204.013 215.979C202.932 215.934 202.061 215.076 201.996 214H201.988C201.923 215.066 201.067 215.918 200 215.977V215.984C201.08 216.043 201.944 216.916 201.99 218H201.994C202.04 216.906 202.919 216.028 204.013 215.982V215.979Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M169.013 220.979C167.932 220.934 167.061 220.076 166.996 219H166.988C166.923 220.066 166.067 220.918 165 220.977V220.984C166.08 221.043 166.944 221.916 166.99 223H166.994C167.04 221.906 167.919 221.028 169.013 220.982V220.979Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M162 243.956C163.596 243.868 164.877 242.594 164.974 241H164.984C165.082 242.609 166.386 243.892 168.003 243.958V243.963C166.366 244.031 165.051 245.344 164.981 246.981H164.977C164.908 245.359 163.616 244.054 162 243.966V243.956Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M181.025 239.463C179.133 239.385 177.608 237.883 177.493 236H177.481C177.367 237.866 175.869 239.357 174 239.46V239.471C175.891 239.575 177.404 241.103 177.484 243H177.489C177.571 241.085 179.11 239.548 181.025 239.469V239.463Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M199.011 243.484C198.2 243.451 197.547 242.807 197.497 242H197.492C197.443 242.8 196.801 243.439 196 243.483V243.488C196.811 243.532 197.459 244.187 197.494 245H197.496C197.531 244.179 198.19 243.521 199.011 243.487V243.484Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M185.011 243.484C184.2 243.451 183.547 242.807 183.497 242H183.492C183.443 242.8 182.801 243.439 182 243.483V243.488C182.811 243.532 183.459 244.187 183.494 245H183.496C183.531 244.179 184.19 243.521 185.011 243.487V243.484Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M180.019 251.473C178.668 251.418 177.579 250.345 177.497 249H177.487C177.406 250.333 176.335 251.399 175 251.472V251.48C176.352 251.553 177.432 252.645 177.49 254H177.494C177.552 252.633 178.651 251.534 180.019 251.478V251.473Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M186.985 256H186.998C187.096 257.613 188.402 258.901 190.024 258.968V258.973C188.382 259.042 187.064 260.359 186.995 262H186.988C186.919 260.373 185.622 259.064 184 258.975V258.966C185.603 258.879 186.888 257.6 186.985 256Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M263.985 209H263.998C264.096 210.613 265.402 211.901 267.024 211.968V211.973C265.382 212.042 264.064 213.359 263.995 215H263.988C263.919 213.373 262.622 212.064 261 211.975V211.966C262.603 211.879 263.888 210.6 263.985 209Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M198.026 210.462C196.133 210.385 194.608 208.883 194.493 207H194.481C194.367 208.866 192.869 210.357 191 210.46V210.471C192.892 210.575 194.404 212.102 194.484 214H194.49C194.571 212.085 196.11 210.547 198.026 210.468V210.462Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M167.001 205.421C164.038 205.299 161.651 202.948 161.471 200H161.451C161.273 202.921 158.926 205.257 156 205.417V205.434C158.962 205.597 161.33 207.988 161.456 210.96H161.465C161.592 207.961 164.002 205.554 167.001 205.43V205.421Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M143.03 188.957C140.867 188.869 139.123 187.152 138.992 185H138.978C138.848 187.132 137.136 188.837 135 188.954V188.967C137.162 189.086 138.89 190.831 138.982 193H138.988C139.081 190.811 140.841 189.053 143.03 188.964V188.957Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M123.021 70.9678C121.4 70.8997 120.094 69.6129 119.996 68H119.985C119.888 69.5998 118.603 70.8787 117 70.9659V70.9755C118.622 71.0638 119.919 72.3729 119.988 73.9999H119.993C120.063 72.3596 121.38 71.0425 123.021 70.9736V70.9678Z" fill="white" class="flicker-anim_2"/>
                <defs>
                <filter id="filter0_f_17_723" x="0.7" y="0.7" width="269.623" height="357.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1.65" result="effect1_foregroundBlur_17_723"/>
                </filter>
                </defs>
            </svg>   
            
            <svg viewBox="0 0 271 359" fill="none" xmlns="http://www.w3.org/2000/svg"  class="hidden sm:block right-0 top-1  sm:right-[-4rem] sm:top-[-5rem] md:right-[-8rem] md:top-[-5rem] absolute z-10  w-[100px] sm:w-[130px] md:w-[200px] lg:w-[230px]">
                <g filter="url(#filter0_f_17_723)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.0354 123.441V123.452C91.0292 123.579 88.6149 125.994 88.4871 129H88.4766C88.3498 126.018 85.9728 123.618 83 123.455V123.437C85.937 123.277 88.2925 120.932 88.4711 118H88.4927C88.6727 120.956 91.0652 123.315 94.0354 123.441Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.0258 128.957C55.8655 128.865 54.1254 127.15 53.9944 125H53.9784C53.8486 127.132 52.1358 128.837 50 128.954V128.967C52.1618 129.086 53.8903 130.831 53.9825 133H53.9904C54.0834 130.814 55.8394 129.058 58.0258 128.965V128.957Z" fill="#EB9AFF"class="flicker-anim_4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64 148.916C60.7699 148.779 58.168 146.215 57.9721 143H57.9479C57.7538 146.188 55.1931 148.737 52 148.913V148.932C55.232 149.11 57.8161 151.719 57.954 154.961H57.9661C58.1051 151.692 60.7308 149.067 64 148.928V148.916Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9632 174C12.2241 178.286 15.6924 181.705 19.9986 181.889V181.904C15.6404 182.09 12.1405 185.59 11.9551 189.949H11.938C11.7542 185.625 8.30905 182.147 4 181.909V181.884C8.25723 181.649 11.6712 178.251 11.93 174H11.9632Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.4937 215C53.641 217.418 55.598 219.348 58.0279 219.452V219.46C55.5687 219.566 53.5938 221.541 53.4892 224H53.4792C53.3754 221.561 51.4315 219.597 49 219.463V219.449C51.4022 219.316 53.3286 217.399 53.4746 215H53.4937Z" fill="#EB9AFF" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.9937 281C43.1573 283.687 45.3321 285.831 48.0323 285.946V285.956C45.2996 286.073 43.105 288.267 42.9886 291H42.9771C42.8617 288.29 40.7017 286.108 38 285.96V285.943C40.6693 285.796 42.8098 283.665 42.9721 281H42.9937Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M104.051 285.914C99.7303 285.731 96.2495 282.3 95.9877 278H95.9546C95.695 282.264 92.2705 285.673 88 285.909V285.935C92.3224 286.174 95.7781 289.663 95.9627 294H95.9796C96.1658 289.627 99.6782 286.115 104.051 285.93V285.914Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M153.491 257C153.671 259.957 156.064 262.316 159.036 262.441V262.452C156.029 262.579 153.613 264.993 153.485 268H153.474C153.347 265.019 150.972 262.619 148 262.455V262.437C150.936 262.275 153.29 259.931 153.469 257H153.491Z" fill="#EB9AFF" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M134.491 344C134.671 346.957 137.064 349.316 140.036 349.441V349.452C137.029 349.579 134.613 351.993 134.485 355H134.474C134.347 352.019 131.972 349.619 129 349.455V349.437C131.936 349.275 134.29 346.931 134.469 344H134.491Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111.975 235C112.138 237.679 114.307 239.817 117 239.93V239.94C114.275 240.055 112.086 242.243 111.97 244.968H111.96C111.845 242.266 109.693 240.092 107 239.943V239.927C109.66 239.78 111.793 237.656 111.955 235H111.975Z" fill="#EB9AFF" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M93.9801 233C94.1107 235.143 95.8458 236.853 97.9996 236.944V236.952C95.8198 237.044 94.0689 238.794 93.976 240.974H93.9685C93.8765 238.813 92.1542 237.074 90 236.955V236.942C92.1283 236.824 93.835 235.125 93.9645 233H93.9801Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M103.994 216C104.093 217.613 105.398 218.899 107.018 218.968V218.974C105.378 219.043 104.061 220.36 103.991 222H103.985C103.916 220.374 102.621 219.066 101 218.976V218.966C102.601 218.877 103.885 217.599 103.982 216H103.994Z" fill="#EB9AFF" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M97.0023 192.918C93.77 192.783 91.1661 190.216 90.9703 187H90.9476C90.7535 190.188 88.1931 192.738 85 192.914V192.934C88.2319 193.113 90.8156 195.722 90.9536 198.965H90.9643C91.1035 195.694 93.7311 193.067 97.0023 192.93V192.918Z" fill="#EB9AFF" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M90.9916 162C91.1226 164.151 92.8642 165.867 95.026 165.957V165.965C92.8382 166.056 91.0806 167.813 90.9876 170H90.9811C90.8888 167.832 89.1611 166.087 87 165.968V165.954C89.1351 165.837 90.8473 164.132 90.9771 162H90.9916Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M141.019 135.968C139.398 135.901 138.091 134.614 137.993 133H137.982C137.885 134.599 136.601 135.877 135 135.966V135.976C136.62 136.066 137.916 137.374 137.985 139H137.99C138.06 137.359 139.378 136.042 141.019 135.974V135.968Z" fill="#EB9AFF" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M177 138.931C174.307 138.817 172.137 136.68 171.974 134H171.955C171.793 136.656 169.66 138.78 167 138.927V138.943C169.693 139.092 171.845 141.266 171.96 143.968H171.969C172.085 141.243 174.274 139.054 177 138.94V138.931Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M226.002 95.4099C222.501 95.2635 219.68 92.484 219.468 89H219.442C219.232 92.4534 216.459 95.2146 213 95.4054V95.4265C216.501 95.6197 219.299 98.446 219.449 101.958H219.461C219.612 98.4153 222.458 95.5702 226.002 95.422V95.4099Z" fill="#EB9AFF"class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M184 47.9298C181.307 47.8168 179.138 45.6793 178.975 43H178.955C178.793 45.6561 176.66 47.7797 174 47.9264V47.9425C176.693 48.091 178.845 50.2649 178.96 52.9663H178.97C179.085 50.2416 181.274 48.0535 184 47.9391V47.9298Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208 7.944C205.845 7.85373 204.11 6.14362 203.979 4H203.964C203.835 6.12488 202.128 7.8238 200 7.94126V7.9541C202.154 8.073 203.876 9.81218 203.968 11.9734H203.975C204.068 9.79335 205.819 8.04272 208 7.95136V7.944Z" fill="#EB9AFF"class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M231 158.944C228.845 158.854 227.11 157.144 226.979 155H226.964C226.835 157.125 225.128 158.824 223 158.941V158.954C225.154 159.073 226.876 160.812 226.968 162.973H226.975C227.068 160.793 228.819 159.043 231 158.951V158.944Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M197.022 179.462C195.131 179.383 193.607 177.882 193.492 176H193.479C193.366 177.865 191.868 179.357 190 179.46V179.471C191.891 179.576 193.402 181.103 193.483 183H193.489C193.57 181.086 195.108 179.549 197.022 179.469V179.462Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208.013 193.979C206.932 193.934 206.061 193.076 205.996 192H205.988C205.923 193.066 205.067 193.918 204 193.977V193.984C205.08 194.043 205.944 194.916 205.99 196H205.994C206.04 194.906 206.919 194.028 208.013 193.982V193.979Z" fill="#EB9AFF"class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M204.013 215.979C202.932 215.934 202.061 215.076 201.996 214H201.988C201.923 215.066 201.067 215.918 200 215.977V215.984C201.08 216.043 201.944 216.916 201.99 218H201.994C202.04 216.906 202.919 216.028 204.013 215.982V215.979Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M169.013 220.979C167.932 220.934 167.061 220.076 166.996 219H166.988C166.923 220.066 166.067 220.918 165 220.977V220.984C166.08 221.043 166.944 221.916 166.99 223H166.994C167.04 221.906 167.919 221.028 169.013 220.982V220.979Z" fill="#EB9AFF"class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M162 243.956C163.596 243.868 164.877 242.594 164.974 241H164.984C165.082 242.609 166.386 243.892 168.003 243.958V243.963C166.366 244.031 165.051 245.344 164.981 246.981H164.977C164.908 245.359 163.616 244.054 162 243.966V243.956Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M181.025 239.463C179.133 239.385 177.608 237.883 177.493 236H177.481C177.367 237.866 175.869 239.357 174 239.46V239.471C175.891 239.575 177.404 241.103 177.484 243H177.489C177.571 241.085 179.11 239.548 181.025 239.469V239.463Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M199.011 243.484C198.2 243.451 197.547 242.807 197.497 242H197.492C197.443 242.8 196.801 243.439 196 243.483V243.488C196.811 243.532 197.459 244.187 197.494 245H197.496C197.531 244.179 198.19 243.521 199.011 243.487V243.484Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M185.011 243.484C184.2 243.451 183.547 242.807 183.497 242H183.492C183.443 242.8 182.801 243.439 182 243.483V243.488C182.811 243.532 183.459 244.187 183.494 245H183.496C183.531 244.179 184.19 243.521 185.011 243.487V243.484Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M180.019 251.473C178.668 251.418 177.579 250.345 177.497 249H177.487C177.406 250.333 176.335 251.399 175 251.472V251.48C176.352 251.553 177.432 252.645 177.49 254H177.494C177.552 252.633 178.651 251.534 180.019 251.478V251.473Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M186.985 256H186.998C187.096 257.613 188.402 258.901 190.024 258.968V258.973C188.382 259.042 187.064 260.359 186.995 262H186.988C186.919 260.373 185.622 259.064 184 258.975V258.966C185.603 258.879 186.888 257.6 186.985 256Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M263.985 209H263.998C264.096 210.613 265.402 211.901 267.024 211.968V211.973C265.382 212.042 264.064 213.359 263.995 215H263.988C263.919 213.373 262.622 212.064 261 211.975V211.966C262.603 211.879 263.888 210.6 263.985 209Z" fill="#EB9AFF"class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M198.026 210.462C196.133 210.385 194.608 208.883 194.493 207H194.481C194.367 208.866 192.869 210.357 191 210.46V210.471C192.892 210.575 194.404 212.102 194.484 214H194.49C194.571 212.085 196.11 210.547 198.026 210.468V210.462Z" fill="#EB9AFF"class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M167.001 205.421C164.038 205.299 161.651 202.948 161.471 200H161.451C161.273 202.921 158.926 205.257 156 205.417V205.434C158.962 205.597 161.33 207.988 161.456 210.96H161.465C161.592 207.961 164.002 205.554 167.001 205.43V205.421Z" fill="#EB9AFF"class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M143.03 188.957C140.867 188.869 139.123 187.152 138.992 185H138.978C138.848 187.132 137.136 188.837 135 188.954V188.967C137.162 189.086 138.89 190.831 138.982 193H138.988C139.081 190.811 140.841 189.053 143.03 188.964V188.957Z" fill="#EB9AFF"class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M123.021 70.9678C121.4 70.8997 120.094 69.6129 119.996 68H119.985C119.888 69.5998 118.603 70.8787 117 70.9659V70.9755C118.622 71.0638 119.919 72.3729 119.988 73.9999H119.993C120.063 72.3596 121.38 71.0425 123.021 70.9736V70.9678Z" fill="#EB9AFF"class="flicker-anim_4"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.0354 123.441V123.452C91.0292 123.579 88.6149 125.994 88.4871 129H88.4766C88.3498 126.018 85.9728 123.618 83 123.455V123.437C85.937 123.277 88.2925 120.932 88.4711 118H88.4927C88.6727 120.956 91.0652 123.315 94.0354 123.441Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.0258 128.957C55.8655 128.865 54.1254 127.15 53.9944 125H53.9784C53.8486 127.132 52.1358 128.837 50 128.954V128.967C52.1618 129.086 53.8903 130.831 53.9825 133H53.9904C54.0834 130.814 55.8394 129.058 58.0258 128.965V128.957Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64 148.916C60.7699 148.779 58.168 146.215 57.9721 143H57.9479C57.7538 146.188 55.1931 148.737 52 148.913V148.932C55.232 149.11 57.8161 151.719 57.954 154.961H57.9661C58.1051 151.692 60.7308 149.067 64 148.928V148.916Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9632 174C12.2241 178.286 15.6924 181.705 19.9986 181.889V181.904C15.6404 182.09 12.1405 185.59 11.9551 189.949H11.938C11.7542 185.625 8.30905 182.147 4 181.909V181.884C8.25723 181.649 11.6712 178.251 11.93 174H11.9632Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.4937 215C53.641 217.418 55.598 219.348 58.0279 219.452V219.46C55.5687 219.566 53.5938 221.541 53.4892 224H53.4792C53.3754 221.561 51.4315 219.597 49 219.463V219.449C51.4022 219.316 53.3286 217.399 53.4746 215H53.4937Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.9937 281C43.1573 283.687 45.3321 285.831 48.0323 285.946V285.956C45.2996 286.073 43.105 288.267 42.9886 291H42.9771C42.8617 288.29 40.7017 286.108 38 285.96V285.943C40.6693 285.796 42.8098 283.665 42.9721 281H42.9937Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M104.051 285.914C99.7303 285.731 96.2495 282.3 95.9877 278H95.9546C95.695 282.264 92.2705 285.673 88 285.909V285.935C92.3224 286.174 95.7781 289.663 95.9627 294H95.9796C96.1658 289.627 99.6782 286.115 104.051 285.93V285.914Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M153.491 257C153.671 259.957 156.064 262.316 159.036 262.441V262.452C156.029 262.579 153.613 264.993 153.485 268H153.474C153.347 265.019 150.972 262.619 148 262.455V262.437C150.936 262.275 153.29 259.931 153.469 257H153.491Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M134.491 344C134.671 346.957 137.064 349.316 140.036 349.441V349.452C137.029 349.579 134.613 351.993 134.485 355H134.474C134.347 352.019 131.972 349.619 129 349.455V349.437C131.936 349.275 134.29 346.931 134.469 344H134.491Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111.975 235C112.138 237.679 114.307 239.817 117 239.93V239.94C114.275 240.055 112.086 242.243 111.97 244.968H111.96C111.845 242.266 109.693 240.092 107 239.943V239.927C109.66 239.78 111.793 237.656 111.955 235H111.975Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M93.9801 233C94.1107 235.143 95.8458 236.853 97.9996 236.944V236.952C95.8198 237.044 94.0689 238.794 93.976 240.974H93.9685C93.8765 238.813 92.1542 237.074 90 236.955V236.942C92.1283 236.824 93.835 235.125 93.9645 233H93.9801Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M103.994 216C104.093 217.613 105.398 218.899 107.018 218.968V218.974C105.378 219.043 104.061 220.36 103.991 222H103.985C103.916 220.374 102.621 219.066 101 218.976V218.966C102.601 218.877 103.885 217.599 103.982 216H103.994Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M97.0023 192.918C93.77 192.783 91.1661 190.216 90.9703 187H90.9476C90.7535 190.188 88.1931 192.738 85 192.914V192.934C88.2319 193.113 90.8156 195.722 90.9536 198.965H90.9643C91.1035 195.694 93.7311 193.067 97.0023 192.93V192.918Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M90.9916 162C91.1226 164.151 92.8642 165.867 95.026 165.957V165.965C92.8382 166.056 91.0806 167.813 90.9876 170H90.9811C90.8888 167.832 89.1611 166.087 87 165.968V165.954C89.1351 165.837 90.8473 164.132 90.9771 162H90.9916Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M141.019 135.968C139.398 135.901 138.091 134.614 137.993 133H137.982C137.885 134.599 136.601 135.877 135 135.966V135.976C136.62 136.066 137.916 137.374 137.985 139H137.99C138.06 137.359 139.378 136.042 141.019 135.974V135.968Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M177 138.931C174.307 138.817 172.137 136.68 171.974 134H171.955C171.793 136.656 169.66 138.78 167 138.927V138.943C169.693 139.092 171.845 141.266 171.96 143.968H171.969C172.085 141.243 174.274 139.054 177 138.94V138.931Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M226.002 95.4099C222.501 95.2635 219.68 92.484 219.468 89H219.442C219.232 92.4534 216.459 95.2146 213 95.4054V95.4265C216.501 95.6197 219.299 98.446 219.449 101.958H219.461C219.612 98.4153 222.458 95.5702 226.002 95.422V95.4099Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M184 47.9298C181.307 47.8168 179.138 45.6793 178.975 43H178.955C178.793 45.6561 176.66 47.7797 174 47.9264V47.9425C176.693 48.091 178.845 50.2649 178.96 52.9663H178.97C179.085 50.2416 181.274 48.0535 184 47.9391V47.9298Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208 7.944C205.845 7.85373 204.11 6.14362 203.979 4H203.964C203.835 6.12488 202.128 7.8238 200 7.94126V7.9541C202.154 8.073 203.876 9.81218 203.968 11.9734H203.975C204.068 9.79335 205.819 8.04272 208 7.95136V7.944Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M231 158.944C228.845 158.854 227.11 157.144 226.979 155H226.964C226.835 157.125 225.128 158.824 223 158.941V158.954C225.154 159.073 226.876 160.812 226.968 162.973H226.975C227.068 160.793 228.819 159.043 231 158.951V158.944Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M197.022 179.462C195.131 179.383 193.607 177.882 193.492 176H193.479C193.366 177.865 191.868 179.357 190 179.46V179.471C191.891 179.576 193.402 181.103 193.483 183H193.489C193.57 181.086 195.108 179.549 197.022 179.469V179.462Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M208.013 193.979C206.932 193.934 206.061 193.076 205.996 192H205.988C205.923 193.066 205.067 193.918 204 193.977V193.984C205.08 194.043 205.944 194.916 205.99 196H205.994C206.04 194.906 206.919 194.028 208.013 193.982V193.979Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M204.013 215.979C202.932 215.934 202.061 215.076 201.996 214H201.988C201.923 215.066 201.067 215.918 200 215.977V215.984C201.08 216.043 201.944 216.916 201.99 218H201.994C202.04 216.906 202.919 216.028 204.013 215.982V215.979Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M169.013 220.979C167.932 220.934 167.061 220.076 166.996 219H166.988C166.923 220.066 166.067 220.918 165 220.977V220.984C166.08 221.043 166.944 221.916 166.99 223H166.994C167.04 221.906 167.919 221.028 169.013 220.982V220.979Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M162 243.956C163.596 243.868 164.877 242.594 164.974 241H164.984C165.082 242.609 166.386 243.892 168.003 243.958V243.963C166.366 244.031 165.051 245.344 164.981 246.981H164.977C164.908 245.359 163.616 244.054 162 243.966V243.956Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M181.025 239.463C179.133 239.385 177.608 237.883 177.493 236H177.481C177.367 237.866 175.869 239.357 174 239.46V239.471C175.891 239.575 177.404 241.103 177.484 243H177.489C177.571 241.085 179.11 239.548 181.025 239.469V239.463Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M199.011 243.484C198.2 243.451 197.547 242.807 197.497 242H197.492C197.443 242.8 196.801 243.439 196 243.483V243.488C196.811 243.532 197.459 244.187 197.494 245H197.496C197.531 244.179 198.19 243.521 199.011 243.487V243.484Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M185.011 243.484C184.2 243.451 183.547 242.807 183.497 242H183.492C183.443 242.8 182.801 243.439 182 243.483V243.488C182.811 243.532 183.459 244.187 183.494 245H183.496C183.531 244.179 184.19 243.521 185.011 243.487V243.484Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M180.019 251.473C178.668 251.418 177.579 250.345 177.497 249H177.487C177.406 250.333 176.335 251.399 175 251.472V251.48C176.352 251.553 177.432 252.645 177.49 254H177.494C177.552 252.633 178.651 251.534 180.019 251.478V251.473Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M186.985 256H186.998C187.096 257.613 188.402 258.901 190.024 258.968V258.973C188.382 259.042 187.064 260.359 186.995 262H186.988C186.919 260.373 185.622 259.064 184 258.975V258.966C185.603 258.879 186.888 257.6 186.985 256Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M263.985 209H263.998C264.096 210.613 265.402 211.901 267.024 211.968V211.973C265.382 212.042 264.064 213.359 263.995 215H263.988C263.919 213.373 262.622 212.064 261 211.975V211.966C262.603 211.879 263.888 210.6 263.985 209Z" fill="white" class="flicker-anim_4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M198.026 210.462C196.133 210.385 194.608 208.883 194.493 207H194.481C194.367 208.866 192.869 210.357 191 210.46V210.471C192.892 210.575 194.404 212.102 194.484 214H194.49C194.571 212.085 196.11 210.547 198.026 210.468V210.462Z" fill="white" class="flicker-anim_2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M167.001 205.421C164.038 205.299 161.651 202.948 161.471 200H161.451C161.273 202.921 158.926 205.257 156 205.417V205.434C158.962 205.597 161.33 207.988 161.456 210.96H161.465C161.592 207.961 164.002 205.554 167.001 205.43V205.421Z" fill="white" class="flicker-anim_1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M143.03 188.957C140.867 188.869 139.123 187.152 138.992 185H138.978C138.848 187.132 137.136 188.837 135 188.954V188.967C137.162 189.086 138.89 190.831 138.982 193H138.988C139.081 190.811 140.841 189.053 143.03 188.964V188.957Z" fill="white" class="flicker-anim_3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M123.021 70.9678C121.4 70.8997 120.094 69.6129 119.996 68H119.985C119.888 69.5998 118.603 70.8787 117 70.9659V70.9755C118.622 71.0638 119.919 72.3729 119.988 73.9999H119.993C120.063 72.3596 121.38 71.0425 123.021 70.9736V70.9678Z" fill="white" class="flicker-anim_4"/>
                <defs>
                <filter id="filter0_f_17_723" x="0.7" y="0.7" width="269.623" height="357.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1.65" result="effect1_foregroundBlur_17_723"/>
                </filter>
                </defs>
            </svg>   

        </div>

      </div>
      
      <app-footer></app-footer>
      </div>
  </div>

</div>
