<div class="calendar__wrapper w-full h-80 sm:h-96 backdrop-blur-xl">
  <div class="calendar">
    <div class="">
      <div class="calendar__month-nav">
        <span class="text-xs sm:text-sm text-left small-font">
          {{currentDate.utc(true).format('MMMM').toUpperCase()}}
           </span>
        <span class="calendar__year-nav text-right">
            {{currentDate.format('YYYY')}}
           </span>
      </div>
    </div>
    <div class="calendar__month">
      <div class="calendar__day-names">
        <div class="calendar__day-name"
             *ngFor="let name of namesOfDays">{{translateDay(name)}}</div>
      </div>
      <div class="calendar__weeks">
        <div class="calendar__week" *ngFor="let week of weeks">

          <ng-container *ngFor="let day of week" >
            <div #numberElement class="calendar__date relative"
                 [ngClass]="{ 'today': day.today,
                              'selected': day.selected,
                              'calendar_disabled': checkEmployeeAvailability(day.mDate),
                              'select-none opacity-20 pointer-events-none cursor-not-allowed': day.mDate.clone().month() !== this.firstDayOfMonth!.clone().month()}"
                 (click)="chooseHours(day, reschedule && !isFirstTime, $event, ( reschedule && !isFirstTime ? editTerm : undefined))">
              <span class="calendar__number">{{day.mDate.date()}}</span>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>

  <div data-popupContainer class="relative"></div>

  <div class="months">
    <div *ngIf="showPreviousMonthButton()" 
        class="prev_month opacity-100 transition-all duration-300" 
        [ngClass]="{'select-none pointer-events-none opacity-20': 
        loadingRangeAvailabilityMap === true 
        && (selectMonth !== undefined 
        && !findInRangeAvailabilityMap(selectedMonth.clone().subtract(1, 'months')))}"      
        (click)="prevMonth()">
      <img src="assets/images/up-arrow.svg" alt="Up Arrow"/>
    </div>
    <div class="calendar__months">
      <div class="vertical_months">
        <div *ngFor="let month of monthsList; let last = last; let i = index" 
             class="month-item transition-all duration-300 select-none cursor-pointer"
             [class.item-disabled]="monthDisabled(month)"
             [class.item-loading]="!monthDisabled(month) && (loadingRangeAvailabilityMap === true && !findInRangeAvailabilityMap(month))"
             [class.last]="last"
             [class.selected]="isMonthSelected(i)"
             (click)="selectMonth(month)">
          {{ month.format('MMMM') }}
        </div>
      </div>
    </div>
    <div class="next_month opacity-100 transition-all duration-300" (click)="nextMonth()" 
      [ngClass]="{'select-none pointer-events-none opacity-20': 
                  loadingRangeAvailabilityMap === true 
                  && (selectedDate !== undefined 
                  && !findInRangeAvailabilityMap(selectedMonth.clone().add(1, 'months')))}">
      <img src="assets/images/down-arrow.svg" alt="Up Arrow"/>
    </div>
  </div>
</div>


<div *ngIf="windowDimensions.width >= windowDimensions.threshold_sm">
  <div class="px-5 pb-5 pt-3 w-[18rem] custom-popup_chooseHours overflow-y-auto absolute h-screen top-1/2 -translate-y-1/2 transition-all duration-500 z-[50] flex flex-col"
  [ngClass]="{'right-0 opacity-100 visible mb-[1.5rem] z-[100]': chooseHourPopupOpened,
  'right-[-50%] opacity-0 invisible': !chooseHourPopupOpened}" id="large-choose-hour-popup-container">
  
  </div>
  
  <div *ngIf="chooseHourPopupOpened" class="fixed w-full h-[100vh] z-[5] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-black/35 overflow-hidden popup-anim" (click)="toggleChooseHourPopUp(false)"> </div>
</div>


<div *ngIf="windowDimensions.width < windowDimensions.threshold_sm && mobileChooseHourPopupOpened">
  <app-swipeable-modal
  #smallChooseHourPopup
  [modal_state_function]="toggleChooseHourPopUp"
  [backgdrop]="true"
  [handle_overflow_input]="true"
  [down_swipe_limiter]="2"
  [opacity_modifier]=".2"
  (handleCloseEmitter)="toggleChooseHourPopUp(false)"
  > 
    <div id="small-choose-hour-popup-container"> </div>
  </app-swipeable-modal>
</div>

