<app-spinner> </app-spinner>
<app-svg-load-morph> </app-svg-load-morph>

<div class="min-h-screen overflow-hidden relative">
  <div class="relative h-full w-screen">
    <header class="flex flex-col justify-center h-[35rem] md:h-[55rem] lg:h-[50rem] w-full relative
    4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 align-middle overflow-hidden">
      
      <div #productBackdrop class="transition-opacity duration-300 ease-in-out opacity-1 w-full h-[90%] md:h-[87%] lg:h-[85%] left-0 top-0 absolute z-[-1] bg-no-repeat bg-cover bg-center overflow-hidden"
      [ngStyle]="{ 'background-image': 'url(' + backdropPath + ')'}"> </div>
    
      <div class="md:w-[40%] w-full mt-[-15rem] self-baseline flex flex-col gap-5 ">
        <div class="text-white text-[1.35rem] md:text-[3rem] font-custom_black text-left w-[60%] lg:w-[120%]">{{'generic-product-page.title' | translate}}
        </div>
        <div class="text-white font-custom_regular text-[0.85rem] md:text-[1.25rem] text-left">
          <span> {{'generic-product-page.title-description_part1' | translate}} </span> 
          <span class="font-custom_semibold"> {{'generic-product-page.title-description_part2' | translate}} </span>
          <span> {{'generic-product-page.title-description_part3' | translate}} </span>
        </div>  
      </div>  
    </header>
  
    <div class="absolute left-1/2 -translate-x-1/2 bottom-[-4.85vh] z-[20]">
      <app-our-products-cards [autoPlay]="false" [autoClickOnTransitionEnd]="true" [index]="getProductIndex(productId)"> </app-our-products-cards>    
    </div>
  </div>
  
  <div #productContainer> </div>
  <div class="mb-[2rem] lg:mb-[5rem]"></div>

</div>
  
<app-footer></app-footer>

<div class="fixed top-0 left-0 right-0 bottom-0 z-[-2] object-cover bg-[#0a070f]"></div>
  