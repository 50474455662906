import {Component, EventEmitter, Output} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";
import {PasswordFormActionEnum} from "../../../enums/PasswordFormActionEnum";
import {ChangePasswordRequestData} from "../../../clients/users-api/UsersApiClient.gen";
import {ToastrService} from "ngx-toastr";
import {PopUpTypeEnum} from "../../../enums/PopUpTypeEnum";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent {

  @Output() submit = new EventEmitter<PopUpTypeEnum>();
  @Output() close = new EventEmitter<void>();
  action = PasswordFormActionEnum.ChangePassword;

  constructor(private authenticationService: AuthenticationService,
              private toasterService: ToastrService,
              private translate: TranslateService) {
  }

  resetPassword(request: ChangePasswordRequestData) {
    this.authenticationService.resetPassword(request).subscribe((result) => {
      if (result == 'Success') {
        this.toasterService.success(this.translate.instant('password success changed') + '!', '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
      } else {
        this.toasterService.error(this.translate.instant('An error occurred'), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
      }
      this.submit.emit(PopUpTypeEnum.resetPassword);
    });
  }

  closePopup() {
    this.close.emit();
  }
}
