import {Injectable} from '@angular/core';
import {UsersApiClient} from "../clients/users-api/UsersApiClient";
import {Observable} from "rxjs";
import {
  BookingDataPaginationDataUsersResult,
  BookingDataUsersResult, SecurityResultStatus, TermDataPaginationDataUsersResult, UserProfileData,
  UserProfileDataUsersResult,
  UsersResultStatus
} from "../clients/users-api/UsersApiClient.gen";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private userApiClient: UsersApiClient) {

  }

  retrieveUserDetailByEmail(email: string): Observable<UserProfileDataUsersResult> {
    return this.userApiClient.retrieveUserByEmail(email);
  }

  checkForUsedTrialSession(email: string): Observable<UsersResultStatus> {
    return this.userApiClient.checkForUsedTrialSession(email);
  }

  storeUserEmail(email: string): Observable<UsersResultStatus> {
    return this.userApiClient.storeUserMail(email);
  }

  editUser(userProfileData: UserProfileData): Observable<UsersResultStatus> {
    return this.userApiClient.editUser(userProfileData);
  }

  resetPasswordWithCode(key: number | undefined, resetPasswordCode: string | undefined, newPassword: string | undefined): Observable<SecurityResultStatus>{
    return this.userApiClient.resetPasswordWithCode(key, resetPasswordCode, newPassword);
  }

}
