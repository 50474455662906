<section class="overflow-hidden z-[9]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 lg:px-10 px-2
justify-center align-middle page-height flex flex-col items-center">
  <div class="flex flex-col items-center text-white mt-[-5rem] lg:mt-[0rem]">
    <h4 *ngIf="bookingData.terms!=undefined"
        class="uppercase font-semibold text-sm md:text-base">{{bookingData!.terms!.length > 1 ? translate('user-booking-overview.title-pl') : translate('user-booking-overview.title-sg')}}</h4>
    <div class="flex mx-8 md:mx-[12vw] mt-1 lg:mt-2 mb-10 lg:mb-16">
      <img src="assets/images/neon_middle.svg" class="h-3" alt="line"/>
    </div>
    <div class="flex flex-col card_generic p-3 sm:p-5 lg:p-10 2xl:p-18 border-radius-20 mx-5">
      <app-booked-term [terms]="bookingData!.terms!"
                        [bookingId]="bookingData!.id!"
                        [employeeName]="bookingData!.employeeName!"
                        [servicePrice]="bookingData!.servicePrice!"
                        [serviceDuration]="bookingData!.serviceDuration!" class="full-width"></app-booked-term>

    </div>

  </div>
</section>

<app-svg-bg-animation> </app-svg-bg-animation>


