<app-svg-load-morph> </app-svg-load-morph>

<section class="overflow-hidden z-[9]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 lg:px-10 px-2
justify-center align-middle page-height flex flex-col items-center">

  <div class="text-white flex flex-col flex-grow justify-center w-full items-center">

    <div class="h-75 card_generic p-3 sm:p-5 lg:p-10 2xl:p-18 border-radius-20 m-auto">

      <!--      BACK BUTTON-->
      <div class="mb-6 lg:mb-4">
        <div class="flex items-center">
          <button class="w-8 h-8 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110"><img
            src="assets/images/button-arrow.svg" alt="" class="rotate-180" (click)="back()"></button>
          <hr class="w-20 h-0.5 bg-[#bdacc5] border-0">
          <h4 class="mr-3 text-xs md:text-sm ml-5 uppercase">{{'actions.back' | translate}}</h4>
        </div>
      </div>

      <!--      CONTENT-->
      <div class="flex flex-col md:flex-row justify-between items-center gap-8 lg:gap-20 w-full">

        <!--            BOOKING SUMMARY-->
        <div *ngIf="step==1 || (step==2 && screenWidth>=768)"
             [ngClass]="{'lg:w-full': loggedInUser, 'lg:w-1/2': !loggedInUser}"
             class="flex flex-col gap-2 lg:gap-5 text-left w-full lg:w-1/2">

          <!--          SERVICE TITLE-->
          <div class="flex flex-row w-full justify-between items-center">
            <div class="w-1/4 mobile-hide">
              <!--              TODO: Change icon if service is of type workout-->
              <img [src]="'assets/images/aroma.svg'"
                   class="w-3/4 sm:w-100 ">
            </div>
            <div
              class="w-3/4 mobile-stretch text-sm lg:text-base md:leading-1 font-semibold uppercase text-white">{{service.name!}}</div>
          </div>

          <!--          SERVICE PRICE AND DURATION-->
          <app-appointment-info class="full-width"
                                [servicePrice]="service.price"
                                [serviceDuration]="service!.duration"
                                [employeeName]="employeeName"></app-appointment-info>

          <!--          SERVICE DESCRIPTION-->
          <div
            class="flex flex-row w-full justify-between items-center pb-4 text-xs md:text-sm">
            {{service!.description}}
          </div>

          <!--          APPOINTMENTS-->
          <div class="flex flex-col mt-3 lg:mt-5 gap-3 lg:gap-5 ">
            <div
              class="text-sm lg:text-base uppercase font-semibold">{{bookingData!.termsList!.length == 1 ? ('user-booking-overview.title-sg' | translate) : ('user-booking-overview.title-pl' | translate)}}</div>
            <div class="flex flex-col gap-3 md:max-h-40 md:overflow-y-auto section ">
              <div *ngFor="let appointment of bookingData!.termsList!;index as i">
                <div class="flex flex-row w-100 wrapper">
                  <div class="w-5/7 flex flex-row p-3 gap-4 items-center card_generic pl-2 py-2 flex-grow">
                    <div class="w-1/7">
                      <app-icon [materialIcon]="'event_available'" [color]="'brown'"></app-icon>
                    </div>
                    <div
                      class="to-column w-6/6 flex flex-row gap-2 justify-start lg:gap-5 text text-xs sm:text-base md:text-sm lg:text_base">
                      <div>{{appointment.fromDate | date: 'dd.MM.YYYY' }}</div>
                      <div>{{appointment.fromDate | date: 'HH:mm' }} {{"hour" | translate }}</div>
                    </div>
                  </div>


                  <div *ngIf="bookingData.termsList!.length>1" id="slide" style="height: 100% !important;"
                       class="w-2/7 bg-red flex flex-col justify-center items-center px-1 lg:px-2 hover:cursor-pointer hover:scale-y-110 transition ease-in-out duration-150"
                       (click)="removeFromList(i)">
                    <span class="material-symbols-outlined text-base">delete_forever</span>
                    <div class="text-[0.5em] text">{{"delete" | translate }}</div>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>


        <!--            USER FORM-->
        <div *ngIf="!loggedInUser && (step==2  || (step==1 && screenWidth>=768))" class="w-full lg:w-1/2">
          <div class="flex flex-col sm:justify-between items-start w-full text-left">
            <div class="text-sm md:text-base lg:text-lg uppercase font-semibold mb-5 lg:mb-8">{{"customer data" | translate }}</div>
            <form [formGroup]="clientForm!" class="flex flex-col gap-3 lg:gap-5 w-100" (submit)="bookAppointment()"
                  style="width: -webkit-fill-available;">

              <!--            NAME INPUT-->
              <input placeholder="Име" [formControlName]="'name'"
                     type="text"
                     [ngClass]="{'focus:border-2 focus:border-b-white' : ((!clientForm.get('name')!.dirty && !submitted) || !clientForm.get('name')!.invalid), 
                     'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (clientForm.get('name')!.dirty || submitted) && clientForm.get('name')!.invalid, 'border border-b-white': clientForm.get('name')!.valid}"
                     class="w-100 text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

              <!--            SURNAME INPUT-->
              <input placeholder="Презиме" [formControlName]="'surname'"
                     type="text"
                     [ngClass]="{'focus:border-2 focus:border-b-white' : ((!clientForm!.get('surname')!.dirty && !submitted) || !clientForm.get('surname')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (clientForm.get('surname')!.dirty || submitted) && clientForm.get('surname')!.invalid, 'border border-b-white': clientForm.get('surname')!.valid}"
                     class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

              <!--            EMAIL INPUT-->
              <input placeholder="Електронска пошта" formControlName="email"
                     type="text"
                     [ngClass]="{'focus:border-2 focus:border-b-white' : ((!clientForm.get('email')!.dirty && !submitted) || !clientForm.get('email')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (clientForm.get('email')!.dirty || submitted) && clientForm.get('email')!.invalid, 'border border-b-white': clientForm.get('email')!.valid}"
                     class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

              <!--            PHONE NUMBER INPUT-->
              <input placeholder="Телефонски број (+38xxxxxxxx)" formControlName="phoneNumber"
                     minlength="7"
                     type="text"
                     [ngClass]="{'focus:border-2 focus:border-b-white' : ((!clientForm.get('phoneNumber')!.dirty && !submitted) || !clientForm.get('phoneNumber')!.invalid), 'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (clientForm.get('phoneNumber')!.dirty || submitted) && clientForm.get('phoneNumber')!.invalid, 'border border-b-white': clientForm.get('phoneNumber')!.valid}"
                     class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

              <div class="flex items-center mt-5 lg:mt-8 justify-end md:justify-start">
                <h4 class="mr-3 text text-xs md:text-sm">{{"confirm booking" | translate }}</h4>
                <hr class="w-20 h-0.5 bg-[#bdacc5] border-0">
                <button class="w-10 h-10 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110"><img
                  src="assets/images/button-arrow.svg" alt="" type="submit"></button>
              </div>
            </form>
          </div>


        </div>

      </div>

      <!--      MOBILE BUTTON-->
      <div *ngIf="(step==1 && screenWidth<768) ||  loggedInUser!=undefined" [ngClass]="{'md:hidden': !loggedInUser}"
           class="flex items-center justify-end mt-8"
           style="width: --webkit-fill-available">
        <h4 class="mr-3 text-xs md:text-sm">ПОНАТАМУ</h4>
        <hr class="w-20 h-0.5 bg-[#bdacc5] border-0">
        <button class="w-10 h-10 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110"><img
          src="assets/images/button-arrow.svg" alt="" (click)="nextStep()"></button>
      </div>
    </div>
  </div>

  <app-copyright class="relative bottom-0 "></app-copyright>

</section>

<app-svg-bg-animation> </app-svg-bg-animation>


