import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BodyShapeComponent } from '../highlighted-products/body-shape/body-shape.component';
import { RollShapeComponent } from '../highlighted-products/roll-shape/roll-shape.component';
import { VaEndoactiveComponent } from '../highlighted-products/va-endoactive/va-endoactive.component';
import { Subscription } from 'rxjs';
import { VibraShapeComponent } from '../highlighted-products/vibra-shape/vibra-shape.component';
import { HighlightedProductsService } from 'src/app/services/highlighted-products.service';
import { ActivShapeComponent } from '../highlighted-products/activ-shape/activ-shape.component';

export interface HighlightedProduct {
  product: HighlightedProduct | undefined;
  productId: string;
  backdropPath: string;
}

const productComponentMap: { [key: string]: any } = {};
const productClasses: any[] = [
  BodyShapeComponent, 
  RollShapeComponent, 
  VaEndoactiveComponent, 
  VibraShapeComponent,
  ActivShapeComponent
];

@Component({
  selector: 'app-highlighted-products',
  templateUrl: './highlighted-products.component.html',
  styleUrls: ['./highlighted-products.component.css']
})
export class HighlightedProductsComponent {
  productId: string = '1';
  currentComponent?: any = undefined;  
  backdropPath: string = '';
  initialRender: boolean = true;

  @ViewChild('productBackdrop') productBackdrop!: ElementRef;
  @ViewChild('productContainer') productContainer!: ElementRef;

  private routeSubscription?: Subscription;

  constructor(private route: ActivatedRoute,
              private viewContainerRef: ViewContainerRef,
              private highlightedProductsService: HighlightedProductsService) {
  }

  ngAfterViewInit(): void {
    productClasses.forEach(productClass => {
      productComponentMap[new productClass().productId] = productClass;
    });

    this.highlightedProductsService.products$.subscribe(() => {
      let product = this.highlightedProductsService.getProductById(this.productId);
      if(product !== undefined){
        if(product.productId !== this.productId) {
          this.changeComponent(product.productId);
        }
      }
    });

    window.scroll({ top: 0, left: 0, behavior: 'smooth'  });
    if(this.productContainer !== undefined){
      this.routeSubscription = this.route.paramMap.subscribe(params => {
        const productIdParam = params.get('productId');
        
        if (productIdParam) {
          this.productId = productIdParam.split(':')[1];
          this.changeComponent();
        }
      });
    }
  }

  private changeComponent(productId?: string): void { 
    if(this.currentComponent !== undefined){ 
      this.currentComponent.location.nativeElement.style.transition = 'all 0.5s ease-in-out';
      this.currentComponent.location.nativeElement.style.opacity = '0';
      setTimeout(() => {
        this.currentComponent.destroy(); 
        this.renderComponent(productId ?? this.productId);
      }, 500);
    } else {
      this.renderComponent(productId ?? this.productId);
    }
  }

  private renderComponent(productId: string): void {
    const selectedComponent = productComponentMap[productId];
    if (!selectedComponent) { return; }
  
    this.currentComponent = this.viewContainerRef.createComponent(selectedComponent);
    this.currentComponent.changeDetectorRef.detectChanges(); 
    this.productContainer.nativeElement.appendChild(this.currentComponent.location.nativeElement);
  
    let backdropPath = this.currentComponent.instance.backdropPath;
  
    if (backdropPath) {
      if (!this.initialRender) {
        this.productBackdrop.nativeElement.style.opacity = '0';
        setTimeout(() => {
          this.backdropPath = backdropPath;
          this.productBackdrop.nativeElement.style.opacity = '1';
        }, 300);
      } else {
        this.backdropPath = backdropPath;
        this.initialRender = false;
      }
    }
  }

  public getProductIndex(index: string): number {
    return isNaN(Number(index)) ? 0 : (Number(index) === 0 ? 0 : Number(index) - 1);
  }

}


