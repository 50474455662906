<div appHighlightProduct scrollReveal class="appear-anim w-screen min-h-max z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 pb-12 xl:pt-20 align-middle relative overflow-hidden">
  <div class="full-width flex flex-col text-white mt-20">

    <!--    SECTION 1-->
    <div class="flex flex-col mt-[-3rem] md:mt-[2rem] lg:mt-12 full-width items-end">

      <div class="flex flex-row justify-end w-full gap-5">

        <!--        IMAGE-->
        <div class="md:block hidden md:w-[75%] lg:w-[45%] z-[-1] max-h-[600px] md:mt-[2rem] lg:mt-[-5rem]">
          <img src="assets/images/va-endoactive-image-1.png">
        </div>

        <div class="w-full flex flex-col gap-[2rem] lg:gap-[5rem]">

            <div class="flex flex-col gap-5">
                <!--  TITLE-->
                <div class="flex flex-col gap-1">
                    <div class="text-xl md:text-3xl uppercase font-custom_semibold">{{'va-endoactiv-page.title' | translate}}</div>
                    <div class="text-sm">{{'va-endoactiv-page.title-description' | translate}}</div>
                </div>

                <!--        DESCRIPTION 1-->
                <div class="text-white text-lg lg:text-2xl font-custom_italic text-left">
                "{{'va-endoactiv-page.description-heading' | translate}}"
                </div>
            </div>

            <!--        DESCRIPTION 2-->
            <div class="flex flex-col gap-12 lg:pl-[5rem]">
                <div class="w-40 border-[0.5px] border-transparent border-b-white lg:mb-8"></div>
                <div class="texts-sm md:text-lg text-justify">{{'va-endoactiv-page.description-section-1' | translate}}</div>

                <div class="texts-sm md:text-lg text-justify lg:w-4/5 transition-all duration-150"
                [ngClass]="{'max-h-[1000px] opacity-100 mb-[-5rem] visible': readMore_1,
                'max-h-[0px] opacity-0 invisible mb-[-1rem] pb-[0rem] md:max-h-full md:opacity-100 md:visible': !readMore_1}">
                  {{'va-endoactiv-page.description-section-2' | translate}}
                </div>
            </div>
        </div>

      </div>
    </div>

    <!--    SECTION 2-->
    <div class="reveal flex flex-col items-start mt-[7.5rem] lg:mt-[10.68rem] mb-[3.5rem] lg:mb-[-6.25rem] gap-8 md:gap-5 transition-all duration-150"
    [ngClass]="{'max-h-[1000px] opacity-100 visible mb-[1.5rem]': readMore_1,
    'max-h-[0px] opacity-0 invisible md:max-h-full md:opacity-100 md:visible': !readMore_1}">
        <div class="text-white text-lg md:text-2xl font-semibold">{{'va-endoactiv-page.how_it_works-title' | translate}}</div>
        <div class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-full">{{'va-endoactiv-page.how_it_works-description' | translate}}</div>
    </div>

    <span class="md:hidden block font-custom_regular text-white w-max
    opacity-50 hover:opacity-100 active:opacity-100 cursor-pointer text-left select-none mb-[2rem] z-[50]"
    (click)="toggleReadMore1()"
    [ngClass]="{'mt-[-2rem]': readMore_1,
    '-mt-[12rem] mb-[0rem]': !readMore_1}">
        <p *ngIf="!readMore_1"> {{'actions.read_more' | translate}} </p>
        <p *ngIf="readMore_1"> {{'actions.read_less' | translate}} </p>
    </span>

    <!--    SECTION 3-->
    <div class="reveal lg:h-[60rem] justify-center w-full flex relative mb-[3.5rem] lg:mb-auto">

        <div class="lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-x-[52%] lg:-translate-y-[50%] lg:w-full flex">
            <!--    CENTER IMAGE-->
            <div class="lg:opacity-100 opacity-50 absolute top-1/2 left-1/2 -translate-x-1/2 lg:-translate-x-[45%] -translate-y-1/2 lg:-translate-y-[40%]
             w-[27rem] h-[27rem] 4xl:w-[35rem] 4xl:h-[35rem] bg-no-repeat bg-center bg-cover z-[-1]"
            style="background-image: url(./assets/images/the_bubble.png);">
            </div>

            <div class="lg:block hidden absolute top-1/2 left-1/2 -translate-x-1/2 lg:-translate-x-[45%] -translate-y-1/2 lg:-translate-y-[40%]
            w-[33rem] h-[33rem] 4xl:w-[35rem] 4xl:h-[35rem] bg-[#0c0912] rounded-full blur-2xl z-[-2]">
            </div>

            <!--    LIST VIEW-->
            <div class="lg:hidden flex flex-col gap-[2rem] p-10 orbitalItem-list-bg" id="orbital-list"> </div>

            <!--    LEFT SEMI-CIRCLE-->
            <div class="table opacity-0 lg:opacity-100 h-auto lg:h-[150%] lg:top-1/2 lg:-translate-y-1/2 lg:flex-1 relative lg:ml-[-5rem]">
                <div class="table-row-group">
                    <div class="table-cell"> </div>
                    <div id="orbit_L" class="lg:h-full relative self-end table-cell lg:w-2/3"> </div>
                </div>
            </div>

            <!--    RIGHT SEMI-CIRCLE-->
            <div class="table opacity-0 lg:opacity-100 h-auto lg:h-[150%] lg:top-1/2 lg:-translate-y-1/2 lg:flex-1 relative lg:mr-[-5rem]">
                <div class="table-row-group">
                    <div id="orbit_R" class="lg:h-full relative self-end table-cell lg:w-2/3"> </div>
                    <div class="table-cell"></div>
                </div>
            </div>
        </div>
    </div>

    <!--    SECTION 4-->
    <div class="reveal flex flex-col items-center">

      <!--      TITLE-->
      <div class="flex flex-col items-end">
        <div class="flex flex-col md:flex-row gap-2">
          <div class="text-xl md:text-3xl uppercase text-center md:text-left w-full md:w-auto">{{'va-endoactiv-page.functionalities' | translate}}</div>

          <!--       BOLD TEXT & NEON LINE -->
          <div>
              <div class="text-xl md:text-3xl uppercase font-custom_bold mb-[0.5rem] text-center md:text-left">{{'va-endoactiv-page.functionalities-bold' | translate}}
              </div>

              <svg viewBox="0 0 326 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_2046_1629)">
                <path d="M4 9H322" stroke="url(#paint0_linear_2046_1629)" stroke-width="9"/>
                </g>
                <g filter="url(#filter1_f_2046_1629)">
                <path d="M4 9H322" stroke="url(#paint1_linear_2046_1629)" stroke-width="4"/>
                </g>
                <defs>
                <filter id="filter0_f_2046_1629" x="0" y="0.5" width="326" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2046_1629"/>
                </filter>
                <filter id="filter1_f_2046_1629" x="2" y="5" width="322" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_2046_1629"/>
                </filter>
                <linearGradient id="paint0_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                <stop stop-color="#CC00FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                </linearGradient>
                </defs>
            </svg>
          </div>
        </div>

      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-[4rem] lg:gap-[5rem] md:gap-[8rem] justify-between mt-[3rem] lg:mt-[5rem]">
        <div *ngFor="let item of items">
          <app-product-info-item [productInfoData]="item"></app-product-info-item>
        </div>
      </div>

    </div>

  </div>
</div>

