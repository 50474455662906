import {Injectable} from '@angular/core';
import {LoginRequestData, UserProfileData} from "../clients/users-api/UsersApiClient.gen";
import { UserProfileDto } from 'src/app/models/UserProfileDto';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() {
  }

  setValue(key: string, value: any) {
    return localStorage.setItem(key, value);
  }

  getValue(key: string): any {
    return localStorage.getItem(key);
  }

  removeKey(key: string) {
    return localStorage.removeItem(key);
  }

  removeAll() {
    return localStorage.clear();
  }

  setProfile(userProfile: UserProfileDto) {
    sessionStorage.setItem("profile", JSON.stringify(userProfile));
  }

  removeProfile() {
    sessionStorage.removeItem("profile");
  }

}
