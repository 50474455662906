<div appHighlightProduct scrollReveal class="appear-anim w-screen min-h-max z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 xl:pt-20 align-middle relative overflow-hidden">
  <div class="full-width flex flex-col text-white mt-20">

    <!--    SECTION 1-->
    <div class="flex flex-col mt-[-3rem] md:mt-[2rem] lg:mt-12 full-width items-end relative">

      <div class="flex flex-row justify-end w-full gap-5">

        <div class="w-[55%] md:block hidden"> </div>

        <!--        IMAGE-->
        <div class="md:block absolute hidden z-[-1] left-[-5rem] top-[-2rem] w-[45rem] mt-[5rem]">
          <img src="assets/images/activ-shape-image.png">
        </div>

        <div class="w-full flex flex-col gap-[2rem] lg:gap-[5rem] ">

            <div class="flex flex-col gap-5">
                <!--  TITLE-->
                <div class="flex flex-col gap-1 md:absolute relative left-0">
                    <div class="text-xl md:text-3xl uppercase font-custom_semibold">{{'activ-shape-page.title' | translate}}</div>
                    <div class="text-sm">{{'activ-shape-page.title-description' | translate}}</div>
                </div>

                <!--        DESCRIPTION 1-->
                <div class="text-white text-lg lg:text-2xl font-custom_italic text-left md:mt-[4rem]">
                "{{'activ-shape-page.description-heading' | translate}}"
                </div>
            </div>

            <!--        DESCRIPTION 2-->
            <div class="flex flex-col gap-12 lg:pl-[5rem]">
                <div class="w-40 border-[0.5px] border-transparent border-b-white lg:mb-8"></div>
                <div class="texts-sm md:text-lg text-justify">{{'activ-shape-page.description-section-1' | translate}}</div>

                <div class="texts-sm md:text-lg text-justify lg:w-4/5 transition-all duration-150"
                [ngClass]="{'max-h-[1000px] opacity-100 mb-[-5rem] visible': readMore_1,
                'max-h-[0px] opacity-0 invisible mb-[-1rem] pb-[0rem] md:max-h-full md:opacity-100 md:visible': !readMore_1}">
                  {{'activ-shape-page.description-section-2' | translate}}
                </div>
            </div>
        </div>

      </div>
    </div>

    <!--    SECTION 2-->
    <div class="flex flex-col items-start mt-[7.5rem] lg:mt-[10.68rem] mb-[3.5rem] md:mb-[-6.25rem] gap-8 md:gap-5 transition-all duration-150"
    [ngClass]="{'max-h-[1000px] opacity-100 visible': readMore_1,
    'max-h-[0px] opacity-0 invisible md:max-h-full md:opacity-100 md:visible': !readMore_1}">
        <div class="flex flex-col items-start gap-8 md:gap-5 z-[-1]">
          <div class="text-lg md:text-2xl font-custom_semibold">{{'activ-shape-page.energy-title' | translate}}</div>
          <div class="flex flex-col md:flex-row gap-5 md:gap-10">
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-1/2">{{'activ-shape-page.energy-description-1' | translate}}</div>
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-1/2">{{'activ-shape-page.energy-description-2' | translate}}</div>
          </div>
        </div>
    </div>

    <span class="md:hidden block font-custom_regular text-white w-max
    opacity-50 hover:opacity-100 active:opacity-100 cursor-pointer text-left select-none mb-[2rem] z-[50]"
    (click)="toggleReadMore1()"
    [ngClass]="{'mt-[-2rem]': readMore_1,
    '-mt-[12rem] mb-[0rem]': !readMore_1}">
        <p *ngIf="!readMore_1"> {{'actions.read_more' | translate}} </p>
        <p *ngIf="readMore_1"> {{'actions.read_less' | translate}} </p>
    </span>

    <!--    SECTION 3-->
    <div class="mb-[3.5rem] md:mt-[3.5rem] lg:mb-auto relative w-full h-full md:min-h-[41rem]">
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[119rem] md:min-h-[41rem] z-[-1]">
            <img src="/assets/images/infrared-graphic.svg">
        </div>

        <div class="flex flex-col md:flex-row gap-5 md:gap-[1.5rem] infrared-mobile-bg md:p-0 p-10 justify-between z-[1] w-full relative md:absolute md:top-1/2 md:left-1/2 md:md:-translate-x-1/2 md:-translate-y-1/2">
            <div class="reveal w-full block md:hidden">
                <div class="flex flex-col gap-3 w-full text-center justify-center align-middle h-full">
                    <div class="text-[1.75rem] xs:text-[1rem] font-custom_black uppercase select-none infrared-text"> {{'activ-shape-page.infraredIR-title-1' | translate}} </div>
                    <div class="text-[1.25rem] xs:text-[.75rem] md:text-[1.5rem] uppercase text-white"> {{'activ-shape-page.infraredIR-title-2' | translate}} </div>
                </div>
            </div>

            <div class="reveal w-full md:w-1/3 text-[#DCDCDC] text-sm md:text-base text-justify">
                {{'activ-shape-page.infraredIR-description-1' | translate}}
            </div>

            <div class="reveal w-[35%] hidden md:block">
                <div class="flex flex-col gap-3 w-full text-center justify-center align-middle h-full">
                    <div class="lg:text-[2.5rem] md:text-[2.25rem] font-custom_black uppercase select-none infrared-text"> {{'activ-shape-page.infraredIR-title-1' | translate}} </div>
                    <div class="lg:text-[1.7rem] md:text-[1.5rem] uppercase infrared-text"> {{'activ-shape-page.infraredIR-title-2' | translate}} </div>
                </div>
            </div>

            <div class="reveal w-full md:w-1/3 text-[#DCDCDC] text-sm md:text-base text-justify">
                {{'activ-shape-page.infraredIR-description-2' | translate}}
            </div>
        </div>
    </div>

    <!--    SECTION 4-->
    <div class="reveal flex flex-col-reverse md:flex-row gap-[2rem] md:gap-0 items-center align-middle justify-between xs:mt-[1rem]  md:mt-[1.5rem]">
        <div class="md:w-[54%] flex flex-row justify-center">
            <img src="assets/images/temperature-slider-graphic.png">
          </div>

        <div class="md:w-[46%]">
          <div class="text-white text-[1rem] md:text-2xl font-custom_italic mb-[1.5rem]">{{'activ-shape-page.temperature-title' | translate}}</div>
          <div class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-full">{{'activ-shape-page.temperature-description' | translate}}</div>
        </div>
    </div>

    <!--    SECTION 5-->
    <div class="flex flex-col md:flex-row gap-[2rem] md:gap-0 items-center align-middle justify-between mt-[1.5rem] md:mt-[3.5rem] w-full">
        <div class="reveal md:w-3/5">
          <div class="text-white text-lg md:text-3xl font-custom_regular mb-[1.5rem]">{{'activ-shape-page.colors-title' | translate}}</div>
          <div class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-full">{{'activ-shape-page.colors-description' | translate}}</div>
        </div>
        <div class="md:w-2/5 flex flex-row justify-end">
          <img src="assets/images/color-sphere-decoration.png">
        </div>
    </div>

  </div>
</div>

