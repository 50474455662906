import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Navigation, NavigationExtras, Router } from '@angular/router';
import { EmployeeService } from 'src/app/services/employee.service';
import { AddBookingData, BookedTermData, EmployeeData, ServiceData, TermData, UsersResultStatus } from 'src/app/clients/users-api/UsersApiClient.gen';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Location } from "@angular/common";
import { BookingService } from "../../../services/booking.service";
import * as moment from "moment";
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { CalendarDate } from 'src/app/components/calendar/calendar.component';
import { WindowDimensions, WindowDimensionsService } from 'src/app/services/window-dimension-service.service';
import { SwipeableModalComponent } from 'src/app/components/swipeable-modal/swipeable-modal.component';
import * as Hammer from 'hammerjs';
import { ScrollLockConfig, ScrollLockService } from 'src/app/services/scroll-lock.service';

const termsListMobileButtonXMargin = 20;
const termsListMobileButtonYMargin = 50;

@Component({
  selector: 'app-book-page',
  templateUrl: './book-page.component.html',
  styleUrls: ['./book-page.component.css']
})
export class BookPageComponent implements OnInit {
  service!: ServiceData
  serviceId!: number
  employeeId!: number
  availableEmployees!: EmployeeData[];
  addBookingData: AddBookingData = new AddBookingData()

  termsMobileOpen: boolean = false;
  edit: boolean = false
  termToEdit!: TermData
  showCancelConfirmation: boolean = false
  displayConfirmationPopUp: boolean = false;
  isTrialSession: boolean = false
  rescheduleTermId!: number

  windowDimensions: WindowDimensions = {} as WindowDimensions;

  @ViewChild(CalendarComponent) calendarComponent!: CalendarComponent;
  @ViewChild(SwipeableModalComponent) mobile!: SwipeableModalComponent;
  @ViewChild('termsListMobileButton') termsListMobileButton!: ElementRef;

  private hammer!: HammerManager;
  termsListMobileButtonStartPosX: number = 0; termsListMobileButtonStartPosY: number = 0;
  termsListMobileButtonLastPosX: number = 0; termsListMobileButtonLastPosY: number = 0;

  constructor(private employeeService: EmployeeService,
              private router: Router,
              private toasterService: ToastrService,
              private translationService: TranslateService,
              private _location: Location,
              private bookingService: BookingService,
              private _windowDimensionService: WindowDimensionsService,
              private translate: TranslateService,
              private scrollLockService: ScrollLockService) {

    this._windowDimensionService.getWindowDimensions$().subscribe((dimensions) => {
      this.windowDimensions = dimensions;
      if(this.windowDimensions.width >= this.windowDimensions.threshold_sm && this.termsMobileOpen){
        this.termsMobileOpen = false;
      }
    });

    let nav: Navigation = this.router.getCurrentNavigation()!;

    if (nav.extras && nav.extras.state && nav.extras.state['serviceId'] && nav.extras.state['service']) {
      this.serviceId = nav.extras.state['serviceId'] as number;
      this.service = nav.extras.state['service'] as ServiceData;

      //If the user is editing a term
      if (nav.extras.state['term'] && nav.extras.state['edit']) {
        this.edit = nav.extras.state['edit'] as boolean
        this.termToEdit = nav.extras.state['term'] as TermData
        this.employeeId = nav.extras.state['employeeId']! as number;

        if (this.edit)
          this.rescheduleTermId = this.termToEdit.id!
      }

      if (this.termToEdit && this.termToEdit.cancelled) {
        this.toasterService.error(this.translate.instant("the requestedhas been cancelled"), '', {positionClass: 'toast-top-center', timeOut: 4000})
        this.router.navigateByUrl('/home');
      }
      this.addBookingData.termsList = [];
    }

    if (localStorage.getItem('termsList') != undefined && localStorage.getItem('serviceId') != undefined && localStorage.getItem('service') != undefined) {
      this.addBookingData.termsList = JSON.parse(localStorage.getItem('termsList')!) as BookedTermData[]
      this.serviceId = JSON.parse(localStorage.getItem('serviceId')!) as number
      this.service = JSON.parse(localStorage.getItem('service')!) as ServiceData
      this.employeeId = this.addBookingData.termsList![0].employeeProfileId!
      localStorage.clear();
    }

    //If the chosen service is a trial session
    //TODO: Maybe change
    if (this.service !== undefined && this.service.credits !== undefined && this.service.price !== undefined && this.service.credits == 0 && this.service.price == 0)
      this.isTrialSession = true;

    this.addBookingData.serviceId = this.serviceId!;
  }

  ngOnInit(): void {
    this.retrieveEmployeesByServiceId()
    if (!this.edit)
      this.retrieveAnyAvailableEmployeeByServiceId();
  }

  ngAfterViewInit() {
    // Terms list mobile button drag
    if (this.termsListMobileButton) {
      this.termsListMobileButton.nativeElement.style.marginLeft = termsListMobileButtonXMargin + 'px';
      this.termsListMobileButton.nativeElement.style.marginRight = termsListMobileButtonXMargin + 'px';

      this.hammer = new Hammer(this.termsListMobileButton.nativeElement);

      this.hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL, threshold: 0.1, velocity: 0.1 });

      this.hammer.on('panstart', (event: HammerInput) => {
        event.preventDefault();
        this.termsListMobileButtonStartPosX = this.termsListMobileButtonLastPosX;
        this.termsListMobileButtonStartPosY = this.termsListMobileButtonLastPosY;
      });

      this.hammer.on('pan', (event: HammerInput) => {
        event.preventDefault();

        let posX = this.termsListMobileButtonStartPosX + event.deltaX;
        let posY = this.termsListMobileButtonStartPosY + event.deltaY;

        this.termsListMobileButtonLastPosX = posX;
        this.termsListMobileButtonLastPosY = posY;

        this.termsListMobileButton.nativeElement.style.transform = `translate(${posX}px, ${posY}px)`;
      });

      this.hammer.on('panend', () => {
        let currentPosX = this.termsListMobileButton.nativeElement.getBoundingClientRect().right;
        let currentPosY = this.termsListMobileButton.nativeElement.getBoundingClientRect().bottom;

        let posX = currentPosX > (this.windowDimensions.width / 2)
        ? this.windowDimensions.width - this.termsListMobileButton.nativeElement.offsetWidth - (termsListMobileButtonXMargin * 2) : 0;

        let posY = currentPosY <= (this.windowDimensions.height / 2)
        ? (this.windowDimensions.height - this.termsListMobileButton.nativeElement.offsetHeight  - (termsListMobileButtonYMargin * 2)) * -1 : 0;

        this.termsListMobileButtonLastPosX = this.termsListMobileButtonStartPosX = posX;
        this.termsListMobileButtonLastPosY = this.termsListMobileButtonStartPosY = posY;
        this.termsListMobileButton.nativeElement.style.transform = `translate(${posX}px, ${posY}px)`;
      });
    }
  }

  /**
   * @description Retrieves all employees that provide a given service
   */
  retrieveEmployeesByServiceId() {
    this.employeeService.retrieveEmployeesByServiceId(this.serviceId!).subscribe((data: any) => {
      this.availableEmployees = data.content.items;
    })
  }

  /**
   * @description Retrieves the first available employee
   */
  retrieveAnyAvailableEmployeeByServiceId() {
    this.employeeService.retrieveAnyAvailableEmployeeByServiceId(this.serviceId!).subscribe(result => {
      this.employeeId = result.content?.id!;
    })
  }

  /**
   * @description Returns employee name and surname string for a provided employeeProfileId
   * @param employeeProfileId
   */
  retrieveEmployeeById(employeeProfileId: number) {
    if (this.availableEmployees) {
      let employee = this.availableEmployees!.filter(x => x.id == employeeProfileId)[0]!
      return employee.name + ' ' + employee.surname;
    }
    return ''
  }

  /**
   * @description Changes the currently selected employeeId
   * @param employeeId
   */
  changeEmployee(employeeId: number) {
    if (employeeId != undefined)
      this.employeeId = employeeId;
  }

  private refreshTermsList(event: any): void {
    this.calendarComponent.currentTermsList = this.addBookingData.termsList!;
    if (this.edit || this.isTrialSession) {
      this.calendarComponent.editTerm = new Date(event[0].fromDate);
    }
  }

  /**
   * @description Updates the list of selected terms
   */
  public updateTermsList(event: any) {
    if (!(event instanceof SubmitEvent)) {
      this.addBookingData.termsList = event;
      this.refreshTermsList(event);
    }
  }

  /**
   * @description Adds term to the list of selected terms
   */
  public addTermsToList(event: any) {
    if (!(event instanceof SubmitEvent)) {
      this.addBookingData.termsList?.push(...event);
      this.addBookingData.termsList = this.sortData(this.addBookingData.termsList!)
      this.refreshTermsList(event);
    }
  }

  /**
   * @description Removes term from the list of selected terms
   */
  removeFromList(index: number) {
    let modifiedTermsList: BookedTermData[] = []
    this.addBookingData.termsList?.forEach((element, position) => {
      if (position != index)
        modifiedTermsList.push(element)
    });
    this.addBookingData.termsList = modifiedTermsList.sort((x, y) => x.fromDate?.getDate()! - y.fromDate?.getDate()!);
    this.calendarComponent.currentTermsList = this.addBookingData.termsList;

    if(this.termsMobileOpen && this.addBookingData.termsList.length === 0){
      this.mobile.handleClose();
    }
  }

  /**
   * @description Notifies calendar component that a pop-up for the selected term date should be opened
   */
  editTerm(index: number) {
    let closedAPopup: boolean = false;
    if(this.termsMobileOpen) { this.mobile.handleClose(); closedAPopup = true; }
    setTimeout(() => {
      var editingTerm = new Date(this.addBookingData.termsList![index].fromDate!);
      var editingTermDate: CalendarDate = { mDate: moment(editingTerm).utc(true) };
  
      this.calendarComponent.chooseHours(editingTermDate, true);
    }, closedAPopup ? 250 : 0);
  }

  /**
   * @description Sorts the filtered terms in ascending order
   */
  sortData(data: TermData[]) {
    return data!.sort((a, b) => {
      return <any>new Date(a.fromDate!) - <any>new Date(b.fromDate!);
    });
  }

  /**
   * @description Navigates to client data screen
   */
  navigateToClientData() {
    if (this.addBookingData!.termsList?.length == 0) {
      this.toasterService.warning(this.translationService.instant('booking-page.warning'), '', {
        positionClass: 'toast-top-center',
        timeOut: 4000
      })
      return
    }

    const navigationExtras = {
      state: {
        addBookingData: this.addBookingData,
        employeeId: this.employeeId
      }
    } as NavigationExtras
    this.router.navigateByUrl('/client-info', navigationExtras)
  }

  back() {
    this._location.back();
  }

  cancelBooking() {
    this.showCancelConfirmation = true;

    this.scrollLockService.disableScroll({
      allow_touch_input_on: undefined,
      handle_extreme_overflow: false,
      animation_duration: 0,
      handle_touch_input: true,
      mobile_only_touch_prevention: false
    } as ScrollLockConfig);
  }

  openRescheduleConfirmationPopUp() {
    if (this.addBookingData.termsList?.length == 0)
      this.toasterService.warning(this.translate.instant('Please select an appointment'), '', {positionClass: 'toast-top-center', timeOut: 5000})
    else {
      this.displayConfirmationPopUp = true;
      this.scrollLockService.disableScroll({
        allow_touch_input_on: undefined,
        handle_extreme_overflow: false,
        animation_duration: 0,
        handle_touch_input: true,
        mobile_only_touch_prevention: false
      } as ScrollLockConfig);
    }
  }

  rescheduleTerm() {
    let termData = {
      profileId: this.termToEdit.profileId,
      serviceId: this.termToEdit.serviceId,
      employeeProfileId: this.employeeId,
      id: this.termToEdit.id,
      serviceName: this.termToEdit.serviceName,
      fromDate: moment(this.addBookingData.termsList![0].fromDate).utc(true).toDate(),
      recurringTermGroupId: this.termToEdit.recurringTermGroupId,
      toDate: moment(this.addBookingData.termsList![0].fromDate).add(this.service.duration, 'minutes').utc(true).toDate()
    } as TermData

    this.bookingService.editTerm(termData).subscribe(result => {
      if (result.status == UsersResultStatus.Success) {
        this.displayConfirmationPopUp = false;
        this.scrollLockService.enableScroll(false);
        this.toasterService.success(this.translate.instant('Appointment successfully rescheduled'), '', {positionClass: 'toast-top-center', timeOut: 4000})

        let nav = {
          state: {
            employeeId: this.employeeId
          }
        } as NavigationExtras

        this.router.navigateByUrl('/user-booking/' + result.content, nav)
      } else {
        this.toasterService.error(this.translate.instant('An error occurred'), '', {
          positionClass: 'toast-top-center',
          timeOut: 4000
        })
        this.closePopUp(false)
      }
    })
  }

  closePopUp(cancel: boolean, redirect?: boolean) {
    if (cancel)
      this.showCancelConfirmation = false;
    else
      this.displayConfirmationPopUp = false;

    if (redirect)
      this.router.navigateByUrl('/home');
      this.scrollLockService.enableScroll(false);
  }

  toggleTermsMobileOpen(state?: boolean) {
    this.termsMobileOpen = state !== undefined ? state : !this.termsMobileOpen;
  }
}
