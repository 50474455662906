<div class="popup-position top-0 left-0 flex items-center justify-center w-max popup-anim" style="max-width: 95% !important;">
  <div class="py-2 sm:py-4 px-5 sm:px-10 popup-background flex flex-col items-center shadow-md " style="width: 600;">
    <div class="mb-2">
      <img src="../../../../assets/images/cancel-confirmation-icon.svg" class="h-24 w-24">
    </div>
    <h4
      class="w-full md:w-2/3 text-xs lg:text-base text-center text-white font-semibold">{{numberOfTerms == 1 ? translate('actions.confirm-cancel') : translate('actions.confirm-cancel-pl')}}</h4>
    <div class="flex flex-row justify-between gap-5 mt-8 w-full">
      <button (click)="closePopup()"
              class="w-full sm:w-1/2 btn btn-sm bg-transparent deep-purple-button text-white text-2xs lg:text-sm font-semibold self-end p-1 sm:p-2 rounded-md select-none shadow-md uppercase">
        {{'actions.back' | translate}}
      </button>
      <button (click)="cancel()"
              class="w-full sm:w-1/2 btn btn-sm cancel-button text-white text-2xs lg:text-sm font-semibold self-end p-1 sm:p-2 rounded-md select-none shadow-md uppercase">
        {{'booking-confirmation-component.cancelBooking' | translate}}
      </button>
    </div>
  </div>
</div>
<div class="overlay popup-anim" (click)="closePopup()"></div>
