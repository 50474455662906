<div class="text-white">
  <div class="flex flex-row gap-3 md:gap-5 items-center">
    <img [src]="productInfoData!.iconPath" class="w-16 h-16">
    <div class="text-base md:text-xl font-bold uppercase">{{mode === translationMode ? translate(productInfoData!.titleKey) : productInfoData!.titleKey}}</div>
  </div>
  <div *ngIf="productInfoData.headingKey != undefined"  
        [ngClass]="{'my-5 md:my-10 ': productInfoData.descriptionKey != undefined, 'mt-5 md:mt-10': productInfoData.descriptionKey == undefined}" 
        class="text-sm md:font-custom_semibold_italic lg:text-[1rem] text-justify">{{mode === translationMode ? translate(productInfoData.headingKey) : productInfoData.headingKey}}
  </div>
  <div *ngIf="productInfoData.descriptionKey != undefined" class="text-sm md:text-base text-stone-400 text-justify">{{mode === translationMode ? translate(productInfoData.descriptionKey) : productInfoData.descriptionKey}}</div>
</div>
