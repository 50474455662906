import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BookingData} from "../../clients/users-api/UsersApiClient.gen";
import {BookingService} from "../../services/booking.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-user-booking-overview',
  templateUrl: './user-booking-overview.component.html',
  styleUrls: ['./user-booking-overview.component.css']
})
export class UserBookingOverviewComponent {

  bookingToken: string = ''
  bookingData: BookingData = new BookingData()

  constructor(private route: ActivatedRoute,
              private bookingService: BookingService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.bookingToken = routeParams.get('bookingToken')!.toString();
    this.retrieveBookingByToken();
  }

  retrieveBookingByToken() {
    this.bookingService.retrieveBookingByBookingToken(this.bookingToken).subscribe(result => {
      if (result.content) {
        this.bookingData = result.content;
      }
    })
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }

}
