<nav
  class="sticky w-screen z-50 top-0 start-0 transition duration-500 ease-in-out custom_nav"
>
  <div
    class="w-full flex items-center justify-between mx-auto py-4 sm:py-5 px-10 sm:px-14"
  >
    <!-- Logo -->
    <div class="md:w-[25%] overflow-hidden">
      <a href="/home" 
      class="inline-block h-full align-middle"
      aria-label="home-page"
      aria-labelledby="home-page">
        <img
          src="assets/images/logo.svg"
          class="h-5 w-[120px] sm:w-[100px] md:w-[120px]"
          alt="VacuActiv Logo"
        />
      </a>
    </div>

    <!-- Mobile Hamburger Menu -->
    <div
      class="flex items-center space-x-3 lg:flex md:items-center lg:space-x-5 z-40 py-2"
    >
      <div class="md:hidden">
        <button
          type="button"
          (click)="toggle()"
          id="hamburger-menu"
          aria-label="Toggle Menu"
          aria-labelledby="hamburger-menu"
          class="flex justify-between flex-col items-start md:hidden cursor-pointer h-[15px]"
        >
          <div
            [ngClass]="{'rotate_top' : isOpen,}"
            class="menu_line w-[22px] h-[2px] bg-white transition-all duration-300 flex flex-col justify-center items-center align-middle relative rounded-2xl"
          ></div>
          <div
            [ngClass]="{ 'opacity-0': isOpen }"
            class="menu_line w-[22px] h-[2px] bg-white transition-all duration-300 flex flex-col justify-center items-center align-middle relative rounded-2xl"
          ></div>
          <div
            [ngClass]="{'rotate_bottom': isOpen, }"
            class="menu_line w-[22px] h-[2px] bg-white transition-all duration-300 flex flex-col justify-center items-center align-middle relative rounded-2xl"
          ></div>
        </button>
      </div>
    </div>

    <!-- Middle -->
    <div
      class="hidden md:flex md:flex-grow md:items-center ml-0 md:justify-center"
    >
      <ul class="flex flex-row md:gap-2 lg:gap-5">
        <li
          *ngFor="let item of navItems"
          (click)="toggleActiveState(item); closeMenu()"
          id="{{ item.route }}"
          class="relative"
        >
          <a
            routerLink="{{ item.route }}"
            routerLinkActive="active-link"
            class="text-3xs text-xs block py-2 px-1 inactive-menu-item md:font-small font-medium hover:text-[#9f7ee1]"
          >
            {{ "navigation." + item.name | translate }}
          </a>
        </li>
      </ul>
    </div>

    <!-- End -->
    <div class="hidden md:flex lg:items-center w-[20%]">
      <div class="flex flex-row w-full justify-end self-end md:gap-1 lg:gap-3 md:text-[.625rem] ml:text-[.425rem] ">

        <button *ngIf="!loggedInUser" 
        (click)="openLoginPopup()"
        aria-label="Login"
        aria-labelledby="Login"
        id="Login"
        role="button"
        class="text-skin-secondary buttonBg font-medium py-2 px-4 text-center rounded-[2em]" >
          {{ "actions.login" | translate }}
        </button>

        <button *ngIf="!loggedInUser" 
        (click)="openSignupPopup()"
        id="Signup"
        class="text-skin-secondary border border-solid border-skin-primary font-medium py-2 px-4 text-center rounded-[2em]"
        aria-label="Signup"
        aria-labelledby="Signup">
          {{ "actions.register" | translate }}
        </button>


        <div *ngIf="loggedInUser" class="w-max h-max wrapper relative" (clickOutside)="closeUserMenu()" id="user">
          <div class="flex flex-row items-center hover:cursor-pointer pr-5"
               (click)="expandProfileMenu()">
            <div class="text-white text-sm px-5 z-20">{{loggedInUser.name + ' ' + loggedInUser.surname}}</div>
            <div class="text-white flex flex-col justify-center z-20">
              <span class="material-symbols-outlined" [ngClass]="{'rotate-180': expanded}">expand_more</span>
            </div>
          </div>
        </div>
        <div *ngIf="expanded && loggedInUser"
             class="absolute top-0 r-0 w-full h-64 card_background slider-top slide-in " [ngStyle]="{'width.px': elementWidth}"
             @flyOut>
          <div class="flex flex-col gap-3 mt-20 mb-5 pl-5 pr-10">
            <div *ngFor="let item of profileNavItems" (click)="toggleActiveState(item); closeMenu()"
                 id="{{ item.route }}" class="relative">
              <a routerLink="{{item.route}}"
                 class="text-3xs text-xs block py-2 px-1 text-white md:font-small font-medium hover:text-[#9f7ee1]">
                {{ "navigation." + item.name | translate }}
              </a>
            </div>
            <div (click)="openResetPasswordPopUp()"><a
              class="text-3xs text-xs block py-2 px-1 text-white md:font-small font-medium hover:text-[#9f7ee1] hover:cursor-pointer uppercase">
              {{ "navigation.change-password" | translate }}
            </a></div>
            <div class="mr-5 border border-white"></div>
            <div>
              <a (click)="signOut()"
                 class="text-3xs text-xs py-2 px-1 text-[#F0E7FF] md:font-small font-medium hover:text-[#9f7ee1] hover:cursor-pointer flex gap-3 items-center uppercase">
                {{ "navigation.log-out" | translate }}
                <div><span class="material-symbols-outlined">logout</span></div>
              </a></div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="menu" [@menuAnimation]="isOpen ? 'open' : 'closed'">
    <div class="fixed inset-x-0 top-0 h-screen background-mobile z-30 md:hidden">
      <div
        *ngIf="loggedInUser"
        class="flex flex-row items-center gap-5 px-10 sm:px-20 pt-10"
      >
        <div class="text-white ml-3 font-semibold relative">
          <img
            src="assets/images/profile%20icon.svg"
            alt="profile-icon"
            class="scale-150 text-center"
          />
          <div class="centered profile-icon-text">
            {{
              loggedInUser.name!.substring(0, 1) +
                "" +
                loggedInUser.surname!.substring(0, 1)
            }}
          </div>
        </div>
        <div class="flex flex-col">
          <div class="text-white leading-0 text-sm -mb-2">
            {{ loggedInUser.name + " " + loggedInUser.surname }}
          </div>
          <div
            *ngFor="let item of profileNavItems"
            (click)="toggleActiveState(item); closeMenu()"
            id="{{ item.route }}"
            class="relative"
          >
            <a routerLink="{{ item.route }}" class="text-3xs text-white" >
              {{ "actions.my-profile" | translate }}
            </a>
          </div>
        </div>
      </div>
      <div class="w-full h-full pb-10 justify-between">
        <div class="flex flex-col justify-start px-10 pb-10 pt-24 sm:px-20 z-30">
          <div *ngFor="let item of navItems" class="element"   >
            <a
              routerLink="{{ item.route }}"
              routerLinkActive="active-link-mobile font-semibold"
              class="block py-2 px-3 inactive-menu-item"
              (click)="toggleActiveState(item); closeMenu()"
            >
              {{ "navigation." + item.name | translate }}
            </a>
          </div>
  
          <div
            *ngIf="!loggedInUser"
            class="w-1/2 my-2 ml-3 bg-white h-[1px]"
          ></div>
  
          <div
            *ngIf="!loggedInUser"
            (click)="openLoginPopup()"
            class="inactive-menu-item py-2 px-4 hover:cursor-pointer"
          >
            {{ "actions.login" | translate }}
          </div>
  
          <div
            *ngIf="!loggedInUser"
            (click)="openSignupPopup()"
            class="inactive-menu-item py-2 px-4 hover:cursor-pointer "
          >
            {{ "actions.register" | translate }}
          </div>
        </div>
  
        <button class="px-10 pb-10 pt-24 sm:px-20 z-30" *ngIf="loggedInUser">
          <a
            (click)="signOut()"
            class="py-2 px-1 text-white md:font-small font-medium hover:text-[#9f7ee1] hover:cursor-pointer flex gap-3 items-center uppercase"
          >
            <div class="flex flex-col justify-center">
              <span class="material-symbols-outlined">logout</span>
            </div>
            <div class="text-sm">{{ "navigation.log-out" | translate }}</div>
          </a>
        </button>
      </div>
    </div>
  </div>
</nav>

<ng-container
  *ngIf="isSignupPopupOpen || isLoginPopupOpen || isResetPasswordPopUpOpen"
>
  <app-signup-popup
    *ngIf="isSignupPopupOpen"
    (submit)="onSubmit($event)"
    (close)="closePopup()"
    (openLogin)="openLoginPopup()"
  ></app-signup-popup>

  <app-login-popup
    *ngIf="isLoginPopupOpen"
    (submit)="onSubmit($event)"
    (close)="closePopup()"
    (openRegister)="openSignupPopup()"
  ></app-login-popup>

  <app-password-reset
    *ngIf="isResetPasswordPopUpOpen"
    (submit)="onSubmit($event)"
    (close)="closePopup()"
  ></app-password-reset>
</ng-container>

<router-outlet></router-outlet>
