<div appHighlightProduct scrollReveal class="appear-anim w-screen min-h-max z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 pb-12 xl:pt-20 align-middle relative overflow-hidden">
  <div class="full-width flex flex-col text-white mt-20">

    <!--    SECTION 1-->
    <div class="flex flex-col mt-[-3rem] md:mt-[2rem] lg:mt-12 full-width items-end relative">

      <div class="flex flex-row justify-end w-full gap-5">

        <div class="w-[55%] md:block hidden"> </div>

        <!--        IMAGE-->
        <div class="md:block absolute hidden z-[-1] left-[-5rem] top-[-2rem]">
          <img src="assets/images/body-shape-image-1.png">
        </div>

        <div class="w-full flex flex-col gap-[2rem] lg:gap-[5rem] ">

            <div class="flex flex-col gap-5">
                <!--  TITLE-->
                <div class="flex flex-col gap-1 md:absolute relative left-0">
                    <div class="text-xl md:text-3xl uppercase font-custom_semibold">{{'body-shape-page.title' | translate}}</div>
                    <div class="text-sm">{{'body-shape-page.title-description' | translate}}</div>
                </div>

                <!--        DESCRIPTION 1-->
                <div class="text-white text-lg lg:text-2xl font-custom_italic text-left md:mt-[4rem]">
                "{{'body-shape-page.description-heading' | translate}}"
                </div>
            </div>

            <!--        DESCRIPTION 2-->
            <div class="flex flex-col gap-12 lg:pl-[5rem]">
                <div class="w-40 border-[0.5px] border-transparent border-b-white lg:mb-8"></div>
                <div class="texts-sm md:text-lg text-justify">{{'body-shape-page.description-section-1' | translate}}</div>

                <div class="texts-sm md:text-lg text-justify lg:w-4/5 transition-all duration-150"
                [ngClass]="{'max-h-[1000px] opacity-100 mb-[-5rem] visible': readMore_1,
                'max-h-[0px] opacity-0 invisible mb-[-1rem] pb-[0rem] md:max-h-full md:opacity-100 md:visible': !readMore_1}">
                  {{'body-shape-page.description-section-2' | translate}}
                </div>
            </div>
        </div>

      </div>
    </div>

    <!--    SECTION 2-->
    <div class="md:hidden flex flex-col items-start mt-[7.5rem] lg:mt-[10.68rem] mb-[3.5rem] lg:mb-[-6.25rem] gap-8 md:gap-5 transition-all duration-150"
    [ngClass]="{'max-h-[1000px] opacity-100 visible': readMore_1,
    'max-h-[0px] opacity-0 invisible md:max-h-full md:opacity-100 md:visible': !readMore_1}">
        <div class="flex flex-col items-start gap-8 md:gap-5 z-[-1]">
          <div class="text-lg md:text-2xl font-custom_semibold">{{'body-shape-page.infrared-title' | translate}}</div>
          <div class="flex flex-col md:flex-row gap-5 md:gap-10">
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-1/2">{{'body-shape-page.infrared-section-1' | translate}}</div>
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-1/2">{{'body-shape-page.infrared-section-2' | translate}}</div>
          </div>
        </div>
    </div>

    <span class="md:hidden block font-custom_regular text-white w-max
    opacity-50 hover:opacity-100 active:opacity-100 cursor-pointer text-left select-none mb-[2rem] z-[50]"
    (click)="toggleReadMore1()"
    [ngClass]="{'mt-[-2rem]': readMore_1,
    '-mt-[12rem] mb-[0rem]': !readMore_1}">
        <p *ngIf="!readMore_1"> {{'actions.read_more' | translate}} </p>
        <p *ngIf="readMore_1"> {{'actions.read_less' | translate}} </p>
    </span>

    <!--    SECTION 3-->
    <div class="flex-col lg:flex-col items-center lg:gap-[5rem] md:mt-[10.68rem] mb-[3.5rem] md:my-[5rem]">

      <!--      STATS DESKTOP-->
      <div class="md:flex hidden flex-col md:flex-row gap-[2rem] md:gap-[2rem] lg:mb-0 md:my-[4rem] w-full justify-between items-end select-none z-[-1] -mt-[5rem]">

        <!--        TILE 1-->
        <div class="reveal not-float flex flex-col gap-2">
          <div class="flex flex-row justify-end gap-[0.5rem] lg:gap-[2rem] items-center">
            <div class="reveal flex flex-col items-end">
              <div class="text-sm sm:text-base lg:text-[1.5475rem] text-right -mb-1">{{'body-shape-page.tile-1-max' | translate}}</div>
              <div class="text-xs lg:text-[0.9375rem] text-stone-400 text-right">{{'body-shape-page.tile-1-max-desc' | translate}}</div>
            </div>

            <div class="reveal tile-text text-2xl lg:text-4xl">30 mBar</div>
          </div>

          <div class="w-full h-full line-fill-reversed">
            <div class="w-full relative">
              <div class="w-full h-[2px] bg-[rgba(255,255,255,0.15)]"> </div>
              <div class="w-[0%] absolute top-1/2 left-0 -translate-y-1/2 h-[5px] line-fill-gradient line_1"> </div>
            </div>
          </div>
        </div>

        <!--        TILE 2-->
        <div class="select-none">
          <div class="reveal flex flex-col items-center md:mb-[-9rem] lg:mb-[-12rem] z-10">
            <div class="tile-text mb-2 text-2xl lg:text-4xl z-10">80%</div>
            <div class="text-sm sm:text-base lg:text-lg">{{'body-shape-page.tile-2-row-1' | translate}}</div>
            <div class="text-xs lg:text-sm text-stone-400">{{'body-shape-page.tile-2-row-2' | translate}}</div>
          </div>

          <img src="assets/images/progress-ring.svg">
        </div>

        <!--        TILE 3-->
        <div class="reveal not-float flex flex-col gap-2 select-none">
          <div class="flex flex-row justify-start gap-[0.5rem] lg:gap-[2rem] items-center">
            <div class="reveal tile-text text-2xl lg:text-4xl">25%</div>

            <div class="reveal flex flex-col items-start">
              <div class="text-sm sm:text-base lg:text-[1.125rem]">{{'body-shape-page.tile-3-row-1' | translate}}</div>
              <div class="text-xs lg:text-[1.125rem] text-stone-400">{{'body-shape-page.tile-3-row-2' | translate}}</div>
            </div>
          </div>

          <div class="w-full h-full">
            <div class="w-full relative">
              <div class="w-full h-[2px] bg-[rgba(255,255,255,0.15)]"> </div>
              <div class="w-[0%] absolute top-1/2 left-0 -translate-y-1/2 h-[5px] line-fill-gradient line_1"> </div>
            </div>
          </div>
        </div>
      </div>

      <!--      STATS MOBILE-->
      <div class="md:hidden mt-[1rem]">

        <div class="reveal flex flex-col gap-[2rem]">
          <!--    LINE 1-->
          <div class="w-full flex flex-col gap-5 ">
            <div class="flex flex-row justify-start gap-[1rem] lg:gap-[2rem] items-center">
              <div class="tile-text text-3xl lg:text-4xl">30 mBar</div>
              <div class="flex flex-col items-start">
                <div class="text-sm sm:text-base lg:text-[1.5475rem] text-right -mb-1">{{'body-shape-page.tile-1-max' | translate}}</div>
                <div class="text-xs lg:text-[0.9375rem] text-stone-400 text-right">{{'body-shape-page.tile-1-max-desc' | translate}}</div>
              </div>

            </div>

            <div class="w-full h-full">
              <div class="w-full relative">
                <div class="w-full h-[2px] bg-[rgba(255,255,255,0.15)]"> </div>
                <div class="w-[0%] absolute top-1/2 left-0 -translate-y-1/2 h-[5px] line-fill-gradient line_1"> </div>
              </div>
            </div>
          </div>

          <!--    LINE 2-->
          <div class="w-full flex flex-col gap-5">
            <div class="flex flex-row justify-start gap-[1rem] lg:gap-[2rem] items-center">
              <div class="tile-text text-3xl lg:text-4xl">80%</div>
              <div class="flex flex-col items-start">
                <div class="text-sm sm:text-base lg:text-[1.5475rem] text-right -mb-1">{{'body-shape-page.tile-2-row-1' | translate}}</div>
                <div class="text-xs lg:text-[0.9375rem] text-stone-400 text-right">{{'body-shape-page.tile-2-row-2' | translate}}</div>
              </div>

            </div>

            <div class="w-full h-full">
              <div class="w-full relative">
                <div class="w-full h-[2px] bg-[rgba(255,255,255,0.15)]"> </div>
                <div class="w-[0%] absolute top-1/2 left-0 -translate-y-1/2 h-[5px] line-fill-gradient line_2"> </div>
              </div>
            </div>
          </div>

          <!--    LINE 3-->
          <div class="w-full flex flex-col gap-5">
            <div class="flex flex-row justify-start gap-[1rem] lg:gap-[2rem] items-center">
              <div class="tile-text text-3xl lg:text-4xl">25%</div>
              <div class="flex flex-col items-start">
                <div class="text-sm sm:text-base lg:text-[1.5475rem] text-right -mb-1">{{'body-shape-page.tile-3-row-1' | translate}}</div>
                <div class="text-xs lg:text-[0.9375rem] text-stone-400 text-right">{{'body-shape-page.tile-3-row-2' | translate}}</div>
              </div>

            </div>

            <div class="w-full h-full">
              <div class="w-full relative">
                <div class="w-full h-[2px] bg-[rgba(255,255,255,0.15)]"> </div>
                <div class="w-[0%] absolute top-1/2 left-0 -translate-y-1/2 h-[5px] line-fill-gradient line_3"> </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!--      INFRARED SECTION-->
      <div class="md:block hidden md:mb-[2rem] md:mt-[6rem]">
        <div class="flex flex-col items-start gap-8 md:gap-5 z-[-1]">
          <div class="text-lg md:text-2xl font-custom_semibold">{{'body-shape-page.infrared-title' | translate}}</div>
          <div class="flex flex-col md:flex-row gap-5 md:gap-10">
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-1/2">{{'body-shape-page.infrared-section-1' | translate}}</div>
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-1/2">{{'body-shape-page.infrared-section-2' | translate}}</div>
          </div>
        </div>
      </div>


    </div>

    <!--    SECTION 4-->
    <div class="reveal flex flex-col items-center">

      <!--      TITLE-->
      <div class="flex flex-col items-end">
        <div class="flex flex-col md:flex-row gap-2">
          <div class="text-xl md:text-3xl uppercase text-center md:text-left w-full md:w-auto">{{'body-shape-page.functionalities' | translate}}</div>

          <!--       BOLD TEXT & NEON LINE -->
          <div>
              <div class="text-xl md:text-3xl uppercase font-custom_bold mb-[0.5rem] text-center md:text-left">{{'body-shape-page.functionalities-bold' | translate}}
              </div>

              <svg viewBox="0 0 326 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_2046_1629)">
                <path d="M4 9H322" stroke="url(#paint0_linear_2046_1629)" stroke-width="9"/>
                </g>
                <g filter="url(#filter1_f_2046_1629)">
                <path d="M4 9H322" stroke="url(#paint1_linear_2046_1629)" stroke-width="4"/>
                </g>
                <defs>
                <filter id="filter0_f_2046_1629" x="0" y="0.5" width="326" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2046_1629"/>
                </filter>
                <filter id="filter1_f_2046_1629" x="2" y="5" width="322" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_2046_1629"/>
                </filter>
                <linearGradient id="paint0_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                <stop stop-color="#CC00FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                </linearGradient>
                </defs>
            </svg>
          </div>
        </div>

      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-[4rem] lg:gap-[5rem] md:gap-[8rem] justify-between mt-[3rem] lg:mt-[5rem]">
        <div *ngFor="let item of items">
          <app-product-info-item [productInfoData]="item"></app-product-info-item>
        </div>
      </div>

    </div>

  </div>
</div>

