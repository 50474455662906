<div appHighlightProduct scrollReveal class="appear-anim w-screen min-h-max z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 pb-12 xl:pt-20 align-middle relative overflow-hidden">
  <div class="full-width flex flex-col text-white mt-20 relative">

    <!--    SECTION 1-->
    <div class="flex flex-col mt-[-3rem] md:mt-[2rem] lg:mt-12 full-width items-end">

      <div class="flex flex-row justify-end w-full gap-5">

        <!--        IMAGE-->
        <div class="md:block hidden md:w-[120%] lg:w-[45rem] z-[-1] max-h-[500px]">
          <img src="assets/images/vibra-shape-image.png">
        </div>

        <div class="w-full flex flex-col gap-[2rem] lg:gap-[5rem]">

            <div class="flex flex-col gap-5">
                <!--  TITLE-->
                <div class="flex flex-col gap-1">
                    <div class="text-xl md:text-3xl uppercase font-custom_semibold">{{'vibra-shape-page.title' | translate}}</div>
                    <div class="text-sm">{{'vibra-shape-page.title-description' | translate}}</div>
                </div>

                <!--        DESCRIPTION 1-->
                <div class="text-white text-lg lg:text-2xl font-custom_italic text-left">
                "{{'vibra-shape-page.description-heading' | translate}}"
                </div>
            </div>

            <!--        DESCRIPTION 2-->
            <div class="flex flex-col gap-12 lg:pl-[5rem]">
                <div class="w-40 border-[0.5px] border-transparent border-b-white lg:mb-8"></div>
                <div class="texts-sm md:text-lg text-justify">{{'vibra-shape-page.description-section-1' | translate}}</div>

                <div class="texts-sm md:text-lg text-justify lg:w-4/5 transition-all duration-150"
                [ngClass]="{'max-h-[1000px] opacity-100 mb-[-5rem] visible': readMore_1,
                'max-h-[0px] opacity-0 invisible mb-[-1rem] pb-[0rem] md:max-h-full md:opacity-100 md:visible': !readMore_1}">
                  {{'vibra-shape-page.description-section-2' | translate}}
                </div>
            </div>
        </div>

      </div>
    </div>

    <!--    SECTION 2-->
    <div class="flex flex-col items-start mt-[7.5rem] lg:mt-[10.68rem] mb-[3.5rem] lg:mb-[-6.25rem] gap-8 md:gap-5 transition-all duration-150"
    [ngClass]="{'max-h-[1000px] opacity-100 visible mb-[1.5rem]': readMore_1,
    'max-h-[0px] opacity-0 invisible md:max-h-full md:opacity-100 md:visible': !readMore_1}">
        <div class="text-white text-lg md:text-2xl font-custom_semibold">{{'vibra-shape-page.classic-training-title' | translate}}</div>
        <div class="flex flex-col md:flex-row gap-5 md:gap-10">
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify">{{'vibra-shape-page.classic-training-description-1' | translate}}</div>
            <div
              class="text-[#DCDCDC] text-sm md:text-base text-justify">{{'vibra-shape-page.classic-training-description-2' | translate}}</div>
          </div>
    </div>

    <span class="md:hidden block font-custom_regular text-white w-max
    opacity-50 hover:opacity-100 active:opacity-100 cursor-pointer text-left select-none mb-[2rem] z-[50]"
    (click)="toggleReadMore1()"
    [ngClass]="{'mt-[-2rem]': readMore_1,
    '-mt-[12rem] mb-[0rem]': !readMore_1}">
        <p *ngIf="!readMore_1"> {{'actions.read_more' | translate}} </p>
        <p *ngIf="readMore_1"> {{'actions.read_less' | translate}} </p>
    </span>

    <!--    SECTION 3-->
    <div class="mb-[3.5rem] md:mt-[10.68rem] md:my-[5rem] lg:mb-auto relative mt-[1rem]">

    <div class="relative w-full h-full md:mt-[13rem]">
        <div class="md:absolute md:flex hidden w-screen -translate-y-[72%] left-1/2 -translate-x-1/2 justify-center items-center">
        <img src="assets/images/line-hz-graphic.svg" alt="" class="self-center">
        </div>

        <div class="md:absolute w-full md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2">
        <div class="flex flex-col md:flex-row md:gap-0 gap-[2.5rem] justify-between w-full md:py-[5rem] px-0 md:px-[15vw] lg:px-[13vw] 2xl:px-[17vw] 3xl:px-[12vw]">

            <!-- HZ 3-->
            <div class="flex flex-col relative md:h-[30px]">
                <div class="absolute md:block hidden top-0 left-1/2 -translate-x-1/2 w-[30px] h-[30px] rounded-full bg-cover bg-no-repeat"
                style="background-image: url(./assets/images/hz_item_dotmarker.svg);">
                </div>

                <div class="reveal flex flex-row md:flex-col ustify-between md:justify-center align-middle items-center gap-[3rem] md:gap-[1rem] md:my-[3rem] md:max-w-[15rem]">
                    <div class="text-start md:text-center font-custom_bold_italic text-[2rem] md:text-[2.5rem] hz-title-mobile-text">{{'vibra-shape-page.hz-item-1-title' | translate}}</div>
                    <div class="text-justify md:text-center justify-center text-[0.85rem]">{{'vibra-shape-page.hz-item-1-description' | translate}}</div>
                </div>
            </div>

            <!-- HZ 2-->
            <div class="flex flex-col md:flex-col-reverse relative md:h-[30px]">
                <div class="absolute md:block hidden top-0 left-1/2 -translate-x-1/2 w-[30px] h-[30px] rounded-full bg-cover bg-no-repeat"
                style="background-image: url(./assets/images/hz_item_dotmarker.svg);">
                </div>

                <div class="reveal flex flex-row md:flex-col-reverse ustify-between md:justify-center  align-middle items-center gap-[3rem] md:gap-[1rem] md:my-[3rem] md:max-w-[15rem]">
                    <div class="text-start md:text-center font-custom_bold_italic text-[2rem] md:text-[2.5rem] hz-title-mobile-text">{{'vibra-shape-page.hz-item-2-title' | translate}}</div>
                    <div class="text-justify md:text-center justify-center text-[0.85rem]">{{'vibra-shape-page.hz-item-2-description' | translate}}</div>
                </div>
            </div>

            <!-- HZ 3-->
            <div class="flex flex-col relative md:h-[30px]">
                <div class="absolute md:block hidden top-0 left-1/2 -translate-x-1/2 w-[30px] h-[30px] rounded-full bg-cover bg-no-repeat"
                style="background-image: url(./assets/images/hz_item_dotmarker.svg);">
                </div>

                <div class="reveal flex flex-row md:flex-col ustify-between md:justify-center align-middle items-center gap-[3rem] md:gap-[1rem] md:my-[3rem] md:max-w-[15rem]">
                    <div class="text-start md:text-center font-custom_bold_italic text-[2rem] md:text-[2.5rem] hz-title-mobile-text">{{'vibra-shape-page.hz-item-3-title' | translate}}</div>
                    <div class="text-justify md:text-center justify-center text-[0.85rem]">{{'vibra-shape-page.hz-item-3-description' | translate}}</div>
                </div>
            </div>

            <!-- HZ 4-->
            <div class="flex flex-col md:flex-col-reverse relative md:h-[30px]">
                <div class="absolute top-0 md:block hidden left-1/2 -translate-x-1/2 w-[30px] h-[30px] rounded-full bg-cover bg-no-repeat"
                style="background-image: url(./assets/images/hz_item_dotmarker.svg);">
                </div>

                <div class="reveal flex flex-row md:flex-col-reverse ustify-between md:justify-center  align-middle items-center gap-[3rem] md:gap-[1rem] md:my-[3rem] md:max-w-[15rem]">
                    <div class="text-start md:text-center font-custom_bold_italic text-[2rem] md:text-[2.5rem] hz-title-mobile-text">{{'vibra-shape-page.hz-item-4-title' | translate}}</div>
                    <div class="text-justify md:text-center justify-center text-[0.85rem]">{{'vibra-shape-page.hz-item-4-description' | translate}}</div>
                </div>
            </div>
        </div>

        </div>

    </div>
    </div>

    <!--    SECTION 4-->
    <div class="reveal flex flex-col gap-10 items-start md:mt-[17.68rem] w-full">
       <div class="flex flex-col gap-2">
           <div class="text-white text-lg md:text-2xl font-custom_semibold"> {{"vibra-shape-page.infrared-title-1" | translate}} </div>
           <div class="text-[#DCDCDC] text-sm md:text-base text-justify"> {{"vibra-shape-page.infrared-title-2" | translate}} </div>
       </div>

       <div class="w-full md:w-3/5 text-[#DCDCDC] text-sm md:text-base text-justify">
        {{"vibra-shape-page.infrared-description" | translate}}
       </div>

       <div class="flex flex-col gap-3 w-full">
            <div class="font-custom_semibold text-white text-[1rem] text-justify"> {{"vibra-shape-page.treatments-title" | translate}} </div>
            <div class="flex flex-col gap-3 md:flex-row justify-between w-full">
                <div class="text-[#DCDCDC] text-sm md:text-base text-justify" *ngFor="let treatment of treatmentItems">
                    {{treatment | translate}}
                </div>
            </div>
        </div>
    </div>

  </div>
</div>

<div class="absolute bottom-0 left-1/2 -translate-x-1/2 w-[105vw] h-[27vw]">
    <svg preserveAspectRatio="xMidYMax slice" viewBox="0 0 1920 628" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" filter="url(#filter0_f_215_792)">
        <mask id="mask0_215_792" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-269" y="0" width="800" height="628">
        <rect x="-269" width="800" height="627.394" fill="url(#paint0_linear_215_792)"/>
        </mask>
        <g mask="url(#mask0_215_792)">
        <mask id="mask1_215_792" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-269" y="0" width="800" height="628">
        <rect x="-269" width="800" height="627.394" fill="url(#paint1_radial_215_792)"/>
        </mask>
        <g mask="url(#mask1_215_792)">
        <g filter="url(#filter1_f_215_792)">
        <path d="M-287.74 415.009C-95.5449 300.075 86.9357 361.833 164.668 598.184" stroke="#540069" stroke-width="2"/>
        <path d="M-306.296 395.068C-95.9108 271.435 104.873 339.594 191.672 596.689" stroke="#5A0170" stroke-width="2.08333"/>
        <path d="M-324.85 375.126C-96.2748 242.796 122.812 317.354 218.677 595.194" stroke="#5F0277" stroke-width="2.16667"/>
        <path d="M-343.406 355.184C-96.6407 214.156 140.749 295.115 245.68 593.698" stroke="#65037D" stroke-width="2.25"/>
        <path d="M-361.961 335.242C-97.0066 185.517 158.687 272.876 272.683 592.202" stroke="#6A0484" stroke-width="2.33333"/>
        <path d="M-380.515 315.3C-97.3706 156.877 176.626 250.636 299.688 590.707" stroke="#70058B" stroke-width="2.41667"/>
        <path d="M-539.053 164.109C-98.3529 -82.3875 328.146 63.5639 519.749 592.806" stroke="#CC00FF" stroke-width="3.5"/>
        <path d="M-399.071 295.359C-97.7365 128.238 194.563 228.397 326.691 589.212" stroke="#760692" stroke-width="2.5"/>
        <path d="M-515.723 185.984C-98.2508 -47.2833 305.882 91.036 487.572 592.206" stroke="#BE01ED" stroke-width="3.33333"/>
        <path d="M-492.392 207.859C-98.1478 -12.1792 283.618 118.508 455.396 591.607" stroke="#AF02DB" stroke-width="3.16667"/>
        <path d="M-469.062 229.734C-98.0447 22.925 261.355 145.98 423.22 591.008" stroke="#A103C8" stroke-width="3"/>
        <path d="M-445.732 251.609C-97.9426 58.0292 239.09 173.452 391.043 590.409" stroke="#9204B6" stroke-width="2.83333"/>
        <path d="M-422.401 273.484C-97.8386 93.1335 216.828 200.925 358.868 589.811" stroke="#8405A4" stroke-width="2.66667"/>
        </g>
        <path d="M-287.74 415.009C-95.5449 300.075 86.9357 361.833 164.668 598.184" stroke="#540069"/>
        <path d="M-306.295 395.068C-95.9098 271.435 104.874 339.593 191.672 596.689" stroke="#5A0170" stroke-width="1.08333"/>
        <path d="M-324.851 375.126C-96.2757 242.796 122.811 317.354 218.676 595.193" stroke="#5F0277" stroke-width="1.16667"/>
        <path d="M-343.406 355.184C-96.6407 214.156 140.749 295.115 245.68 593.698" stroke="#65037D" stroke-width="1.25"/>
        <path d="M-361.959 335.242C-97.0047 185.517 158.689 272.876 272.685 592.203" stroke="#6A0484" stroke-width="1.33333"/>
        <path d="M-380.516 315.3C-97.3716 156.877 176.625 250.636 299.687 590.707" stroke="#70058B" stroke-width="1.41667"/>
        <path d="M-539.053 164.109C-98.3529 -82.3875 328.146 63.5639 519.749 592.806" stroke="#CC00FF" stroke-width="2"/>
        <path d="M-399.071 295.359C-97.7365 128.238 194.563 228.397 326.691 589.212" stroke="#760692" stroke-width="1.5"/>
        <path d="M-422.4 273.484C-97.8376 93.1337 216.829 200.925 358.869 589.811" stroke="#8405A4" stroke-width="1.58333"/>
        <path d="M-445.73 251.609C-97.9407 58.0292 239.092 173.452 391.045 590.409" stroke="#9204B6" stroke-width="1.66667"/>
        <path d="M-469.062 229.734C-98.0447 22.9251 261.355 145.98 423.22 591.009" stroke="#A103C8" stroke-width="1.75"/>
        <path d="M-492.39 207.859C-98.1458 -12.179 283.62 118.508 455.398 591.608" stroke="#AF02DB" stroke-width="1.83333"/>
        <path d="M-515.722 185.984C-98.2498 -47.2835 305.883 91.0359 487.573 592.206" stroke="#BE01ED" stroke-width="1.91667"/>
        </g>
        </g>
        <mask id="mask2_215_792" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1190" y="0" width="800" height="628">
        <rect width="800" height="627.394" transform="matrix(-1 0 0 1 1990 0)" fill="url(#paint2_linear_215_792)"/>
        </mask>
        <g mask="url(#mask2_215_792)">
        <mask id="mask3_215_792" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1190" y="0" width="800" height="628">
        <rect width="800" height="627.394" transform="matrix(-1 0 0 1 1990 0)" fill="url(#paint3_radial_215_792)"/>
        </mask>
        <g mask="url(#mask3_215_792)">
        <g filter="url(#filter2_f_215_792)">
        <path d="M2008.74 415.009C1816.54 300.075 1634.06 361.833 1556.33 598.184" stroke="#540069" stroke-width="2"/>
        <path d="M2027.3 395.068C1816.91 271.435 1616.13 339.594 1529.33 596.689" stroke="#5A0170" stroke-width="2.08333"/>
        <path d="M2045.85 375.126C1817.27 242.796 1598.19 317.354 1502.32 595.194" stroke="#5F0277" stroke-width="2.16667"/>
        <path d="M2064.41 355.184C1817.64 214.156 1580.25 295.115 1475.32 593.698" stroke="#65037D" stroke-width="2.25"/>
        <path d="M2082.96 335.242C1818.01 185.517 1562.31 272.876 1448.32 592.202" stroke="#6A0484" stroke-width="2.33333"/>
        <path d="M2101.52 315.3C1818.37 156.877 1544.37 250.636 1421.31 590.707" stroke="#70058B" stroke-width="2.41667"/>
        <path d="M2260.05 164.109C1819.35 -82.3875 1392.85 63.5639 1201.25 592.806" stroke="#CC00FF" stroke-width="3.5"/>
        <path d="M2120.07 295.359C1818.74 128.238 1526.44 228.397 1394.31 589.212" stroke="#760692" stroke-width="2.5"/>
        <path d="M2236.72 185.984C1819.25 -47.2833 1415.12 91.036 1233.43 592.206" stroke="#BE01ED" stroke-width="3.33333"/>
        <path d="M2213.39 207.859C1819.15 -12.1792 1437.38 118.508 1265.6 591.607" stroke="#AF02DB" stroke-width="3.16667"/>
        <path d="M2190.06 229.734C1819.04 22.925 1459.65 145.98 1297.78 591.008" stroke="#A103C8" stroke-width="3"/>
        <path d="M2166.73 251.609C1818.94 58.0292 1481.91 173.452 1329.96 590.409" stroke="#9204B6" stroke-width="2.83333"/>
        <path d="M2143.4 273.484C1818.84 93.1335 1504.17 200.925 1362.13 589.811" stroke="#8405A4" stroke-width="2.66667"/>
        </g>
        <path d="M2008.74 415.009C1816.54 300.075 1634.06 361.833 1556.33 598.184" stroke="#540069"/>
        <path d="M2027.29 395.068C1816.91 271.435 1616.13 339.593 1529.33 596.689" stroke="#5A0170" stroke-width="1.08333"/>
        <path d="M2045.85 375.126C1817.28 242.796 1598.19 317.354 1502.32 595.193" stroke="#5F0277" stroke-width="1.16667"/>
        <path d="M2064.41 355.184C1817.64 214.156 1580.25 295.115 1475.32 593.698" stroke="#65037D" stroke-width="1.25"/>
        <path d="M2082.96 335.242C1818 185.517 1562.31 272.876 1448.32 592.203" stroke="#6A0484" stroke-width="1.33333"/>
        <path d="M2101.52 315.3C1818.37 156.877 1544.38 250.636 1421.31 590.707" stroke="#70058B" stroke-width="1.41667"/>
        <path d="M2260.05 164.109C1819.35 -82.3875 1392.85 63.5639 1201.25 592.806" stroke="#CC00FF" stroke-width="2"/>
        <path d="M2120.07 295.359C1818.74 128.238 1526.44 228.397 1394.31 589.212" stroke="#760692" stroke-width="1.5"/>
        <path d="M2143.4 273.484C1818.84 93.1337 1504.17 200.925 1362.13 589.811" stroke="#8405A4" stroke-width="1.58333"/>
        <path d="M2166.73 251.609C1818.94 58.0292 1481.91 173.452 1329.95 590.409" stroke="#9204B6" stroke-width="1.66667"/>
        <path d="M2190.06 229.734C1819.04 22.9251 1459.65 145.98 1297.78 591.009" stroke="#A103C8" stroke-width="1.75"/>
        <path d="M2213.39 207.859C1819.15 -12.179 1437.38 118.508 1265.6 591.608" stroke="#AF02DB" stroke-width="1.83333"/>
        <path d="M2236.72 185.984C1819.25 -47.2835 1415.12 91.0359 1233.43 592.206" stroke="#BE01ED" stroke-width="1.91667"/>
        </g>
        </g>
        </g>
        <defs>
        <filter id="filter0_f_215_792" x="-271.5" y="43.4547" width="2264" height="557.542" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1.25" result="effect1_foregroundBlur_215_792"/>
        </filter>
        <filter id="filter1_f_215_792" x="-542.907" y="42.9547" width="1067.3" height="558.542" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_215_792"/>
        </filter>
        <filter id="filter2_f_215_792" x="1196.61" y="42.9547" width="1067.3" height="558.542" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_215_792"/>
        </filter>
        <linearGradient id="paint0_linear_215_792" x1="320" y1="99.5" x2="-199.667" y2="605.091" gradientUnits="userSpaceOnUse">
        <stop offset="0.16298" stop-color="#D9D9D9" stop-opacity="0"/>
        <stop offset="0.319849" stop-color="#D9D9D9" stop-opacity="0.25"/>
        <stop offset="0.426397" stop-color="#D9D9D9"/>
        </linearGradient>
        <radialGradient id="paint1_radial_215_792" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(280.818 79.5152) rotate(130.593) scale(713.846 591.611)">
        <stop offset="0.419101" stop-color="#D9D9D9"/>
        <stop offset="0.616678" stop-color="#D9D9D9" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint2_linear_215_792" x1="592.5" y1="209" x2="-26.5" y2="339" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D9D9D9" stop-opacity="0"/>
        <stop offset="0.178279" stop-color="#D9D9D9" stop-opacity="0.35"/>
        <stop offset="0.379264" stop-color="#D9D9D9" stop-opacity="0.85"/>
        <stop offset="0.594459" stop-color="#D9D9D9"/>
        </linearGradient>
        <radialGradient id="paint3_radial_215_792" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(549.818 79.5152) rotate(138.535) scale(795.101 658.953)">
        <stop offset="0.419101" stop-color="#D9D9D9"/>
        <stop offset="0.616678" stop-color="#D9D9D9" stop-opacity="0"/>
        </radialGradient>
        </defs>
    </svg>
</div>
