import { Component, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AvailabilityData, AvailabilityStatusEnum, UsersResultStatus } from "../../../clients/users-api/UsersApiClient.gen";
import { ScheduleService } from 'src/app/services/schedule.service';
import { DailyAvailabilityArgs } from 'src/app/clients/users-api/UsersApiClient.gen';
import { TranslateService } from '@ngx-translate/core';
import { RepeatTypeEnum } from 'src/app/enums/RepeatTypeEnum';

export interface RecurringTermAvailability {
  status: AvailabilityStatusEnum;
  date: string;
}

@Component({
  selector: 'app-choose-hour-popup',
  templateUrl: './choose-hour-popup.component.html',
  styleUrls: ['./choose-hour-popup.component.css']
})
export class ChooseHourPopupComponent {
  @Input() hourlyAvailabilityList: Date[] = [];
  @Input() finalSelectedDate!: moment.Moment;
  
  @Input() editingTerm?: Date;
  @Input() currentDateList: Date[] = [];
  
  @Input() reschedule!: boolean;
  @Input() isTrialSession!: boolean;
  @Input() dailyAvailabilityArgs!: DailyAvailabilityArgs;
  @Input() forceTermToEdit?: Date;

  @Output() closePopUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() addTermsToList = new EventEmitter<Date[]>();
  @Output() updateTermsList = new EventEmitter<Date[]>();
  
  currentList: Date[] = [];
  selectedHour: Date | null = null;
  recurring: boolean = false;

  modalOpened: boolean = false;
  availabilityStatusCheckOpen: boolean = false;
  recurringTermsAvailability:RecurringTermAvailability[] = [];

  DaysRepeatType = RepeatTypeEnum.Days;
  WeeksRepeatType = RepeatTypeEnum.Weeks;
  AvailableStatus = AvailabilityStatusEnum.Available;
  UnavailableStatus = AvailabilityStatusEnum.Unavailable;

  dropdownOptionsRepeatTypeCount = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7}
  ];
  dropdownOptionsRepeatType = [
    {label: this.translate('Дена'), value: RepeatTypeEnum.Days, singularKey: 'actions.repeatingType_dropdown_label_type1_singular', pluralKey: 'actions.repeatingType_dropdown_label_type1_plural'},
    {label: this.translate('Недели'), value: RepeatTypeEnum.Weeks, singularKey: 'actions.repeatingType_dropdown_label_type2_singular', pluralKey: 'actions.repeatingType_dropdown_label_type2_plural'}
  ];
  dropdownOptionsRepeatCount = [
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7}
  ];

  selectedRepeatTypeCount: number = this.dropdownOptionsRepeatTypeCount[0].value;
  selectedRepeatType: RepeatTypeEnum = this.dropdownOptionsRepeatType[0].value;
  selectedRepeatCount: number = this.dropdownOptionsRepeatCount[0].value;

  constructor(private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private scheduleService: ScheduleService) {
  }

  ngOnInit() {
    if (this.editingTerm != undefined) {
      var tempSelectedDate = this.forceTermToEdit ? this.forceTermToEdit : this.editingTerm;

      this.selectedHour = new Date(tempSelectedDate.getUTCFullYear(), tempSelectedDate.getUTCMonth(),
      tempSelectedDate.getUTCDate(), tempSelectedDate.getUTCHours(),
      tempSelectedDate.getUTCMinutes(), tempSelectedDate.getUTCSeconds());
    }
    new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
      this.modalOpened = true;
    });
  }

  public isSelectedHour(hour: Date): boolean { return this.selectedHour?.getUTCHours() === hour.getUTCHours() && this.selectedHour?.getUTCMinutes() === hour.getUTCMinutes(); }

  public handleHourClick(selectedHour: Date): void {
    this.selectedHour = selectedHour;
  }

  public onChooseTerm(): void {
    let combinedDate = new Date();
    if (this.finalSelectedDate && this.selectedHour) {
      const hours = this.selectedHour.getHours();
      const minutes = this.selectedHour.getUTCMinutes();
      const year = this.finalSelectedDate.year();
      const month = this.finalSelectedDate.month();
      const day = this.finalSelectedDate.date();

      combinedDate = new Date(year, month, day, hours, minutes);

      let date = combinedDate;

      //If the client is editing a previous term
      if (this.editingTerm !== undefined || this.forceTermToEdit !== undefined || ((this.reschedule || this.isTrialSession) && this.currentDateList.length == 1)) {
        this.currentList = this.currentDateList!.map((element) => {
          if (this.editingTerm !== undefined && this.forceTermToEdit === undefined){
            return (element.getFullYear() === this.editingTerm.getUTCFullYear() &&
            element.getMonth() === this.editingTerm.getUTCMonth() &&
            element.getDate() === this.editingTerm.getUTCDate() &&
            element.getHours() === this.editingTerm.getUTCHours() &&
            element.getMinutes() === this.editingTerm.getUTCMinutes()) 
            ? new Date(date) : element;
          } else if (this.forceTermToEdit !== undefined) {
            return (element.getFullYear() === this.forceTermToEdit.getUTCFullYear() &&
            element.getMonth() === this.forceTermToEdit.getUTCMonth() &&
            element.getDate() === this.forceTermToEdit.getUTCDate() &&
            element.getHours() === this.forceTermToEdit.getUTCHours() &&
            element.getMinutes() === this.forceTermToEdit.getUTCMinutes()) 
            ? new Date(date) : element;
          } else {
            return new Date(date)}
        });

        this.updateTermsList.emit(this.currentList);
      }
      //If the client is adding a new term
      else if (this.editingTerm === undefined || (this.reschedule && this.currentDateList.length == 0)) {
          this.currentDateList = [];
          this.currentDateList.push(new Date(date));
          this.currentList.push(new Date(date));

          this.addTermsToList.emit(this.currentList);
      }
    
      this.closeThePopUp();
    }
  }

  public async checkRecurringAvailability(): Promise<void> {
    if(this.selectedRepeatCount > 0) {
      this.currentList = [];
      this.recurringTermsAvailability = [];

      const currentSelectedDateTime = moment(this.finalSelectedDate).set({
        hour: this.selectedHour!.getHours(),
        minute: this.selectedHour!.getMinutes(),
        second: 0,
      });
  
      let multiplier = this.selectedRepeatType === RepeatTypeEnum.Days ? 1 : 7;

      let args:DailyAvailabilityArgs = new DailyAvailabilityArgs();
      args.startDate = currentSelectedDateTime.clone().toDate();
      args.endDate = currentSelectedDateTime.clone().add((this.selectedRepeatCount - 1) * this.selectedRepeatTypeCount * multiplier, 'days').toDate();
      args.employeeProfileId = this.dailyAvailabilityArgs.employeeProfileId;
      args.serviceId = this.dailyAvailabilityArgs.serviceId;
      args.editingTermId = this.dailyAvailabilityArgs.editingTermId;
      args.hourlyAvailabilityCheck = true;
      args.hourlyAvailabilityCheckLimit = undefined;
      args.preBookedTermsList = this.dailyAvailabilityArgs.preBookedTermsList;

      new Promise<void> ((resolve) => {
        this.scheduleService.RetrieveAvailability(args).subscribe((data) => {
          if (data.status == UsersResultStatus.Success) {
  
            for (let i = 0; i < this.selectedRepeatCount; i++) {
              let recurrence = currentSelectedDateTime.clone().utc(true).add(i * this.selectedRepeatTypeCount * multiplier, 'days');
              let targetDate = recurrence.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
              let currentStatus = AvailabilityStatusEnum.Unavailable;
            
              if (data.content && targetDate in data.content) {
                const value = (data.content as { [key: string]: AvailabilityData })[targetDate];
                if (value.status === AvailabilityStatusEnum.Available) {
                  if(!value.hourlyAvailability?.find(element => element.getHours() === recurrence.hour() && element.getMinutes() === recurrence.minute())) {
                  } else {
                    currentStatus = AvailabilityStatusEnum.Available;
                    this.currentList.push(new Date(
                      recurrence.year(), recurrence.month(), recurrence.date(),
                      recurrence.hours(), recurrence.minutes(), recurrence.seconds()
                    ));
                  }
                }
              } 
  
              this.recurringTermsAvailability.push({
                status: currentStatus,
                date: this.formatDate(recurrence.clone().format('YYYY-MM-DDTHH:mm:ss[Z]'))
              } as RecurringTermAvailability);
            }
          }
        });

        resolve();
      }).finally(() => {        
        this.availabilityStatusCheckOpen = true;
      });
    }
  }

  public isRecurring(): void {
    this.recurring = !this.recurring;
  }

  private formatDate(dateString: string): string {
    // DateString is in the format '2024-01-25T10:00:00Z'
    const date = new Date(dateString);
    const formattedDate = `${this.formatTwoDigits(date.getDate())}.${this.formatTwoDigits(date.getMonth() + 1)}.${date.getFullYear()} ${this.formatTwoDigits(date.getUTCHours())}:${this.formatTwoDigits(date.getMinutes())}`;
    return formattedDate;
  }

  private formatTwoDigits(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  public closeThePopUp() {
    this.modalOpened = false;
    this.availabilityStatusCheckOpen = false;
    new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
      this.closePopUp.emit();
    });
  }

  public confirmRecurring(){
    this.addTermsToList.emit(this.currentList);
    this.closeThePopUp();
  }

  public back(){
    this.availabilityStatusCheckOpen = false; 
    this.currentList = []; 
    this.recurringTermsAvailability = [];
  }

  public translate(key: string) {
    return this.translateService.instant(key);
  }
}
