import {Component, Input} from '@angular/core';
import {BookingData, ServiceData,} from "../../../clients/users-api/UsersApiClient.gen";
import {BookingService} from "../../../services/booking.service";
import {Navigation, NavigationExtras, Router} from "@angular/router";

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.css']
})
export class BookingConfirmationComponent {

  employeeName: string = ''
  bookingData: BookingData = new BookingData()
  showCancelConfirmation: boolean = false;
  employeeId!: number

  constructor(private bookingService: BookingService,
              private router: Router) {

    let nav: Navigation = this.router.getCurrentNavigation()!;

    if (nav.extras && nav.extras.state && nav.extras.state['bookingData'] && nav.extras.state['employeeName'] && nav.extras.state['employeeId']) {
      this.bookingData = nav.extras.state['bookingData'] as BookingData;
      this.employeeName = nav.extras.state['employeeName'] as string;
      this.employeeId = nav.extras.state['employeeId'] as number
    }

  }

  editBooking() {
    if (this.bookingData!.terms!.length == 1) {
      let nav: NavigationExtras = {
        state: {
          term: this.bookingData.terms![0]!,
          employeeId: this.employeeId,
          serviceId: this.bookingData.serviceId,
          service: {
            name: this.bookingData.serviceName,
            id: this.bookingData.serviceId,
            duration: this.bookingData.serviceDuration,
            price: this.bookingData.servicePrice,
          } as ServiceData,
          edit: true
        }
      }
      this.router.navigateByUrl('/reservation/book', nav)
    } else {
      let nav: NavigationExtras = {
        state: {
          employeeId: this.employeeId,
        }
      }
      this.router.navigateByUrl('/user-booking/' + this.bookingData.bookingToken, nav)
    }
  }

  cancelBooking() {
    if (this.bookingData!.terms!.length == 1) {
      this.showCancelConfirmation = true;
    } else {
      let nav: NavigationExtras = {
        state: {
          employeeId: this.employeeId,
        }
      }
      this.router.navigateByUrl('/user-booking/' + this.bookingData.bookingToken, nav)
    }
  }

  closePopUp() {
    this.showCancelConfirmation = false;
  }

}
