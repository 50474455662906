import { Component } from '@angular/core';

@Component({
  selector: 'app-our-products-landing',
  standalone: false,
  templateUrl: './our-products-landing.component.html',
  styleUrl: './our-products-landing.component.css'
})
export class OurProductsLandingComponent {

}
