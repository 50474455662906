import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  DailyAvailabilityArgs,
  DateTimeAvailabilityDataDictionaryUsersResult,
  EmployeeDataUsersResult
} from '../clients/users-api/UsersApiClient.gen';

import {UsersApiClient} from '../clients/users-api/UsersApiClient';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  constructor(private usersApiClient: UsersApiClient) {
  }

  RetrieveAvailability(body: DailyAvailabilityArgs | undefined): Observable<DateTimeAvailabilityDataDictionaryUsersResult>{
    return this.usersApiClient.retrieveAvailability(body);
  }
}
