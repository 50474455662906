import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { HomeComponent } from './pages/home-page/home.component';
import { OurServicesPageComponent } from './pages/our-services-page/our-services-page.component';
import { PricesPageComponent } from './pages/prices-page/prices-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonHttpRequestInterceptor } from './clients/CommonHttpRequestInterceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarComponent } from './components/calendar/calendar.component';
import { BookPageComponent } from './pages/book-pages/book-page/book-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { PriceListSectionComponent } from './pages/prices-page/price-list-section/price-list-section.component';
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SwipeableModalComponent } from './components/swipeable-modal/swipeable-modal.component';
import { ChooseHourPopupComponent } from './components/pop-ups/choose-hour-popup/choose-hour-popup.component';
import { ClientDataComponent } from './components/client-data/client-data.component';
import { IconComponent } from './components/icon-component/icon.component';
import { BookingConfirmationComponent } from './pages/book-pages/booking-confirmation/booking-confirmation.component';
import { AppointmentInfoComponent } from './components/appointment-info/appointment-info.component';
import { UserBookingOverviewComponent } from './pages/user-booking-overview-page/user-booking-overview.component';
import { BookedTermComponent } from './pages/book-pages/booked-term/booked-term.component';
import { ConfirmCancelPopupComponent } from './components/pop-ups/confirm-cancel-popup/confirm-cancel-popup.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { VerificationCodeComponent } from './pages/account-management-pages/verification-code/verification-code.component';
import { PasswordFormComponent } from './pages/account-management-pages/password-form/password-form.component';
import { PasswordResetComponent } from './components/pop-ups/password-reset/password-reset.component';
import { ClientProfileComponent } from './pages/client-profile-page/client-profile.component';
import {LoginPopupComponent} from "./components/pop-ups/login-popup/login-popup.component";
import {SignupPopupComponent} from "./components/pop-ups/signup-popup/signup-popup.component";
import {ServiceCardComponent} from "./pages/our-services-page/service-card/service-card.component";
import {ServiceCategoryComponent} from "./pages/our-services-page/service-category/service-category.component";
import {ClickOutsideDirective} from "./directives/click-outside.directive";
import { RollShapeComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products/roll-shape/roll-shape.component'
import { BodyShapeComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products/body-shape/body-shape.component';
import { VaEndoactiveComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products/va-endoactive/va-endoactive.component';
import { OrbitalItemComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products/va-endoactive/orbital-item/orbital-item.component';
import { ProductInfoItemComponent } from './components/product-info-item/product-info-item.component';
import { EditUserPopUpComponent } from './components/pop-ups/edit-user-pop-up/edit-user-pop-up.component';
import { register } from 'swiper/element/bundle';
import { CommonModule } from '@angular/common';
import { SwiperDirective } from './directives/swiper.directive';
import { StreamsSwiperComponent } from './components/streams-swiper/streams-swiper.component';
import { OurProductsComponent } from './pages/our-products-pages/our-products/our-products.component';
import { OurProductsCardsComponent } from './pages/our-products-pages/our-products-cards/our-products-cards.component';
import { ProductCardComponent } from './pages/our-products-pages/our-products-cards/product-card/product-card.component';
import { HighlightedProductsComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products-page/highlighted-products.component';
import { SvgBgAnimationComponent } from './animation/svg-bg-animation/svg-bg-animation.component';
import { SpinnerComponent } from './animation/spinner-animation/spinner.component';
import { SpinnerInterceptorService } from './services/spinner-interceptor.service';
import { SvgLoadMorphComponent } from './animation/svg-load-morph-animation/svg-load-morph.component';
import { VibraShapeComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products/vibra-shape/vibra-shape.component';
import { ActivShapeComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products/activ-shape/activ-shape.component';
import { ScrollRevealDirective } from './directives/scroll-reveal.directive';
import { EmailVerificationComponent } from './pages/account-management-pages/email-verification/email-verification.component';
import { SetPasswordComponent } from './pages/account-management-pages/set-password/set-password.component';
import { TruncatePipe } from './pipes/truncate';
import { ColorfulCanvasComponent } from './animation/colorful-canvas/colorful-canvas.component';
import { OurProductsLandingComponent } from './pages/our-products-pages/our-products-landing/our-products-landing.component';

register();

console.warn = () => {};

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    OurServicesPageComponent,
    PricesPageComponent,
    ContactPageComponent,
    LoginPopupComponent,
    SignupPopupComponent,
    ServiceCardComponent,
    ServiceCategoryComponent,
    CalendarComponent,
    BookPageComponent,
    FooterComponent,
    PriceListSectionComponent,
    ClientDataComponent,
    IconComponent,
    BookingConfirmationComponent,
    PriceListSectionComponent,
    ClientDataComponent,
    IconComponent,
    BookingConfirmationComponent,
    AppointmentInfoComponent,
    UserBookingOverviewComponent,
    BookedTermComponent,
    ConfirmCancelPopupComponent,
    StatusIndicatorComponent,
    VerificationCodeComponent,
    PasswordFormComponent,
    PasswordResetComponent,
    ClientProfileComponent,
    SwipeableModalComponent,
    ChooseHourPopupComponent,
    ClickOutsideDirective,
    RollShapeComponent,
    BodyShapeComponent,
    VaEndoactiveComponent,
    ProductInfoItemComponent,
    OrbitalItemComponent,
    EditUserPopUpComponent,
    StreamsSwiperComponent,
    OurProductsCardsComponent,
    ProductCardComponent,
    OurProductsComponent,
    HighlightedProductsComponent,
    SvgBgAnimationComponent,
    SpinnerComponent,
    SvgLoadMorphComponent,
    VibraShapeComponent,
    ActivShapeComponent,
    ScrollRevealDirective,
    EmailVerificationComponent,
    SetPasswordComponent,
    TruncatePipe,
    ColorfulCanvasComponent,
    OurProductsLandingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperDirective,
    CommonModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, defaultLanguage: 'mk'
    })
  ],
  providers: [
    [
      { provide: HTTP_INTERCEPTORS, useClass: CommonHttpRequestInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptorService, multi: true },
    ]
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/');
}
