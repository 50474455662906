import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {nameValidator, phoneValidator} from 'src/app/common/validators';
import {MailService} from 'src/app/services/mail.service';
import {RegistrationData, VerificationCodeData} from "../../../clients/users-api/UsersApiClient.gen";
import {AuthenticationService} from "../../../services/authentication.service";
import {PasswordFormActionEnum} from "../../../enums/PasswordFormActionEnum";
import {PopUpTypeEnum} from "../../../enums/PopUpTypeEnum";

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-signup-popup',
  templateUrl: './signup-popup.component.html',
  styleUrls: ['./signup-popup.component.css']
})
export class SignupPopupComponent {

  signupForm!: FormGroup;
  passwordForm!: FormGroup;
  submitted: boolean = false;
  resultStatus!: string

  showUserAlreadyExistsError: boolean = false;
  userPassword!: string
  userEmail!: string

  showPassword: boolean = false;
  showVerificationCode: boolean = false;
  verificationCode!: VerificationCodeData
  action = PasswordFormActionEnum.Register


  @Output() submit = new EventEmitter<PopUpTypeEnum>();
  @Output() close = new EventEmitter<void>();
  @Output() openLogin = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private mailService: MailService,
              private toasterService: ToastrService,
              private authenticationService: AuthenticationService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.pattern(nameValidator)]),
      surname: new FormControl('', [Validators.required, Validators.pattern(nameValidator)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNum: new FormControl('', [Validators.required, Validators.pattern(phoneValidator)]),
    });

    this.passwordForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
    })
  }


  onPhoneNumInput(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
  }

  /**
   * @description Returns input styling based on control name and specified form
   * @param controlName - describes which control should be accessed from the form
   * */
  getStyling(controlName: string) {

    let control = this.signupForm.get(controlName);
    return {
      'focus:border-2 focus:border-b-white': !control?.dirty && !this.submitted && control?.invalid,
      'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (control?.dirty || this.submitted) && control?.invalid,
      'border border-b-white text-white': control?.valid
    };
  }


  /**
   * @description Performs one step back based on the current location
   * */
  back() {
    if (this.showPassword) {
      this.showPassword = false;
      this.showVerificationCode = false;
      this.passwordForm.reset();
    }
  }


  /**
   * @description If all user entered user data is valid, this function shows a form where the user can enter his password
   * */
  goToPasswordScreen() {
    this.submitted = true;
    if (this.signupForm.invalid)
      return

    this.userEmail = this.signupForm.get('email')?.value;
    this.authenticationService.checkForExistingUsername(this.signupForm.get('email')?.value).subscribe(result => {
      this.resultStatus = result;

      if (result! == 'Success') {
        this.submitted = false
        this.showUserAlreadyExistsError = false;
        this.showPassword = true;
        this.showVerificationCode = false;

      } else if (result! == 'UsernameAlreadyTaken' || result == 'EmailAlreadyTaken') {
        this.showUserAlreadyExistsError = true;
      } else {
        this.toasterService.error(this.translateService.instant('An error occurred later'), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
        this.closePopup();
      }
    })

  }


  /**
   * @description Registers a user based on previously provided data and password
   * */
  register(event: any) {

    if (event != undefined && event != '' && !(event instanceof SubmitEvent)) {

      let registerRequestData = {
        email: this.signupForm.get('email')?.value!,
        phone: this.signupForm.get('phoneNum')?.value,
        name: this.signupForm.get('name')?.value,
        surname: this.signupForm.get('surname')?.value,
        password: event!
      } as RegistrationData

      this.userPassword = event!;

      this.authenticationService.register(registerRequestData).subscribe(result => {
        this.resultStatus = result.status!;
        if (result.status! == 'Success') {
          this.verificationCode = result.content!;
          this.submitted = false
          this.showUserAlreadyExistsError = false;
          this.showVerificationCode = true;
          this.showPassword = false;
          this.authenticationService.set(true);
        } else {
          this.toasterService.error(this.translateService.instant('An error occurred later'), '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          })
          this.closePopup();
        }
      })
    }
  }


  /**
   * @description Emits event to close sign-up pop up
   * */
  closePopup() {
    this.close.emit()
  }

  codeVerified() {
    this.submit.emit(PopUpTypeEnum.register)
    this.authenticationService.set(true);
    this.closePopup()
  }

  /**
   * @description Emits event to open log-in pop up
   * */
  openLogInPopUp() {
    this.openLogin.emit();
  }

}
