import {Component, HostListener} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {nameValidator, phoneValidator} from "../../common/validators";
import {
  AddBookingData,
  BookedTermData,
  ServiceData,
  UsersResultStatus
} from "../../clients/users-api/UsersApiClient.gen";
import {ServicesService} from "../../services/services.service";
import {BookingService} from "../../services/booking.service";
import {ToastrService} from "ngx-toastr";
import {Location} from '@angular/common';
import {EmployeeService} from "../../services/employee.service";
import {Navigation, NavigationExtras, Router} from "@angular/router";
import * as moment from "moment";
import {UserProfileDto} from "../../models/UserProfileDto";
import {AuthenticationService} from "../../services/authentication.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-data',
  templateUrl: './client-data.component.html',
  styleUrls: ['./client-data.component.css']
})
export class ClientDataComponent {

  bookingData: AddBookingData = new AddBookingData()
  service: ServiceData = new ServiceData()
  employeeName!: string
  clientForm!: FormGroup
  submitted: boolean = false;
  step: number = 1
  screenWidth: any
  employeeId!: number
  loggedInUser!: UserProfileDto

  constructor(private formBuilder: FormBuilder,
              private servicesService: ServicesService,
              private bookingService: BookingService,
              private toasterService: ToastrService,
              private _location: Location,
              private employeeService: EmployeeService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private translate : TranslateService) {

    let nav: Navigation = this.router.getCurrentNavigation()!;

    if (nav.extras && nav.extras.state && nav.extras.state['addBookingData'] && nav.extras.state['employeeId']) {
      this.bookingData = nav.extras.state['addBookingData'] as AddBookingData;
      this.employeeId = nav.extras.state['employeeId'] as number
    }

    if (this.authenticationService.isLoggedIn) {
      this.loggedInUser = JSON.parse(sessionStorage.getItem('profile')!) as UserProfileDto
    }

  }

  ngOnInit() {
    this.screenWidth = window.innerWidth

    this.retrieveServiceByBookingNumber();
    if (this.bookingData.termsList != null)
      this.getEmployeeNameById(this.bookingData!.termsList[0]!.employeeProfileId);

    this.clientForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.pattern(nameValidator)]),
      surname: new FormControl('', [Validators.required, Validators.pattern(nameValidator)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(phoneValidator)])
    })
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  bookAppointment() {
    if (this.clientForm.invalid && !this.loggedInUser) {
      this.submitted = true;
      return;
    }

    let addBookingData = {
      serviceId: this.service.id,
      email: this.loggedInUser != undefined ? this.loggedInUser.username : this.clientForm.get('email')?.value,
      name: this.loggedInUser != undefined ? this.loggedInUser.name : this.clientForm.get('name')?.value,
      surname: this.loggedInUser != undefined ? this.loggedInUser.surname : this.clientForm.get('surname')?.value,
      phone: this.loggedInUser != undefined ? this.loggedInUser.phone : this.clientForm.get('phoneNumber')?.value,
      address: '',
      termsList: this.bookingData.termsList?.map(x => {
        return {
          employeeProfileId: x.employeeProfileId,
          fromDate: moment(x.fromDate).utc(true).toDate()
        } as BookedTermData
      })
    } as AddBookingData

    this.bookingService.addBooking(addBookingData).subscribe(result => {
      if (result.status==UsersResultStatus.Success) {
        this.submitted = false;
        this.clientForm.reset();

        const navigationExtras: NavigationExtras = {
          state: {
            bookingData: result.content,
            employeeName: this.employeeName,
            employeeId: this.employeeId
          }
        };
        localStorage.clear()
        this.router.navigateByUrl('/booking-confirmation', navigationExtras)

      } else if(result.status==UsersResultStatus.TrialSessionAlreadyUsed){
        this.toasterService.warning(this.translate.instant('trial_session_been_used'), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
      } else {
        this.toasterService.error(this.translate.instant('An error occurred'), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
      }
    });
  }

  retrieveServiceByBookingNumber() {
    this.servicesService.retrieveServiceById(this.bookingData!.serviceId).subscribe(result => {

      if (result.content)
        this.service = result.content
    })
  }

  removeFromList(appointmentIndex: number) {
    if (this.bookingData!.termsList!.length > 1)
      this.bookingData.termsList?.splice(appointmentIndex, 1);
  }

  back() {
    if (this.screenWidth <= 768 && this.step == 2)
      this.previousStep()
    else {
      localStorage.setItem('termsList', JSON.stringify(this.bookingData.termsList))
      localStorage.setItem('serviceId', JSON.stringify(this.service.id))
      localStorage.setItem('service', JSON.stringify(this.service))
      this._location.back();
    }

  }

  nextStep() {
    if (this.loggedInUser != undefined) {
      this.bookAppointment();
      return;
    }

    if (this.screenWidth <= 768)
      this.step += 1;
  }

  previousStep() {
    if (this.screenWidth <= 768)
      this.step -= 1;
  }

  getEmployeeNameById(employeeId: any) {
    this.employeeService.retrieveEmployeeById(employeeId).subscribe(result => {
      if (result) {
        this.employeeName = result.content!.name + " " + result.content!.surname;
      }
    })
  }

  onPhoneNumInput(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
  }
}
