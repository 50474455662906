import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {BookingService} from "../../../services/booking.service";
import {ToastrService} from "ngx-toastr";

import {Route, Router} from "@angular/router";
import {TermData} from "../../../clients/users-api/UsersApiClient.gen";

@Component({
  selector: 'app-confirm-cancel-popup',
  templateUrl: './confirm-cancel-popup.component.html',
  styleUrls: ['./confirm-cancel-popup.component.css']
})
export class ConfirmCancelPopupComponent {

  @Input() numberOfTerms!: number
  @Input() termId!: number
  @Input() bookingId!: number
  @Output() close = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService,
              private bookingService: BookingService,
              private toasterService: ToastrService,
             ) {
  }

  closePopup() {
    this.close.emit(false);
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }

  cancel() {
    if (this.numberOfTerms == 1)
      this.bookingService.cancelTerm(this.termId).subscribe(result => {
        if (result != 'Success') {
          this.toasterService.error(this.translate('An error occurred'), '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          })
          this.close.emit(false);
        } else {
          this.toasterService.success(this.translateService.instant('success_cancel_term'), '', {positionClass: 'toast-top-center', timeOut: 5000})
          this.close.emit(true);
        }
      })
    else
      this.bookingService.cancelBooking(this.bookingId!).subscribe(result => {
        if (result != 'Success') {
          this.toasterService.error(this.translate('An error occurred'), '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          })
          this.close.emit(false)
        } else {
          this.toasterService.success(this.translate('success_cancel_terms'), '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          })
          this.close.emit(true);
        }
      })
  }

}
