import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-appointment-info',
  templateUrl: './appointment-info.component.html',
  styleUrls: ['./appointment-info.component.css']
})
export class AppointmentInfoComponent {
  @Input() servicePrice: number | undefined;
  @Input() serviceDuration: number | undefined;
  @Input() employeeName: string = '';

  constructor(private translationService: TranslateService) {
  }

  getTranslation(translateString: string) {
    return this.translationService.instant(translateString)
  }
}

