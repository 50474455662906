<div class="relative">
  <div class="flex sm:w-full w-max h-full transition-all absolute left-1/2 -translate-x-1/2 top-0"
  [ngClass]="{'opacity-100 select-auto visible z-[1] delay-300': availabilityStatusCheckOpen,
  'opacity-0 select-none invisible z-[-1] delay-0': !availabilityStatusCheckOpen}">
    <div class="flex flex-col items-center justify-between w-full h-full">

      <div class="flex flex-col justify-start gap-6 overflow-y-auto w-full pr-[1rem] sm:pr-[0rem]">
        <div class="text-white flex flex-row justify-between align-middle items-center w-full p-3 recurring-term-bg"
        *ngFor="let term of recurringTermsAvailability">

          <div class="flex flex-row gap-3 justify-center align-middle items-center"
                [ngClass]="{'opacity-100 select-auto': term.status === AvailableStatus, 'opacity-25 select-none': term.status === UnavailableStatus}" >
            <div class="bg-dark_purple rounded-full h-6 w-6 flex items-center justify-center">
              <span class="text-lg material-symbols-outlined text-brown">today</span>
            </div>
            <div [style.textDecoration]="term.status !== AvailableStatus ? 'line-through' : 'none'"> {{ term.date.split(' ')[0] }} </div>
          </div>

          <div class="flex flex-row gap-3 justify-center align-middle items-center">
            <div class="w-full" [ngClass]="{'opacity-100 select-auto': term.status === AvailableStatus, 'opacity-25 select-none': term.status === UnavailableStatus}"
                 [style.textDecoration]="term.status !== AvailableStatus ? 'line-through' : 'none'"> {{ term.date.split(' ')[1] }} </div>
            <svg *ngIf="term.status === AvailableStatus" width="25" height="3" viewBox="0 0 25 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2428_82)">
              <g filter="url(#filter0_f_2428_82)">
              <path d="M1 1.5L24 1.5" stroke="#83e655" stroke-width="2"/>
              </g>
              <path d="M1 1.5L24 1.5" stroke="white" stroke-opacity="0.35" stroke-width="0.5"/>
              </g>
              <defs>
              <filter id="filter0_f_2428_82" x="0.2" y="-0.3" width="24.6" height="3.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_2428_82"/>
              </filter>
              <clipPath id="clip0_2428_82">
              <rect width="25" height="3" fill="white"/>
              </clipPath>
              </defs>
            </svg>

            <svg *ngIf="term.status === UnavailableStatus" width="25" height="3" viewBox="0 0 25 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2428_81)">
              <g filter="url(#filter0_f_2428_81)">
              <path d="M1 1.5H24" stroke="#DD503D" stroke-width="2"/>
              </g>
              <path d="M1 1.5H24" stroke="white" stroke-opacity="0.35" stroke-width="0.5"/>
              </g>
              <defs>
              <filter id="filter0_f_2428_81" x="0.2" y="-0.3" width="24.6" height="3.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_2428_81"/>
              </filter>
              <clipPath id="clip0_2428_81">
              <rect width="25" height="3" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>

        </div>
      </div>

      <div class="flex flex-col mt-[1rem] gap-4">
        <h4 class="w-full text-xs text-white/70 text-left font-custom_italic"> {{'choose-hour-popup.unavailable-terms-message' | translate}} </h4>
        <div class="flex flex-row justify-between gap-5 w-full">
          <button (click)="back()"
                  class="w-1/2 btn btn-sm bg-transparent deep-purple-button text-white text-2xs lg:text-sm font-semibold self-end p-1 sm:p-2 rounded-md select-none shadow-md uppercase">
            {{'actions.back' | translate}}
          </button>
          <button (click)="confirmRecurring()"
                  class="w-1/2 btn btn-sm bg-transparent deep-purple-button text-white text-2xs lg:text-sm font-semibold self-end p-1 sm:p-2 rounded-md select-none shadow-md uppercase">
            {{'actions.choose' | translate}}
          </button>
        </div>
      </div>
    </div>

  </div>

  <div class="transition-all duration-300"
        [ngClass]="{'opacity-100 visible z-[1] select-auto delay-0': !availabilityStatusCheckOpen,
        'opacity-0 invisible select-none z-[-1]delay-300': availabilityStatusCheckOpen}">
    <div class="flex flex-col gap-5 w-full sm:mt-[2rem]">
      <div class="flex justify-between items-center mb-3 gap-[2rem]">
        <div class="text-left text-white text-[0.85rem]">
          <p> {{'actions.repeatingPopup_title' | translate}}: {{finalSelectedDate.format("DD.MM.YYYY")}}</p>
        </div>
        <div class="self-center hidden sm:flex flex-col justify-center align-middle text-center bg-red-600 w-[20px] h-[20px] rounded-full hover:cursor-pointer" (click)="closeThePopUp()"> 
          <p class="text-white self-center text-[.75rem]"> ✖ </p> 
         </div>
      </div>

      <div class="grid grid-cols-3 max-h-[10rem] pr-[.5rem] sm:pr-0 sm:max-h-[22rem] gap-x-6 gap-y-3 text-skin-tertiary overflow-y-auto scrollbar-thin scrollbar-thumb-primary scrollbar-track-primary">
        <div *ngFor="let hour of hourlyAvailabilityList"
              class="cursor-pointer h-7 flex flex-col items-center justify-end text-center" [class.selected_hour]="isSelectedHour(hour)" (click)="handleHourClick(hour)">
          {{ hour | date: 'HH:mm' }}
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <div class="flex justify-between items-center my-1 sm:my-3" *ngIf="!reschedule && !isTrialSession && editingTerm==undefined">
          <span class="mr-6 text-white text-xs text-left">{{'actions.repeatingPopup_repeatingButton_label' | translate}}</span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" class="sr-only peer" (click)="isRecurring()">
            <div
              class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-[rgba(255,255,255,0.15)] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-toggle_color"></div>
          </label>
        </div>

        <div class="text-left text-white text-opacity-60 mb-4 text-xs transition-all duration-100 select-none"
        [ngClass]="{'opacity-100': recurring,
        'opacity-25': !recurring}">
          {{'actions.repeatingPopup_startingFrom_label' | translate}} {{finalSelectedDate.format("DD.MM.YYYY")}}, {{translate(selectedRepeatTypeCount % 10 === 1
            ? 'actions.repeatingPopup_repeatingLabel_singular'
            : 'actions.repeatingPopup_repeatingLabel_plural')}}
        </div>

        <div class="flex flex-row gap-[0.75rem] select-none"
        [ngClass]="{'opacity-100': recurring,
        'opacity-25': !recurring}">

          <div class="w-1/2 relative custom-select">
            <select class="w-full text-white pl-3 text-[.85rem]" [(ngModel)]="selectedRepeatTypeCount"
            [disabled]="!recurring">
              <option *ngFor="let option of dropdownOptionsRepeatTypeCount"
                      [value]="option.value">{{ option.label }}
              </option>
            </select>

            <div class="h-full absolute left-[.45rem] top-0 flex items-center align-middle justify-center z-[1]">
              <img src="assets/images/calendar-plus.svg" class="w-[15px] h-[15px] self-center">
              <div class="border-l-2 border-solid border-tertiary h-[65%] ml-1.5"></div>
            </div>
          </div>

          <div class="w-1/2 relative custom-select">
            <select class="w-full text-white pl-3 text-[.85rem]" [(ngModel)]="selectedRepeatType"
            [disabled]="!recurring">
              <option *ngFor="let option of dropdownOptionsRepeatType"
                      [value]="option.value">{{ translate(selectedRepeatTypeCount % 10 === 1 ? option.singularKey : option.pluralKey) }}
              </option>
            </select>

            <div class="h-full absolute left-[.45rem] top-0 flex items-center align-middle justify-center z-[1]">
              <img src="assets/images/calendar-num.svg" class="w-[15px] h-[15px] self-center">
              <div class="border-l-2 border-solid border-tertiary h-[65%] ml-1.5"></div>
            </div>
          </div>

        </div>

        <div class="flex mb-3 relative transition-all duration-100 select-none"
        [ngClass]="{'opacity-100': recurring,
        'opacity-25': !recurring}">

          <div class="w-full relative custom-select">
            <select class="w-full text-white pl-3 pr-5 z-[1] text-[.85rem]" [(ngModel)]="selectedRepeatCount"
            [disabled]="!recurring">
              <option class="text-black" *ngFor="let option of dropdownOptionsRepeatCount"
                      [value]="option.value">{{ option.label }}</option>
            </select>

            <div class="h-full absolute left-[.45rem] top-0 flex items-center align-middle justify-center z-[1]">
              <img src="assets/images/recurring.svg" class="w-[15px] h-[15px] self-center">
              <div class="border-l-2 border-solid border-tertiary h-[65%] ml-1.5"></div>
            </div>

            <div class="h-full absolute right-[.45rem] top-0 flex items-center align-middle justify-center z-[-1] text-white text-[.75rem] font-custom_medium lowercase">
              <p>
                {{ translate(selectedRepeatCount % 10 === 1
                    ? "actions.repeatingCount_dropdown_label_singular"
                    : "actions.repeatingCount_dropdown_label_plural")
                }}
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>

    <button
      class="btn my-3 btn-sm bg-primary_gradient text-white text-xs xl:text-sm
      font-semibold self-end bottom-0 p-2 rounded w-full xl:w-2/12 select-none shadow-md
      transition-all duration-100 uppercase"
      (click)="recurring && selectedRepeatCount > 1 ? checkRecurringAvailability() : onChooseTerm()"
      [ngClass]="{'opacity-100': selectedHour !== null,
      'opacity-25': selectedHour === null}"
      [disabled]="selectedHour === null">
      {{ translate( recurring && selectedRepeatCount > 1 ? "actions.chooseHourPopup_continueButtonOption1" : "actions.chooseHourPopup_continueButtonOption2") }}
    </button>
  </div>
</div>
