<app-spinner> </app-spinner>
<app-svg-load-morph> </app-svg-load-morph>

<section class="overflow-hidden sm:pb-[4rem]">
    <div class="z-[-1] overflow-x-clip">
        <header class="relative flex justify-center align-middle items-center top-[-6rem]">
        
          <div class="sliding-background-container">
            <div class="sliding-background self-center w-[490.125rem] 3xl:w-[150vw]"></div>
          </div>

          <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full self-center text-center flex flex-col gap-[.5rem] select-none">
            <div
                class="text-white text-[2rem] md:text-[3.5rem] font-custom_black text-center self-center">
                {{'generic-product-page.landing-title' | translate}}
            </div>
            <div class="text-white font-custom_regular text-[0.85rem] md:text-[1.25rem] text-center">
                <span> {{'generic-product-page.landing-description' | translate}} </span>
            </div>
        </div>
    
        </header>
    </div>  
    
    <div class="text-white w-screen flex items-center justify-center text-center z-[-2] overflow-x-clip">
    
        <div class="relative z-10 w-screen">
                
            <div class="relative z-[-1] w-screen top-0">
                <div class="absolute top-[-13rem] sm:top-[-16rem] lg:top-[-17rem] left-1/2 -translate-x-1/2">
                    <app-our-products-cards></app-our-products-cards>
                </div>
            </div>
        </div>
      
    </div>

    <div class="fixed top-0 left-0 right-0 bottom-0 z-[-2] object-cover bg-[#0a070f]"></div>
</section>

  

  