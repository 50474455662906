import {Component, HostListener} from '@angular/core';
import {UserService} from "../../services/user.service";
import {
  MembershipData,
  ServiceData,
  TermData,
  UserProfileData,
  UsersResultStatus
} from "../../clients/users-api/UsersApiClient.gen";
import {AuthenticationService} from "../../services/authentication.service";
import {MembershipService} from "../../services/membership.service";
import {BookingService} from "../../services/booking.service";
import {TermStatusEnum} from "../../enums/TermStatusEnum";
import {NavigationExtras, Router} from "@angular/router";
import { UserProfileDto } from 'src/app/models/UserProfileDto';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent {

  passedTerms = TermStatusEnum.Passed
  upcomingTerms = TermStatusEnum.Upcoming

  userBookings!: TermData[]
  filteredUserBookings!: TermData[]

  userMemberships!: MembershipData[]
  loggedInUser!: UserProfileData
  editOpened: boolean = false
  tabValue: number = 1
  expanded: boolean = false
  screenWidth: any
  displayOptions: boolean = false;
  displayOptionsForElement!: number;
  showConfirmCancelPopUp: boolean = false;
  termToCancel: number = 0
  chosenTerm!: TermData

  constructor(private userService: UserService,
              private authenticationService: AuthenticationService,
              private membershipService: MembershipService,
              private bookingService: BookingService,
              private router: Router) {
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth

    if (this.authenticationService.isLoggedIn) {

      let user = sessionStorage.getItem('profile')
      if (user != undefined) {
        let userProfile = JSON.parse(user) as UserProfileDto

        this.userService.retrieveUserDetailByEmail(userProfile.username).subscribe(result => {
          this.loggedInUser = result.content!
          this.retrieveUserBookings()
          this.retrieveUserMemberships()
        })
      }
      else {
        this.router.navigate(['/home']);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }


  /**
   * @description Retrieves all booked terms made by the logged-in user
   */
  retrieveUserBookings() {
    this.bookingService.retrieveTermsByUserId(this.loggedInUser.id!).subscribe(result => {
      if (result.status === UsersResultStatus.Success) {
        this.userBookings = result.content?.items!
        this.filteredUserBookings = this.userBookings.filter(x => x.fromDate! > new Date())
      }
    })
  }

  /**
   * @description Sorts the filtered terms in ascending/descending order
   */
  get sortData() {
    if (this.filteredUserBookings != undefined) {
      return this.filteredUserBookings!.sort((a, b) => {
        return this.tabValue == 1 ? <any>new Date(a.fromDate!) - <any>new Date(b.fromDate!) : <any>new Date(b.fromDate!) - <any>new Date(a.fromDate!);
      });
    } else {
      return this.filteredUserBookings
    }
  }


  /**
   * @description Opens user edit pop up
   */
  toggleEdit() {
    this.editOpened = true
    this.disableScroll()
  }

  /**
   * @description Expands/minimizes client memberships
   */
  toggleExpanded() {
    this.expanded = !this.expanded;
  }


  /**
   * @description Displays edit and cancel options panel
   */
  openOptions(index: number) {

    this.chosenTerm = this.userBookings[index]!

    if (index + 1 <= this.sortData.length - 1 && this.screenWidth >= 640) {
      const element = document.getElementById((index + 1).toString())!
      if (!this.displayOptions || this.displayOptionsForElement != index)
        element.setAttribute('style', 'z-index: -10')
      else
        element.removeAttribute('style')
    }

    if (this.displayOptionsForElement == index) {
      this.displayOptions = !this.displayOptions;
    } else {
      this.displayOptionsForElement = index;
      this.displayOptions = true;
    }


  }


  /**
   * @description Closes edit and cancel options panel
   */
  closeOptions(index: number) {
    if (this.displayOptionsForElement == index && this.screenWidth >= 640) {
      if (index + 1 <= this.sortData.length - 1) {
        const element = document.getElementById((index + 1).toString())!
        element.removeAttribute('style')
      }
      this.displayOptions = false
    }
  }


  /**
   * @description Retrieves all memberships for the logged-in user
   */
  retrieveUserMemberships() {
    this.membershipService.retrieveMembershipsByProfileId(this.loggedInUser.profileId!).subscribe(result => {
      if (result.status === UsersResultStatus.Success)
        this.userMemberships = result.content!
    })
  }


  /**
   * @description Filters the list of booked terms based on the provided parameter
   * @param active: Indicates whether a list of upcoming or passed terms should be provided
   */
  filterBookings(active: boolean) {
    this.filteredUserBookings = this.userBookings!.filter(term => active ? term.fromDate! > new Date() : term.fromDate! <= new Date())
  }


  /**
   * @description Updates user profile data to contain the newly entered data from the pop-up
   */
  updateUserData(event: any) {
    if (!(event instanceof SubmitEvent)) {
      this.loggedInUser.name = event.name;
      this.loggedInUser.surname = event.surname;
      this.loggedInUser.email = event.email;
      this.loggedInUser.username = event.email;
      this.loggedInUser.phone = event.phone;

      this.closePopUp();
    }
  }


  /**
   * @description Closes the pop-up for user data modification
   */
  closePopUp() {
    this.editOpened = false;
    this.showConfirmCancelPopUp = false;
    this.enableScroll()
  }


  /**
   * @description Navigates through tabs
   * @param termStatus
   */
  changeTab(termStatus: TermStatusEnum) {
    this.tabValue = termStatus.valueOf();
    termStatus == TermStatusEnum.Upcoming ?
      this.filterBookings(true) : this.filterBookings(false)

  }


  /**
   * @description Checks if a provided membership is currently active
   * @param membership
   */
  checkIfMembershipIsActive(membership: MembershipData) {
    return membership.toDate == null || membership.toDate > new Date();
  }

  redirectToEdit(termId: number) {
    let term = this.userBookings.filter(x => x.id == termId)![0]
    let nav: NavigationExtras = {
      state: {
        term: term,
        employeeId: term.employeeProfileId,
        serviceId: term.serviceId,
        service: {
          id: term.serviceId,
          price: term.servicePrice,
          duration: term.serviceDuration,
          name: term.serviceName
        } as ServiceData,
        edit: true
      }
    }
    this.router.navigateByUrl('/reservation/book', nav)
  }

  openConfirmCancelPopUp(termId: number) {
    this.showConfirmCancelPopUp = true;
    this.disableScroll();
    this.termToCancel = this.userBookings.filter(x => x.id == termId)![0].id!;
  }

  onTermCanceled() {
    this.ngOnInit()
  }


  disableScroll() {
    const element = document.querySelector('[body]')!
    element.setAttribute('style', 'overflow:hidden;')
  }

  enableScroll() {
    const element = document.querySelector('[body]')!
    element.removeAttribute('style')
  }

  toggleModal(isOpen: boolean) {
    this.displayOptions = isOpen;
    this.disableScroll()
  }

  handleModalClose() {
    this.displayOptions = false;
    this.enableScroll()
  }

}
