import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  facebookUrl: string = '';
  instagramUrl: string = '';
  
  constructor(private router: Router, private translate: TranslateService) {  }

  ngAfterContentInit (){
    this.translate.get('links.facebook').subscribe((res: string) => {
      this.facebookUrl = res;
    });

    this.translate.get('links.instagram').subscribe((res: string) => {
      this.instagramUrl = res;
    });
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }
}
