import { Component } from '@angular/core';
import { ProductInfoData } from 'src/app/models/ProductInfoData';
import { HighlightedProduct } from '../../highlighted-products-page/highlighted-products.component';
import { HighlightedProductsService } from 'src/app/services/highlighted-products.service';

@Component({
  selector: 'app-roll-shape',
  templateUrl: './roll-shape.component.html',
  styleUrls: ['./roll-shape.component.css']
})
export class RollShapeComponent implements HighlightedProduct {
  product: HighlightedProduct | undefined;
 
  productId: string = '2'; 
  backdropPath: string = 'assets/images/rollshape_bg.jpg'; 

  constructor(private highlightedProductsService: HighlightedProductsService) { }

  ngOnInit(): void {
    if(this.highlightedProductsService){
      this.product = this.highlightedProductsService.getProductById(this.productId);
      this.highlightedProductsService.addProduct(this);
    }
  }

  items: ProductInfoData[] = [{
    iconPath: 'assets/images/infrared-icon.svg',
    titleKey: 'roll-shape-page.infrared-title',
    headingKey: 'roll-shape-page.infrared-heading',
    descriptionKey: 'roll-shape-page.infrared-description'
  },
    {
      iconPath: 'assets/images/color-therapy-icon.svg',
      titleKey: 'roll-shape-page.color-therapy-title',
      headingKey: 'roll-shape-page.color-therapy-heading',
      descriptionKey: 'roll-shape-page.color-therapy-description'
    },
    {
      iconPath: 'assets/images/ionisation-icon.svg',
      titleKey: 'roll-shape-page.ionisation-title',
      headingKey: 'roll-shape-page.ionisation-heading',
      descriptionKey: 'roll-shape-page.ionisation-description'
    },{
      iconPath: 'assets/images/ozontherapy-icon.png',
      titleKey: 'roll-shape-page.ozontherapy-title',
      headingKey: 'roll-shape-page.ozontherapy-heading',
      descriptionKey: 'roll-shape-page.ozontherapy-description'
    },

  ]
}
