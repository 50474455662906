import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserProfileData, UserRoleData, UsersResultStatus} from "../../../clients/users-api/UsersApiClient.gen";
import {UserService} from "../../../services/user.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {nameValidator, phoneValidator} from "../../../common/validators";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-edit-user-pop-up',
  templateUrl: './edit-user-pop-up.component.html',
  styleUrls: ['./edit-user-pop-up.component.css']
})
export class EditUserPopUpComponent {

  @Input() userData!: UserProfileData
  @Output() submit = new EventEmitter<UserProfileData>()
  @Output() close = new EventEmitter<void>()

  userForm!: FormGroup
  submitted: boolean = false
  showError: boolean = false

  constructor(private userService: UserService,
              private formBuilder: FormBuilder,
              private toasterService: ToastrService,
              private translateService: TranslateService) {
  }

  ngOnInit() {

    this.userForm = this.formBuilder.group({
      name: new FormControl(this.userData.name, [Validators.required, Validators.pattern(nameValidator)]),
      surname: new FormControl(this.userData.surname, [Validators.required, Validators.pattern(nameValidator)]),
      email: new FormControl(this.userData.email, [Validators.required, Validators.email]),
      phone: new FormControl(this.userData.phone, [
        Validators.required,
        Validators.pattern(phoneValidator),
      ]),
    });

  }

  onPhoneNumInput(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
  }

  getStyling(controlName: string) {
    let control = this.userForm.get(controlName!);

    return {
      'focus:border-2 focus:border-b-white': !control?.dirty && !this.submitted && control?.invalid,
      'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (control?.dirty || this.submitted) && control?.invalid,
      'border border-b-white text-white': control?.valid
    };
  }

  editUser() {
    this.submitted = true
    if (this.userForm.invalid)
      return

    let userProfileData = {
      id: this.userData.id,
      profileId: this.userData.id,
      name: this.userForm.get('name')?.value,
      surname: this.userForm.get('surname')?.value,
      email: this.userForm.get('email')?.value,
      username: this.userForm.get('email')?.value,
      phone: this.userForm.get('phone')?.value,
      active: true,
      roleId: 2
    } as UserProfileData

    this.userService.editUser(userProfileData).subscribe(result => {
      if (result === UsersResultStatus.Success) {
        this.submitted = false;
        this.toasterService.success(this.translateService.instant('Data modified successfully'), '', {positionClass: 'toast-top-center', timeOut: 4000})
        this.submit.emit(userProfileData)
      } else if (result === UsersResultStatus.UsernameAlreadyTaken || result === UsersResultStatus.EmailAlreadyTaken) {
        this.showError = true;
        return;
      } else {
        this.toasterService.error(this.translateService.instant('An error occurred'), '', {
          positionClass: 'toast-top-center',
          timeOut: 4000
        })
        this.close.emit()
      }
    })
  }

  closePopup() {
    this.close.emit()
  }
}
