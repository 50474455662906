<!--      TITLE-->
<h2 class="md:text-[1.5rem] text-wrap text-center text-white font-semibold mb-4 text-[18px] uppercase">
  {{ "actions.verification-code" | translate }}
</h2>

<!--      DESCRIPTION-->
<div class="space-y-2 space-x-1">
  <div
    class="text-center md:gap-2">
    <div
      class="w-100 text-xs sm:text-sm text-white">{{ "actions.verification-code-description" | translate }}</div>
  </div>
</div>

<!--      INPUT-->
<div class="flex flex-col gap-5 mt-4">
  <input type="text" placeholder="{{'actions.verification-code' | translate}}"
         [ngClass]="getInputStyling()"
         [formControl]="verificationCodeInput"
         class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

  <div *ngIf="showError" class="mt-2">
    <app-status-indicator [message]="'register.code-invalid' | translate"></app-status-indicator>
  </div>
  <div *ngIf="codeExpired" class="mt-2">
    <app-status-indicator [message]="'register.code-expired' | translate"></app-status-indicator>
  </div>
  <div [ngClass]="{'mt-2': !showError && !codeExpired, '-mt-2': showError || codeExpired}">
    <button (click)="verifyCode()"
            class="bg-[#9f7ee1] kin-primary w-full text-white px-4 py-2 rounded text-xs md:text-sm uppercase">{{ "register.verify" | translate }}</button>
  </div>
</div>

<!--      SEND NEW LINK-->

<div class="text-xs text-center mt-2 text-[#9f7ee1] hover:cursor-pointer"
     (click)="sendNewLink()">{{"login.send-new-link" | translate}}</div>


