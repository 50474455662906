import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductInfoMode } from 'src/app/components/product-info-item/product-info-item.component';
import { ProductInfoData } from 'src/app/models/ProductInfoData';
import { Device, DeviceData, DevicesData, StrapiDeviceService } from 'src/app/services/strapi-device.service';
import { environment } from 'src/environments/environment';

export interface InformationSection {
  title: string;
  description: string;
}

export interface OurProductComponent {
  title: string;
  imageUrl: string;
  shortDescription: string;
  quote: string;
  descriptionPart1: string;
  descriptionPart2: string;
  deviceInformationSections: InformationSection[];
  functionalitiesHeading: string;
  items: ProductInfoData[];
}

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.css']
})
export class OurProductsComponent {
  productId: string = ''
  productIndex: string = '';
  localImageURL: string = '/assets/images/';
  deviceInfo: Device = new Device();
  showDevice: boolean = false;

  product: OurProductComponent = {
    title: '',
    imageUrl: '',
    shortDescription: '',
    quote: '',
    descriptionPart1: '',
    descriptionPart2: '',
    deviceInformationSections: [],
    functionalitiesHeading: '',
    items: []
  } as OurProductComponent;
  otherProductsCount:number = 0;
  productsMode: ProductInfoMode = ProductInfoMode.staticMode;

  readMore_1: boolean = false;

  private routeSubscription?: Subscription;

  constructor(private route: ActivatedRoute,
              private strapiDeviceService: StrapiDeviceService) {
  }

  ngAfterViewInit() {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      const productIdParam = params.get('productId');
      const productIndexParam = params.get('productIndex');
      
      if (productIdParam && productIndexParam) {
        this.productId = productIdParam.split(':')[1];
        this.productIndex = productIndexParam?.split(':')[1];
        this.showDevice = false;

        setTimeout(() => {
          this.getStrapiDevice((isNaN(Number(this.productId)) ? 0 : Number(this.productId)));
        }, 50);
      }
    });
  }

  public toggleReadMore1() { 
    this.readMore_1 = !this.readMore_1;
  }

  public getProductIndex(index: string): number {
    return (isNaN(Number(index)) ? 0 : (Number(index) === 0 ? 0 : Number(index) - 1));
  }

  public getStrapiDevice(productId: number){
    this.strapiDeviceService.getStrapiDevices(productId, true).subscribe((device: DevicesData | DeviceData) => {
      let fetchedDevice = device as DeviceData;
      this.deviceInfo = fetchedDevice.data.attributes;
      if(this.deviceInfo){
        this.product.imageUrl = this.isNullOrEmpty(this.deviceInfo.DeviceImage.data.attributes.url) ? "" : environment.cmsBaseUrl + this.deviceInfo.DeviceImage.data.attributes.url;
        this.product.title = this.isNullOrEmpty(this.deviceInfo.DeviceName) ? "" : this.deviceInfo.DeviceName;
        this.product.shortDescription = this.isNullOrEmpty(this.deviceInfo.DescriptionSection.ShortDeviceDescription) ? "" : this.deviceInfo.DescriptionSection.ShortDeviceDescription;
        
        this.product.quote = this.extractTextFromSections(this.deviceInfo.DescriptionSection.DeviceQuote);
        this.product.descriptionPart1 = this.extractTextFromSections(this.deviceInfo.DescriptionSection.DeviceDescriptionPart1);
        this.product.descriptionPart2 = this.extractTextFromSections(this.deviceInfo.DescriptionSection.DeviceDescriptionPart2);
        this.product.functionalitiesHeading = this.isNullOrEmpty(this.deviceInfo.DeviceFeatures.DeviceFeaturesHeading) ? "" : this.deviceInfo.DeviceFeatures.DeviceFeaturesHeading;
        this.product.items = [];
        this.product.deviceInformationSections = [];

        for(let feature of this.deviceInfo.DeviceFeatures.DeviceFeature){
          this.product.items.push({
            iconPath: environment.cmsBaseUrl + feature.FeatureIcon.data.attributes.url,
            titleKey: feature.FeatureName,
            headingKey: this.extractTextFromSections(feature.FeatureHeading),
            descriptionKey: this.extractTextFromSections(feature.FeatureDescription)
          } as ProductInfoData);
        }

        for(let infoSection of this.deviceInfo.DeviceInformationSection) {
          this.product.deviceInformationSections.push({
            title: this.isNullOrEmpty(infoSection.DeviceInformationTitle) ? "" : infoSection.DeviceInformationTitle,
            description: this.extractTextFromSections(infoSection.DeviceInformation)
          } as InformationSection);
        }

        this.showDevice = true;
      }
    });
  }

  public isNullOrEmpty(value: string): boolean {
    return (!value || value == undefined || value == "" || value.length == 0);
  }

  private extractTextFromSections(sections: any[]): string {
    let extractedText = '';
    if (sections && sections.length > 0) {
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].children && sections[i].children.length > 0) {
          for (let j = 0; j < sections[i].children.length; j++) {
            extractedText += this.isNullOrEmpty(sections[i].children[j].text) ? '' : sections[i].children[j].text;
          }
        }
      }
    }
    return extractedText;
  }

  public getBackgroundImage(){

  }
}
