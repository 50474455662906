<div class="flex flex-row justify-between items-center gap-[2rem] text-white">
  <img id="element{{serviceCategory!.id}}" class="hidden sm:flex w-1/3 h-8/10 lg:h-full" [height]="height"
       [ngClass]="{'bottom_right' : shadow==0, 'bottom_left': shadow==1, 'lg:w-1/6': serviceCategory!.services!.length>7}"
       src="{{image}}">
  <div class="flex flex-col w-full sm:w-2/3 gap-2" [ngClass]="{'sm:w-5/6': serviceCategory!.services!.length>7}">
    <div class="flex flex-row gap-5 items-center">
      <img id="element{{serviceCategory!.id}}" class="sm:hidden w-1/5"
           [ngClass]="{'bottom_right_sm' : shadow==0, 'bottom_left_sm': shadow==1}"
           src="{{image}}">
      <div class="w-3/5 sm:w-full uppercase font-semibold mb-1 xl:mb-2">{{serviceCategory!.name}}</div>
    </div>

    <div
      [ngClass]="{'lg:grid lg:grid-flow-row lg:grid-cols-5 lg:grid-rows-7 gap-x-10 lg:gap-x-12 justify-between items-center' : serviceCategory!.services!.length>7}">
      <div *ngFor="let service of serviceCategory!.services; index as i"
           class="border-b border-white lg:border-transparent"
           [ngClass]="{'col-span-2': i%2==0, 'col-span-3': i%2==1}">
        <div class="flex flex-row mt-2 items-center justify-between">
          <div class="w-2/3 font-light text-xs lg:text-sm leading-1">{{service.name}}</div>
          <div class="w-1/3 font-semibold text-xs lg:text-sm text-right">{{service.price}} {{"money" | translate }}.</div>
        </div>
      </div>
    </div>
  </div>
</div>


