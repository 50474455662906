<div appHighlightProduct scrollReveal class="appear-anim w-screen min-h-max z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 pb-12 xl:pt-20 align-middle relative overflow-hidden">
  <div class="full-width flex flex-col text-white mt-20">

    <!--    SECTION 1-->
    <div class="flex flex-col mt-[-3rem] md:mt-[2rem] lg:mt-12 full-width items-end relative">

      <div class="flex flex-row justify-end w-full gap-5">

        <div class="w-[55%] md:block hidden"> </div>

        <!--        IMAGE-->
        <div class="md:block absolute hidden z-[-1] left-[0rem] top-[5.35rem] w-[50%]">
          <img src="assets/images/roll-shape-icon.png" alt="">
        </div>

        <div class="w-full flex flex-col gap-[2rem] lg:gap-[5rem] ">

            <div class="flex flex-col gap-5">
                <!--  TITLE-->
                <div class="flex flex-col gap-1 md:absolute relative left-0">
                    <div class="text-xl md:text-3xl uppercase font-custom_semibold">{{'roll-shape-page.title' | translate}}</div>
                    <div class="text-sm">{{'roll-shape-page.title-description' | translate}}</div>
                </div>

                <!--        DESCRIPTION 1-->
                <div class="text-white text-lg lg:text-2xl font-custom_italic text-left md:mt-[4rem]">
                "{{'roll-shape-page.description-heading' | translate}}"
                </div>
            </div>

            <!--        DESCRIPTION 2-->
            <div class="flex flex-col gap-12 lg:pl-[5rem]">
                <div class="w-40 border-[0.5px] border-transparent border-b-white lg:mb-8"></div>
                <div class="texts-sm md:text-lg text-justify">{{'roll-shape-page.description-section-1' | translate}}</div>
            </div>
        </div>

      </div>
    </div>

    <!--    SECTION 2-->
    <div class="reveal flex flex-col md:flex-row gap-[2rem] md:gap-0 items-center align-middle justify-between mt-[5rem] md:mt-[10.68rem] mb-[2.5rem] md:my-[5rem]">
      <div class="md:w-3/5">
        <div class="text-white text-lg md:text-2xl font-custom_semibold mb-[1.5rem]">{{'roll-shape-page.functionalities-description-section-title' | translate}}</div>
        <div class="text-[#DCDCDC] text-sm md:text-base text-justify md:w-full">{{'roll-shape-page.functionalities-description-section-description' | translate}}</div>
      </div>
      <div class="md:w-2/5">
        <img src="assets/images/roll-shape_2.png">
      </div>
    </div>

    <!--    SECTION 3-->
    <div class="reveal flex flex-col items-center">

      <!--      TITLE-->
      <div class="flex flex-col items-end">
        <div class="flex flex-col md:flex-row gap-2">
          <div class="text-xl md:text-3xl uppercase text-center md:text-left w-full md:w-auto">{{'roll-shape-page.functionalities' | translate}}</div>

          <!--       BOLD TEXT & NEON LINE -->
          <div>
              <div class="text-xl md:text-3xl uppercase font-custom_bold mb-[0.5rem] text-center md:text-left">{{'roll-shape-page.functionalities-bold' | translate}}
              </div>

              <svg viewBox="0 0 326 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_2046_1629)">
                <path d="M4 9H322" stroke="url(#paint0_linear_2046_1629)" stroke-width="9"/>
                </g>
                <g filter="url(#filter1_f_2046_1629)">
                <path d="M4 9H322" stroke="url(#paint1_linear_2046_1629)" stroke-width="4"/>
                </g>
                <defs>
                <filter id="filter0_f_2046_1629" x="0" y="0.5" width="326" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2046_1629"/>
                </filter>
                <filter id="filter1_f_2046_1629" x="2" y="5" width="322" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_2046_1629"/>
                </filter>
                <linearGradient id="paint0_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                <stop stop-color="#CC00FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                </linearGradient>
                </defs>
            </svg>
          </div>
        </div>

      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-[4rem] lg:gap-[5rem] md:gap-[8rem] justify-between mt-[3rem] lg:mt-[5rem]">
        <div *ngFor="let item of items">
          <app-product-info-item [productInfoData]="item"></app-product-info-item>
        </div>
      </div>

    </div>

  </div>
</div>

