import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrbitalItemPositioning } from '../va-endoactive.component';
import { OrbitalItemData } from 'src/app/models/OrbitalItemData';
import { WindowDimensions, WindowDimensionsService } from 'src/app/services/window-dimension-service.service';

@Component({
  selector: 'app-orbital-item',
  templateUrl: './orbital-item.component.html',
  styleUrls: ['./orbital-item.component.css']
})
export class OrbitalItemComponent {
  @Input() data!: OrbitalItemData;
  @Input() positioning!: OrbitalItemPositioning;
  @Input() index!: string;

  leftSide = OrbitalItemPositioning.Left;
  rightSide = OrbitalItemPositioning.Right;
  centered = OrbitalItemPositioning.Center;

  descriptionOpen = false;
  windowDimensions: WindowDimensions = {} as WindowDimensions;

  constructor(private translateService: TranslateService,
              private _windowDimensionService: WindowDimensionsService) {
    this._windowDimensionService.getWindowDimensions$().subscribe((dimensions) => {
      this.windowDimensions = dimensions;
    });
  }

  toggleInfoMore(){
    this.descriptionOpen = !this.descriptionOpen;
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }
}
