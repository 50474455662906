<div *ngFor="let service of services" class="mb-5 w-full">
  <div
    [ngClass]="{'select-none pointer-events-none disabled_service': (requiresMembership && 
    ((loggedInUser === undefined || service.ServiceData.credits! <= 0 || !validMembership) &&
    (loggedInUser === undefined || service.ServiceData.credits !== 0 || trialSessionUsed) &&
    (loggedInUser !== undefined || service.ServiceData.credits !== 0)))}"
    class="bg-secondary_gradient border border-solid border-tertiary p-5 flex flex-col gap-5 justify-between backdrop-blur-[57.5px]">
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex flex-col justify-center align-middle items-center text-center px-5 text-[1rem] sm:text-[1.05rem] bg-[rgba(0,0,0,0.35)] backdrop-blur-sm" 
      *ngIf="(requiresMembership && 
      ((loggedInUser === undefined || service.ServiceData.credits! <= 0 || !validMembership) &&
      (loggedInUser === undefined || service.ServiceData.credits !== 0 || trialSessionUsed) &&
      (loggedInUser !== undefined || service.ServiceData.credits !== 0)))">
        <div *ngIf="loggedInUser !== undefined">
          <p class="white_text text-center" *ngIf="service.ServiceData.credits! > 0 && !validMembership">{{translate("A valid membership is required with") + service.ServiceData.credits + " " + translate("remaining(s) credit(s)")}}</p>
          <p class="white_text text-center" *ngIf="service.ServiceData.credits! <= 0 && trialSessionUsed">{{translate("already used your trial session")}}</p>
        </div>
        <div *ngIf="loggedInUser === undefined">
          <p class="white_text text-center" *ngIf="service.ServiceData.credits! >= 0">{{translate("must be logged")}}</p>
        </div>

    </div>
   
    <div class="flex flex-col sm:flex-row justify-start items-center sm:justify-between gap-2 sm:gap-5">

      <!--    SERVICE NAME-->
      <div
        class="text-center sm:text-left font-semibold text-sm sm:text-base lg:text-xl text-white h-full leading-tight">
        {{service.ServiceData.name}}
      </div>

      <!--    PRICE AND DURATION-->
      <div class="min-w-max w-full sm:w-max flex flex-row justify-between items-center align-center gap-1 sm:gap-5">
        <div
          class="to-column flex flex-row justify-between items-center sm:justify-start min-w-[10rem] py-3 mt-2 sm:mt-0 borders sm:px-0 full-width gap-3">
          <div class="flex flex-row justify-center self-start sm:w-max">
            <div
              class="flex flex-row-reverse sm:flex-row items-center justify-center sm:justify-end gap-1 sm:gap-0 align-middle self-start text-white h-full">
              <div class="flex font-semibold text-xs mr-3 items-center w-max">
                {{service.ServiceData.duration}} {{'service-card-component.minutes' | translate}}
              </div>
              <app-icon [imageSource]="'assets/images/Clock_brown.png'"></app-icon>
            </div>
          </div>

          <div clas="flex flex-row justify-center w-full sm:w-max">
            <div *ngIf="service.ServiceData.price! >0"
                 class="flex flex-row-reverse sm:flex-row items-center justify-center sm:justify-end gap-1 sm:gap-0 self-start border border-solid border-transparent rounded w-max">
              <div class="flex font-semibold text-xs text-white mr-3 items-center">
                {{service.ServiceData.price}} {{'den' | translate }}
              </div>
              <app-icon [materialIcon]="'attach_money'" [color]="'brown'"></app-icon>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="flex flex-col md:flex-row justify-between gap-6 lg:gap-12">

      <!--    SERVICE DESCRIPTION-->
      <p class="text-white text-xs md:text-sm lg:text-base leading-tight mt-[-2] w-100 md:w-2/3 lg:w-10/12">
        <span [ngClass]="{'hidden': checkTextTruncate(service.ServiceData.description ?? '', service.ShowMore)}"> {{ service.ServiceData.description }} </span>
        <span [ngClass]="{'hidden': !checkTextTruncate(service.ServiceData.description ?? '', service.ShowMore)}">
          {{(service.ServiceData.description ?? "" | truncate:(windowDimensions.width >= windowDimensions.threshold_3xl ? truncateLengths.LongTextLargeDisplay : (windowDimensions.width >= windowDimensions.threshold_lg ? truncateLengths.LongText : truncateLengths.LongTextMobileDisplay)))
          }}
        </span>
        <button *ngIf="checkTextTruncate(service.ServiceData.description ?? '', false)" (click)="service.ShowMore = !service.ShowMore;" class="">
          <p class="opacity-70 ml-[.15rem]"> 
            {{ (service.ShowMore ? 'actions.read_less' : 'actions.read_more') | translate}}
          </p>
        </button>
      </p>

      <!--    BOOK APPOINTMENT-->
      <button (click)="!requiresMembership || (loggedInUser !== undefined && service.ServiceData.credits! > 0 && validMembership) ||
        (loggedInUser !== undefined && service.ServiceData.credits === 0 && !trialSessionUsed) ||
        (loggedInUser === undefined && service.ServiceData.credits === 0) ? navigateToBookingPage(service.ServiceData.id!) : null"
              class="btn btn-sm bg-primary_gradient text-white text-xs lg:text-sm sm:font-semibold self-end p-2 rounded-md w-full md:w-1/3 lg:w-2/12 select-none shadow-md">
        {{'service-card-component.make-appointment' | translate}}
      </button>


    </div>
  </div>
</div>
