import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  DailyAvailabilityArgs,
  DateTimeAvailabilityDataDictionaryUsersResult, EmployeeData,
  EmployeeDataUsersResult
} from '../clients/users-api/UsersApiClient.gen';

import {UsersApiClient} from '../clients/users-api/UsersApiClient';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private usersApiClient: UsersApiClient) {
  }

  retrieveScheduleByAvailability(body: DailyAvailabilityArgs | undefined): Observable<DateTimeAvailabilityDataDictionaryUsersResult> {
    return this.usersApiClient.retrieveAvailability(body);
  }

  retrieveEmployeeById(employeeId: number): Observable<EmployeeDataUsersResult> {
    return this.usersApiClient.retrieveEmployeeById(employeeId);
  }

  retrieveEmployeesByServiceId(serviceId: number): Observable<EmployeeDataUsersResult> {
    return this.usersApiClient.retrieveEmployeesByServiceId(serviceId);
  }

  retrieveAnyAvailableEmployeeByServiceId(serviceId: number): Observable<EmployeeDataUsersResult>{
    return this.usersApiClient.retrieveAnyAvailableEmployeeByServiceId(serviceId);
  }

}
