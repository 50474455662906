import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { ProductInfoData } from 'src/app/models/ProductInfoData';

export enum ProductInfoMode {
  translationMode,
  staticMode
}

@Component({
  selector: 'app-product-info-item',
  templateUrl: './product-info-item.component.html',
  styleUrls: ['./product-info-item.component.css']
})
export class ProductInfoItemComponent {

  @Input() productInfoData!: ProductInfoData
  @Input() mode: ProductInfoMode = ProductInfoMode.translationMode;

  translationMode = ProductInfoMode.translationMode;
  staticMode = ProductInfoMode.staticMode;

  constructor(private translateService: TranslateService) {
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }
}
