<div class="w-full text-center relative flex gap-[1rem] lg:gap-[.5rem] flex-col align-baseline
lg:opacity-20 opacity-100 hover:opacity-100 transition-all duration-300 cursor-default lg:select-none"
[ngClass]="{'mr-[3.5rem]': positioning === leftSide, 
            'ml-[3.5rem]': positioning === rightSide}"> 
    <div class="flex w-full lg:w-auto justify-between lg:justify-center items-center align-middle gap-5"
    [ngClass]="{'text-right self-end flex-row-reverse': positioning === leftSide, 
                'text-left flex-row self-baseline': positioning === rightSide || positioning === centered}">
        
        <div class="relative hidden lg:flex">
            <p class="orbitalItem_index absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"> {{index}} </p>
            <img src="./assets/images/bubble_marker.svg" class="" alt="">      
        </div>

        <h1 class="uppercase text-white w-full text-[1rem] font-custom_semibold cursor-pointer lg:cursor-default" (click)="windowDimensions.width < windowDimensions.threshold_lg ? toggleInfoMore() : null">
            {{translate(data.titleKey)}}
        </h1>

        <span 
            class="lg:hidden font-custom_regular cursor-pointer text-[200%] text-center flex justify-center align-middle items-center select-none" 
            data-status="0"
            (click)="toggleInfoMore()"> 
            <p class="w-[1rem] text-center self-center" *ngIf="!descriptionOpen"> + </p> 
            <p class="w-[1rem] text-center self-center" *ngIf="descriptionOpen"> − </p> 
        </span>
    </div>

    <div class="text-[#DCDCDC] text-[0.85rem] lg:text-[0.7rem] transition-all duration-150"
    [ngClass]="{'text-right pl-[3.5rem]': positioning === leftSide, 
                'text-left pr-[3.5rem]': positioning === rightSide,
                'text-justify' : positioning === centered,
                'max-h-[300px] opacity-100 visible mb-[0rem] pb-[1rem] lg:pb-0': descriptionOpen,
                'max-h-[0px] opacity-0 invisible mb-[-1rem] pb-[0rem] lg:max-h-full lg:opacity-100 lg:visible': !descriptionOpen}">
    <p>
        {{translate(data.descriptionKey)}}
    </p>    
    </div>

    
    <hr class="w-full h-[1px] border-white opacity-10 border-solid lg:hidden">

</div>