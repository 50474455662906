import { Component, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StreamsSwiperComponent } from 'src/app/components/streams-swiper/streams-swiper.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { AutoplayOptions } from 'swiper/types';
import { DeviceData, DevicesData, StrapiDeviceService } from 'src/app/services/strapi-device.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

const NEW_COMPONENT_DATE_DAYS_DIFF = 30;

export enum ProductType {
  HighlightedProduct,
  OurProduct
}

export interface ProductCardData {
  productCard: ComponentRef<any>;
  productType: ProductType;
}

@Component({
  selector: 'app-our-products-cards',
  templateUrl: './our-products-cards.component.html',
  styleUrls: ['./our-products-cards.component.css']
})
export class OurProductsCardsComponent {
  @Input() index?: number;
  @Input() autoClickOnTransitionEnd: boolean = false;
  @Input() loop: boolean = true;
  @Input() includeHighlightProducts: boolean = true;
  @Input() includeOurProducts: boolean = true;
  @Input() autoPlay: AutoplayOptions | boolean = {
    delay: 5000,
    waitForTransition: true,
    reverseDirection: false
  };
  
  readonly highlightProductsURL:string = '/highlighted-products/:';
  readonly ourProductsURL:string = '/our-products/:';

  @ViewChild(StreamsSwiperComponent) swiper!: StreamsSwiperComponent;

  constructor(private translateService: TranslateService, private viewContainerRef: ViewContainerRef, 
    private strapiDeviceService: StrapiDeviceService
  ){ }

  ngAfterViewInit(): void {
    let productElements: ProductCardData[] = [];

    if(this.includeHighlightProducts || this.includeOurProducts){
      this.translateService.get('our-products.highlighted-products').forEach(result => {
        if(this.includeHighlightProducts) {
          Object.keys(result).forEach((key: string, index: number) => {
            const product = result[key];
            const componentRef = this.viewContainerRef.createComponent(ProductCardComponent);
            componentRef.instance.title = product.title;
            componentRef.instance.image = product.image;
            componentRef.instance.path = `${this.highlightProductsURL}${index+1}`
  
            productElements.push({
              productCard: componentRef,
              productType: ProductType.HighlightedProduct
            } as ProductCardData);
            componentRef.changeDetectorRef.detectChanges();
            componentRef.destroy();
          });
        }
  
        if(this.includeOurProducts){
          this.strapiDeviceService.getStrapiDevices().subscribe((devices: DevicesData | DeviceData) => { 
            let fetchedDevices = devices as DevicesData;
            if(fetchedDevices.data.length > 0){
              for(let device of fetchedDevices.data){
                let deviceInfo = device.attributes;
                if(deviceInfo){
                  const componentRef = this.viewContainerRef.createComponent(ProductCardComponent);
                  componentRef.instance.title = deviceInfo.DeviceName;
                  componentRef.instance.image = `${environment.cmsBaseUrl}/${deviceInfo.DeviceImage.data.attributes.url}`;
                  componentRef.instance.path = `${this.ourProductsURL}${device.id}/:${productElements.length + 1}`;

                  let updatedAt = moment(device.attributes.updatedAt).utc(true);
                  let date = moment(new Date).utc(true);
                  let diff = date.diff(updatedAt, 'days');
                  
                  if(diff <= NEW_COMPONENT_DATE_DAYS_DIFF){
                    componentRef.location.nativeElement.classList.add('new-product');
                  }

                  productElements.push({
                    productCard: componentRef,
                    productType: ProductType.OurProduct
                  } as ProductCardData);
                  componentRef.changeDetectorRef.detectChanges();
                  componentRef.destroy();
                }
              }
            }
            
            this.swiper.generateSlides(productElements, this.index ?? 0);
          });
        } else {
          this.swiper.generateSlides(productElements, this.index ?? 0);
        }
      });
    }

  }
}
