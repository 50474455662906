import { TranslateService } from '@ngx-translate/core';
import {Component, Input, OnInit} from '@angular/core';
import {ServicesService} from 'src/app/services/services.service';
import {
  ServiceData,
  ServiceDataPaginationDataUsersResult,
  UsersResultStatus
} from 'src/app/clients/users-api/UsersApiClient.gen';
import {NavigationExtras, Router} from '@angular/router';
import {AuthenticationService} from "../../../services/authentication.service";
import { UserProfileDto } from 'src/app/models/UserProfileDto';
import {UserService} from "../../../services/user.service";
import { WindowDimensions, WindowDimensionsService } from 'src/app/services/window-dimension-service.service';
import { TruncateLengths, TruncateLengthsDefault } from 'src/app/pipes/truncate';
import { cloneDeep } from 'lodash';

enum checkTextTruncateType {
  LongText,
  ShortText
}

interface ServiceCard  {
  ServiceData: ServiceData;
  ShowMore: boolean;
}

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.css']
})
export class ServiceCardComponent implements OnInit {

  @Input() categoryId: number | undefined;
  @Input() requiresMembership: boolean = false;
  result: ServiceDataPaginationDataUsersResult | undefined;
  services: ServiceCard [] | undefined = [];
  loggedInUser?: UserProfileDto | undefined;
  validMembership: boolean = false
  trialSessionUsed: boolean = false;

  windowDimensions: WindowDimensions = {} as WindowDimensions;
  truncateLengths: TruncateLengths = TruncateLengthsDefault;
  showMore: boolean = false;

  longTextType: checkTextTruncateType = checkTextTruncateType.LongText;
  shortTextType: checkTextTruncateType = checkTextTruncateType.ShortText;

  constructor(private servicesService: ServicesService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private _windowDimensionService: WindowDimensionsService,
              private translationService: TranslateService) {
    this._windowDimensionService.getWindowDimensions$().subscribe((dimensions) => {
      this.windowDimensions = dimensions;
    });
  }

  ngOnInit() {
    this.translationService.defaultLang = 'mk';
    
    this.retrieveServicesByCategoryId(this.categoryId);
    this.authenticationService.isLoggedIn.subscribe(value => {
      if (value) {
        let user = sessionStorage.getItem('profile')
        if (user !== undefined) {
          this.loggedInUser = JSON.parse(user ?? "") as UserProfileDto;
          this.userService.checkForUsedTrialSession(this.loggedInUser.username).subscribe(result => {
            if (result == UsersResultStatus.Success) {
              this.validMembership = false;
              this.trialSessionUsed = false;
            } else if (result == UsersResultStatus.TrialSessionAlreadyUsed) {
              this.validMembership = false;
              this.trialSessionUsed = true;
            } else {
              this.validMembership = true;
              this.trialSessionUsed = true;
            }
          })
        }
      } else {
        this.loggedInUser = undefined;
      }

    })
  }

  public translate(key: string): string {
    return this.translationService.instant(key);
  }

  retrieveServicesByCategoryId(categoryId: number | undefined): void {
    if (categoryId) {
      this.servicesService.retrieveAllServicesByCategoryId(categoryId).subscribe((data) => {
        this.result = data;
        if(data.content?.items !== undefined){ 
          for(let element of data.content?.items){
            this.services?.push({ServiceData: cloneDeep(element), ShowMore: false} as ServiceCard );
          }
        }
      });
    }
  }

  navigateToBookingPage(serviceId: number) {
    this.servicesService.retrieveServiceById(serviceId).subscribe(result => {
      const navigationExtras = {
        state: {
          service: result.content,
          serviceId: result.content?.id
        }
      } as NavigationExtras;

      this.router.navigateByUrl('/reservation/book', navigationExtras);
    })
  }

  checkTextTruncate(text: string, showMoreComparator?: boolean, type: checkTextTruncateType = checkTextTruncateType.LongText): boolean {
    let LengthCompare = 0;
    let LargeDisplayLengthCompare = 0;
    let MobileDisplayLengthCompare = 0;

    switch(type){
      case checkTextTruncateType.LongText: 
        LengthCompare = this.truncateLengths.LongText; 
        LargeDisplayLengthCompare = this.truncateLengths.LongTextLargeDisplay; 
        MobileDisplayLengthCompare = this.truncateLengths.LongTextMobileDisplay; 
      break;
      case checkTextTruncateType.ShortText: 
        LengthCompare = this.truncateLengths.ShortText; 
        LargeDisplayLengthCompare = this.truncateLengths.ShortTextLargeDisplay; 
        MobileDisplayLengthCompare = this.truncateLengths.ShortTextMobileDisplay;
      break;
    }

    return text.length! > (this.windowDimensions.threshold_3xl ? LargeDisplayLengthCompare
      : (this.windowDimensions.width >= this.windowDimensions.threshold_lg ? LengthCompare : MobileDisplayLengthCompare)) && (showMoreComparator == undefined || !showMoreComparator)
  }

}
