import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'VacuActivUsers';

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('mk');
    translate.addLangs(['mk', 'en']);
    translate.use('mk');
  }

  navigationLinks = [
    { label: 'ПОЧЕТНА', route: 'home' },
    { label: 'РЕЗЕРВИРАЈ ОНЛАЈН', route: 'reservation' },
    { label: 'ЦЕНОВНИК', route: 'prices' },
    { label: 'НАШИТЕ УСЛУГИ', route: 'our-services' },
    { label: 'КОНТАКТ', route: 'contact' }
  ];
}
