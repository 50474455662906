import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HighlightedProduct } from 'src/app/pages/our-products-pages/highlighted-products-pages/highlighted-products-page/highlighted-products.component';

@Injectable({
  providedIn: 'root'
})
export class HighlightedProductsService {
  public products: HighlightedProduct[] = [];
  public products$ = new BehaviorSubject<HighlightedProduct[]>([]);

  constructor() { }

  addProduct(product: HighlightedProduct) {
    this.products.push(product);
    this.products$.next(this.products);
  }

  getProductById(productId: string): HighlightedProduct | undefined {
    return this.products.find(product => product.productId === productId);
  }
}
