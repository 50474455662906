<div class="bg-[#160e24] px-4 md:px-16 text-white pb-[1rem] sm:pb-[0rem]">

  <div class="flex flex-col items-start sm:flex-row m-auto sm:justify-between py-4">
     <!-- email -->
    <div class="flex flex-col justify-between items-start sm:w-[30%] sm:h-[140px]">
      <div class="items-start">
        <h2 class="text-xs sm:text-sm md:text-md lg:text-lg hidden sm:block text-start items-start md:h-[21px]">{{"бидете во тек со сите новости" | translate }}</h2>
      </div>

        <div class="relative">
          <form (submit)="saveMail()" class="flex items-center">
            <label for="email"></label>
        
            <div class="relative">
              <input
                type="email"
                id="email"
                name="email"
                [formControl]="userEmail"
                required
                placeholder="Enter your email"
                class="sm:w-[220px] md-[220px] lg:w-[288px] p-2 border text-[14px] border-gray-300 rounded-[2rem] bg-transparent focus:outline-none focus:border-[#9f7ee1] hidden sm:block"
              />
        
              <button 
                id="enter-email-footer"
                aria-label="Submit Email"
                aria-labelledby="enter-email-footer"  
                type="submit" 
                class="absolute right-2 top-0 bottom-0 w-8 h-full overflow-hidden">
                <img src="assets/images/newsBtnArrow.svg" alt="subscribe-news-enter" class="w-full h-full">
              </button>
            </div>
          </form>
        </div>
      
      <div class="flex flex-row items-center justify-center space-x-2">
        <img src="/assets/images/logo.svg" class="w-[8rem] sm:w-[6rem] md:w-[8rem]" alt="vacuactiv-logo">
        <hr class="h-8 w-0.5 bg-white border-0 rounded mr-1 ">
        <h1 class="text-[1rem] sm:text-[0.8rem] md:text-[1rem] font-semibold mt-1 sm-mt-0 ">SKOPJE</h1>
      </div>
    </div>
<!-- contact info -->
    <div class="sm:flex flex-col items-start text-start sm:w-[32%] md:w-[20%] sm:h-[140px]">
      <h1 class="text-[14px] hidden sm:block md:text-1xl font-semibold ">{{ "navigation.contact" | translate }}</h1>

      <div class="flex flex-row space-x-2 my-[-.15rem]">
        <img src="/assets/images/mapIcon.svg" alt="map-icon">
        <a href="https://maps.app.goo.gl/s2t1BkYVxGnuuJCh8" 
        target="_blank"
        aria-label="Google Maps"
        aria-labelledby="Google Maps" 
        class="text-[13px] sm:text-sm py-3.5">{{"address" | translate }}</a>
      </div>

      <div class="flex flex-row space-x-2 my-[-.15rem]">
        <img src="/assets/images/mobIcon.svg" alt="phonenumber-icon" >
        <a [href]="'tel:' + phoneNumber" class="text-[13px] sm:text-sm py-3.5"
        aria-label="Phone Number"
        aria-labelledby="Phone Number" >{{"compnay_phone_number" | translate}}</a>
      </div>

      <div class="flex flex-row space-x-2 my-[-.15rem]">
        <img src="/assets/images/emailIcon.svg" alt="email-icon">
        <a [href]="'mailto:' + companyMail" class="text-[13px] sm:text-sm py-3.5">
       {{"company_mail" | translate}}
         </a>
      </div>
    </div>
 <!-- location map -->
    <div class="hidden sm:flex flex-col justify-between self-end sm:w-[20%] sm:h-[140px]">
        <h3 class="text-[14px] md:text-1xl text-start font-semibold h">{{"location" | translate}}</h3>
        <div style="width: 70% self-end">
          <iframe width="100%" height="100" frameborder="0" 
          scrolling="no" marginheight="0" marginwidth="0" title="Map of Vacu Activ Skopje, location of our business"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d185.30900131159822!2d21.412767555746555!3d42.0014769194072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135415f59d845f53%3A0xfa1d6d77a34bea2!2sVacu%20Activ%20Skopje!5e0!3m2!1sen!2smk!4v1710113248484!5m2!1sen!2smk" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            <a href="https://www.gps.ie/">
              {{"map" | translate }}
            </a>
          </iframe>
        </div>
      
    </div>

  </div>

  <hr class="bg-white w-full h-[1px]"/>

  <div class="flex flex-row justify-between py-4 items-center">
    <p class="text-[10px] sm:text-[14px]">Vacu Activ Skopje &#64; All Rights Reserved 2023</p>
    <div class="flex flex-row space-x-6">
      <img src="/assets/images/fb.svg" class=" w-6 h-auto" alt="facebook">
      <img src="/assets/images/insta.svg" class=" w-6 h-auto" alt="instagram">
    </div>
  </div>

</div>
