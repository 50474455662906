<app-spinner> </app-spinner>
<app-svg-load-morph> </app-svg-load-morph>

<div class="min-h-screen overflow-hidden relative">
    <div class="relative h-full w-screen">
        <header class="flex flex-col justify-center h-[35rem] md:h-[55rem] lg:h-[50rem] w-full relative
      4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 align-middle overflow-hidden">

            <div class="sliding-background-container left-0 top-0 absolute z-[-1] h-[90%] md:h-[87%] lg:h-[85%] overflow-hidden">
                <div class="sliding-background self-center w-[490.125rem] 3xl:w-[150vw] h-full"></div>
            </div>

            <div class="md:w-[40%] w-full mt-[-15rem] self-baseline flex flex-col gap-5 ">
                <div
                    class="text-white text-[1.35rem] md:text-[3rem] font-custom_black text-left w-[60%] lg:w-[120%]">
                    {{'generic-product-page.title' | translate}}
                </div>
                <div class="text-white font-custom_regular text-[0.85rem] md:text-[1.25rem] text-left">
                    <span> {{'generic-product-page.title-description_part1' | translate}} </span>
                    <span class="font-custom_semibold"> {{'generic-product-page.title-description_part2' | translate}} </span>
                    <span> {{'generic-product-page.title-description_part3' | translate}} </span>
                </div>
            </div>
            
        </header>

        <div class="absolute left-1/2 -translate-x-1/2 bottom-[-4.85vh]">
            <app-our-products-cards [autoPlay]="false" [autoClickOnTransitionEnd]="true" [index]="getProductIndex(productIndex)"> </app-our-products-cards>
        </div>
    </div>

    <div class="mb-[2rem] lg:mb-[5rem]"></div>

    <div scrollReveal class="transition-all duration-500 ease-in-out w-screen min-h-max z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
    4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] sm:px-20 px-7 xl:pt-20 align-middle relative overflow-hidden mb-[5rem]" 
        [ngClass]="{'opacity-0': !showDevice, 'opacity-100': showDevice}">
        <div class="full-width flex flex-col text-white mt-20">

            <!--    SECTION 1-->
            <div class="flex flex-col mt-[-3rem] md:mt-[2rem] lg:mt-12 full-width items-end relative">

            <div class="flex flex-row justify-end w-full gap-5">

                <div class="w-[17%] md:block hidden"> </div>

                <!--        IMAGE-->
                <div class="md:block absolute hidden z-[-1] left-[-5rem] top-[2rem] w-[70rem] mt-[-15rem]">
                    <img src="assets/images/generic_wave_decoration.svg">
                </div>

                <div class="w-full flex flex-col gap-[2rem] lg:gap-[5rem]">

                    <div class="flex flex-col gap-5">
                        <!--  TITLE-->
                        <div class="flex flex-col gap-1 md:absolute relative left-0">
                            <div *ngIf="!isNullOrEmpty(product.title)" class="text-xl md:text-3xl uppercase font-custom_semibold">{{product.title}}</div>
                            <div *ngIf="!isNullOrEmpty(product.shortDescription)" class="text-sm">{{product.shortDescription}}</div>
                        </div>

                        <!--        DESCRIPTION 1-->
                        <div *ngIf="!isNullOrEmpty(product.quote)" class="text-white text-lg lg:text-2xl font-custom_italic text-left md:mt-[4rem] lg:pt-[3rem]">
                        "{{product.quote}}"
                        </div>
                    </div>

                    <!--        DESCRIPTION 2-->
                    <div class="flex flex-col gap-12 lg:pl-[5rem]" *ngIf="!isNullOrEmpty(product.descriptionPart1) || !isNullOrEmpty(product.descriptionPart2)">
                        <div class="w-40 border-[0.5px] border-transparent border-b-white lg:mb-8" *ngIf="!isNullOrEmpty(product.quote)"></div>
                        <div *ngIf="!isNullOrEmpty(product.descriptionPart1)" class="texts-sm md:text-lg text-justify">{{product.descriptionPart1}}</div>
                        <div class="texts-sm md:text-lg text-justify transition-all duration-150" *ngIf="!isNullOrEmpty(product.descriptionPart2)"
                        [ngClass]="{'max-h-[1000px] opacity-100 mb-[-5rem] visible': readMore_1,
                        'max-h-[0px] opacity-0 invisible mb-[-1rem] pb-[0rem] md:max-h-full md:opacity-100 md:visible': !readMore_1}">
                          {{product.descriptionPart2}}
                        </div>
                    </div>
                </div>

            </div>
            </div>

            <!--    SECTION 2-->
            <div *ngIf="product.deviceInformationSections && product.deviceInformationSections.length > 0" class="md:hidden flex flex-col items-start mt-[7.5rem] lg:mt-[10.68rem] mb-[3.5rem] lg:mb-[-6.25rem] gap-8 md:gap-5 transition-all duration-150"
            [ngClass]="{'max-h-[1000px] opacity-100 visible': readMore_1,
            'max-h-[0px] opacity-0 invisible md:max-h-full md:opacity-100 md:visible': !readMore_1}">
                <div class="flex flex-col items-start gap-8 md:gap-5 z-[-1]">
                    <div *ngFor="let section of product.deviceInformationSections">
                        <div class="flex flex-col items-start gap-8 md:gap-5 z-[-1]">
                        <div class="text-lg md:text-2xl font-custom_semibold">{{section.title}}</div>
                        <div class="text-[#DCDCDC] text-sm md:text-base text-justify">{{section.description}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <span class="md:hidden block font-custom_regular text-white w-max
            opacity-50 hover:opacity-100 active:opacity-100 cursor-pointer text-left select-none mb-[2rem] z-[50]"
            (click)="toggleReadMore1()"
            [ngClass]="{'mt-[-2rem]': readMore_1,
            '-mt-[12rem] mb-[0rem]': !readMore_1}">
                <p *ngIf="!readMore_1"> {{'actions.read_more' | translate}} </p>
                <p *ngIf="readMore_1"> {{'actions.read_less' | translate}} </p>
            </span>

            <!--    SECTION 3-->
            <div class="md:flex hidden flex-col lg:flex-col items-center gap-[3rem] md:mt-[10.68rem] mb-[3.5rem] md:my-[5rem]">
                <!--      INFO SECTION-->
                <div class="reveal" *ngFor="let section of product.deviceInformationSections">
                    <div class="flex flex-col items-start gap-8 md:gap-5 z-[-1]">
                    <div class="text-lg md:text-2xl font-custom_semibold">{{section.title}}</div>
                    <div class="text-[#DCDCDC] text-sm md:text-base text-justify">{{section.description}}</div>
                    </div>
                </div>
            </div>

            <!--    SECTION 4-->
            <div *ngIf="product.items && product.items.length > 0" class="reveal flex flex-col items-center">

                <!--      TITLE-->
                <div class="flex flex-col items-end">
                <div class="flex flex-col md:flex-row gap-2">
                    <div class="text-xl md:text-3xl uppercase text-center md:text-left w-full md:w-auto">{{product.functionalitiesHeading}}</div>
        
                    <!--       BOLD TEXT & NEON LINE -->
                    <div>
                        <div class="text-xl md:text-3xl uppercase font-custom_bold mb-[0.5rem] text-center md:text-left">{{product.title}}
                        </div>
        
                        <svg viewBox="0 0 326 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_2046_1629)">
                        <path d="M4 9H322" stroke="url(#paint0_linear_2046_1629)" stroke-width="9"/>
                        </g>
                        <g filter="url(#filter1_f_2046_1629)">
                        <path d="M4 9H322" stroke="url(#paint1_linear_2046_1629)" stroke-width="4"/>
                        </g>
                        <defs>
                        <filter id="filter0_f_2046_1629" x="0" y="0.5" width="326" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2046_1629"/>
                        </filter>
                        <filter id="filter1_f_2046_1629" x="2" y="5" width="322" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_2046_1629"/>
                        </filter>
                        <linearGradient id="paint0_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CC00FF"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_2046_1629" x1="1210.61" y1="8.496" x2="77.1001" y2="8.48956" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                </div>
        
                </div>
        
                <div class="grid grid-cols-1 md:grid-cols-2 gap-[4rem] lg:gap-[5rem] md:gap-[8rem] justify-between mt-[3rem] lg:mt-[5rem]">
                <div *ngFor="let item of product.items">
                    <app-product-info-item [productInfoData]="item" [mode]="productsMode"></app-product-info-item>
                </div>
                </div>
        
            </div>

        </div>
    </div>
    
</div>

<app-footer></app-footer>

<div class="fixed top-0 left-0 right-0 bottom-0 z-[-2] object-cover bg-[#0a070f]"></div>
  