import { Component } from '@angular/core';
import { ProductInfoData } from 'src/app/models/ProductInfoData';
import { HighlightedProduct } from '../../highlighted-products-page/highlighted-products.component';
import { HighlightedProductsService } from 'src/app/services/highlighted-products.service';

@Component({
  selector: 'app-body-shape',
  templateUrl: './body-shape.component.html',
  styleUrls: ['./body-shape.component.css']
})
export class BodyShapeComponent implements HighlightedProduct {
  product:HighlightedProduct | undefined;

  productId: string = '1'; 
  backdropPath: string = 'assets/images/bodyshape_bg.jpg'; 

  constructor(private highlightedProductsService: HighlightedProductsService) { }

  ngOnInit(): void {
    if(this.highlightedProductsService){
      this.product = this.highlightedProductsService.getProductById(this.productId);
      this.highlightedProductsService.addProduct(this);
    }
  }

  items: ProductInfoData[] = [{
    iconPath: 'assets/images/aromatherapy-icon.svg',
    titleKey: 'body-shape-page.aromatherapy-title',
    headingKey: 'body-shape-page.aromatherapy-heading',
    descriptionKey: 'body-shape-page.aromatherapy-description'
  }, {
    iconPath: 'assets/images/ozontherapy-icon.png',
    titleKey: 'body-shape-page.ozontherapy-title',
    headingKey: 'body-shape-page.ozontherapy-heading',
    descriptionKey: 'body-shape-page.ozontherapy-description'
  },
    {
      iconPath: 'assets/images/collagen-lamp-icon.svg',
      titleKey: 'body-shape-page.collagen-lamp-title',
      headingKey: 'body-shape-page.collagen-lamp-heading',
      descriptionKey: 'body-shape-page.collagen-lamp-description'
    },
    {
      iconPath: 'assets/images/color-therapy-icon.svg',
      titleKey: 'body-shape-page.color-therapy-title',
      headingKey: 'body-shape-page.color-therapy-heading',
      descriptionKey: 'body-shape-page.color-therapy-description'
    },
    {
      iconPath: 'assets/images/ionisation-icon.svg',
      titleKey: 'body-shape-page.ionisation-title',
      headingKey: 'body-shape-page.ionisation-heading',
      descriptionKey: 'body-shape-page.ionisation-description'
    },
    {
      iconPath: 'assets/images/under-pressure-icon.svg',
      titleKey: 'body-shape-page.under-pressure-title',
      headingKey: 'body-shape-page.under-pressure-heading',
      descriptionKey: 'body-shape-page.under-pressure-description'
    },

  ]

  readMore_1: boolean = false;

  toggleReadMore1()
  { 
    this.readMore_1 = !this.readMore_1;
  }
}
