<div class="flex flex-col items-center gap-4 w-full">
  <!--          SERVICE TITLE-->
  <div class="flex flex-row w-full justify-center items-center">
    <div
      class="text-sm lg:text-base md:leading-1 font-semibold uppercase">{{terms![0].serviceName!}}</div>
  </div>

  <!--          SERVICE PRICE AND DURATION-->
  <app-appointment-info [serviceDuration]="serviceDuration!"
                        [servicePrice]="servicePrice!"
                        [employeeName]="employeeName!" class="full-width"></app-appointment-info>

  <div class="flex flex-col gap-3 full-width">
    <div *ngFor="let term of terms!">
      <div class="flex flex-row full-width wrapper"
           [ngClass]="{'disabled': term!.cancelled || term.fromDate! < currentTime}">
        <div class="flex flex-row p-3 gap-4 items-center card_generic pl-2 py-2 flex-grow">
          <div class="w-1/7 ">
            <app-icon [materialIcon]="'event_available'" [color]="'brown'"></app-icon>
          </div>
          <div
            class="full-width to-column flex flex-row gap-2 justify-between lg:gap-5 text text-xs sm:text-base md:text-sm lg:text_base">
            <div
              [ngClass]="{'justify-between gap-2': !term.cancelled, 'justify-start items-center': term.cancelled || term.fromDate! < currentTime}"
              class="full-width flex flex-row">
              <div class="align-left-mobile w-full text-left"
                   [ngClass]="{'line-through': term.cancelled || term.fromDate!<currentTime}">{{term.fromDate | date: 'dd.MM.YYYY'}}</div>
              <div *ngIf="!term.cancelled && term.fromDate!>=currentTime && terms!.length==1" class="align-left-mobile w-full text-right"
                   [ngClass]="{'line-through': term.cancelled || term.fromDate!<currentTime}">{{term.fromDate | date: 'HH:mm'}}
                часот
              </div>
              <div *ngIf="!term.cancelled && term.fromDate!>=currentTime && terms!.length>1" class="align-left-mobile w-full text-right"
                   id="slide-along"
                   [ngClass]="{'line-through': term.cancelled || term.fromDate!<currentTime}">{{term.fromDate | date: 'HH:mm'}}
                часот
              </div>
              <div *ngIf="term.cancelled || term.fromDate!<currentTime"
                   class="align-left-mobile w-full text-right line-through px-3">
                {{term.fromDate | date: 'HH:mm'}} {{"hour" | translate }}
              </div>
              <div *ngIf="term.cancelled"
                   class="text-left py-1 px-2 border border-red-900 cancel-button rounded-sm w-min h-min text-white text-xs uppercase">{{'booking-confirmation-component.cancelled' | translate}}</div>
              <div *ngIf="term.fromDate! < currentTime && !term.cancelled"
                   class="text-left py-1 px-2 border border-gray-500 bg-gray-700 rounded-sm w-min h-min text-gray-200 text-xs uppercase">{{'booking-confirmation-component.passed' | translate}}</div>
            </div>


          </div>
          <div *ngIf="terms!.length>1 && !term.cancelled && countAvailableTerms()>0 && !rerouting" id="slide"
               style="height: 100% !important;" class="flex flex-row">
            <div
              class="w-[50px] bg-purple flex flex-col justify-center items-center px-1 lg:px-2 hover:cursor-pointer hover:scale-y-110 transition ease-in-out duration-150"
              (click)="editTerm(term!.id)">
              <span class="material-symbols-outlined text-base">edit</span>
              <div class="text-[0.5em] text">{{"edit" | translate }}</div>
            </div>
            <div
              class="w-[50px] bg-red flex flex-col justify-center items-center px-1 lg:px-2 hover:cursor-pointer hover:scale-y-110 transition ease-in-out duration-150"
              (click)="onCancel(term!.id)">
              <span class="material-symbols-outlined text-base">delete_forever</span>
              <div class="text-[0.5em] text">{{'actions.cancel' | translate}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <!--          ACTION BUTTONS-->
    <div class="flex flex-col md:flex-row justify-between pt-4 border border-transparent border-t-white"
         *ngIf="countAvailableTerms()>0">

      <div class="full-width">
        <div class="flex flex-col sm:flex-row w-100 gap-2 sm:gap-5 justify-center"
             [ngClass]="{'md:justify-center': terms!.length>1, 'md:justify-between': terms!.length==1}">

          <button *ngIf="terms!.length==1 || countAvailableTerms()==1" (click)="editTerm(terms[0].id!)" [disabled]="rerouting"
                  class="w-full sm:w-1/2 btn btn-sm bg-primary_gradient text-white text-xs xl:text-sm font-semibold self-end p-2 rounded-md select-none shadow-md uppercase">
            {{'actions.edit' | translate}}
          </button>

          <button (click)="cancelBooking(countAvailableTerms()==1)" [ngClass]="{'md:w-1/2': terms!.length==1}"
                  [disabled]="rerouting"
                  class="w-full sm:w-1/2 btn btn-sm cancel-button text-white text-xs xl:text-sm font-semibold self-end p-2 rounded-md select-none shadow-md uppercase">
            {{terms!.length == 1 || countAvailableTerms() == 1 ? translate('booking-confirmation-component.cancelBooking') : translate('user-booking-overview.cancel-booking')}}
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="isConfirmationPopUpOpen">
  <app-confirm-cancel-popup (close)="closePopUp($event)" [bookingId]="bookingId!"
                            [numberOfTerms]="selectedTerm!=0 ? 1 : terms!.length"
                            [termId]="selectedTerm!"></app-confirm-cancel-popup>
</ng-container>


