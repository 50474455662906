<div class="popup-position top-0 left-0 flex items-center justify-center popup-anim">
  <div class="m-0 md:m-4 p-10 popup-background flex flex-col justify-center align-middle items-center 
  rounded rounded-bl-[3rem] rounded-tr-[3rem] shadow-md space-y-2 w-[90vw] sm:w-[450px] h-[28rem] sm:h-[29rem]">

    <!--  USER DATA-->
    <div *ngIf="!showVerificationCode && !showPassword" class="w-full">

      <!--  TITLE-->
      <h2 class="md:text-[1.5rem] text-wrap text-center text-white font-semibold mb-4 text-[18px]">
        {{ "actions.Sign Up Account" | translate }}
      </h2>

      <!--  SEPARATOR-->
      <div class="border-b border-solid border-white my-4 w-1/2 mx-auto mb-4" style="width: 80px"></div>

      <!--  INPUT FORM-->
      <form [formGroup]="signupForm" (submit)="goToPasswordScreen()" class="flex flex-col gap-5">

        <input type="text" placeholder="{{'actions.yourName' | translate}}" id="name" [ngClass]="getStyling('name')"
               formControlName="name"
               class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

        <input type="text" placeholder="{{'actions.yourLastname' | translate}}" [ngClass]="getStyling('surname')"
               formControlName="surname"
               class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

        <input type="text" id="phoneNumber" placeholder="{{'actions.yourPhone' | translate}}"
               [ngClass]="getStyling('phoneNum')"
               formControlName="phoneNum"
               class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"
               inputmode="numeric" pattern="[0-9]*" (input)="onPhoneNumInput($event)"/>

        <input type="text" placeholder="{{'actions.yourEmail' | translate}}" id="email"
               [ngClass]="getStyling('email')"
               formControlName="email"
               class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>


        <div *ngIf="showUserAlreadyExistsError" class="mt-2">
          <app-status-indicator [message]="'register.user-email-exists' | translate"
                                [status]="resultStatus"></app-status-indicator>
        </div>
        <div [ngClass]="{'mt-2': !showUserAlreadyExistsError, '-mt-2': showUserAlreadyExistsError}">
          <button (click)="goToPasswordScreen()"
                  class="bg-[#9f7ee1] kin-primary w-full text-white px-4 py-2 rounded text-xs md:text-sm uppercase">{{ "actions.next" | translate }}</button>
        </div>

      </form>

      <div class="pace-y-2 space-x-1 mt-[1rem]">
        <div
          class="to-column-leading-0 flex gap-1 md:gap-2 flex-row justify-center text-center md:text-start items-center md:items-end ">
          <p class="text-xs sm:text-sm text-white ">{{"actions.haveAnAccount" | translate}}</p>
          <p class="text-xs sm:text-sm text-[#9f7ee1] hover:cursor-pointer"
             (click)="openLogInPopUp()">{{"actions.logIn" | translate}}</p>
        </div>
      </div>

    </div>

    <!--  PASSWORD-->
    <div *ngIf="showPassword">

      <!--    BACK BUTTON-->
      <div class="mb-6 lg:mb-4">
        <div class="flex items-center">
          <button class="w-8 h-8 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110"><img
            src="../../../../assets/images/button-arrow.svg" alt="" class="rotate-180" (click)="back()"></button>
          <h4 class="mr-3 text-xs md:text-sm ml-5 uppercase text-white">{{'actions.back' | translate}}</h4>
        </div>
      </div>

      <app-password-form (submit)="register($event)" [action]="action"></app-password-form>

    </div>

    <!--      VERIFICATION CODE-->
    <div *ngIf="showVerificationCode">
      <app-verification-code (codeVerified)="codeVerified()"
                             (closePopUp)="closePopup()"
                             [userEmail]="userEmail"
                             [userPassword]="userPassword"
                             [verificationCode]="verificationCode"></app-verification-code>
    </div>

  </div>
</div>

<div class="overlay popup-anim" (click)="closePopup()"></div>
