import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent {
  @Input() title!: string;
  @Input() image!: string;
  @Input() path!: string;

  @Input() selected: boolean = false;

  constructor(private router: Router, private ngZone: NgZone) {
  }

  click() {
    this.ngZone.run(() => {
      this.router.navigateByUrl('/' + this.path);
    });
  }
}
