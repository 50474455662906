import { Injectable } from '@angular/core';
import { UsersApiClient } from '../clients/users-api/UsersApiClient';
import { ConfigurationParameterDataUsersResult } from '../clients/users-api/UsersApiClient.gen';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationParameterService {
  
  constructor(private userApiClient: UsersApiClient) {
  }

  retrieveActiveConfigurationParameter(): Observable<ConfigurationParameterDataUsersResult> {
    return this.userApiClient.retrieveActiveConfigurationParameter();
  }

}
