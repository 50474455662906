<section class="page-height w-screen overflow-x-hidden z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 md:px-[8.625rem] ml:px-[7rem] px-5 sm:px-5 md:pb-40 xl:pt-20 align-middle relative">

  <div class="flex flex-col gap-[2rem] w-full mb-[2rem]">
    <div class="flex flex-col gap-[1rem]">
      <div class="flex text-white font-bold text-lg md:text-2xl uppercase">
        {{'price-list.title' | translate}}
      </div>
    
      <div class="flex mb-[1rem]">
        <img src="assets/images/neon_middle.svg" class="h-3" alt="line"/>
      </div>
    </div>
  
    <div class="flex flex-col lg:grid lg:grid-flow-row lg:grid-cols-2 gap-10 lg:gap-[5rem]">
  
      <div *ngFor="let serviceCategory of serviceCategoriesData; index as i" 
      [ngClass]="{'col-span-2': serviceCategory.services!.length > 7, 'col-span-1': serviceCategory.services!.length<=7}">
        <app-price-list-section *ngIf="serviceCategory.image!=undefined" [serviceCategory]="serviceCategory" [shadow]="i==0 || i==3 ? 0 : 1"  ></app-price-list-section>
      </div>
  
    </div>
  </div>

</section>

<app-footer></app-footer>

<app-spinner 
[secondaryConditionSatisfied]="loadingConditionSatisfied"  
(functionToRetry)="retrieveServiceCategories()">
</app-spinner>

<app-svg-bg-animation> </app-svg-bg-animation>
