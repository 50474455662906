import { Component } from '@angular/core';
import { HighlightedProduct } from '../../highlighted-products-page/highlighted-products.component';
import { HighlightedProductsService } from 'src/app/services/highlighted-products.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activ-shape',
  templateUrl: './activ-shape.component.html',
  styleUrls: ['./activ-shape.component.css']
})
export class ActivShapeComponent implements HighlightedProduct {
  product: HighlightedProduct | undefined;

  productId: string = '5'; 
  backdropPath: string = 'assets/images/activshape_bg.jpg'; 
  
  readMore_1: boolean = false;

  constructor(
    private highlightedProductsService: HighlightedProductsService) { 
  }

  ngOnInit(): void {
    if(this.highlightedProductsService){
      this.product = this.highlightedProductsService.getProductById(this.productId);
      this.highlightedProductsService.addProduct(this);
    }
  }

  toggleReadMore1() { 
    this.readMore_1 = !this.readMore_1;
  }
}
