import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {UsersResultStatus} from "../../clients/users-api/UsersApiClient.gen";
import {ToastrService} from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  userEmail: FormControl = new FormControl('', [Validators.email])
  phoneNumber: string = '';
  companyMail: string = '';
  facebookUrl: string = '';
  instagramUrl: string = '';

  constructor(private userService: UserService,
              private toasterService: ToastrService,
              private translate: TranslateService) {
  }

  ngAfterContentInit (){
    this.translate.get('compnay_phone_number').subscribe((res: string) => {
      this.phoneNumber = res;
    });

    this.translate.get('company_mail').subscribe((res: string) => {
      this.companyMail = res;
    });

    this.translate.get('links.facebook').subscribe((res: string) => {
      this.facebookUrl = res;
    });

    this.translate.get('links.instagram').subscribe((res: string) => {
      this.instagramUrl = res;
    });
  }

  saveMail() {
    if (this.userEmail.invalid){ return; }

    this.userService.storeUserEmail(this.userEmail.value!).subscribe(result => {
      if (result === UsersResultStatus.Success) {
        this.toasterService.success(this.translate.instant('Успешно зачленување'), '', {positionClass: 'toast-top-center', timeOut: 4000})
        this.userEmail.reset();
      }
    })

  }
}
