import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceCategoryDataPaginationDataUsersResult} from '../clients/users-api/UsersApiClient.gen';

import {UsersApiClient} from '../clients/users-api/UsersApiClient';

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService {
  constructor(private usersApiClient: UsersApiClient) {
  }

  retrieveAllServiceCategory(includePackages: boolean): Observable<ServiceCategoryDataPaginationDataUsersResult> {
    return this.usersApiClient.retrieveAllServiceCategories(includePackages);
  }


}
