import { Component } from '@angular/core';
import { ServiceCategoryService } from "../../services/service-category.service";
import { ServiceCategoryData } from "../../clients/users-api/UsersApiClient.gen";

@Component({
  selector: 'app-prices-page',
  templateUrl: './prices-page.component.html',
  styleUrls: ['./prices-page.component.css']
})
export class PricesPageComponent {
  loadingConditionSatisfied: boolean = false;
  serviceCategoriesData: ServiceCategoryData[] | undefined

  constructor(private serviceCategoryService: ServiceCategoryService) {
  }
  ngOnInit(){
    this.retrieveServiceCategories();
  }

  retrieveServiceCategories(): void {
    this.serviceCategoryService.retrieveAllServiceCategory(true).subscribe((data) => {
      if(data.status === 'Success'){
        this.serviceCategoriesData = data.content ? data.content.items?.filter(x => x.image !== null) : []
        this.loadingConditionSatisfied = this.serviceCategoriesData !== undefined &&  this.serviceCategoriesData?.length > 0 ? true : false;
      } else {
        this.loadingConditionSatisfied = false;
      }
    })
  }

}
