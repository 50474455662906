import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {nameValidator} from "../../common/validators";
import {MailService} from "../../services/mail.service";
import {ToastrService} from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationParameterService } from 'src/app/services/configuration-parameter.service';
import { ConfigurationParameterData, UsersResultStatus, WorkingHoursDailyConfigData } from 'src/app/clients/users-api/UsersApiClient.gen';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent {
  contactForm!: FormGroup
  submitted: boolean = false;
  phoneNumber: string = '';
  companyMail: string = '';
  
  currentConfigurationData?: ConfigurationParameterData;
  sortedWorkingHours: WorkingHoursDailyConfigData[][] = [];
  hasConfiguration: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private mailService: MailService,
              private toasterService: ToastrService,
              private configurationParameterService: ConfigurationParameterService,
              private translationService: TranslateService) {
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.pattern(nameValidator)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required]),
    })
  }

  ngAfterViewInit(){
    this.translationService.get('compnay_phone_number').subscribe((res: string) => {
      this.phoneNumber = res;
    });

    this.translationService.get('company_mail').subscribe((res: string) => {
      this.companyMail = res;
    });

    this.retrieveActiveConfigurationParameter();
  }

  public sendMessage() {
    if (this.contactForm.invalid) {
      this.submitted = true;
      return
    }

    this.submitted = false;
    let message = "";

    message += this.translate('from') + ": " + this.contactForm.get('name')?.value + '<br>'
    message += this.translate('email') + ": " + this.contactForm.get('email')?.value + '<br>'
    message += '<br>'
    message += '<hr>' + '<br>'
    message += this.contactForm.get('message')?.value

    this.mailService.sendMail(message).subscribe(result => {
      if (result === 'Success') {
        this.contactForm.get('name')?.reset();
        this.contactForm.get('email')?.reset();
        this.contactForm.get('message')?.reset();
        this.toasterService.success(this.translationService.instant('Your message has been sent successfully'),'', {positionClass: 'toast-top-center', timeOut:5000,})
      }
      else this.toasterService.error(this.translationService.instant('An error occurred later'),'', {positionClass: 'toast-top-center', timeOut:5000})
    })
  }

  public async retrieveActiveConfigurationParameter(): Promise<void> {
    await new Promise<void>((resolve) => {
      this.configurationParameterService
        .retrieveActiveConfigurationParameter()
        .subscribe((data) => {
          if (data.status === UsersResultStatus.Success) {
            this.currentConfigurationData = data.content;
            if( this.currentConfigurationData?.workingHours && this.currentConfigurationData?.workingHours.length > 0){
              this.groupWorkingHourConfigs();
              console.log(this.sortedWorkingHours);
            }

            if (!this.hasConfiguration) {
              this.hasConfiguration = true;
            }
            resolve();
          } else {
            this.hasConfiguration = false;
            resolve();
          }
        });
    });
  }

  public groupWorkingHourConfigs() {
    if (
      this.currentConfigurationData &&
      this.currentConfigurationData.workingHours
    ) {
      this.sortedWorkingHours = [];
      this.currentConfigurationData.workingHours =
        this.currentConfigurationData.workingHours.filter(
          (config) => config.working == true
        );
      const currentWorkingHours = this.currentConfigurationData.workingHours;

      let sortedWorkingHoursGroup: WorkingHoursDailyConfigData[] = [currentWorkingHours[0]];

      if (currentWorkingHours.length > 1) {
        for (let i = 1; i < currentWorkingHours.length; i++) {
          let currentWorkingHour = sortedWorkingHoursGroup[sortedWorkingHoursGroup.length - 1];
          let comparingWorkingHour = currentWorkingHours[i];

          if(comparingWorkingHour && currentWorkingHour && currentWorkingHour.dayOfWeek && comparingWorkingHour.dayOfWeek) {
            if (Math.abs(currentWorkingHour.dayOfWeek - comparingWorkingHour.dayOfWeek) === 1 &&
              currentWorkingHour.fromTime?.getUTCHours() === comparingWorkingHour.fromTime?.getUTCHours() 
              && currentWorkingHour.fromTime?.getUTCMinutes() === comparingWorkingHour.fromTime?.getUTCMinutes() 
              && currentWorkingHour.toTime?.getUTCHours() === comparingWorkingHour.toTime?.getUTCHours() 
              && currentWorkingHour.toTime?.getUTCMinutes() === comparingWorkingHour.toTime?.getUTCMinutes()) {
              sortedWorkingHoursGroup.push(comparingWorkingHour);
            } else {
              this.sortedWorkingHours.push(sortedWorkingHoursGroup);
              sortedWorkingHoursGroup = [comparingWorkingHour];
            }
          }
        }
      }

      this.sortedWorkingHours.push(sortedWorkingHoursGroup);
    }
  }

  public translate(translateString: string) {
    return this.translationService.instant(translateString)
  }
}
