import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PasswordFormActionEnum} from "../../../enums/PasswordFormActionEnum";
import {ChangePasswordRequestData} from "../../../clients/users-api/UsersApiClient.gen";

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.css']
})
export class PasswordFormComponent {
  passwordForm!: FormGroup
  submitted: boolean = false;
  showPasswordsDontMatchError: boolean = false;

  @Input() action = PasswordFormActionEnum.ChangePassword

  @Input() hideTitle: boolean = false;
  @Output() submit: EventEmitter<string> = new EventEmitter<string>();
  @Output() reset: EventEmitter<ChangePasswordRequestData> = new EventEmitter<ChangePasswordRequestData>();

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    })

    if (this.action == PasswordFormActionEnum.Register || this.action == PasswordFormActionEnum.ForgotPassword) {
      this.passwordForm.addControl('confirmPassword', new FormControl('', [Validators.required, Validators.minLength(8)]))
    } else {
      this.passwordForm.addControl('oldPassword', new FormControl('', [Validators.required, Validators.minLength(8)]))
    }
  }

  getStyling(controlName: string) {
    let control = this.passwordForm.get(controlName);

    return {
      'focus:border-2 focus:border-b-white': !control?.dirty && !this.submitted && control?.invalid,
      'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (control?.dirty || this.submitted) && control?.invalid,
      'border border-b-white text-white': control?.valid
    };
  }

  checkPasswords(event: any) {
    if (this.action == PasswordFormActionEnum.Register || this.action == PasswordFormActionEnum.ForgotPassword) {
      const input = event.target.value;
      this.showPasswordsDontMatchError = input != this.passwordForm.get('password')?.value;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.passwordForm.invalid)
      return;

    let password = this.passwordForm.get('password')?.value
    if (this.action == PasswordFormActionEnum.Register || this.action == PasswordFormActionEnum.ForgotPassword) {
      let confirmPassword = this.passwordForm.get('confirmPassword')?.value

      if (password != confirmPassword) {
        this.showPasswordsDontMatchError = true;
        return;
      }
    }

    this.submitted = false;
    this.showPasswordsDontMatchError = false;

    if (this.action == PasswordFormActionEnum.Register)
      this.submit.emit(this.passwordForm.get('password')?.value);
    else if (this.action == PasswordFormActionEnum.ForgotPassword){
      this.reset.emit({
        newPassword: this.passwordForm.get('password')?.value,
        oldPassword: this.passwordForm.get('password')?.value
      } as ChangePasswordRequestData)
    }
    else if (this.action == PasswordFormActionEnum.ChangePassword)
      this.reset.emit({
        newPassword: this.passwordForm.get('password')?.value,
        oldPassword: this.passwordForm.get('oldPassword')?.value
      } as ChangePasswordRequestData)
  }

  determineAction(): number {
    return this.action.valueOf();
  }
}
