<div class="flex page-height justify-center overflow-hidden">
  <div class="mx-5  flex w-fit lg:flex-row flex-col gap-10 sm:gap-5 sm:justify-between mt-[1rem] mb-[3rem] lg:mb-[0rem] lg:mt-[5rem]">
    <div class="flex flex-col gap-10 w-full lg:max-w-[20rem] lg:w-1/4">

      <!--      BACK BUTTON-->
      <div class="flex items-center mt-[3rem] md:mt-0">
        <button class="w-10 h-10 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110 rotate-180" (click)="back()">
          <img src="assets/images/button-arrow.svg" alt="">
        </button>
        <hr class="w-20 h-0.5 bg-[#bdacc5] border-0">
        <h4 class="ml-3 text-sm sm:text-base uppercase text-white">{{'actions.back' | translate}}</h4>

      </div>

      <!-- Displaying Service -->
      <div class="flex flex-col gap-4">
        <div
          class="text-white text-sm md:text-base lg:text-lg small-font leading-0 font-semibold text-left">{{service.name}}</div>
        <app-appointment-info [serviceDuration]="service.duration!"
                              [servicePrice]="service.price" class="text-white"></app-appointment-info>
      </div>

      <!--      Display available employees-->
      <div class="flex flex-col gap-3 text-white lg:mt-20">
        <div class="flex flex-row gap-3 items-center">
          <app-icon [materialIcon]="'badge'" [color]="'brown'"></app-icon>
          <div
            class="text-xs md:text-sm font-semibold uppercase">{{  ('booking-page.employee-title' | translate)}}</div>
        </div>

        <div class="pb-2 text-white ">
          <div class="max-h-64  overflow-y-auto section">
            <div *ngFor="let employee of this.availableEmployees">
              <div
                [ngClass]="{'text-white': employeeId==employee.id, 'text-stone-400': employeeId!=employee.id}"
                class="text-xs md:text-sm py-2 pl-4 border border-transparent border-b-stone-400 hover:cursor-pointer text-left"
                (click)="changeEmployee(employee.id!)">{{employee.name + ' ' + employee.surname}}</div>
            </div>
          </div>

        </div>
      </div>


    </div>

    <!--    SECTION 2-->
    <div class="w-full lg:w-3/4 flex flex-col-reverse lg:flex-col items-end">

      <!--      NEXT BUTTON-->
      <div class="flex items-center mb-3 mt-8 lg:mt-0 lg:mb-8">
        <h4 class="mr-3 text-sm sm:text-base uppercase text-white">{{'actions.next' | translate}}</h4>
        <hr class="w-20 h-0.5 bg-[#bdacc5] border-0">
        <button class="w-10 h-10 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110"
                (click)="!edit ? navigateToClientData() : openRescheduleConfirmationPopUp()"><img
          src="assets/images/button-arrow.svg" alt="" type="submit"></button>
      </div>

      <div class="flex flex-col lg:flex-row justify-between full-width">

        <div class="hidden sm:flex flex-row lg:flex-col sm:mt-[1rem] sm:mb-[2.2rem] lg:mt-[0rem] lg:mb-[0rem] gap-[1.35rem] lg:gap-10 lg:w-2/5">

          <!--            PREVIOUSLY CHOSEN TERM CARD-->
          <div *ngIf="edit" class="w-full flex-col text-white hidden sm:flex">
            <div class="card_generic lg:mx-8 spread flex flex-col items-center h-max">

              <!--            TITLE-->
              <div class=" text-center px-3 py-5">{{ ('booking-page.term-to-edit-details' | translate) }}</div>

              <!--              APPOINTMENT INFO-->
              <div class="full-width mx-5 mb-5 w-full">
                <div class="flex flex-row gap-5 mb-2 border border-transparent border-b-white border-t-white">
                  <div class="py-3 full-width flex flex-row justify-between items-center">
                    <div class="flex flex-row items-center gap-2">
                      <div class="bg-dark_purple rounded-full h-8 w-8 flex items-center justify-center">
                        <span class="text-lg material-symbols-outlined text-brown">today</span>
                      </div>
                      <div class="flex flex-col items-left">
                        <div
                          class="text-sm lg:text-base shrink-font">{{termToEdit.fromDate | date : 'dd.MM.yyyy'}}</div>

                      </div>
                    </div>
                    <div class="flex flex-row justify-end items-center gap-2 w-1/2">
                      <app-icon [imageSource]="'assets/images/Clock_brown.png'"></app-icon>
                      <div class="text-sm lg:text-base shrink-font">{{termToEdit.fromDate | date: 'HH:mm'}} часот</div>

                    </div>
                  </div>

                </div>
                <button (click)="cancelBooking()" [disabled]="showCancelConfirmation"
                        class="w-full mt-[1rem] btn btn-sm cancel-button text-white text-xs xl:text-sm font-semibold self-end p-2 rounded-md select-none shadow-md uppercase">
                  {{'booking-confirmation-component.cancelBooking' | translate}}
                </button>

              </div>

            </div>


          </div>

          <!--      CHOSEN TERMS-->
          <div class="w-full md:flex flex-col text-white ">
            <div class="card_generic lg:mx-8 spread flex flex-col items-center"
                 [ngClass]="{'h-full lg:min-h-[8.75rem] lg:mt-[-1.2rem]': edit, 'lg:h-96': !edit}">

              <!--            TITLE-->
              <div class="text-center px-3 py-5">{{edit ? ('booking-page.chosen-appointment' | translate) : ('booking-page.chosen-appointments' | translate)}}</div>

              <!--            CHOSEN TERMS-->
              <div class="max-h-80 overflow-y-auto section full-width">
                <div *ngIf="addBookingData.termsList!.length==0" class="text-xs text-center pb-5">{{"dates not selected" | translate }}
                </div>
                <div *ngFor="let term of addBookingData.termsList; index as i">
                  <div class="w-full term-bg flex flex-row gap-5 mb-2 wrapper">
                    <div class="p-3">
                      <div class="flex flex-row items-center gap-2">
                        <div class="bg-dark_purple rounded-full h-8 w-8 flex items-center justify-center">
                          <span class="text-lg material-symbols-outlined text-brown">today</span>
                        </div>
                        <div class="flex flex-col items-left">
                          <div class="flex flex-row gap-2 items-center -mb-1">
                            <div class="text-sm lg:text-base shrink-font">{{term.fromDate | date : 'dd.MM.yyyy'}}</div>
                            <div class="text-xs lg:text-sm shrink-font">{{term.fromDate | date: 'HH:mm'}} часот</div>
                          </div>
                          <div
                            class="text-sm text-brown text-left text-wrap">{{retrieveEmployeeById(term.employeeProfileId!)}}</div>
                        </div>

                      </div>
                    </div>
                    <div *ngIf="!edit && !isTrialSession" class="flex flex-row w-2/7" id="slide"
                         style="height: 100% !important;">
                      <div
                        class="select-none w-1/2 bg-purple flex flex-col justify-center items-center px-1 lg:px-2 hover:cursor-pointer hover:scale-y-110 transition ease-in-out duration-150"
                        (click)="editTerm(i)">
                        <span class="material-symbols-outlined text-base">edit</span>
                        <div class="text-[0.5em] text">{{'actions.edit' | translate}}</div>
                      </div>
                      <div
                        class="select-none w-1/2 bg-red flex flex-col justify-center items-center px-1 lg:px-2 hover:cursor-pointer hover:scale-y-110 transition ease-in-out duration-150"
                        (click)="removeFromList(i)">
                        <span class="material-symbols-outlined text-base">delete_forever</span>
                        <div class="text-[0.5em] text">{{'actions.delete' | translate}}</div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>


          </div>
        </div>

        <!--      CALENDAR-->
        <app-calendar class="w-full lg:w-3/5"
                      (addTerms)="addTermsToList($event)"
                      (updateTerms)="updateTermsList($event)"
                      [serviceId]="serviceId"
                      [employeeProfileId]="employeeId!"
                      [currentTermsList]="this.addBookingData.termsList!"
                      [reschedule]="edit" [isTrialSession]="isTrialSession"
                      [rescheduleTermId]="rescheduleTermId"></app-calendar>
      </div>

    </div>
  </div>

  <div *ngIf="windowDimensions.width < windowDimensions.threshold_sm && termsMobileOpen">
    <app-swipeable-modal
    [modal_state_function]="toggleTermsMobileOpen"
    [backgdrop]="true"
    [handle_overflow_input]="true"
    [down_swipe_limiter]="2"
    [opacity_modifier]=".2"
    (handleCloseEmitter)="toggleTermsMobileOpen(false)"
    >
      <!--      CHOSEN TERMS MOBILE-->
      <div class="w-100 flex-col text-white">
        <div>
          <div class="text-sm text-center px-3 py-5">{{'booking-page.chosen-appointments' | translate}}</div>
          <div class="max-h-72 overflow-y-auto section">

            <div *ngFor="let term of addBookingData.termsList; index as i">
              <div class="w-full term-bg flex flex-row gap-5 mb-2 wrapper">
                <div class="p-3 w-4/7">
                  <div class="flex flex-row items-center gap-2">
                    <div class="bg-dark_purple rounded-full h-8 w-8 flex items-center justify-center">
                      <span class="text-lg material-symbols-outlined text-brown">today</span>
                    </div>
                    <div class="flex flex-col items-left">
                      <div class="flex flex-row gap-2 items-center -mb-1">
                        <div class="text-sm lg:text-base shrink-font">{{term.fromDate | date : 'dd.MM.yyyy'}}</div>
                        <div class="text-xs lg:text-sm shrink-font">{{term.fromDate | date: 'HH:mm'}} часот</div>
                      </div>
                      <div
                        class="text-sm text-brown text-left text-wrap">{{retrieveEmployeeById(term.employeeProfileId!)}}</div>
                    </div>


                  </div>
                </div>
                <div class="flex flex-row w-3/7" id="slide-mobile" style="height: 100% !important;">
                  <div
                    class="select-none w-[55px] bg-purple flex flex-col justify-center items-center px-1 lg:px-2 hover:cursor-pointer hover:scale-y-110 transition ease-in-out duration-150"
                    (click)="editTerm(i)">
                    <span class="material-symbols-outlined text-base">edit</span>
                    <div class="text-[0.5em] text">{{'actions.edit' | translate}}</div>
                  </div>
                  <div
                    class="select-none w-[55px] bg-red flex flex-col justify-center items-center px-1 lg:px-2 hover:cursor-pointer hover:scale-y-110 transition ease-in-out duration-150"
                    (click)="removeFromList(i)">
                    <span class="material-symbols-outlined text-base">delete_forever</span>
                    <div class="text-[0.5em] text">{{'actions.delete' | translate}}</div>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    </app-swipeable-modal>
  </div>
</div>

<div #termsListMobileButton
class="fixed w-max bottom-[0.75rem] sm:hidden transition-all duration-[70ms] mb-5"
[ngClass]="{'opacity-20': (addBookingData.termsList?.length! <= 0)}">
<div>

<div
class="w-16 h-16 rounded-[4rem] hover:cursor-pointer relative"
[ngClass]="{'select-none pointer-events-none cursor-default': (addBookingData.termsList?.length! <= 0)}"
(click)="toggleTermsMobileOpen(true)">
<div class="absolute w-[1.45rem] h-[1.45rem] bg-[#9dacaf] top-[-.25rem] right-[-0.75rem] -translate-x-1/2 rounded-full z-[1]
  flex flex-col justify-center align-middle items-center text-center text-white font-custom_semibold text-[1rem] visible"
  [ngClass]="{'invisible': addBookingData.termsList?.length! <= 0}">
    {{ addBookingData.termsList!.length }}
</div>

<div class="rounded-full flex items-center justify-center">
  <img src="assets/images/booked-terms-icon.svg">
</div>
</div>

</div>
</div>

<app-footer></app-footer>

<ng-container *ngIf="displayConfirmationPopUp">
  <app-generic-pop-up (closePopUp)="closePopUp(false)" [actionKey]="'actions.reschedule'"
                      (submit)="rescheduleTerm()" [iconPath]="'assets/images/question-mark-icon.svg'"
                      [message]="'actions.confirm-reschedule'"></app-generic-pop-up>
</ng-container>

<ng-container *ngIf="showCancelConfirmation">
  <app-confirm-cancel-popup (close)="closePopUp(true, true)" [termId]="termToEdit.id!"
                            [numberOfTerms]="1">
  </app-confirm-cancel-popup>
</ng-container>

<app-svg-bg-animation> </app-svg-bg-animation>

