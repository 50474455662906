<div class="popup-position top-0 left-0 flex items-center justify-center popup-anim text-white">

  <div
    class="m-4 p-10 popup-background rounded rounded-bl-[3rem] rounded-tr-[3rem] shadow-md space-y-2 w-[90vw] sm:w-[450px] h-full sm:h-[30rem]">

    <div class="absolute top-10 right-12 mr-1 cursor-pointer flex flex-col justify-center align-middle text-center bg-red-600 w-[20px] h-[20px] rounded-full hover:cursor-pointer" (click)="closePopup()"> 
     <p class="text-white self-center text-[.75rem]"> ✖ </p> 
    </div>

    <!--  TITLE-->
    <h2 class="md:text-[1.5rem] text-wrap text-center text-white font-semibold mb-4 text-[18px] mt-1">
      {{ "actions.edit-user" | translate }}
    </h2>

    <!--  SEPARATOR-->
    <div class="border-b border-solid border-white my-4 w-1/2 mx-auto mb-4" style="width: 80px"></div>

    <!--  INPUT FORM-->
    <form [formGroup]="userForm" (submit)="editUser()" class="flex flex-col gap-5">

      <input type="text" placeholder="{{'actions.yourName' | translate}}" id="name" [ngClass]="getStyling('name')"
             formControlName="name"
             class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

      <input type="text" placeholder="{{'actions.yourLastname' | translate}}" [ngClass]="getStyling('surname')"
             formControlName="surname"
             class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

      <input type="text" placeholder="{{'actions.yourEmail' | translate}}" id="email"
             [ngClass]="getStyling('email')"
             formControlName="email"
             class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

      <input type="text" id="phoneNumber" placeholder="{{'actions.yourPhone' | translate}}"
             [ngClass]="getStyling('phone')"
             formControlName="phone"
             class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"
             inputmode="numeric" pattern="[0-9+\s]*" (input)="onPhoneNumInput($event)"/>


      <div *ngIf="showError" class="mt-2">
        <app-status-indicator [message]="'register.user-email-exists' | translate"></app-status-indicator>
      </div>
      <div [ngClass]="{'mt-2': !showError, '-mt-2': showError}">
        <button type="submit"
                class="bg-[#9f7ee1] kin-primary w-full text-white px-4 py-2 rounded text-xs md:text-sm uppercase">{{ "actions.save-user-data" | translate }}</button>
      </div>

    </form>


  </div>
</div>

<div class="overlay popup-anim" (click)="closePopup()"></div>
