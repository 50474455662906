import { Component, ViewContainerRef } from '@angular/core';
import { WindowDimensions, WindowDimensionsService } from 'src/app/services/window-dimension-service.service';
import { OrbitalItemComponent } from './orbital-item/orbital-item.component';
import { ProductInfoData } from 'src/app/models/ProductInfoData';
import { OrbitalItemData } from 'src/app/models/OrbitalItemData';
import { HighlightedProduct } from '../../highlighted-products-page/highlighted-products.component';
import { HighlightedProductsService } from 'src/app/services/highlighted-products.service';

export enum OrbitalItemPositioning {
  Left,
  Right,
  Center
}

@Component({
  selector: 'app-va-endoactive',
  templateUrl: './va-endoactive.component.html',
  styleUrls: ['./va-endoactive.component.css']
})
export class VaEndoactiveComponent implements HighlightedProduct {
  product: HighlightedProduct | undefined;

  productId: string = '3'; 
  backdropPath: string = 'assets/images/vaendoactive_bg.jpg'; 

  windowDimensions: WindowDimensions = {} as WindowDimensions;
  readMore_1: boolean = false;

  items: ProductInfoData[] = [
    {
      iconPath: 'assets/images/sports-icon.svg',
      titleKey: 'va-endoactiv-page.sports-title',
      headingKey: 'va-endoactiv-page.sports-heading',
      descriptionKey: undefined
    }, 
    {
      iconPath: 'assets/images/flatten-icon.svg',
      titleKey: 'va-endoactiv-page.flatten-title',
      headingKey: 'va-endoactiv-page.flatten-heading',
      descriptionKey: undefined
    },
    {
      iconPath: 'assets/images/meditation-icon.svg',
      titleKey: 'va-endoactiv-page.meditation-title',
      headingKey: 'va-endoactiv-page.meditation-heading',
      descriptionKey: undefined
    },
    {
      iconPath: 'assets/images/scar-icon.svg',
      titleKey: 'va-endoactiv-page.scar-title',
      headingKey: 'va-endoactiv-page.scar-heading',
      descriptionKey: undefined
    }
  ]

  orbitaltems: OrbitalItemData[] = [
    {
      titleKey: 'va-endoactiv-page.orbital-item-1-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-1-description'
    },
    {
      titleKey: 'va-endoactiv-page.orbital-item-2-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-2-description'
    },
    {
      titleKey: 'va-endoactiv-page.orbital-item-3-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-3-description'
    },
    {
      titleKey: 'va-endoactiv-page.orbital-item-4-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-4-description'
    },
    {
      titleKey: 'va-endoactiv-page.orbital-item-5-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-5-description'
    },
    {
      titleKey: 'va-endoactiv-page.orbital-item-6-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-6-description'
    },
    {
      titleKey: 'va-endoactiv-page.orbital-item-7-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-7-description'
    },
    {
      titleKey: 'va-endoactiv-page.orbital-item-8-title',
      descriptionKey: 'va-endoactiv-page.orbital-item-8-description'
    },
  ];

  constructor(private viewContainerRef?: ViewContainerRef,
            private _windowDimensionService?: WindowDimensionsService,
            private highlightedProductsService?: HighlightedProductsService,) {
    this._windowDimensionService?.getWindowDimensions$().subscribe((dimensions) => {
      this.windowDimensions = dimensions;
    });
  }
  
  ngOnInit(): void {
    if(this._windowDimensionService && this.viewContainerRef && this.highlightedProductsService){
      this.product = this.highlightedProductsService?.getProductById(this.productId);
      this.highlightedProductsService?.addProduct(this);
  
      this.createOrbit();
      this.createList();
    }
  }

  createOrbit()
  {
    var orbitR = document.querySelector("#orbit_R");
    var orbitL = document.querySelector("#orbit_L");

    if(orbitR != null && orbitL != null && this.orbitaltems.length > 0)
    {
      const evenIndexes = this.orbitaltems.filter((_, index) => index % 2 === 0);
      const oddIndexes = this.orbitaltems.filter((_, index) => index % 2 !== 0);

      this.generateOrbitalItemsSemiCircle(evenIndexes, OrbitalItemPositioning.Left, orbitL);
      this.generateOrbitalItemsSemiCircle(oddIndexes.reverse(), OrbitalItemPositioning.Right, orbitR);
  
      var leftItems = document.querySelectorAll("#orbit_L > .orbital-item");
      var rightItems = document.querySelectorAll("#orbit_R > .orbital-item");
      
      if(leftItems.length > 0 && rightItems.length > 0)
      {
        this.arrangeInHalfCircle(leftItems, OrbitalItemPositioning.Left);
        this.arrangeInHalfCircle(rightItems, OrbitalItemPositioning.Right);
      }
    }
  }

  createList() {
    var list = document.querySelector("#orbital-list");

    if(list != null && this.orbitaltems.length > 0)
    {
      this.generateOrbitalItemsList(this.orbitaltems, list);
    }
  }

  toggleReadMore1() { 
    this.readMore_1 = !this.readMore_1;
  }

  generateOrbitalItemsList(items: OrbitalItemData[], element: Element) {
    items.forEach((_item, _i) => {
      if(this.viewContainerRef){
        const componentRef = this.viewContainerRef.createComponent(OrbitalItemComponent);
        componentRef.location.nativeElement.classList.add("orbital-list-item");
        componentRef.instance.positioning = OrbitalItemPositioning.Center;
        componentRef.instance.data = _item;
        componentRef.instance.index = (_i + 1).toString();
        element.appendChild(componentRef.location.nativeElement);
      }
    });
  }

  generateOrbitalItemsSemiCircle(items: OrbitalItemData[], positioning: OrbitalItemPositioning, orbit : Element) {
    items.forEach((_item, _i) => {
      if(this.viewContainerRef) {
        const componentRef = this.viewContainerRef.createComponent(OrbitalItemComponent);
        componentRef.location.nativeElement.classList.add("orbital-item");
        componentRef.location.nativeElement.style.position = "absolute";
        componentRef.location.nativeElement.style.width = "100%";
        componentRef.instance.data = _item;
        componentRef.instance.positioning = positioning;
        componentRef.instance.index = (positioning === OrbitalItemPositioning.Left ? _i + 1 : (items.length * 2) - _i).toString();

        orbit.appendChild(componentRef.location.nativeElement);
      }
    });
  }

  arrangeInHalfCircle(elements: NodeListOf<Element>, side: OrbitalItemPositioning){
    const leftRotation = -160;
    const rightRotation = 270;

    const minimumOffsetWidth = 568;
    const maximumOffsetWidth = this.windowDimensions.threshold_md * 0.75;
    
    var start_element = elements[0];
    
    if(start_element != null)
    {
      const circleRotation = side === OrbitalItemPositioning.Left ? leftRotation : (side === OrbitalItemPositioning.Right ? rightRotation : 200);

      var startAngle = (Math.PI / elements.length),
      angle = Math.PI / (circleRotation / 100),
      parentElementWidth = start_element.parentElement!.offsetWidth,
      radius = (parentElementWidth < minimumOffsetWidth ? minimumOffsetWidth : parentElementWidth > maximumOffsetWidth ? maximumOffsetWidth : parentElementWidth) / 2;

      for (var i = 0; i < elements.length; i++) {
        var container = elements[i].parentElement;
        var element = elements[i] as HTMLElement;

        if (container != null) {
          var x = container.offsetWidth / 2 + radius * Math.cos(angle) - element.offsetWidth / 2;
          var y = container.offsetHeight / 2 + radius * Math.sin(angle) - element.offsetHeight / 2;

          element.style.left = `${x}px`;
          element.style.top = `${y}px`;
          angle -= startAngle;
        }
      }
    }
  }

}
