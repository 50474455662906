import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {NavItem} from "../../models/NavItem";
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthenticationService} from "../../services/authentication.service";
import {SecurityResultStatus, UserProfileData, UsersResultStatus} from "../../clients/users-api/UsersApiClient.gen";
import {Router} from "@angular/router";
import {SessionService} from "../../services/session.service";

import {PopUpTypeEnum} from "../../enums/PopUpTypeEnum";
import {
  WindowDimensions,
  WindowDimensionsService
} from 'src/app/services/window-dimension-service.service';
import { UserProfileDto } from 'src/app/models/UserProfileDto';

const defaults = {
  delayLeave: '0ms',
  timingLeave: '0.5s',
};

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  animations: [
    trigger('menuAnimation', [
      state('open', style({
        transform: 'translate(0, 0)',
        transformOrigin: 'bottom',
        visibility: 'visible',
      })),
      state('closed', style({
        transform: 'translate(-100vw, 0)',
        transformOrigin: 'top',
        visibility: 'hidden'
      })),
      transition('open => closed', [
        animate('0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)')
      ]),
      transition('closed => open', [
        animate('0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000)')
      ])
    ])
,
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      state('*', style({
        opacity: 1
      })),
      transition('void <=> *', animate('300ms ease-in-out'))
    ]),
    trigger('flyOut', [
      state('in', style({transform: 'translate(0)'})),
      transition(
        ':leave',
        [
          style({transform: 'translate(0)'}),
          animate(
            '{{timingLeave}} {{delayLeave}} ease-in-out',
            style({transform: 'translateY(-100%)'})
          ),
        ],
        {
          params: {
            delayLeave: defaults.delayLeave,
            timingLeave: defaults.timingLeave,
          },
        }
      ),
    ]),
  ]
})

export class NavBarComponent implements OnInit {
  isOpen = false;
  isButtonVisible = false
  isLoginPopupOpen: boolean = false;
  isSignupPopupOpen: boolean = false;
  isResetPasswordPopUpOpen: boolean = false;
  loggedInUser!: UserProfileDto
  activeRoute: string = ''
  expanded: boolean = false;
  elementWidth: number = 300

  windowDimensions: WindowDimensions = {} as WindowDimensions;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private authenticationService: AuthenticationService,
              private router: Router,
              private _windowDimensionService: WindowDimensionsService,
              private sessionStorage: SessionService,) {
    this._windowDimensionService.getWindowDimensions$().subscribe((dimensions) => {
      this.windowDimensions = dimensions;
    });
  }

  navItems: NavItem[] = [
    {name: 'home', route: 'home', selector: 'home', isActive: false},
    {name: 'reservation', route: 'reservation', selector: 'reservation', isActive: false},
    {name: 'prices', route: 'prices', selector: 'prices', isActive: false},
    {name: 'our-services', route: '/our-products-landing', selector: 'our-products-landing', isActive: false},
    {name: 'contact', route: 'contact', selector: 'contact', isActive: false}
  ];

  profileNavItems: NavItem[] = [{name: 'profile', route: 'client-profile', selector: 'profile', isActive: false}];

  ngOnInit(): void {
    this.activeRoute = this.router.url;

    if (sessionStorage.getItem('profile') != undefined) {
      this.loggedInUser = JSON.parse(sessionStorage.getItem('profile')!) as UserProfileDto
      this.authenticationService.set(true);
    }

    this.checkWindowWidth(window.innerWidth);
  }

  ngOnChanges(){
    this.getSignedInUserElementWidth()
  }

  ngAfterViewInit() {
    this.getSignedInUserElementWidth()
  }

  getSignedInUserElementWidth() {
    let element = document.getElementById('user')!
    if (element)
      this.elementWidth = element.clientWidth
  }

  toggle() {
    if (!this.isOpen) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }

    this.isOpen = !this.isOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkWindowWidth((event.target as Window).innerWidth)
  }

  private checkWindowWidth(width: number) {
    this.isButtonVisible = width <= 1024
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
  }


  toggleActiveState(clickedItem: NavItem): void {

    this.navItems.forEach(item => {
      const elementId = item.selector ? `#${item.selector}` : null;
      if(elementId){
        try {
          const element = this.el.nativeElement.querySelector(elementId);
          if (element) {
            this.renderer.removeClass(element, 'border-bottom');
          }
        } catch (error) { }
      }
    });

    const clickedElementId = clickedItem.selector ? `#${clickedItem.selector}` : null;
    const clickedElement = clickedElementId ? this.el.nativeElement.querySelector(clickedElementId) : null;

    if (clickedElement) {
      this.renderer.addClass(clickedElement, 'border-bottom');
    }

    this.navItems.forEach(item => item.isActive = item === clickedItem);
    this.activeRoute = this.router.url;
    this.expanded = false;
  }

  openLoginPopup() {
    this.disableScroll();
    this.isLoginPopupOpen = true;
    this.isSignupPopupOpen = false;
  }

  openSignupPopup() {
    this.disableScroll();
    this.isSignupPopupOpen = true;
    this.isLoginPopupOpen = false;
  }

  onSubmit(formData: PopUpTypeEnum): void {
    if (formData == PopUpTypeEnum.login){ this.isLoginPopupOpen = false; this.ngOnInit(); this.enableScroll(); }
    else if (formData == PopUpTypeEnum.register){ this.isSignupPopupOpen = false; this.ngOnInit(); this.enableScroll(); }
    else if (formData == PopUpTypeEnum.resetPassword){ this.isResetPasswordPopUpOpen = false; this.ngOnInit(); this.enableScroll(); }
  }

  closePopup() {
    this.isLoginPopupOpen = false;
    this.isSignupPopupOpen = false;
    this.isResetPasswordPopUpOpen = false;
    this.enableScroll();
  }

  closeMenu() {
    this.isOpen = false;
    this.enableScroll();
  }

  signOut() {
    this.authenticationService.signOut().subscribe(result => {
      if (result == SecurityResultStatus.Success) {
        this.loggedInUser = undefined!
        this.sessionStorage.removeProfile();

        this.authenticationService.set(false);
        this.router.navigate(['/home']);
      }
    })
  }

  openResetPasswordPopUp() {
    this.disableScroll();
    this.isResetPasswordPopUpOpen = true;
    this.expanded = false;
  }

  expandProfileMenu() {
    this.expanded = !this.expanded
  }

  closeUserMenu() {
    if (this.expanded)
      this.expanded = false;
  }

  disableScroll() {
    const element = document.querySelector('[body]')!
    element.setAttribute('style', 'overflow:hidden;')
  }

  enableScroll() {
    const element = document.querySelector('[body]')!
    element.removeAttribute('style')
  }


}
