<div class="popup-position top-0 left-0 flex items-center justify-center popup-anim">

  <div class="m-4 p-10 popup-background rounded rounded-bl-[3rem] rounded-tr-[3rem] shadow-md space-y-
  flex flex-col justify-center align-middle items-center w-[90vw] sm:w-[450px] h-[19rem]">

    <!--      RESET PASSWORD FORM-->
    <div class="flex flex-col gap-5 mt-4 w-full">
      <app-password-form (reset)="resetPassword($event)" [action]="action"></app-password-form>
    </div>
  </div>
</div>

<div class="overlay popup-anim" (click)="closePopup()"></div>
