import { TranslateService } from '@ngx-translate/core';
import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from "../../../services/authentication.service";
import { LoginRequestData, SecurityResultStatus, UserProfileData, UsersResultStatus } from "../../../clients/users-api/UsersApiClient.gen";
import {SessionService} from "../../../services/session.service";
import {ToastrService} from "ngx-toastr";
import {PasswordFormActionEnum} from "../../../enums/PasswordFormActionEnum";
import { UserProfileDto } from 'src/app/models/UserProfileDto';
import {PopUpTypeEnum} from "../../../enums/PopUpTypeEnum";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent {
  loginForm!: FormGroup
  verificationCodeForm!: FormGroup
  submitted: boolean = false;

  showWrongCredentialsError: boolean = false;
  showInvalidUserError: boolean = false
  resultStatus!: SecurityResultStatus
  submittedUsername: string = ''
  userPassword: string = ''
  emailControl!: FormControl
  emailSubmitted: boolean = false;
  emailError: boolean = false

  showSecondScreen: boolean = false;
  showVerificationCode: boolean = false;
  showPasswordResetForm: boolean = false;
  showEmailFieldForm: boolean = false;
  generatedVerificationCode!: number
  codeError: boolean = false;
  codeExpired: boolean = false;

  codeSubmitted: boolean = false
  action = PasswordFormActionEnum.ForgotPassword
  loggedInUser!: UserProfileData

  @Output() submit = new EventEmitter<PopUpTypeEnum>();
  @Output() close = new EventEmitter<void>();
  @Output() openRegister = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private sessionService: SessionService,
              private toasterService: ToastrService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    //Initialize log in form
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });

    //Initialize verification code form
    this.verificationCodeForm = this.formBuilder.group({
      verificationCode: new FormControl('', [Validators.required])
    })

    //Initialize email controll
    this.emailControl = new FormControl<any>('', [Validators.required, Validators.email])
  }

  /**
   * @description Returns input styling based on control name and specified form
   * @param controlName - describes which control should be accessed from the form
   * @param fromVerificationForm - describes whether the control name should be taken from the verification code form
   * */
  getStyling(controlName: string, fromVerificationForm?: boolean) {
    let control = this.loginForm.get(controlName!);
    if (fromVerificationForm)
      control = this.verificationCodeForm.get(controlName);
    return {
      'focus:border-2 focus:border-b-white': !control?.dirty && !this.submitted && control?.invalid,
      'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (control?.dirty || this.submitted) && control?.invalid,
      'border border-b-white text-white': control?.valid
    };

  }

  /**
   * @description Returns styling for the email control presented upon requesting a new password
   * */
  getEmailControlStyling() {
    return {
      'focus:border-2 focus:border-b-white': !this.emailControl?.dirty && !this.emailSubmitted && this.emailControl?.invalid,
      'border border-b-red-500 focus:border focus:border-b-red-500/100 focus:text-red-500/100 text-red-500': (this.emailControl?.dirty || this.emailSubmitted) && this.emailControl?.invalid,
      'border border-b-white text-white': this.emailControl?.valid
    };
  }

  /**
   * @description Performs user login
   * */
  login() {
    this.submitted = true;
    if (this.loginForm.invalid){ return; }

    let loginRequestData = {
      username: this.loginForm.get('email')?.value,
      password: this.loginForm.get('password')?.value
    } as LoginRequestData

    this.submittedUsername = this.loginForm.get('email')?.value;
    this.authenticationService.login(loginRequestData).subscribe(result => {

      this.resultStatus = result.status!

      if (result!.status == SecurityResultStatus.WrongCreditials) {
        this.showWrongCredentialsError = true;
        this.showInvalidUserError = false;
        this.showSecondScreen = false;
      } else if (result!.status == SecurityResultStatus.InvalidUser) {
        this.showInvalidUserError = true;
        this.showWrongCredentialsError = false;
        this.showSecondScreen = false;
      } else if (result.status == SecurityResultStatus.EmailNotVerified) {
        this.showSecondScreen = true;
        this.showPasswordResetForm = false;
        this.showVerificationCode = true;
        this.userPassword = this.loginForm.get('password')?.value;
      } else if (result!.status == SecurityResultStatus.Success) {

        this.submitted = false

        this.userService.retrieveUserDetailByEmail(loginRequestData.username!).subscribe(result => {
          if (result.status == UsersResultStatus.Success) {
            this.loggedInUser = result.content!;
            let userProfile = {
              username: result.content?.username,
              name: result.content?.name,
              surname: result.content?.surname,
              phone: result.content?.phone
            } as UserProfileDto
            this.sessionService.setProfile(userProfile);
            this.authenticationService.set(true);

            this.showInvalidUserError = false;
            this.showWrongCredentialsError = false;
            this.showSecondScreen = false;

            this.toasterService.success(this.translateService.instant("Login successful"), '', {positionClass: 'toast-top-center', timeOut: 5000})
            this.submit.emit(PopUpTypeEnum.login);
            this.authenticationService.set(true);
          }
        })

      } else {
        this.toasterService.error(this.translateService.instant("An error occurred later"), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
        this.closePopup();
      }
    })

  }


  /**
   * @description Emits event to close pop up
   * */
  closePopup() {
    this.close.emit();
  }

  /**
   * @description Opens log in component
   * */
  openRegisterPopUp() {
    this.openRegister.emit();
  }


  /**
   * @description Resends an email containing a verification code
   * */
  sendNewLink() {
    this.emailSubmitted = true;
    if (this.emailControl.invalid)
      return

    this.emailSubmitted = false;
    this.authenticationService.generateResetPasswordCode(this.emailControl?.value!).subscribe(result => {
      if (result.status == SecurityResultStatus.Success) {
        this.generatedVerificationCode = result.content!;
        this.toasterService.success(this.translateService.instant('Verification email successfully sent'), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
        this.emailError = false;
        this.showPasswordResetForm = true;
        this.showEmailFieldForm = false;
        this.showVerificationCode = false;

      } else if (result.status == SecurityResultStatus.NotFound) {
        this.emailError = true;
      }
    })
  }

  /**
   * @description Navigates through steps based on current step
   * */
  back() {
    if (this.showEmailFieldForm || this.showVerificationCode) {
      this.showSecondScreen = false;
      this.showEmailFieldForm = false;
      this.emailControl.reset();
      this.verificationCodeForm.reset();
    } else if (this.showPasswordResetForm) {
      this.showPasswordResetForm = false;
      this.showEmailFieldForm = true;
    }
  }

  /**
   * @description Shows forgot password screen
   * */
  forgotPassword() {
    this.showSecondScreen = true
    this.showEmailFieldForm = true
    this.showPasswordResetForm = false;
    this.showVerificationCode = false
  }


  /**
   * @description Resets user password
   * */
  resetPassword(event: any) {
    this.codeSubmitted = true;
    if (this.verificationCodeForm.invalid)
      return
    
    if (event != null && event != '' && !(event instanceof SubmitEvent))
      this.authenticationService.resetPasswordWithCode(this.generatedVerificationCode, this.verificationCodeForm.get('verificationCode')?.value, event['newPassword']).subscribe(result => {
        if (result == 'Success') {
          this.toasterService.success('Успешна измена на лозинка', '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          })
          this.showSecondScreen = false;
          this.loginForm.reset();
        } else if (result == 'VerificationCodeExpired') {
          this.codeExpired = true;
          this.codeError = false;
        } else if (result == 'InvalidVerificationCode') {
          this.codeExpired = false;
          this.codeError = true;
        }
      })
  }

}
