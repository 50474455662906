import { Component } from '@angular/core';

@Component({
  selector: 'app-our-services-page',
  templateUrl: './our-services-page.component.html',
  styleUrls: ['./our-services-page.component.css']
})
export class OurServicesPageComponent {

}
