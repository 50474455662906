import { AfterViewInit, Component, Input } from '@angular/core';
import anime from 'animejs/lib/anime.es';

@Component({
  selector: 'app-svg-bg-animation',
  templateUrl: './svg-bg-animation.component.html',
  styleUrls: ['./svg-bg-animation.component.css']
})
export class SvgBgAnimationComponent implements AfterViewInit {
  @Input() animDuration?: number = 40000;

  ngAfterViewInit(): void {
    setTimeout(() => {
      const morphing = anime({
        targets: '.bg-morph',
        d: [
          { value: 'M0 1335V244.315C266.164 221.95 532.328 199.585 747.567 176.508C962.807 153.431 1127.12 129.641 1342.43 86.7633C1557.73 43.8852 1824.02 -18.0814 2034.95 4.99583C2245.87 28.0731 2401.44 136.194 2557 244.315V1335H0Z' },
          { value: 'M0 1335V3.01153C193.487 108.221 386.975 213.431 594.858 254.829C802.741 296.228 1025.02 273.816 1271.4 218.855C1517.77 163.895 1788.25 76.387 2006.53 34.9884C2224.82 -6.41022 2390.91 -1.69935 2557 3.01153V1335H0Z' },
          { value: 'M0 1335V39.2559C239.211 5.76351 478.423 -27.7289 703.175 36.2976C927.927 100.324 1138.22 261.869 1361.96 338.046C1585.7 414.223 1822.88 405.031 2024.29 341.005C2225.71 276.978 2391.35 158.117 2557 39.2559V1335H0Z' },
          { value: 'M0 1335V234.347C273.584 122.164 547.168 9.98083 744.016 0.647183C940.864 -8.68647 1060.98 84.8295 1262.52 158.96C1464.06 233.091 1747.03 287.836 1976.35 297.17C2205.67 306.504 2381.33 270.426 2557 234.347V1335H0Z' },

        ],
        easing: 'linear',
        duration: this.animDuration,
        loop: true
      });
    }, 1);
  }

}
