<svg class="lg:h-auto h-[105vh] flex fixed left-[-1px] right-0 bottom-0 z-[-2] object-cover" viewBox="0 0 2558 945" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax slice">
    <path class="bg-morph" d="M0 1335V234.347C273.584 122.164 547.168 9.98083 744.016 0.647183C940.864 -8.68647 1060.98 84.8295 1262.52 158.96C1464.06 233.091 1747.03 287.836 1976.35 297.17C2205.67 306.504 2381.33 270.426 2557 234.347V1335H0Z" fill="url(#paint0_linear_223_137)" stroke="#62527E"/>
    <defs>
    <linearGradient id="paint0_linear_223_137" x1="1279" y1="74.8577" x2="1348.85" y2="1103.68" gradientUnits="userSpaceOnUse">
    <stop stop-color="#191324"/>
    <stop offset="0.883371" stop-color="#0E0A15"/>
    </linearGradient>
    </defs>
</svg>

<div class="fixed top-0 left-0 animated-gradient-bg h-[105vh] w-screen z-[-3]"> </div>