
const ApiUrl = "https://users-api.vacuactivskopje.mk";
const ServerDomain = "https://users-api.vacuactivskopje.mk/";
const CMSBaseUrl = "https://cms.vacuactivskopje.mk";

export const environment = {
  production: true,
  apiUrl: ApiUrl,
  serverDomain: ServerDomain,
  cmsBaseUrl: CMSBaseUrl
};