<div class="popup-position top-0 left-0 flex items-center justify-center popup-anim">
  <div class="m-0 md:m-4 p-10 popup-background flex flex-col justify-center align-middle items-center 
  rounded rounded-bl-[3rem] rounded-tr-[3rem] shadow-md space-y-2 w-[90vw] sm:w-[450px] h-[20rem] sm:h-[23rem]" 
  [ngClass]="{'h-[28rem] sm:h-[28rem]':showPasswordResetForm}">

    <div *ngIf="!showSecondScreen" class="w-full">
      <!--    TITLE-->
      <h2 class="md:text-[1.5rem] text-center text-white font-semibold text-[18px] w-full">{{ "actions.announce" | translate }}</h2>

      <!--    DESCRIPTION-->
      <div class="space-y-2 space-x-1 w-full">
        <div class="flex flex-col md:flex-row justify-center items-center text-center md:gap-2">
          <p class="w-100 text-xs sm:text-sm text-white">{{ "actions.dontHaveAccount" | translate }}</p>
          <p class="w-100 text-xs sm:text-sm text-[#9f7ee1] hover:cursor-pointer" (click)="openRegisterPopUp()">
            {{ "actions.signUp" | translate }}</p>
        </div>
      </div>

      <!--    DIVIDER-->
      <div class="w-full flex justify-center">
        <div class="border-b border-solid border-white w-1/5 md:w-1/5 my-2 md:my-4"></div>
      </div>

      <!--    LOGIN FORM-->
      <form [formGroup]="loginForm" (submit)="login()" class="flex flex-col gap-5 w-full">

        <!--    EMAIL INPUT-->
        <input type="text" placeholder="{{'actions.username' | translate}}"
               formControlName="email" [ngClass]="getStyling('email')"
               class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

        <!--    PASSWORD INPUT-->
        <input type="password" autocomplete="on" name="password" placeholder="{{'actions.password' | translate}}"
               formControlName="password" [ngClass]="getStyling('password')"
               class="text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none"/>

        <!--    WRONG CREDENTIALS ERROR-->
        <div *ngIf="showWrongCredentialsError" class="mt-4">
          <app-status-indicator [message]="'login.wrong-credentials'|translate"></app-status-indicator>
        </div>

        <!--    INVALID USER ERROR-->
        <div *ngIf="showInvalidUserError" class="mt-4">
          <app-status-indicator [message]="'login.invalid-user'|translate"></app-status-indicator>
        </div>

        <!--    SUBMIT BUTTON-->
        <button
          class="bg-[#9f7ee1] border-[#670e7f] kin-primary w-full text-white px-4 py-2 rounded text-xs md:text-sm">
          {{"actions.submit" | translate}}
        </button>

      </form>

      <!--    FORGOT PASSWORD LINK-->
      <div class="w-100 text-xs text-white mt-2 hover:cursor-pointer text-center" 
           (click)="forgotPassword()">{{'login.forgot-password' | translate}}</div>
    </div>

    <div *ngIf="showSecondScreen" class="w-full">

      <!--    BACK BUTTON-->
      <div class="mb-6">
        <div class="flex items-center">
          <button class="w-8 h-8 hover:bg-indigo-200/[.06] rounded-2xl hover:scale-110"><img
            src="../../../../assets/images/button-arrow.svg" alt="" class="rotate-180" (click)="back()"></button>
          <h4 class="mr-3 text-xs md:text-sm ml-5 uppercase text-white">{{'actions.back' | translate}}</h4>
        </div>
      </div>

      <!--    FORM-->
      <div class="flex flex-col gap-5 mt-6 items-center">

        <!--    MAIL FORM-->
        <div *ngIf="showEmailFieldForm" class="flex flex-col gap-5">

          <!--    TITLE AND DESCRIPTION-->
          <div class="flex flex-col gap-3">
            <div class="text-white text-sm md:text-lg font-semibold text-center leading-0">
              {{'login.password-reset' | translate}}</div>
            <div class="text-white text-xs md:text-sm text-center">
              {{'login.enter-mail-description' | translate}}</div>
          </div>


          <!--    DIVIDER-->
          <div class="w-full flex justify-center">
            <div class="border-b border-solid border-white w-1/5 md:w-1/5 my-2"></div>
          </div>


          <!--    FORM-->
          <div class="full-width">
            <input type="text" [formControl]="emailControl"
                   [ngClass]="getEmailControlStyling()"
                   placeholder="{{'actions.yourEmail'|translate}}"
                   class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">

            <div *ngIf="emailError" class="mt-6">
              <app-status-indicator [message]="'login.mail-not-found' | translate"></app-status-indicator>
            </div>

            <button (click)="sendNewLink()"
                    class="bg-[#9f7ee1] kin-primary w-full text-white mt-[1rem] px-4 py-2 rounded text-xs md:text-sm">{{ ("login.send" | translate)  }}</button>

          </div>


        </div>

        <!--    CHANGE PASSWORD FORM-->
        <div *ngIf="showPasswordResetForm">

          <!--    TITLE AND DESCRIPTION-->
          <div class="text-white text-sm md:text-lg font-semibold text-center leading-0">
            {{'login.password-reset' | translate}}</div>
          <div class="text-white text-xs md:text-sm text-center">
            {{'login.password-reset-description' | translate}}</div>


          <!--    DIVIDER-->
          <div class="w-full flex justify-center">
            <div class="border-b border-solid border-white w-1/5 md:w-1/5 my-2"></div>
          </div>

          <!--    FORM-->
          <form *ngIf="showPasswordResetForm" [formGroup]="verificationCodeForm" class="full-width mb-5">
            <input type="text" formControlName="verificationCode"
                   [ngClass]="getStyling('verificationCode',true)"
                   placeholder="{{'actions.verification-code'|translate}}"
                   class="w-full text text-xs md:text-sm py-3 bg-transparent border-2 border-transparent border-b-gray-500 focus:px-2 focus:outline-none">
          </form>

          <app-password-form *ngIf="showPasswordResetForm" (reset)="resetPassword($event)" [hideTitle]="true"
                             [action]="action" class="full-width"></app-password-form>

          <div *ngIf="codeError" class="mt-2">
            <app-status-indicator [message]="'register.code-invalid' | translate"></app-status-indicator>
          </div>
          <div *ngIf="codeExpired" class="mt-2">
            <app-status-indicator [message]="'register.code-expired' | translate"></app-status-indicator>
          </div>

        </div>

        <!--    VERIFICATION CODE FORM-->
        <div *ngIf="showVerificationCode">
          <app-verification-code (submit)="closePopup()" (closePopUp)="closePopup()"
                                 [userPassword]="userPassword"
                                 [userEmail]="submittedUsername"></app-verification-code>
        </div>

      </div>
    </div>

  </div>
</div>

<div class="overlay popup-anim" (click)="closePopup()"></div>
