<div
  class="flex flex-wrap w-full items-start gap-2 justify-between lg:items-center py-3 border border-transparent border-b-white border-t-white">
  <div class="flex flex-row gap-2 items-center">
    <app-icon [imageSource]="'assets/images/Clock_brown.png'"></app-icon>
    <div class="text-2xs sm:text-sm">{{serviceDuration}} {{"minutes" | translate }} </div>
  </div>

  <div class="flex flex-row gap-2 items-center" *ngIf="employeeName!=''">
    <app-icon [materialIcon]="'badge'" [color]="'brown'"></app-icon>
    <div
      class="text-2xs sm:text-sm">{{employeeName}}</div>
  </div>

  <div class="flex flex-row gap-2 items-center" *ngIf="servicePrice!>0">
    <app-icon [materialIcon]="'attach_money'" [color]="'brown'"></app-icon>
    <div
      class="text-2xs sm:text-sm">{{servicePrice}} {{ servicePrice! % 10 == 1 ? getTranslation('appointment-info-component.price-singular') : getTranslation('appointment-info-component.price-plural')}}</div>
  </div>


</div>
