import {Component, Input} from '@angular/core';
import {ServiceData, TermData} from "../../../clients/users-api/UsersApiClient.gen";
import {TranslateService} from "@ngx-translate/core";
import {Navigation, NavigationExtras, Router} from "@angular/router";
import {EmployeeService} from "../../../services/employee.service";
import {result} from "lodash";

@Component({
  selector: 'app-booked-term',
  templateUrl: './booked-term.component.html',
  styleUrls: ['./booked-term.component.css']
})
export class BookedTermComponent {

  @Input() terms!: TermData[]
  @Input() bookingId!: number
  @Input() servicePrice!: number
  @Input() serviceDuration!: number
  @Input() employeeName!: string
  isConfirmationPopUpOpen: boolean = false
  selectedTerm: number = 0
  rerouting: boolean = false;
  currentTime: Date = new Date()
  employeeId!: number

  constructor(private translateService: TranslateService,
              private router: Router,
              private employeeService: EmployeeService) {

    let nav: Navigation = this.router.getCurrentNavigation()!;
    if (nav.extras && nav.extras.state && nav.extras.state['employeeId'])
      this.employeeId = nav.extras.state['employeeId'] as number
    else if(this.terms && this.terms?.length > 0) {
      this.employeeService.retrieveEmployeeById(this.terms[0].employeeProfileId!).subscribe(result=>{
        this.employeeId = result.content?.id!
      })
    }
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }

  editTerm(termId?: number) {
    let nav: NavigationExtras = {
      state: {
        term: this.terms.filter(x => x.id == termId)![0],
        employeeId: this.employeeId,
        serviceId: this.terms[0].serviceId,
        service: {
          id: this.terms[0].serviceId,
          price: this.servicePrice,
          duration: this.serviceDuration,
          name: this.terms[0].serviceName
        } as ServiceData,
        edit: true
      }
    }
    this.router.navigateByUrl('/reservation/book', nav)
  }


  onCancel(termId?: number) {
    this.selectedTerm = termId!;
    this.isConfirmationPopUpOpen = true;
  }

  cancelBooking(isSingleBooking: boolean) {
    this.rerouting = true
    if (isSingleBooking)
      this.selectedTerm = this.terms!.filter(x => !x.cancelled)![0].id!
    else
      this.selectedTerm = 0
    this.isConfirmationPopUpOpen = true;
    setTimeout(() => {
      window.location.reload()
      this.rerouting = false
    }, 4000)
  }


  closePopUp(event: any) {
    this.rerouting = true;
    this.isConfirmationPopUpOpen = false;

    if (event)
      setTimeout(() => {
        window.location.reload()
        this.rerouting = false;
      }, 1000)
    else
      this.rerouting = false

  }

  countAvailableTerms() {
    return this.terms!.filter(x => !x.cancelled && x.fromDate! > this.currentTime)!.length;
  }

}
