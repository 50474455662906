<section class="page-height w-screen overflow-x-hidden z-[9] flex flex-col justify-between pt-[2rem] lg:pt-[2rem]
4xl:px-[25vw] 3xl:px-[11vw] 2xl:px-[9vw] xl:px-20 md:px-[8.625rem] ml:px-[7rem] px-5 sm:px-5 md:pb-40 xl:pt-20 align-middle relative">
    <!-- <app-service-category></app-service-category> -->
    <div class="text-white flex flex-col flex-grow justify-center w-full items-center ">

        <div class="h-75 max-w-[40rem] card_generic p-3 sm:p-5 lg:p-10 2xl:p-18 border-radius-20 m-auto flex flex-col gap-5 text-center justify-center items-center">

            <h1 class="mx-auto text-[1.75rem]">{{"register.verify-profile" | translate}}</h1>
            <img
                src="assets/images/logo.svg"
                class="w-[10rem] mx-auto"
                alt="VacuActiv Logo"
              />
             <div class="rounded-xl border-solid border p-4 mt-[1rem]">
                <div *ngIf="verified==1">
                    {{'register.profile-successfuly-verified' | translate}}
                </div>
                <div *ngIf="verified==0">
                    {{'register.verification-failed' | translate}}
                </div>
                <div *ngIf="verified==2">
                    {{'register.verification-link-expired' | translate}}
                </div>
                <div *ngIf="verified==3">
                    {{'register.verification-link-used' | translate}}
                </div>
            </div>

            <button *ngIf="verified==1"
                class="btn btn-sm bg-primary_gradient mt-6 text-white text-xs lg:text-sm sm:font-semibold  p-2 rounded-md w-1/2  select-none shadow-md"
                (click)="setNewPassword()">
                {{"Set a new password" | translate}}              
            </button>
        </div>
    </div>
</section>



<app-svg-bg-animation> </app-svg-bg-animation>
