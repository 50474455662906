import { Component, ComponentRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScheduleService } from '../../services/schedule.service';
import {
  AvailabilityData,
  AvailabilityStatusEnum,
  BookedTermData,
  DailyAvailabilityArgs,
  PreBookedTermData,
  TermData,
  UsersResultStatus,
} from 'src/app/clients/users-api/UsersApiClient.gen';
import { WindowDimensions, WindowDimensionsService } from 'src/app/services/window-dimension-service.service';
import { ChooseHourPopupComponent } from '../pop-ups/choose-hour-popup/choose-hour-popup.component';
import * as moment from 'moment';
import { range } from 'lodash';
import { SwipeableModalComponent } from '../swipeable-modal/swipeable-modal.component';
import { ScrollLockConfig, ScrollLockService } from 'src/app/services/scroll-lock.service';

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

enum PreloadDirectionEnum {
  Forward = 0,
  Backward = 1,
}

const maximumDateRangePreloadAmount:number = 24;
const dateRangePreloadAmount:number = 4;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent {
  @Input() serviceId?: number;
  @Input() employeeProfileId?: number;
  @Input() currentTermsList!: BookedTermData[];
  @Input() reschedule!: boolean
  @Input() isTrialSession!: boolean
  @Input() rescheduleTermId!: number

  @Output() addTerms = new EventEmitter<BookedTermData[]>();
  @Output() updateTerms = new EventEmitter<BookedTermData[]>();
  @Output() closeChooseHourPopup = new EventEmitter<boolean>();

  // Schedule
  args: DailyAvailabilityArgs = new DailyAvailabilityArgs();
  hourlyAvailabilityList: Date[] = [];
  dateRangeAvailabilityPreloadMap: Record<string, { [key: string]: AvailabilityData; } | Map<string, AvailabilityData>> = {};
  loadedRangeAvailabilityMaps: moment.Moment[] = [];
  loadingRangeAvailabilityMap?: boolean = false;
  addedRangeAvailabilityKeys: string[] = [];
  currentDateKey: string = '';

  editTerm?: Date;
  currentDate: moment.Moment;
  isFirstTime: boolean = true;
  initialLoad: boolean = true;

  // Calendar
  namesOfDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  monthsList?: moment.Moment[]; weeks: Array<CalendarDate[]> = [];
  firstDayOfMonth?: moment.Moment; lastDayOfMonth?: moment.Moment;
  selectedMonth: moment.Moment; selectedDate: any;

  // Choose hour popup
  chooseHourPopupOpened:boolean = false;
  smallChooseHourPopup?:ComponentRef<ChooseHourPopupComponent> = undefined;
  largeChooseHourPopup?:ComponentRef<ChooseHourPopupComponent> = undefined;
  mobileChooseHourPopupOpened:boolean = false;
  desktopChooseHourPopupOpened:boolean = false;

  // UI
  defaultLanguage = this.translate.getDefaultLang();
  windowDimensions: WindowDimensions = {} as WindowDimensions;

  @ViewChild('smallChooseHourPopup') SmallChooseHourPopup!: SwipeableModalComponent;

  constructor(private translate: TranslateService,
              private _windowDimensionService: WindowDimensionsService,
              private scheduleService: ScheduleService,
              private viewContainerRef: ViewContainerRef,
              private scrollLockService: ScrollLockService) {
    this.currentDate = moment();
    this.selectedMonth = moment();

    this._windowDimensionService.getWindowDimensions$().subscribe((dimensions) => {
      this.windowDimensions = dimensions;
        if((this.windowDimensions.width >= this.windowDimensions.threshold_sm && this.smallChooseHourPopup !== undefined)
        || (this.windowDimensions.width < this.windowDimensions.threshold_sm && this.largeChooseHourPopup !== undefined)){
          this.toggleChooseHourPopUp(false);
        }
    });

  }

  ngOnInit() {
    this.currentDate = moment();
    this.currentDate.locale(this.defaultLanguage);
    this.selectedDate = moment(this.currentDate).format('DD/MM/YYYY');

    this.dataInit(this.employeeProfileId!);
    this.generateMonths(this.currentDate);
    this.selectMonth(this.currentDate);

    window.scroll({ top: 0, left: 0, behavior: 'auto' });
  }

  /**
   * @description Retrieve employee availability if there is a change in the selected employee
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['employeeProfileId']) {
      let employeeId = changes['employeeProfileId'].currentValue;
      if (employeeId != undefined) {
        this.dataInit(employeeId);
      }
    }

    if (changes['rescheduleTermId']) {
      let value = changes['rescheduleTermId'].currentValue!;
      this.rescheduleTermId = value;
    }
  }

  private async dataInit(employeeId: number): Promise<void> {
    if(employeeId !== undefined) {
      this.clearMap(this.dateRangeAvailabilityPreloadMap);
      this.hourlyAvailabilityList = []; this.loadedRangeAvailabilityMaps = []; this.addedRangeAvailabilityKeys = [];
      this.loadingRangeAvailabilityMap = true; this.currentDateKey = '';

      this.generateCalendar();
      this.args = new DailyAvailabilityArgs()
      this.args.serviceId = this.serviceId;
      this.args.employeeProfileId = employeeId;
      this.args.hourlyAvailabilityCheck = true;
      let currentActualDate = moment(new Date()).utc(true).toDate();
      let currentVirtualDate = this.selectedMonth.clone().toDate();
      this.args.startDate = currentActualDate > currentVirtualDate ? currentActualDate : currentVirtualDate;
      let endDate = moment(this.lastDayOfMonth).subtract(1, 'days');
      this.args.endDate = endDate ? endDate.toDate() : undefined;

      this.initialLoad = false;
      
      await this.updateSchedule(this.args).finally(async () => {
        await this.preloadSchedule(this.args, dateRangePreloadAmount - 1, PreloadDirectionEnum.Forward, undefined, true);
      });
    }
  }

  //#region Calendar
  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  private fillDates(currentMoment: moment.Moment) {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const lastOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
    const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'days').add(7, 'days');
    const startCalendar = firstDayOfGrid.date();

    this.firstDayOfMonth = currentMoment.clone().startOf('month');
    this.lastDayOfMonth = currentMoment.clone().endOf('month').add(1, 'day');

    return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        mDate: newDate,
      };
    });
  }

  private isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  private isSelected(date: moment.Moment): boolean {
    return this.selectedDate === moment(date).format('DD/MM/YYYY');
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    if (this.monthsList) {
      let firstItem = this.monthsList[0];
      let newMonth = moment(firstItem).subtract(1, 'months');
      //Remove the last item from the list
      this.monthsList.pop();
      // Add a new item to the beginning of the array
      this.monthsList.unshift(newMonth);
    }

    this.selectedMonth = this.currentDate;
    this.currentDate = this.currentDate.locale(this.defaultLanguage);

    this.refreshCalendar();
  }

  public nextMonth(): void {
    let currentMonth = this.currentDate.month();
    let calculatedNextMonth = (currentMonth == 11 ? 1 : currentMonth + 1) + 1;

    let year = currentMonth == 11 ? this.currentDate.year() + 1 : this.currentDate.year()
    let nextMonthString = year + '-' + calculatedNextMonth.toString().padStart(2, '0') + '-01T00:00:00'

    this.currentDate = moment(new Date(nextMonthString))
    this.currentDate = this.currentDate.locale(this.defaultLanguage);

    if (this.monthsList) {
      let lastItem = this.monthsList[this.monthsList.length - 1];
      let newMonth = moment(lastItem).add(1, 'months');

      this.monthsList.shift();
      this.monthsList.push(newMonth);
    }

    this.selectedMonth = this.currentDate;

    this.refreshCalendar();
  }

  /**
   * @description Takes in the selected month and shows according dates
   * @param month
   */
  public selectMonth(month: moment.Moment): void {
    if (!this.monthDisabled(month)) {
      let monthNumeric = month.month() + 1;
      let monthString = monthNumeric < 10 ? '0' + monthNumeric : monthNumeric;

      let newMonthString = month.year() + '-' + monthString + '-01T00:00:00'
      this.selectedMonth = moment(new Date(newMonthString));
      this.currentDate = moment(this.selectedMonth);
      this.currentDate = this.currentDate.locale(this.defaultLanguage);

      this.refreshCalendar();
    }
  }

  /**
   * @description Regenerates the calendar with the currently selected month
   * @param month
   */
  private async refreshCalendar(): Promise<void> {
    this.generateCalendar();
    this.generateMonths(this.currentDate);

    this.args.startDate = moment(this.firstDayOfMonth).toDate();

    let endDate = moment(this.lastDayOfMonth).subtract(1, 'days');
    this.args.endDate = endDate.toDate();

    this.args.hourlyAvailabilityCheck = true;

    if(this.args.employeeProfileId !== undefined) {

      await this.updateSchedule(this.args).finally(async () => {
        if(this.loadingRangeAvailabilityMap === false) {
          const upcomingDate = moment(this.args.startDate).add(Math.floor(dateRangePreloadAmount / 2), 'months');
          const upcomingDateKey = upcomingDate.month().toString() + upcomingDate.year().toString();

          const oldDate = moment(this.args.startDate).subtract(Math.floor(dateRangePreloadAmount / 2), 'months');
          const oldDateKey = oldDate.month().toString() + oldDate.year().toString();

          if (!(upcomingDateKey in this.dateRangeAvailabilityPreloadMap)) {
            await this.preloadSchedule(this.args, dateRangePreloadAmount, PreloadDirectionEnum.Forward, upcomingDate.clone().subtract('1', 'months').toDate());
          } else if (!(oldDateKey in this.dateRangeAvailabilityPreloadMap)) {
            await this.preloadSchedule(this.args, dateRangePreloadAmount, PreloadDirectionEnum.Backward, oldDate.clone().add('1', 'months').toDate());
          }
        }
      });
    }
  }

   /**
   * @description Takes in the currently selected month and shows previous two months, current month and next two months
   * @param month
   */
  public generateMonths(currentDate: moment.Moment): void {
    const result: moment.Moment[] = [];

    // Add previous two months
    for (let i = 2; i >= 1; i--) {
      result.push(currentDate.clone().subtract(i, 'months'));
    }

    // Add current month
    result.push(currentDate.clone());

    // Add next two months
    for (let i = 1; i <= 2; i++) {
      result.push(currentDate.clone().add(i, 'months'));
    }

    this.monthsList = result;
  }

  /**
   * @description Checks if the provided month is the currently selected one
   * @param month
   */
  public isMonthSelected(index: number): boolean {
    return index + 1 === Math.ceil((this.monthsList?.length || 0) / 2);
  }

  public monthDisabled(month: moment.Moment) {
    let currentStart = moment(new Date()).utc(true).startOf('month');
    let compareMonthStart = month.utc(true).startOf('month');
    return compareMonthStart < currentStart;
  }

  public showPreviousMonthButton() {
    let firstMonth = this.monthsList![0];
    let secondMonth = this.monthsList![1];
    return !this.monthDisabled(firstMonth) || !this.monthDisabled(secondMonth);
  }
  //#endregion

  private checkScheduleForKey(targetDate: string, schedule: { [key: string]: AvailabilityData } | Map<string, AvailabilityData>): boolean {
    this.args.hourlyAvailabilityCheck = true;

    if (schedule && targetDate in schedule) {
      const value = (schedule as { [key: string]: AvailabilityData })[targetDate];
      return value.status === AvailabilityStatusEnum.Available;
    }

    return false;
  }

  private async checkHoursForDate(date: moment.Moment, currentList: BookedTermData[], editingMode?: boolean): Promise<void> {
    let freeBookingHours!: { [key: string]: AvailabilityData; } | Map<string, AvailabilityData>;
    let newHourlyAvailabilityList:Date[] = [];

    let args = new DailyAvailabilityArgs();
    args.serviceId = this.serviceId;
    args.employeeProfileId = this.employeeProfileId;
    args.hourlyAvailabilityCheck = true;
    args.startDate = date ? date.utc(true).toDate() : undefined;
    args.endDate = date ? date.utc(true).toDate() : undefined;

    args.preBookedTermsList = currentList.map((element: BookedTermData) => {
      return new PreBookedTermData({
        employeeProfileId: element.employeeProfileId,
        fromDate: moment(element.fromDate).utc(true).toDate(),
        serviceId: this.serviceId!
      });
    });

    if (editingMode){
      args.preBookedTermsList = args.preBookedTermsList.filter((element: PreBookedTermData) => {
        return element.fromDate?.getTime() !== this.currentDate?.clone().utc(true).toDate().getTime();
      });
    }

    if (this.reschedule) { args.editingTermId = this.rescheduleTermId; }

    await new Promise<void>((resolve) => {
      this.scheduleService.RetrieveAvailability(args).subscribe((data) => {
        if (data.content) {
          freeBookingHours = data.content;

          Object.keys(freeBookingHours).forEach((key) => {
            let bookingHours = freeBookingHours as { [key: string]: AvailabilityData };
            const availabilityDataForCurrentKey = bookingHours[key];

            // Check if availabilityDataForCurrentKey is defined before adding to the list
            if (availabilityDataForCurrentKey && availabilityDataForCurrentKey.hourlyAvailability) {
              let selectedDayParts = this.selectedDate.split('/');
              let selected = new Date(selectedDayParts[2] + '-' + selectedDayParts[1] + '-' + selectedDayParts[0] + 'T00:00:00')

              newHourlyAvailabilityList.push(...availabilityDataForCurrentKey.hourlyAvailability?.filter(x =>
                (selected.toDateString() == new Date().toDateString() && x.toTimeString() > new Date().toTimeString()) || (selected.toDateString() != new Date().toDateString())));
            }
          });

          resolve();
        } else {
          resolve();
        }
      });
    }).finally(() => {
      this.hourlyAvailabilityList = newHourlyAvailabilityList;
    });
  }

  public checkEmployeeAvailability(date: moment.Moment): boolean {
    if (this.currentDateKey in this.dateRangeAvailabilityPreloadMap) {
      const resetTimeMoment = date.startOf('day');
      const targetDate = resetTimeMoment.format('YYYY-MM-DDTHH:mm:ss[Z]');
      const today = moment();
      if (moment(date).isBefore(today, 'day')) {
        return true;
      } else {
        return !this.checkScheduleForKey(targetDate, this.dateRangeAvailabilityPreloadMap[this.currentDateKey]);
      }
    }
    return true;
  }

  public async chooseHours(date: CalendarDate, editingMode: boolean, event?: Event, forcedTermToEdit?: Date) {
    this.currentDate = moment(date.mDate).utc(true);
    this.selectedDate = moment(date.mDate).format('DD/MM/YYYY');

    if(this.firstDayOfMonth !== undefined && this.lastDayOfMonth !== undefined) {
      if(date.mDate < this.firstDayOfMonth || date.mDate > this.lastDayOfMonth.clone().subtract(1, 'days')) this.refreshCalendar();
      else {
        this.weeks.forEach((week) => { week.forEach((day) => { day.selected = false; }); });
        date.selected = true;
      }
    }

    await this.checkHoursForDate(this.currentDate, forcedTermToEdit ? [] : this.currentTermsList, editingMode || this.reschedule || this.isTrialSession).finally(() => {
      // Check if there are no available hours for the selected date and disable the element if so
      if(this.hourlyAvailabilityList.length <= 0) {
        const element = event?.target as HTMLElement;
        element.parentElement?.classList.add('calendar_disabled');
        element.parentElement?.classList.toggle('selected', false);
        return;
      }

      let selectedTermDate = moment(this.currentDate, 'yyyy-mm-dd');
      let day = selectedTermDate.date() < 10 ? '0' + selectedTermDate.date() : selectedTermDate.date();
      let month = (selectedTermDate.month() + 1) < 10 ? '0' + (selectedTermDate.month() + 1) : (selectedTermDate.month() + 1);
      let selectedTermDateString = day + "/" + month + "/" + selectedTermDate.year();

      const componentRef = this.viewContainerRef.createComponent(ChooseHourPopupComponent);

      this.editTerm = editingMode || ((this.isTrialSession) && this.selectedDate == selectedTermDateString) ? this.currentDate.clone().utc(true).toDate() : undefined

      componentRef.instance.hourlyAvailabilityList = this.hourlyAvailabilityList;
      componentRef.instance.finalSelectedDate = moment(date.mDate);
      componentRef.instance.editingTerm = this.editTerm;
      componentRef.instance.currentDateList = this.currentTermsList?.map((x: BookedTermData) => { return new Date(x.fromDate!); })!;
      componentRef.instance.reschedule = this.reschedule;
      componentRef.instance.isTrialSession = this.isTrialSession;
      componentRef.instance.dailyAvailabilityArgs = this.args;
      componentRef.instance.forceTermToEdit = editingMode && forcedTermToEdit ? moment(forcedTermToEdit).clone().utc(true).toDate() : undefined;
      componentRef.instance.closePopUp.subscribe(() => { this.toggleChooseHourPopUp(false); });
      componentRef.instance.addTermsToList.subscribe((value) => { this.addTerms.emit(this.refreshTermsList(value)); });
      componentRef.instance.updateTermsList.subscribe((value) => { this.updateTerms.emit(this.refreshTermsList(value)); });

      if(this.windowDimensions.width < this.windowDimensions.threshold_sm)
      {
        this.smallChooseHourPopup = componentRef;
        this.largeChooseHourPopup?.destroy(); this.largeChooseHourPopup = undefined; this.mobileChooseHourPopupOpened = true;

        new Promise((resolve) => setTimeout(resolve, 1)).then(() => {
          var container = document.getElementById('small-choose-hour-popup-container');
          container?.appendChild(this.smallChooseHourPopup?.location.nativeElement);
        });
      } else {
        this.largeChooseHourPopup = componentRef;
        this.smallChooseHourPopup?.destroy(); this.smallChooseHourPopup = undefined; this.mobileChooseHourPopupOpened = false;

        if(!this.initialLoad){
          this.scrollLockService.disableScroll({
            allow_touch_input_on: undefined,
            handle_extreme_overflow: false,
            animation_duration: 0,
            handle_touch_input: false,
            mobile_only_touch_prevention: false
          } as ScrollLockConfig);
          window.scroll({ top: 0, left: 0, behavior: 'auto' });
        }

        var container = document.getElementById('large-choose-hour-popup-container');
        container?.appendChild(this.largeChooseHourPopup?.location.nativeElement);
      }

      this.chooseHourPopupOpened = true;
      this.isFirstTime = false;
    });
  }

  private refreshTermsList(chosenTerms: Date[]):  BookedTermData[]{
    let termData = chosenTerms.map((x, y) => {
      return {employeeProfileId: this.employeeProfileId, fromDate: x} as BookedTermData
    })

    return this.sortData(termData);
  }

  /**
   * @description Sorts the filtered terms in ascending order
   */
  private sortData(data: TermData[]) {
    return data!.sort((a, b) => {
      return <any>new Date(a.fromDate!) - <any>new Date(b.fromDate!);
    });
  }

  public async updateSchedule(args: DailyAvailabilityArgs): Promise<void> {
    this.currentDateKey = args.startDate!.getMonth().toString() + args.startDate!.getUTCFullYear().toString();

    if (!(this.currentDateKey in this.dateRangeAvailabilityPreloadMap)) {
      // Retrieve availability for the current date range
      await new Promise<void>((resolve) => {
        if (this.employeeProfileId != undefined) {
          this.scheduleService.RetrieveAvailability(args).subscribe((data) => {
            if (data.status == UsersResultStatus.Success) {
              this.dateRangeAvailabilityPreloadMap[this.currentDateKey] = data.content!;
              this.loadedRangeAvailabilityMaps.push(moment(args.startDate));
              this.addedRangeAvailabilityKeys.push(this.currentDateKey);
            }
            resolve();
          });
        } else {
          resolve();
        }
      });
    }
  }

  private async preloadSchedule(currentArgs: DailyAvailabilityArgs, preloadAmount: number, direction: PreloadDirectionEnum, virtualStartDate?: Date, forceLoad?: boolean): Promise<void> {
    if (this.loadingRangeAvailabilityMap === false || forceLoad) {
      let args = new DailyAvailabilityArgs();
      args.employeeProfileId = currentArgs.employeeProfileId;
      args.serviceId = currentArgs.serviceId;
      args.startDate = virtualStartDate !== undefined ? virtualStartDate : currentArgs.startDate;
      args.endDate = currentArgs.endDate;
      args.hourlyAvailabilityCheck = true;
      args.hourlyAvailabilityCheckLimit = currentArgs.hourlyAvailabilityCheckLimit;
      args.editingTermId = currentArgs.editingTermId;
      args.preBookedTermsList = currentArgs.preBookedTermsList;

      this.loadingRangeAvailabilityMap= true;

      const promises: Promise<void>[] = [];

      for (let i = 0; i < preloadAmount; i++) {
        const date = direction === PreloadDirectionEnum.Forward ? moment(args.startDate).add(1, 'months') : moment(args.startDate).subtract(1, 'months');
        const dateKey = date.month().toString() + date.year().toString();

        if (!(dateKey in this.dateRangeAvailabilityPreloadMap)) {
          args.startDate = moment(date.clone().startOf('month')).toDate();
          args.endDate = moment(date.clone().endOf('month')).subtract(1, 'days').toDate();

          // Remove the oldest keys if the maximum amount of keys is reached
          if(this.loadedRangeAvailabilityMaps.length >= maximumDateRangePreloadAmount){
            const keysToRemove = this.addedRangeAvailabilityKeys.slice(0, dateRangePreloadAmount);
            keysToRemove.forEach((key) => { delete this.dateRangeAvailabilityPreloadMap[key]; });
            this.loadedRangeAvailabilityMaps = this.loadedRangeAvailabilityMaps.slice(dateRangePreloadAmount);
            this.addedRangeAvailabilityKeys = this.addedRangeAvailabilityKeys.slice(dateRangePreloadAmount);
          }

          // Retrieve availability for the current date range
          const promise = new Promise<void>((resolve) => {
            if (this.employeeProfileId != undefined) {
              this.scheduleService.RetrieveAvailability(args).subscribe((data) => {
                if (data.status == UsersResultStatus.Success) {
                  this.dateRangeAvailabilityPreloadMap[dateKey] = data.content!;
                  this.loadedRangeAvailabilityMaps.push(date);
                  this.addedRangeAvailabilityKeys.push(dateKey);
                }
                resolve();
              });
            } else {
              resolve();
            }
          });

          promises.push(promise);
        }
      }

      await Promise.all(promises).then(() => {
        this.loadingRangeAvailabilityMap = false;
      });
    }
  }

  public toggleChooseHourPopUp(state?: boolean){
    this.chooseHourPopupOpened = state !== undefined ? state : !this.chooseHourPopupOpened;
    if(!this.chooseHourPopupOpened){
       if(this.SmallChooseHourPopup !== undefined){
        this.SmallChooseHourPopup.handleClose();
        setTimeout(() => {
          if(this.smallChooseHourPopup !== undefined){
            this.smallChooseHourPopup.destroy();
            this.mobileChooseHourPopupOpened = false;
          }
        }, 50);
      } else if(this.largeChooseHourPopup !== undefined){
        this.largeChooseHourPopup.destroy();
        this.scrollLockService.enableScroll(false);
      }
    }
  }

  public findInRangeAvailabilityMap(month: moment.Moment): boolean{
    return this.loadedRangeAvailabilityMaps.find(x => x.month() === month.month() && x.year() === month.year()) !== undefined;
  }

  private clearMap(map: any) {
    Object.keys(map).forEach(key => {
    const value = map[key] as { [key: string]: AvailabilityData; };

      if (typeof value === 'object' && value !== null) {
        Object.keys(value).forEach(innerKey => {
          delete value[innerKey];
        });
      }

      if (value instanceof Map) { value.clear(); }
      delete map[key];
    });
  }

  /**
   * @description Translate day to default language
   */
  public translateDay(day: string): string {
    return this.translate.instant(`days.${day}`);
  }
}
