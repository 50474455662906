<div class="bg-transparent w-full mb-24" *ngFor="let serviceCategory of serviceCategories">
  <div class="flex text-white font-bold text-lg md:text-2xl uppercase">
    {{serviceCategory.name}}
  </div>
  <div class="flex mt-2 mb-[1.75rem]">
    <img src="../../../../assets/images/neon_middle.svg" class="h-3" alt="line"/>
  </div>
  <app-service-card [categoryId]="serviceCategory.id" [requiresMembership]="serviceCategory.requiresMembership!"></app-service-card>
</div>

<app-spinner 
  [secondaryConditionSatisfied]="loadingConditionSatisfied" 
  (functionToRetry)="retrieveAllServiceCategory()">
</app-spinner>