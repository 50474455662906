import {Injectable} from '@angular/core';
import {UsersApiClient} from "../clients/users-api/UsersApiClient";
import {
  AddBookingData,
  BookingDataUsersResult, StringUsersResult,
  TermData, TermDataPaginationDataUsersResult,
  UsersResultStatus
} from "../clients/users-api/UsersApiClient.gen";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(private userApiClient: UsersApiClient) {
  }

  addBooking(bookingData: AddBookingData): Observable<BookingDataUsersResult> {
    return this.userApiClient.addBooking(bookingData)
  }

  retrieveBookingByBookingToken(bookingToken: string): Observable<BookingDataUsersResult> {
    return this.userApiClient.retrieveBookingByBookingToken(bookingToken);
  }

  cancelBooking(bookingId: number): Observable<UsersResultStatus> {
    return this.userApiClient.cancelBooking(bookingId);
  }

  cancelTerm(termId: number): Observable<UsersResultStatus> {
    return this.userApiClient.cancelTerm(termId);
  }

  editTerm(termData: TermData): Observable<StringUsersResult> {
    return this.userApiClient.editTerm(termData);
  }

  retrieveBookingByBookingId(bookingId: number): Observable<BookingDataUsersResult> {
    return this.userApiClient.retrieveBookingById(bookingId);
  }

  retrieveTermsByUserId(userId: number): Observable<TermDataPaginationDataUsersResult> {
    return this.userApiClient.retrieveTermsByUserId(userId);
  }
}
