import {Component} from '@angular/core';
import {ServiceCategoryService} from '../../../services/service-category.service';
import {
  DailyAvailabilityArgs,
  ServiceCategoryData,
  ServiceCategoryDataPaginationData,
  UsersResultStatus,
} from 'src/app/clients/users-api/UsersApiClient.gen';

export const MAXIMUM_LOADING_TRIES: number = 3;

@Component({
  selector: 'app-service-category',
  templateUrl: './service-category.component.html',
  styleUrls: ['./service-category.component.css']
})
export class ServiceCategoryComponent {
  serviceCategories: ServiceCategoryData[] | undefined;
  result: ServiceCategoryDataPaginationData | undefined;
  args: DailyAvailabilityArgs | undefined;
  loadingConditionSatisfied: boolean = false;
  maximumLoadingTries: number = MAXIMUM_LOADING_TRIES;

  constructor(private serviceCategoryService: ServiceCategoryService) {
    this.args = new DailyAvailabilityArgs();
  }

  ngOnInit() {
    this.loadingConditionSatisfied = false;
    this.retrieveAllServiceCategory();
  }

  retrieveAllServiceCategory(): void {
    this.serviceCategoryService.retrieveAllServiceCategory(false).subscribe((data) => {
      if(data.status === UsersResultStatus.Success){
        this.result = data.content;
        this.serviceCategories = data.content?.items || [];
        this.loadingConditionSatisfied = this.serviceCategories !== undefined &&  this.serviceCategories?.length > 0;
      } else {
        if(this.maximumLoadingTries-- > 0){
          setTimeout(() => {
            this.retrieveAllServiceCategory();
          }, 50);
        }else {
          this.loadingConditionSatisfied = false;
        };
      }
    });
  }

}
