import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home-page/home.component';
import {OurServicesPageComponent} from './pages/our-services-page/our-services-page.component';
import {PricesPageComponent} from './pages/prices-page/prices-page.component';
import {ContactPageComponent} from './pages/contact-page/contact-page.component';
import {BookPageComponent} from './pages/book-pages/book-page/book-page.component';
import {ClientDataComponent} from "./components/client-data/client-data.component";
import {BookingConfirmationComponent} from "./pages/book-pages/booking-confirmation/booking-confirmation.component";
import {UserBookingOverviewComponent} from "./pages/user-booking-overview-page/user-booking-overview.component";
import {ClientProfileComponent} from "./pages/client-profile-page/client-profile.component";
import { OurProductsComponent } from './pages/our-products-pages/our-products/our-products.component';
import { HighlightedProductsComponent } from './pages/our-products-pages/highlighted-products-pages/highlighted-products-page/highlighted-products.component';
import { EmailVerificationComponent } from './pages/account-management-pages/email-verification/email-verification.component';
import { SetPasswordComponent } from './pages/account-management-pages/set-password/set-password.component';
import { OurProductsLandingComponent } from './pages/our-products-pages/our-products-landing/our-products-landing.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'reservation', component: OurServicesPageComponent},
  {path: 'prices', component: PricesPageComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'reservation/book', component: BookPageComponent},
  {path: 'client-info', component: ClientDataComponent},
  {path: 'booking-confirmation', component: BookingConfirmationComponent},
  {path: 'user-booking/:bookingToken', component: UserBookingOverviewComponent},
  {path: 'client-profile', component: ClientProfileComponent},
  {path: 'our-products-landing', component: OurProductsLandingComponent},
  { path: 'our-products/:productId/:productIndex', component: OurProductsComponent },
  {path: 'highlighted-products/:productId', component: HighlightedProductsComponent},
  {path: 'email-verification', component: EmailVerificationComponent },
  { path: 'verification-code/:id/:email/:code', component: SetPasswordComponent },
  { path: 'verification-code/:id/:email', component: SetPasswordComponent },
  {path: "**", redirectTo: "home", pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: "reload"})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
