import { Component} from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LoginRequestData, SecurityResultStatus, UserProfileData, UsersResultStatus, VerificationCodeData } from 'src/app/clients/users-api/UsersApiClient.gen';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { UserProfileDto } from 'src/app/models/UserProfileDto';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent {

  changePasswordForm!: FormGroup;
  showPasswordsDontMatchError: boolean = false;
  showEmailInput: boolean = false;
  verificationCode!: string;
  userEmail: string = ''
  verificationCodeId!: number;
  resultStatus!: SecurityResultStatus
  submittedUsername: string = ''
  showWrongCredentialsError: boolean = false;
  showInvalidUserError: boolean = false
  showSecondScreen: boolean = false
  userPassword: string = ''
  loggedInUser!: UserProfileData

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private toasterService: ToastrService,
              private userService: UserService,
              private sessionService: SessionService,
              private translate: TranslateService){

  }

  ngOnInit() {
    this.route.params.subscribe(parameter => {
      this.verificationCodeId = parameter['id'];
      this.userEmail = parameter['email'];
      this.verificationCode = parameter['code'];
    });

    this.changePasswordForm = this.formBuilder.group({
      verification_code: new FormControl('', [Validators.required,]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    })


    this.changePasswordForm.get('verification_code')?.setValue(this.verificationCode);
  }

  checkPasswords(event: any) {
      const input = event.target.value;
      this.showPasswordsDontMatchError = input != this.changePasswordForm.get('newPassword')?.value;
  }

  public async onSubmit(){
    console.log(this.changePasswordForm.valid)
    const formValues = this.changePasswordForm.value;
    console.log(formValues);

    if (this.changePasswordForm.invalid) { return; }


    this.verificationCode = formValues.verification_code;
    const newPassword = formValues.newPassword;
    console.log(newPassword);

    await this.resetPassword(this.verificationCodeId, this.verificationCode, newPassword).finally(() => {
      setTimeout(() => {
        this.router.navigate(['/home']).then(() => {
          window.location.reload();
        });
      }, 1500);
    });
  }

  private async resetPassword(key: number, resetPasswordCode: string, newPassword: string): Promise<void> {
    await new Promise<void>(async (resolve) => {
      this.userService.resetPasswordWithCode(key, resetPasswordCode, newPassword).subscribe(async (result) => {
        if (result == SecurityResultStatus.Success) {
          await this.login(this.userEmail, newPassword).finally(() => {
            resolve();
          });
        }
        else {
            this.toasterService.error(this.translate.instant('An error occurred'), '', {
              positionClass: 'toast-top-center',
              timeOut: 5000
            });
            resolve();
        }
      });
    });

  }

  sendNewLink(){
    this.showEmailInput = true;
    this.authenticationService.generateResetPasswordCode(this.userEmail).subscribe(result => {
      if (result.status == SecurityResultStatus.Success) {
        this.toasterService.success(this.translate.instant('new code sent to your email'), '', {
          positionClass: 'toast-top-center',
          timeOut: 5000
        })
      }
    })
  }

  public async login(email:string, password: string): Promise<void> {

    await new Promise<void>((resolve) => {
      let loginRequestData = {
        username: email,
        password: password
      } as LoginRequestData;

      this.submittedUsername = email;
      this.authenticationService.login(loginRequestData).subscribe(result => {
        this.resultStatus = result.status!
        if (result!.status == SecurityResultStatus.Success) {
          this.userService.retrieveUserDetailByEmail(loginRequestData.username!).subscribe(result => {
            if (result.status == UsersResultStatus.Success) {
              this.loggedInUser = result.content!;
              let userProfile = {
                username: result.content?.username,
                name: result.content?.name,
                surname: result.content?.surname,
                phone: result.content?.phone
              } as UserProfileDto
              this.sessionService.setProfile(userProfile);
              this.authenticationService.set(true);

              this.toasterService.success(this.translate.instant('Login successful'), '', {positionClass: 'toast-top-center', timeOut: 5000})
              this.authenticationService.set(true);
            }
          })

        } else {
          this.toasterService.error(this.translate.instant('An error occurred log in later'), '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
        }
      });

      resolve();
    });
  }
}
